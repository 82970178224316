import { Role } from '@/auth/utils'

export default [
  {
    path: '/deep-ancestry',
    name: 'deep-ancestry',
    component: () => import('@/views/results/deep-ancestry/dashboard/DeepAncestryDashboard.vue'),
    meta: {
      authorize: [Role.Customer],
      layout: 'deep-ancestry',
    },
    children: [
      {
        path: 'periodical',
        name: 'periodical',
        component: () => import('@/views/results/deep-ancestry/periodical/PeriodicalResult.vue'),
        meta: {
          layout: 'deep-ancestry',
          code: 'PERIODICAL',
          parentCode: 'DEEP-ANCESTRY'
        },
      },
      {
        path: 'hgfarmer',
        name: 'hgfarmer',
        component: () => import('@/views/results/deep-ancestry/hg-farmer/HgFarmerResult.vue'),
        meta: {
          layout: 'deep-ancestry',
          code: 'HG-FARMER',
          parentCode: 'DEEP-ANCESTRY'
        },
      },
      {
        path: 'unsupervised-analysis',
        name: 'unsupervised-analysis',
        component: () => import('@/views/results/deep-ancestry/unsupervised-analysis/UnsupervisedAnalysisResult.vue'),
        meta: {
          layout: 'deep-ancestry',
          code: 'UNSUPERVISED-ANALYSIS',
          parentCode: 'DEEP-ANCESTRY'
        },
      },
      {
        path: 'genetic-distances',
        name: 'genetic-distances',
        component: () => import('@/views/results/deep-ancestry/genetic-similarities/GeneticSimilaritiesResult.vue'),
        meta: {
          layout: 'deep-ancestry',
          code: 'GENETIC-DISTANCES',
          parentCode: 'DEEP-ANCESTRY'
        },
      },
      {
        path: 'pca-plots',
        name: 'pca-plots',
        component: () => import('@/views/results/deep-ancestry/pca-plots/PcaPlotResult.vue'),
        meta: {
          layout: 'deep-ancestry',
          code: 'PCA-PLOTS',
          parentCode: 'DEEP-ANCESTRY'
        },
      },
      {
        path: 'diy-tools',
        name: 'diy-tools',
        component: () => import('@/views/results/deep-ancestry/diy-tools/DiyToolsResult.vue'),
        meta: {
          layout: 'deep-ancestry',
          code: 'DIY-TOOLS',
          parentCode: 'DEEP-ANCESTRY'
        },
      },
      {
        path: 'sample-database',
        name: 'sample-database',
        component: () => import('@/views/results/deep-ancestry/sample-database/SampleDatabase.vue'),
        meta: {
          layout: 'deep-ancestry',
          code: 'SAMPLE-DATABASE',
          parentCode: 'DEEP-ANCESTRY'
        },
      },
      {
        path: 'download-coordinates',
        name: 'download-coordinates',
        component: () => import('@/views/results/deep-ancestry/download-coordinates/DownloadCoordinatesPage.vue'),
        meta: {
          layout: 'deep-ancestry',
          code: 'DOWNLOAD-COORDINATES',
          parentCode: 'DEEP-ANCESTRY'
        },
      },
    ],
  },
]
