<template>
  <div>
    <b-modal
      id="modal-center"
      ref="hg-farmer-proximity-modal"
      hide-footer
      hide-header
      scrollable
      centered
      @hidden="hiddenModal"
      size="lg"
    >
      <div class="modal-close-container">
        <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
      </div>
      <b-row v-if="dataSample">
        <b-col cols="12" xl="12" md="12">
          <h5>{{ dataSample.title }}</h5>
        </b-col>
      </b-row>
      
      <b-row v-if="dataSample" class="model-content-area cs-scroll-style">
        <div class="col-12 col-sm-12 col-md-5">
          <b-media-aside class="proximity-modal-avatar-container">
            <b-avatar
              rounded
              size="200"
              :src="dataSample.pictureUrl"
            />
          </b-media-aside>
        </div>
        <div class="col-12 col-sm-12 col-md-7 cs-scroll-style pop-detail-modal-right">
          <b-col cols="12" xl="12" md="12">
            <h5>{{ 'Description' }}</h5>
            <hr />
            <div>
              <p class="description" v-html="formattedDescription"></p>
            </div>
          </b-col>
          <b-col cols="12" xl="12" md="12" class="mt-2">
            <h5>{{ 'Reference Samples' }}</h5>
            <hr />
            <div>
              <p class="description" v-html="formattedTextHTML(dataSample.referenceSamples)"></p>
            </div>
          </b-col>
          <b-col cols="12" xl="12" md="12" class="mt-2">
            <h5>{{ 'Research Links' }}</h5>
            <hr />
            <div>
              <p v-html="dataSample.researchLinks"></p>
            </div>
          </b-col>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';

export default {
  components: {
    CloseModalIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    async initialModal() {
      this.$refs['hg-farmer-proximity-modal'].show();
      this.$store.dispatch('hgFarmerResult/fetchHgFarmerDetail', this.modalObject.item.id);
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['hg-farmer-proximity-modal'].hide();
    },

    formattedTextHTML(text) {
      return text ? text.replace(/\n/g, '<br>') : '';
    },
  },
  computed: {
    dataSample() {
      return this.$store.state.hgFarmerResult.openedHgFarmerDetail;
    },
    formattedDescription() {
      return this.dataSample ? this.dataSample.description.replace(/\n/g, '<br>') : '';
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.model-content-area {
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 5rem);
  max-height: 70vh;
  padding: 2rem !important;
}

.modal-body {
  background: #ccc !important;
  overflow: hidden;
  overflow-y: scroll !important;
}

.pop-detail-modal-right {
  max-height: 400px;
  overflow: auto;
}
span.b-avatar.badge-secondary.rounded {
    background: var(--bg-card-color);
}

@media screen and (max-width: 1079px) {
  .pop-detail-modal-right {
    height: auto;
    overflow:hidden;
    max-height: none;
  }
}
.modal-close-container {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
}
</style>
