<template>
  <div class="pca-plot-region">
    <label for="custom-dropdown" class="dropdown-label mb-1">Narrow down by region</label>

    <div class="lab-dropdown sm region mb-1">
      <div class="lab-dropdown-btn" @click="isShowDropdownParentRegion = !isShowDropdownParentRegion" ref="dropdownParentRegion">
        <span class="lab-dropdown-btn-value">
          {{ selectedRegionGroupName || 'Global' }}
        </span>
        <span class="lab-dropdown-btn-icon">
          <DropdownOneIcon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownParentRegion}">
        <ul>
          <li v-for="(item, index) in regionDatas"
              :key="index"
              @click="selectParentRegion(item, index)">
              {{ item.groupName }}
          </li>
        </ul>
      </div>
    </div>
    
    <div class="lab-dropdown sm region mb-1">
      <div class="lab-dropdown-btn" @click="isShowDropdownRegion = !isShowDropdownRegion" ref="dropdownRegion">
        <span class="lab-dropdown-btn-value small-area">
          {{ selectedRegionName || 'Global' }}
        </span>
        <span class="lab-dropdown-btn-icon">
          <DropdownOneIcon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownRegion}">
        <ul>
          <li v-for="(item, index) in selectedParent.regionList"
              :key="index"
              @click="selectRegion(item)">
              {{ item.regionName }}
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="group-dropdown-container">
      <div class="select-custom-dropdown">
        <div class="dropdown-select" @click="toggleDropdown">{{ selectedRegionName || 'Global' }}
          <DropdownOneIcon class="dropdown-icon-svg"/>
        </div>
        <div class="custom-dropdown-menu cs-scroll-style" v-if="isDropdownOpen">
          <div 
            v-for="(item, index) in regionDatas" 
            :key="index" 
            class="custom-dropdown-item" 
            @click="toggleSubItems(index)"
          >
            {{ item.groupName }}
            <div v-if="item.regionList && item.isOpen" class="subitem-container">
              <div 
                v-for="(subItem, subIndex) in item.regionList" 
                :key="subIndex" 
                class="subitem"
                @click.stop="selectRegion(subItem)"
              >
                {{ subItem.regionName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      isShowDropdownParentRegion: false,
      isShowDropdownRegion: false,
      selectedRegion: null,
      selectedParent: null,

      regionDatas: [],
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownParentRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownParentRegion = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownRegion = false;
        }
      } catch (error) {}
    },

    applySelectedRegion() {
      this.$store.commit('pcaRegion/setSelectRegion', this.selectedRegion);
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    toggleSubItems(index) {
      if (this.regionDatas[index].regionList) {
        this.$set(this.regionDatas[index], 'isOpen', !this.regionDatas[index].isOpen);
      } else {
        this.selectRegion(this.regionDatas[index].groupName);
        this.isDropdownOpen = false;
      }
    },

    selectParentRegion(item, index) {
      this.selectedParent = item;
      if (this.selectedParent.regionList) {
        this.selectRegion(this.selectedParent.regionList[0]);
      }
      else {
        this.selectRegion(this.selectedParent.groupName);
      }
      this.isShowDropdownParentRegion = false;
    },

    selectRegion(region) {
      this.selectedRegion = region;
      this.isDropdownOpen = false;
      this.applySelectedRegion();
    },

    onLoadRegions() {
      const query = {
        selectedRegionId: this.selectedResult.presetRegionId, 
        dataVersion: this.selectedResult.dataVersion,
      };

      this.$store.dispatch('pcaRegion/fetchRegionDatas', query).then(() => {
        this.regionDatas = this.$store.getters['pcaRegion/getRegionDatas'];
        this.selectedParent = this.regionDatas[0];
        this.selectedRegion = this.$store.getters['pcaRegion/getSelectedRegion'];
      });
    },
  },
  computed: {
    selectedRegionGroupName() {
      return this.selectedParent ? this.selectedParent.groupName : 'GLOBAL';
    },
    selectedRegionName() {
      return this.selectedRegion ? this.selectedRegion.regionName : '';
    },
    selectedResult() {
      return this.$store.state.daResult.selectedResult;
    },
  },
  watch: {
    selectedResult(newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.onLoadRegions();
      }
    }
  },
  mounted() {
    this.onLoadRegions();
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>

.group-dropdown-container {

  padding: 0;
  width: 100%;

  svg {
    height: 10px;
  }
  .dropdown-icon-svg {
    position: absolute;
    right: 10px;
  }


  .dropdown-label {
    display: block;
    font-size: 0.95rem;
    margin-bottom: 10px;
    color: var(--bg-card-font-color);
    font-weight: bold;
  }

  .select-custom-dropdown {
    width: 100%;
    position: relative;
    font-family: Arial, sans-serif;

    .dropdown-select {
      position: relative;
      width: 100%;
      padding: 10px;
      font-size: 0.95rem;
      border: 2px solid #ddd;
      border-radius: 5px;
      cursor: pointer;
      display: flex; // Enables flexbox for vertical alignment
      align-items: center; // Vertically centers the text and arrow
      justify-content: space-between; // Pushes arrow to the far right

      &::after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background-size: contain;
      }
    }

    .custom-dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: var(--bg-card-color);
      border: 1px solid var(--lab-dropdown-border-color);
      border-radius: 5px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      max-height: 250px;
      overflow-y: auto;
      z-index: 1001;
    }

    .custom-dropdown-item {
      padding: 10px;
      font-size: 0.95rem;
      font-size: bold;
      cursor: pointer;
      &:hover {
        background-color: var(--bg-card-hover-color);
      }

      .subitem-container {
        .subitem {
          padding-left: 20px;
          padding-top: 8px;
          padding-bottom: 8px;
          font-size: 0.85rem;
          border-radius: 5px;
          color: var(--black-40);
          &:hover {
            background-color: #e9e9e988;
          }
        }
      }
    }
  }
}
.dark-layout {
  .dropdown-select {
    border: 2px solid #1bddcc99!important;
  }
}

.small-area {
  font-size: 1rem!important;
}

</style>
