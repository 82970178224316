
<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3346 8.99984C17.3346 4.39746 13.6037 0.666504 9.0013 0.666504C4.39893 0.666504 0.667969 4.39746 0.667969 8.99984C0.667969 13.6022 4.39893 17.3332 9.0013 17.3332C13.6037 17.3332 17.3346 13.6022 17.3346 8.99984ZM1.91797 8.99984C1.91797 5.08782 5.08929 1.9165 9.0013 1.9165C12.9133 1.9165 16.0846 5.08782 16.0846 8.99984C16.0846 12.9119 12.9133 16.0832 9.0013 16.0832C5.08929 16.0832 1.91797 12.9119 1.91797 8.99984ZM8.16797 8.99984C8.16797 8.5396 8.54107 8.1665 9.0013 8.1665C9.46154 8.1665 9.83463 8.5396 9.83463 8.99984V12.3332C9.83463 12.7934 9.46154 13.1665 9.0013 13.1665C8.54107 13.1665 8.16797 12.7934 8.16797 12.3332V8.99984ZM9.0013 6.49984C9.46154 6.49984 9.83463 6.12674 9.83463 5.6665C9.83463 5.20627 9.46154 4.83317 9.0013 4.83317C8.54107 4.83317 8.16797 5.20627 8.16797 5.6665C8.16797 6.12674 8.54107 6.49984 9.0013 6.49984Z" fill="#595959"/>
  </svg>
</template>
  
<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 18px;
  path {
    fill: var(--lab-info-color)!important;
  }
}  
</style>
