<template>
  <div class="col-sm-12">
    <div class="col-sm-12">
      <div class="result-list" v-for="item in calculatedResults" :key="item.name">
        <div class="result-list-header">
          <div class="target-header">
            <div class="target-name">
              Target: {{ replaceName(item.name) }}
            </div>
            <div>
              Genetic Fit: <span :style="{ color: CalculateModernPopColor(item.result), 'margin-left': '5px' }">{{  item.result }} ({{AncestryFitStatus(item.result)}})</span>
            </div>
          </div>
        </div>

        <template v-for="resulItem in item.outPuts">
          <div class="result-item" :key="resulItem.resultsTable">
            <div class="result-label">{{replaceName(resulItem.resultsTable)}}</div>
            <div class="current-result">{{resulItem.currentResult.toFixed(1)}}%</div>
          </div>
          
          <template v-for="subItem in resulItem.subResult">
            <div class="result-item sub-1" :key="subItem.resultsTable">
              <div class="result-label">{{ replaceName(subItem.resultsTable)}}</div>
              <div class="current-result">{{subItem.currentResult.toFixed(1)}}%</div>
            </div>

            <template v-for="subItem2 in subItem.subResult">
              <div class="result-item sub-2" :key="subItem2.resultsTable">
                <div class="result-label">{{replaceName(subItem2.resultsTable) }}</div>
                <div class="current-result">{{subItem2.currentResult.toFixed(1)}}%</div>
              </div>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import { CalculateModernPopColor } from '@/common/custom-service/dna-source/utils'
import diyCalculateService from '../services/diyCalculateService';

export default {
  components: {
  },
  data() {
    return {
      isDropDown: false,
      selectedPopNumber: 0,
      limitTypes: [
        { value: 0, name: 'No limit' },
        { value: 3, name: '3' },
        { value: 4, name: '4' },
        { value: 5, name: '5' },
      ]
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownReport;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isDropDown = false;
        }
      } catch (error) {}
    },
    replaceName(name) {
      return name.replaceAll('*', ' ').replaceAll(':', ': ').replaceAll('_scaled', '');
    },

    CalculateModernPopColor,
    AncestryFitStatus(value) {
      if(value > 0 && value <= 1) {
        return 'Very Good';
      }
      if(value > 1 && value <= 3) {
        return 'Good';
      }
      if(value > 3 && value <= 5) {
        return 'Moderate';
      }
      return 'Bad';
    },
    calculate() {
      diyCalculateService.runCalculate(this.$store.state.diySourceData.populationNumber);
    },
    goToTop() {
      const element = document.querySelector(".diy-tools-container");
      const topy = element.offsetTop;
      element.scrollTo({
        top: topy,
        behavior: 'smooth',
      });
    },
  },
  computed: {
    calculatedResults() {
      return this.$store.state.diySourceData.calculatedResults;
    },
  },
  mounted() {
    this.calculate();
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>
<style lang="scss" scoped>
.result-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 40px;
  align-items: end;
  font-size: 11px;
}
// .col-sm-12 {
//     padding-right: 0rem!important;
//     padding-left: 0rem!important;
//   }
.result-list-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom:2pt solid var(--lab-hr-color);;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: var(--lab-diy-header-font-color);
}

.target-label {
  width: 50%;
}

.genetic-fit-label {
  width: 50%;
  display: flex;
  justify-content: end;
}

.result-item {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.sub-1 {
  width: 95%;
  color: #999999;
}
.sub-2 {
  width: 90%;
  color: #999999;
}

.result-label {
  width: 70%;
}

.current-result {
  width: 30%;
  display: flex;
  justify-content: end;
}



.view-container {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.vertical-border {
  border-left:1px solid #8e8e8e;
  height:auto;
  margin-top: 30px;
}
.left-side {
  padding: 25px 10px;
  width: 50%;
}
.right-side {
  padding: 25px 10px;
  width: 50%;
}

.calculate-tools {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>