<template>
  <div class="lab-dropdown-container">
    <div class="lab-dropdown sm region">
      <div class="lab-dropdown-btn" @click="isShowDropdownReport = !isShowDropdownReport" ref="dropdownReport">
        <span class="lab-dropdown-btn-value">
          {{ $store.state.mixedModePeriod.selectedReport }}
        </span>
        <span class="lab-dropdown-btn-icon">
          <DropdownOneIcon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownReport}">
        <ul>
          <li v-for="report in reports"
              :key="report"
              @click="selectReport(report)">
              {{ report }}
          </li>
        </ul>
      </div>
    </div>
    <div class="lab-dropdown sm region custom-margin-left">
      <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownMode">
        <span class="lab-dropdown-btn-value">
          {{ $store.state.mixedModePeriod.selectedMode }}
        </span>
        <span class="lab-dropdown-btn-icon">
          <DropdownOneIcon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
        <ul>
          <li v-for="mode in mods"
              :key="mode"
              @click="$store.state.mixedModePeriod.selectedMode = mode">
              {{ mode }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon
  },
  props: {
  },
  data() {
    return {
      isShowDropdownReport: false,
      isShowDropdown: false,

      reports: ['Ancient', 'Modern'],
      mods: ['Two Way', 'Three Way'],
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownReport;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownReport = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownMode;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },

    selectReport(report) {
      this.$store.state.mixedModePeriod.selectedReport = report;
    },
  },
  mounted() {
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>
  
<style lang="scss" scoped>
.select-region-modal-body {
  position: relative;
}
.lab-dropdown-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem !important;
}
.lab-dropdown.region {
  width: 50%;
}
.custom-margin-left {
  margin-left: 1.5rem !important;
}
@media only screen and (max-width: 1079px) {
  .select-region-modal-body {
    display: block;
  }
  .lab-dropdown-container {
    flex-direction: column!important;
  }
  .lab-dropdown.region {
    width: 100%!important;
    margin-bottom:1rem;
  }
  .custom-margin-left {
    margin-left: 0rem !important;
  }
}

.lab-dropdown .lab-dropdown-btn {
    padding: 10px 15px !important;
}
</style>
  