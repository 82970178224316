function isScrollbarVisible(element) {
  const { scrollHeight, clientHeight } = element;
  const style = window.getComputedStyle(element);
  const overflowY = style.overflowY;

  return (
    scrollHeight > clientHeight 
    && (overflowY === 'auto' || overflowY === 'scroll')
  );
}

const getDefaultState = () => ({
  checkIfScrolledToBottom: (element, parentElement) => {
    if (!element) {
      return false;
    }

    // console.log(`element.clientHeight ${element.clientHeight}`);
    // console.log(`element.scrollTop ${element.scrollTop}`);
    // console.log(`element.scrollHeight ${element.scrollHeight}`);
    // console.log(`element.height ${element.offsetHeight}`);

    if (parentElement) {
      // Check if scrolled to the bottom
      if (element.scrollTop + element.clientHeight >= element.scrollHeight - 10) {
        parentElement.classList.add('no-scroll');
      } else {
        parentElement.classList.remove('no-scroll');
      }
      return true;
    }

    if (isScrollbarVisible(element) === false) {
      element.classList.add('no-scroll');
      return true;
    }
    
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 10) {
      element.classList.add('no-scroll');
    } else {
      element.classList.remove('no-scroll');
    }
    
    return true;
  }
});



const state = getDefaultState()

const getters = {
}

const mutations = {
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
