<template>
  <div>
    <div class="result-list-container cs-scroll-style">
      <template v-if="uiResults && !$store.getters.getLoader">
        
        <b-alert variant="warning" show>
          <div class="alert-body">
            <span class="mr-1 mt-1"><strong>Caution:</strong>  These results are subject to change</span>
          </div>
        </b-alert>

        <div class="pop-view" v-if="uiResults">
          <div v-for="itemAncestry in uiResults.outPuts"
              :key="itemAncestry.resultsTable" 
              @click="onOpenDetailPage(itemAncestry)" class="pop-view-item">
            <div class="d-flex">
              <b-media-aside class="mr-75">
                <b-avatar
                  rounded
                  size="55"
                  :src="itemAncestry.logoUrl"
                  style="background-color: transparent;"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="mb-0">
                  {{ itemAncestry.resultsTable.replaceAll('*', ' ') }}
                </h6>
              </b-media-body>
            </div>
            <div class="d-flex align-items-center lab-p-8">
              <span class="mr-75 percent-txt" :style="{'color': skin === 'light' ? '#595959' : itemAncestry.color }"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
              <vue-apex-charts
                type="radialBar"
                height="40"
                width="40"
                :options="onGetChartOption(itemAncestry.color)"
                :series="[itemAncestry.currentResult.toFixed(1)]"
              />
            </div>
          </div>
        </div>
      </template>
      <SpinnerContainer v-else />
    </div>
    
    <HgFarmerProximityModal v-if="hgFarmerDetailModal.showModal" :modalObject="hgFarmerDetailModal" />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import HgFarmerProximityModal from '../modals/HgFarmerProximityModal.vue';

export default {
  components: {
    VueApexCharts,
    HgFarmerProximityModal,
  },
  directives: {
  },
  data() {
    return {
      hgFarmerDetailModal: {
        showModal: false,
        item: null,
      },
    }
  },
  methods: {
    /* eslint-disable */
    onGetChartOption(color) {
      const barColor = color ? color : '#00ADB5';
      return {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [barColor],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: this.ddAlpha(color, 0.1),
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      };
    },
    ddAlpha(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
    fitTitle(value) {
      if(value > 0 && value <= 3) {
        return 'Very close';
      }
      if(value > 3 && value <= 5) {
        return 'Close';
      }
      if(value > 5 && value <= 6) {
        return 'Moderate';
      }
      return 'Distant';
    },

    onOpenDetailPage(item) {
      this.hgFarmerDetailModal.item = item;
      this.hgFarmerDetailModal.showModal = true;
    },
  },
  computed: {
    uiResults() {
      return this.$store.getters['hgFarmerResult/getUiResults'];
    },
    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
.result-list-container {
  padding: 28px;
  flex: 1;
  overflow-y: auto;

  .pop-view-item.selected {
    // box-shadow: 0px 0px 10px 0px var(--bg-card-shadow);
    border: 1px solid #1BBFCC;
  }
}
</style>
