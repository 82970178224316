<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="origin-breakdown-info-modal"
      hide-footer
      hide-header
      scrollable
      @hidden="hiddenModal"
      size="md"
    >
     <div class="modal-close-container">
      <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
     </div>
     <div class="info-modal-container">
        <div class="info-modal-header">
          <h5>What is the Ancient Origins Breakdown?</h5>
        </div>
        <p class="description third-info-content">
          The Ancient Origins Breakdown analyzes your ancient ethnic and geographic roots based on population genetics and historical context. 
          The report reveals new insights into the evolution of your ethnicity through different eras in history by indicating the ancient 
          populations from which you descend.
        </p>
     </div>
     <div class="model-info-bottom">
      <div></div>
      <button class="lab-btn info-modal-btn single-btn" @click="hiddenModal()">Got it</button>
     </div>
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';

export default {
  components: {
    CloseModalIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      sampleInfo: null,
    }
  },
  methods: {
    initialModal() {
      this.$refs['origin-breakdown-info-modal'].show();
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['origin-breakdown-info-modal'].hide();
    },
  },
  computed: {
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>

</style>
