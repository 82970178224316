<template>
    <!-- select 2 demo -->
    <div>
      <b-modal
        ref="unsupervised-analysis-info-modal"
        hide-footer
        hide-header
        scrollable
        @hidden="hiddenModal"
        size="md"
      >
       <div class="modal-close-container">
        <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
       </div>
       <div class="info-modal-container">
          <div class="info-modal-header">
            <h5>Unsupervised Analysis</h5>
          </div>
          <p class="description">
            Unsupervised Analysis info modal...
          </p>
       </div>
       <div class="model-info-bottom">
        <div></div>
        <button class="lab-btn info-modal-btn single-btn" @click="hiddenModal()">Got it</button>
       </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';
  
  export default {
    components: {
      CloseModalIcon,
    },
    props: {
      modalObject: {
        type: Object,
      },
      callback: {
        type: Function,
        default() {
          return {}
        },
      },
    },
    data() {
      return {
        sampleInfo: null,
      }
    },
    methods: {
      initialModal() {
        this.$refs['unsupervised-analysis-info-modal'].show();
      },
      hiddenModal() {
        /* eslint-disable */
        this.modalObject.showModal = false;
        this.$refs['unsupervised-analysis-info-modal'].hide();
      },
    },
    computed: {
    },
    mounted() {
      this.initialModal()
    },
  };
  </script>
  
  <style lang="scss" scoped>

  </style>
  