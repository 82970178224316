<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="origin-breakdown-info-modal"
      hide-footer
      hide-header
      scrollable
      @hidden="hiddenModal"
      size="md"
    >
     <div class="modal-close-container">
      <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
     </div>
     <div class="info-modal-container">
        <div class="info-modal-header">
          <h5>PCA Plots</h5>
        </div>
        <p class="description">
          Principal Component Analysis (PCA) plots provide a visualization of your genetic proximity to various ancient populations. PCA is a statistical method used to find patterns in genetic variation across different regions and ethnic backgrounds.
        </p>
        <p class="description">
          Each point on the plot represents a different ancient population or individual, clustered based on genetic similarities. 
          If your DNA aligns closely with certain populations, you’ll see your point positioned near those groups on the plot, indicating historical connections.
        </p>
        <p class="description">
          Use the dropdown to customize the analysis by selecting different regions and populations, so you can focus on areas or groups most relevant to your origins.
        </p>
     </div>
     <div class="model-info-bottom">
      <div></div>
      <button class="lab-btn info-modal-btn single-btn" @click="hiddenModal()">Got it</button>
     </div>
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';

export default {
  components: {
    CloseModalIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      sampleInfo: null,
    }
  },
  methods: {
    initialModal() {
      // communicationService.sendTriggerToParent({ key: dataKeys.MODAL_STATUS, value: true });
      this.$refs['origin-breakdown-info-modal'].show();
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['origin-breakdown-info-modal'].hide();
      // communicationService.sendTriggerToParent({ key: dataKeys.MODAL_STATUS, value: false });
    },
  },
  computed: {
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>

</style>
