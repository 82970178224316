/* eslint-disable */
const getDefaultState = () => {
  return {
    selectedReport: 'Ancient',
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
