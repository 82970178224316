<template>
  <div class="lab-dropdown mr-1 kit-dropdown"  v-if="resultList.length">
    <div class="lab-dropdown-btn reduce-radius" @click="isShowDropdownMenu = !isShowDropdownMenu" ref="dropdown">
      <span class="lab-dropdown-btn-value">{{ selectedResult.resultTitle }}</span>
      <span class="lab-dropdown-btn-icon">
        <dropdown-icon />
      </span>
    </div>
    <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownMenu}">
      <ul>
        <li v-for="(resultItem, index)  in resultList"
            :key="resultItem.id"
            @click="onChangeResultItem(resultItem, index)">
          {{ resultItem.resultTitle }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DropdownIcon from '@/layouts/icons/DropdownIcon.vue';

export default {
  components: {
    DropdownIcon,
  },
  data() {
    return {
      isShowDropdownMenu: false,
    }
  },
  methods: {
    onChangeResultItem(item) {
      this.$store.state.daResult.selectedResult = item;
    },

    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdown;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownMenu = false;
        }
      } catch (error) {}
    },
  },
  computed: {
    selectedResult() {
      return this.$store.state.daResult.selectedResult;
    },
    resultList() {
      return this.$store.state.daResult.resultList;
    },
  },
  watch: {
    selectedResult(newVal, oldVal) {
      if(newVal.id !== oldVal.id) {
        this.$store.commit('region/setSelectRegion', newVal.presetRegionId);
        this.$store.commit('period/setInitialSelectedPeriod', newVal.dataVersion);
        this.$store.commit('mixedModePeriod/setInitialSelectedPeriod', newVal.dataVersion);
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('region/fetchRegionGroupList');
    await this.$store.dispatch('region/fetchRegionList');
    await this.$store.dispatch('period/fetchPeriods');
    await this.$store.dispatch('daResult/fetchResultList');
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  }
}
</script>

<style lang="scss" scoped>
.reduce-radius {
  border-radius: 4px;
}
.kit-dropdown {
  .lab-dropdown-btn-value {
    padding: 5px 15px 5px 0;
  }
}
.dark-layout {
  .reduce-radius {
    background:#333333;
  }
}
</style>
