<template>
  <div class="mb-2">
    <div id="pcaPlotContainer"></div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
};
</script>

<style>
#pcaPlotContainer {
  height: calc(100vh - 230px)!important;
  box-sizing: border-box !important;
}
@media screen and (max-width: 1079px) {
  .pcaPlotContainer {
    height: calc(100vh - 60px)!important;
    overflow: hidden;
    overflow-y: auto;
  }
}

</style>
