import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'

const state = {
  tempProducts: [],
  products: [],
}

const getters = {
  getProducts(state) {
    return state.products
  },
}

const mutations = {
  setProducts(state, products) {
    state.products = products;
    state.tempProducts = products;
  },
  setProductTypeFilter(state, type) {
    if (type === 0) {
      state.products = state.tempProducts;
    } else {
      state.products = state.tempProducts.filter(x => x.productType === type);
    }
  },
}

const actions = {
  fetchProducts(context, queryParams) {
    /* eslint-disable */
    if(context.state.products.length > 0) {
      return;
    }

    return ApiService.getSilent('products', queryParams, this)
      .then(response => {
        if (response.statusCode === 200) {
          context.commit('setProducts', response.result)
        } else {
          AlertService.error(response.message)
        }
      })
  },

  fetchProduct(context, productCode) {
    return new Promise((resolve, reject) => {
      ApiService.get(`products/code/${productCode}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },

  fetchProductById(context, productId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`products/${productId}`, {}, this)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
