<template>
  <div class="mobile-result-list-container">
    <div class="model-view-item">
      <div class="model-view-item-header d-flex align-items-center">
        <div id="menu-area" class="menu-content w-100">
          
        </div>
      </div>
      <div>
      </div>
      <div class="model-view-item-content d-flex justify-content-space-between">
        <MobileDiyToolsTargetView v-if="$store.state.diySourceData.selectedTab === 'TARGET'" />
        <MobileDiyToolsSourceView v-if="$store.state.diySourceData.selectedTab === 'SOURCE'" />
        <MobileDiyToolsResultsView v-if="$store.state.diySourceData.selectedTab === 'RESULT'" />
      </div>
      <div class="model-view-item-footer d-flex justify-content-space-between">
        
      </div>
    </div>
  </div>
</template>


<script>
import diySourceDataService from '../services/diySourceDataService';
import MobileDiyToolsResultsView from './MobileDiyToolsResultsView.vue';
import MobileDiyToolsSourceView from './MobileDiyToolsSourceView.vue';
import MobileDiyToolsTargetView from './MobileDiyToolsTargetView.vue';

export default {
  components: {
    MobileDiyToolsSourceView,
    MobileDiyToolsTargetView,
    MobileDiyToolsResultsView
  },
  data() {
    return {
      tabs: [
        { name: 'Target', Id: 'TARGET' },
        { name: 'Source', Id: 'SOURCE' },
        { name: 'Results', Id: 'RESULT' },
      ],
      selectedTab: "TARGET",
    }
  },
  methods: {
    selectTab(Id) {
      this.$store.state.diySourceData.selectedTab = Id;
    },
    nextTab() {
      if (this.$store.state.diySourceData.selectedTab === 'TARGET') {
        this.$store.state.diySourceData.selectedTab = "SOURCE";
      } else if (this.$store.state.diySourceData.selectedTab === 'SOURCE') {
        this.$store.state.diySourceData.selectedTab = "RESULT";
      }
    },
    prevTab() {
      if (this.$store.state.diySourceData.selectedTab === 'SOURCE') {
        this.$store.state.diySourceData.selectedTab = "TARGET";
      } else if (this.$store.state.diySourceData.selectedTab === 'RESULT') {
        this.$store.state.diySourceData.selectedTab = "SOURCE";
      }
    }
  },
  
  async mounted() {
    this.selectTab(this.selectedTab);
    await diySourceDataService.loadSourceDatas();
  }
}
</script>


<style lang="scss" scoped>
.progress-bar {
  transition : width 0.5s linear!important;
}
.mobile-result-list-container {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
  // margin-top: 1.5rem;
  font-size: 0.9rem;
  @media screen and (max-width: 1079px) {
    overflow-y: hidden;
    overflow-x: hidden !important;
  }

  .model-view-header {
    display: flex;
    flex-direction: row;
    width: 75%;
  }
  .model-latest-version {
    width: 50%;
  }
  .model-genetic-fit {
    width: 50%;
  }
  .text-end {
    float:right;
  }
  
  .model-view-item {
    width: 100%;
    background-color: var(--bg-card-color);
    border: var(--lab-card-border);
    box-shadow: var(--lab-card-shadow);
    // border-radius: 10pt;
    margin-bottom: 15px;
    flex-direction: column;
    padding: 20px 20px;
    padding-bottom: 70px;
  }
  .model-view-item-donut {
    width: 45%;
  }
  .model-view-item-header {
    width: 100%;
    justify-content: space-between;
  }
  .model-view-item-content {
    width: 100%;
    height: 90%;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
  }
  .model-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .model-view-progress {
    width: 90%;
    margin-right: 10px;
  }
  .model-view-percentage {
    font-size: 0.9em;
    font-weight: 600;
  }
  .btn-outline-light {
    border: 1px solid #ddd !important;
    background-color: transparent;
  }
  .model-view .model-view-item {
    height: 100%;
    display: flex;
    justify-content: space-between;
    transition: all 0.25s linear;
    }
    .model-item-row-container {
        width: 75%;
        display: flex;
        flex-direction: column;
    }
}
.menu-items {
  position: relative;
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0; 
  padding: 0;
  border-bottom: 2pt solid var(--lab-hr-color);
  flex-direction: row;
  justify-content: space-between;

  .menu-item-li {
    flex: 0 0 auto;
    width: 30%;
    padding: 10px 0px;
    margin: 0 5px;
    color: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
  }

  .menu-link {
    position: relative;
    display: flex;
    align-items: center; 
    gap: 8px;
    margin-right: 10px;
    text-decoration: none;
    color: var(--lab-header-menu-color);
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    display: block;
    &:hover,
    &.active {
      color: var(--lab-main-color);
    }
    &:hover::after,
    &.active::after {
      width: 110%;
      left: -5%; 
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 0;
      height: 4px;
      border-bottom: 2pt solid var(--lab-main-color);
      height: 14px;
      background-color: transparent; 
      transition: width 0.3s ease, opacity 0.3s ease;
      opacity: 0; 
    }
  }
}
</style>