import store from '@/store';
import ApiService from '@/common/api.service';
import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService'
import { ParseHgFarmerResult } from './utils'

const DataWatcherService = {
  isRunning: false,
  unwatchers: [],

  init() {
    this.watchSelectedResultState();
    this.runHgFarmerResult();
  },

  watchSelectedResultState() {
    const unwatch = store.watch(
      (state) => state.daResult.selectedResult,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runHgFarmerResult();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  async runHgFarmerResult() {
    try {
      const resultId = store.state.daResult.selectedResult.id;
      if (!resultId) {
        return;
      }
      
      if (this.isRunning) {
        return;
      }
      this.isRunning = true;

      const tagetResolveSource = CalculateFMCService.codeDataToArray(store.state.daResult.selectedResult.targetCoordinate);
      if (tagetResolveSource.isSuccess) {
        CalculateFMCService.onSetTarget(tagetResolveSource.result);
      }

      const dataVersion = store.getters['daResult/dataVersion'];
      const sourceDataResult = await store.dispatch('hgFarmerSourceData/fetchHgFarmerSourceData', { dataVersion, resultId });
      if (!sourceDataResult) {
        this.isRunning = false;
        return;
      }
      
      const resolveSource = CalculateFMCService.codeDataToArray(sourceDataResult.sourceData);
      if (resolveSource.isSuccess) {
        CalculateFMCService.onSetSource(resolveSource.result);
      }

      const calculatedResult = await this.getCurrentResult(resultId, sourceDataResult);
      
      const parsedCalculatedResult = ParseHgFarmerResult(calculatedResult);
  
      store.commit('hgFarmerResult/setResults', parsedCalculatedResult);
      
      const groupedSourceCodes = parsedCalculatedResult.outPuts.map(item => item.resultsTable);
      const uiDetailDataResult = await store.dispatch('hgFarmerResult/fetchHgFarmerDetails', { groupedSourceCodes, dataVersion });
      if (!uiDetailDataResult) {
        this.isRunning = false;
        return;
      }
      
      uiDetailDataResult.forEach(geoItem => {
        /* eslint-disable */
        let isFoundData = false;
        geoItem.title = 'Map Title Not Found';
        parsedCalculatedResult.outPuts.forEach(resultItem => {
          if (resultItem.resultsTable === geoItem.sourceCode) {
            resultItem.color = geoItem.color;
            resultItem.logoUrl = geoItem.logoUrl;
            resultItem.id = geoItem.id;
            resultItem.sourceCode = geoItem.sourceCode;

            geoItem.title = resultItem.resultsTable.replaceAll('*', ' ') + ' : ' + resultItem.currentResult.toFixed(1);
            geoItem.name = resultItem.resultsTable.replaceAll('*', ' ');
            geoItem.ancestryValue = resultItem.currentResult.toFixed(1);

            isFoundData = true;
          }
        });
        
        if (isFoundData === false) {
          geoItem.title = geoItem.sourceCode.replaceAll('*', ' ') + ' : ' + 0;
          geoItem.name = geoItem.sourceCode.replaceAll('*', ' ');
          geoItem.ancestryValue = 0;
          geoItem.color = '#b8b8b8';
        }
      });
      
      store.commit('hgFarmerResult/setUiResults', parsedCalculatedResult);
      store.commit('hgFarmerResult/setGeoJson', uiDetailDataResult);

      if (parsedCalculatedResult.outPuts[0]) {
        store.dispatch('hgFarmerResult/fetchHgFarmerDetail', parsedCalculatedResult.outPuts[0].id);
      }
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }
    this.isRunning = false;
  },

  async getCurrentResult(resultId, sourceDataResult) {
    return CalculateFMCService.runABAlgorithm();
    // if (sourceDataResult.calculatedResult === null || sourceDataResult.calculatedResult === '[]') {
    //   const calculatedResult = CalculateFMCService.runABAlgorithm();
      
    //   const saveResultQuery = {
    //     calculatedResult: JSON.stringify(calculatedResult),
    //     orderResultId: resultId,
    //   };

    //   ApiService.postSilent('result/farmerhgs', saveResultQuery, this);

    //   return calculatedResult;
    // } else {
    //   return JSON.parse(sourceDataResult.calculatedResult);
    // }
  },

  cleanup() {
    this.unwatchers.forEach(unwatch => unwatch());
    this.unwatchers = [];
    
    this.isRunning = false;
  },
};

export default DataWatcherService;
