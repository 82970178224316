export const ParsePeriodicalResult = (result, allSourceData = null) => {
  const state = {
    originalResults: null,
    groupedResults: null,
  }

  state.originalResults = JSON.parse(JSON.stringify(result));
  const tempRegionResults = JSON.parse(JSON.stringify(result));
  
  tempRegionResults.outPuts = [];
  result.outPuts.forEach(value => {
    const splitValue = value.resultsTable.split(':');
    if (splitValue.length > 0) {
      const findValue = tempRegionResults.outPuts.find(x => x.resultsTable === splitValue[0]);
      if (findValue) {
        findValue.currentResult += value.currentResult;
      } else {
        value.resultsTable = splitValue[0];
        tempRegionResults.outPuts.push(value);
      }
    }
  });
  tempRegionResults.outPuts.sort((a, b) => b.currentResult - a.currentResult);
  state.groupedResults = tempRegionResults;

  if (allSourceData) {
    const sourceCodeList = allSourceData.map(item => item[0].split(':')[0]);
    sourceCodeList.forEach(value => {
      if (state.groupedResults.outPuts.some(x => x.resultsTable === value) === false) {
        state.groupedResults.outPuts.push({ resultsTable: value, currentResult: 0 });
      }
    });
  }
  
  return state;
}