
<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.90678 3.90996C4.66344 3.72662 4.31608 3.74424 4.09257 3.96449C3.8467 4.20677 3.84379 4.60248 4.08607 4.84835L9.12254 9.95944L4.08607 15.0705C3.84379 15.3164 3.8467 15.7121 4.09257 15.9544C4.31608 16.1746 4.66344 16.1923 4.90678 16.0089L4.97643 15.9479L10 10.8499L15.0236 15.9479L15.0932 16.0089C15.3366 16.1923 15.6839 16.1746 15.9074 15.9544C16.1533 15.7121 16.1562 15.3164 15.9139 15.0705L10.8775 9.95944L15.9139 4.84835C16.1562 4.60248 16.1533 4.20677 15.9074 3.96449C15.6839 3.74424 15.3366 3.72662 15.0932 3.90996L15.0236 3.97099L10 9.06898L4.97643 3.97099L4.90678 3.90996Z" fill="#999999"/>
</svg>

</template>
    
<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>
  
  <style lang="scss" scoped>
  svg {
    height: 18px;
  }  
  </style>
    
    