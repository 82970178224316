<template>
  <div class="lab-dropdown sm region">
    <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownPersonNumber">
      <span class="lab-dropdown-btn-value">
        {{ productQuantity }}
      </span>
      <span class="lab-dropdown-btn-icon">
        <DropdownOneIcon />
      </span>
    </div>
    <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
      <ul v-if="productData">
        <li v-for="productItem in productData.productPrices"
            :key="productItem.id"
            @click="onChangeQuantity(productItem.productCount)">
            {{ productItem.productCount }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon,
  },
  props: {
  },
  data() {
    return {
      isShowDropdown: false,
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownPersonNumber;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },

    onChangeQuantity(value) {
      const findPrice = this.productData.productPrices.find(x => x.productCount === value);
      if (findPrice) {
        const subTotal = findPrice.disCountedPrice.toString().replace('.', ',');
        this.$store.commit('deepACheckout/setCreateOrderData', { key: 'subtotal', value: subTotal });
        this.$store.commit('deepACheckout/setCreateOrderData', { key: 'discountSubtotal', value: null });
        this.$store.commit('deepACheckout/setCreateOrderData', { key: 'promotionDetailGuid', value: null });
        this.$store.commit('deepACheckout/changeKitNumber', value);
      }
    },
  },
  computed: {
    productData() {
      return this.$store.getters['deepACheckout/getProductData'];
    },
    productQuantity() {
      return this.$store.getters['deepACheckout/getCreateOrderData'].productQuantity;
    },
  },
  watch: {
    productData(newValue, oldValue) {
      this.onChangeQuantity(this.productQuantity);
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
  mounted() {
    this.onChangeQuantity(this.productQuantity);
  },
}
</script>

<style lang="scss" scoped>
.lab-dropdown {
  width: 70px;
  .lab-dropdown-btn {
    padding: 0.2rem 0.3rem;
  }
  .lab-dropdown-btn-icon {
    border-left: 2px solid var(--lab-coronation);;
  }
  .lab-dropdown-content {
    width: 100px;
  }
}
</style>
