import store from '@/store';
import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService'
import { ParseHgFarmerResult } from './utils'

const DataWatcherService = {
  isRunning: false,
  unwatchers: [],

  init() {
    this.watchAncientSampleDetail();
  },

  watchAncientSampleDetail() {
    const unwatchAncient = store.watch(
      (state) => state.ancientDatabase.sampleDetail,
      async (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue) {
            this.clearCaculated();
            const sourceDataResult = await store.dispatch('ancientProxSourceData/fetchAncientProxSourceData', 'D-02');
            if (!sourceDataResult) {
              return;
            }
            await this.setTargetSourceDataAndRun(sourceDataResult.sourceData, newValue.sourceCodes);
            await this.runSampleDistance();
            await this.runHgFarmerResult();
          }
        }
      }
    );

    const unwatchModern = store.watch(
      (state) => state.modernDatabase.sampleDetail,
      async (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue) {
            this.clearCaculated();
            const sourceDataResult = await store.dispatch('modernProxSourceData/fetchModernProxSourceData', 'D-02');
            if (!sourceDataResult) {
              return;
            }
            await this.setTargetSourceDataAndRun(sourceDataResult.sourceData, newValue.title);
            await this.runSampleDistance();
            await this.runHgFarmerResult();
          }
        }
      }
    );

    const unwatchInvidual = store.watch(
      (state) => state.ancientIndividualDatabase.sampleDetail,
      async (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue) {
            this.clearCaculated();
            
            const targetResolveSource = CalculateFMCService.codeDataToArray(newValue.g25Coordinate);
            if (targetResolveSource.isSuccess) {
              CalculateFMCService.onSetTarget(targetResolveSource.result);
            }

            await this.runSampleDistance();
            await this.runHgFarmerResult();
          }
        }
      }
    );

    this.unwatchers.push(unwatchAncient);
    this.unwatchers.push(unwatchModern);
    this.unwatchers.push(unwatchInvidual);
  },

  clearCaculated() {
    store.state.sampleDatabase.calculatedFarmerHgResult = null;
    store.state.sampleDatabase.calculatedDistance = [];
  },

  async setTargetSourceDataAndRun(sourceData, sourceCodes) {
    const resolveAncientSource = CalculateFMCService.codeDataToArray(sourceData);
    if (!resolveAncientSource.isSuccess) {
      return;
    }
    const targetSourceData = resolveAncientSource.result.filter(x => x[0] === sourceCodes);
    if (targetSourceData.length) {
      CalculateFMCService.onSetTarget(targetSourceData);
    }
  },

  async runSampleDistance() {
    try {
      const sourceDataResult = await store.dispatch('modernProxSourceData/fetchModernProxSourceData', 'D-02');
      if (!sourceDataResult) {
        return;
      }

      const resolveSource = CalculateFMCService.codeDataToArray(sourceDataResult.sourceData);
      if (resolveSource.isSuccess) {
        CalculateFMCService.onSetSource(resolveSource.result);
      }

      CalculateFMCService.dataObject.maxDistOutPut = 10;
      const calculatedResult = await CalculateFMCService.calculateDistance(0);
      store.state.sampleDatabase.calculatedDistance = calculatedResult;
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }
  },

  async runHgFarmerResult() {
    try {
      const sourceDataResult = await store.dispatch('hgFarmerSourceData/fetchHgFarmerSourceData', { dataVersion: 'D-02', resultId: null, });
      if (!sourceDataResult) {
        return;
      }
      
      const resolveSource = CalculateFMCService.codeDataToArray(sourceDataResult.sourceData);
      if (resolveSource.isSuccess) {
        CalculateFMCService.onSetSource(resolveSource.result);
      }

      const calculatedResult = CalculateFMCService.runABAlgorithm();
      const parsedCalculatedResult = ParseHgFarmerResult(calculatedResult);
      
      store.state.sampleDatabase.calculatedFarmerHgResult = parsedCalculatedResult;
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }
  },

  cleanup() {
    this.unwatchers.forEach(unwatch => unwatch());
    this.unwatchers = [];
    
    this.isRunning = false;
  },
};

export default DataWatcherService;
