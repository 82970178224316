import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService'
import store from '@/store';

const diySourceDataService = {
   /* eslint-disable */
  async loadSourceDatas() {
    const sourceData = await store.dispatch('diySourceData/fetchDiySourceData', 'D-02');
    await store.dispatch('daResult/fetchResultList');

    const modernSouceDataList = CalculateFMCService.codeDataToArray(sourceData.diyModernSourceData);
    const ancientSouceDataList = CalculateFMCService.codeDataToArray(sourceData.diyAncientSourceData);
    const kitList = store.state.daResult.resultList.filter(x => x.dataVersion === 'D-02')

    const targetList = [];
    const sourceList = [];
    
    kitList.forEach(item => {
      targetList.push({ id: item.id, name: item.resultTitle, type: "MY_KITS", groupName: 'Kits', coordinate: item.targetCoordinate })
    });

    modernSouceDataList.result.forEach(item => {
      let groupName = this.groupName(item[0]);
      targetList.push({ name: item[0], type:  "MODERN_POP", groupName, coordinate: item.join(",") });
      sourceList.push({ name: item[0], type:  "MODERN_POP", groupName });
    });
    ancientSouceDataList.result.forEach(item => {
      let groupName = this.groupName(item[0]);
      targetList.push({ name: item[0], type:  "ANCIENT_POP", groupName, coordinate: item.join(",") })
      sourceList.push({ name: item[0], type:  "ANCIENT_POP", groupName });
    });

    store.state.diySourceData.souceDataList["MODERN_POP"] = modernSouceDataList.result;
    store.state.diySourceData.souceDataList["ANCIENT_POP"] = ancientSouceDataList.result;
    store.state.diySourceData.souceDataList["MY_KITS"] = kitList;

    store.state.diySourceData.targetUnSelectedList = targetList;
    store.state.diySourceData.sourceUnSelectedList = sourceList;
  },

  groupName(id) {
    const parts = id.split(':');
    if (parts.length > 1) {
      return parts[0] + ':' + parts[1];
    } else {
      return parts[0]; 
    }
  },
};

export default diySourceDataService;