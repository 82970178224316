/* eslint-disable */
import { generateDistinctColorsFromBase } from './utils'
import { $themeConfig } from '@themeConfig'

const PcaService = {
  skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
  sourceObject: {
    sourceData: null,
    targetData: null,

    selectedTargetTitle: null,

    pcaData: null,

    pcaColorMode: 'light',
    isEnableConvexHull: false,
    colorList: generateDistinctColorsFromBase(600)
  },

  // Kaynak verileri set eder
  onSetSourceDatas(sourceData, targetData) {
    this.sourceObject.sourceData = sourceData;
    this.sourceObject.targetData = targetData;
  },

  // Metin alanındaki veriyi satırlara ve sütunlara ayrıştırır
  parseSourceData(sourceData, isTarget) {
    const lines = sourceData.trim().split('\n');
    const dataArray = [];
    const dataNames = [];
  
    for (let line of lines) {
      const parts = line.trim().split(',');
      
      if(isTarget === false)
        dataNames.push(parts[0])
      else 
        dataNames.push(this.sourceObject.selectedTargetTitle);

      parts.shift();
      const numbers = parts.map(Number);
      dataArray.push(numbers);
    }
  
    return { dataArray, dataNames };
  },

  setSkin(skin) {
    this.skin = skin;
  },

  // PCA işlemini çalıştırır
  runPCA() {
    const srObject  = this.sourceObject;
    const response = { message: '', isSuccess: true };

    const sourceArrayResponse = this.parseSourceData(srObject.sourceData, false);
    const projectedArrayResponse = this.parseSourceData(srObject.targetData, true);
    
    sourceArrayResponse.dataNames.push(projectedArrayResponse.dataNames[0])
    sourceArrayResponse.dataArray.push(projectedArrayResponse.dataArray[0])

    const dataNames = sourceArrayResponse.dataNames;
    const projectedArray = sourceArrayResponse.dataArray;

     // 1.  PCA Modeli oluştur
    const pca = new ML.PCA(sourceArrayResponse.dataArray);

    // 2. Varyansları al ve kaç bileşen seçileceğine karar ver
    const explainedVariance = pca.getExplainedVariance();
    let totalVariance = 0;
    let compLength = explainedVariance.findIndex((v) => (totalVariance += v) > 0.98) + 1;

    if (compLength < 1) {
      return { isSuccess: false, message: "Not enough variance explained." };
    }

    // 3. Yeni verileri ana bileşenlere projekte et
    const projected = pca.predict(projectedArray);
 
    // 4. Çıktıyı oluştur: İsimleri ve projeksiyon sonuçlarını birleştir
    const results = projected.data.map((row, i) => {
      const name = dataNames[i];
      const components = row.slice(0, compLength).map((val) => val.toFixed(6));
      return `${name},${components.join(",")}`;
    });

    srObject.pcaData = results;
    // 5. Varyans bilgisini ekle
    // const varianceInfo = explainedVariance
    //   .slice(0, componentsNum)
    //   .map((v, i) => `PC${i + 1}: ${(v * 100).toFixed(1)}%`)
    //   .join(", ");

    return response;
  },

  computeConvexHull(points) {
    if (points.length < 3) return points;
  
    // 1) X'e göre sırala (x aynıysa y'ye göre)
    const sorted = points.slice().sort((a, b) => {
      if (a.x === b.x) return a.y - b.y;
      return a.x - b.x;
    });
  
    // "cross" helper fonksiyonu
    function cross(o, a, b) {
      return (a.x - o.x) * (b.y - o.y) - (a.y - o.y) * (b.x - o.x);
    }
  
    const lower = [];
    for (let p of sorted) {
      while (lower.length >= 2 && cross(lower[lower.length - 2], lower[lower.length - 1], p) <= 0) {
        lower.pop();
      }
      lower.push(p);
    }
  
    const upper = [];
    for (let i = sorted.length - 1; i >= 0; i--) {
      const p = sorted[i];
      while (upper.length >= 2 && cross(upper[upper.length - 2], upper[upper.length - 1], p) <= 0) {
        upper.pop();
      }
      upper.push(p);
    }
  
    // Son elemanlar tekrar etmesin diye pop() ediyoruz
    lower.pop();
    upper.pop();
  
    // Tam hull
    return lower.concat(upper);
  },

  // PCA verisini çizdirir
  plotPCA() {

    const srObject = this.sourceObject;
  
    // 1) Verileri ayıkla
    const projectedNames = srObject.pcaData.map(row => row.split(",")[0]);
    const projected = srObject.pcaData.map(row => row.split(",").slice(1).map(Number));
    const pc1 = projected.map(row => row[0]);
    const pc2 = projected.map(row => row[1]);
  
    // 2) Grupları ayır
    const groups = {};
    projectedNames.forEach((fullName, index) => {
      const [groupName, sampleName] = fullName.split(":");
      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push({
        x: pc1[index],
        y: pc2[index],
        name: fullName
      });
    });
  
    const traces = [];
    const groupCenters = [];
  
    // 3) Her grup için scatter trace + merkez hesaplama
    let colorIndex = 0;
    for (const groupName in groups) {
      const groupData = groups[groupName];
      const groupX = groupData.map(p => p.x);
      const groupY = groupData.map(p => p.y);
  
      // Grup merkezi
      const centerX = groupX.reduce((a, b) => a + b, 0) / groupX.length;
      const centerY = groupY.reduce((a, b) => a + b, 0) / groupY.length;
  
      // Grup noktaları trace
      traces.push({
        x: groupX,
        y: groupY,
        mode: "markers",
        marker: {
          size: groupName === srObject.selectedTargetTitle ? 15 : 8,
          color:
            groupName === srObject.selectedTargetTitle
              ? "#2D2D2D"
              : srObject.colorList[colorIndex % srObject.colorList.length],
          
          opacity: groupName === srObject.selectedTargetTitle ? 1 : 0.5,
          bordercolor: srObject.colorList[colorIndex % srObject.colorList.length],
          borderwidth: 1,  
          borderpad: 2  
        },
        text: groupData.map(p => `${p.name ? p.name : ""}`),
        hoverinfo: "text",
        name: groupName,
        visible: srObject.isEnableConvexHull ? 'legendonly' : true,
        
      });
  
      // Merkez bilgilerini kaydet
      groupCenters.push({
        x: centerX,
        y: centerY,
        groupName,
        color:
          groupName === srObject.selectedTargetTitle
            ? this.skin === 'dark' ? "#efefef" : "#2D2D2D"
            : srObject.colorList[colorIndex % srObject.colorList.length]
      });
  
      // Eğer convex hull çizmek istiyorsak, hull noktalarını hesaplayıp ek bir trace olarak ekleyelim
      if (srObject.isEnableConvexHull) {
        const hullPoints = this.computeConvexHull(groupData);
        if (hullPoints.length >= 3) {
          // Hull'u kapatmak için ilk noktayı sona tekrar ekle
          const hullX = hullPoints.map(p => p.x);
          const hullY = hullPoints.map(p => p.y);
          hullX.push(hullX[0]);
          hullY.push(hullY[0]);
  
          traces.push({
            x: hullX,
            y: hullY,
            mode: "lines",
            fill: "toself",
            fillcolor: (groupName === srObject.selectedTargetTitle)
              ? "rgba(45, 45, 45, 0.3)" 
              : srObject.colorList[colorIndex % srObject.colorList.length].replace("rgb", "rgba").replace(")", ",0.5)"),
            line: {
              color:
                groupName === srObject.selectedTargetTitle
                  ? "#2D2D2D"
                  : srObject.colorList[colorIndex % srObject.colorList.length],
              width: 1
            },
            hoverinfo: "skip", // Hull üzerinde hover göstermeyebiliriz
            name: groupName + " hull",
            opacity: 0.3,
            showlegend: false  // İsterseniz legend'da görünmesin
          });
        }
      }
  
      colorIndex++;
    }
  
    // 4) Merkez noktalarını tek bir trace olarak ekleyelim
    traces.push({
      x: groupCenters.map(c => c.x),
      y: groupCenters.map(c => c.y),
      mode: "markers",
      marker: {
        size: 10,
        symbol: "circle",
        color: groupCenters.map(c => c.color),
        opacity: 0.5,
      },
      text: groupCenters.map(c => c.groupName),
      hoverinfo: "text",
      name: "Centers",
    });
  
    // 5) Annotation’lar: Draggable label ve arka plan rengi
    const layout = {
      title: "",
      xaxis: { 
        title: {
          text: 'PC1',
          font: {
            color: this.skin === 'dark' ? '#bbb' : 'rgb(68, 68, 68)',
          }
        }, 
        zeroline: false, 
        linecolor: this.skin === 'dark' ? '#222' : 'rgb(238, 238, 238)', 
        gridcolor: this.skin === 'dark' ? '#222' : 'rgb(238, 238, 238)',
        tickfont : {
          color : this.skin === 'dark' ? '#bbb' : '#444444'
        }
      },
      yaxis: { 
        title: {
          text: 'PC2',
          font: {
            color: this.skin === 'dark' ? '#bbb' : 'rgb(68, 68, 68)',
          }
        }, 
        zeroline: false, 
        linecolor: this.skin === 'dark' ? '#222' : 'rgb(238, 238, 238)', 
        gridcolor: this.skin === 'dark' ? '#222' : 'rgb(238, 238, 238)',
        tickfont : {
          color : this.skin === 'dark' ? '#bbb' : '#444444'
        }
      },
      hovermode: "closest",
      dragmode: "pan",
      margin: { l: 50, r: 50, t: 50, b: 50 },
      paper_bgcolor: this.skin === 'dark' ? "rgba(19, 24, 28, 1)" : "rgba(255,255,255,1)",
      plot_bgcolor: this.skin === 'dark' ? "rgba(19, 24, 28, 1)" : "rgba(255,255,255,1)",
      annotations: groupCenters.map(center => ({
        x: center.x,
        y: center.y,
        xref: "x",
        yref: "y",
        text: center.groupName,
        showarrow: true,
        arrowhead: 0,
        axref: "pixel",
        ayref: "pixel",
        ax: center.groupName === srObject.selectedTargetTitle ? 0 : 5,
        ay: center.groupName === srObject.selectedTargetTitle ? -35 : -5,
        font: {
          color: center.color,
          size: center.groupName === srObject.selectedTargetTitle ? 25 : 14,
          family: "Arial"
        },
        bgcolor: this.skin === 'dark' ? "rgba(0, 0, 0, 0.7)" : "rgba(255,255,255,0.7)",
        bordercolor: center.color,               // Siyah kenarlık
        borderwidth: 1,                       // Kenarlık kalınlığı
        borderpad: 2  
      })),
      legend: {
        font: {
          color: this.skin === 'dark' ? '#bbb' : '#444444'
        },
      }
    };
  
    // 6) Plotly çizimi
    const config = {
      editable: true,
      scrollZoom: true
    };
    const graphDiv = document.getElementById("pcaPlotContainer");
    Plotly.newPlot(graphDiv, traces, layout, config);
  }
};

export default PcaService;
