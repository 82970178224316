<template>
  <div>
    <b-modal
      ref="modal-lab-order-kits" scrollable no-close-on-backdrop
      hide-footer hide-header size="lg" centered>
      <div>
        <div class="mobile-select-kit-header">
          <h4>Select kit</h4>
          <span @click="hiddenModal">
            <circle-x-icon />
          </span>
        </div>
        <div class="desc">
          The check mark indicates the currently selected kit
        </div>

        <div class="kits-body cs-scroll-style">
          <div class="mobile-kit-item"
            v-for="(resultItem, index)  in resultList" 
            :key="resultItem.id" @click="onChangeResultItem(resultItem, index)">
            <label class="lab-checkbox-container">
              <span class="label">{{resultItem.resultTitle}}</span>
              <input type="checkbox" v-if="resultItem.id === selectedResult.id" checked />
              <input type="checkbox" v-if="resultItem.id !== selectedResult.id" />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';

export default {
  components: {
    CircleXIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
  },
  data() {
    return {
      isShowDropdownMenu: false,
    }
  },
  methods: {
    onChangeResultItem(item) {
      this.$store.state.daResult.selectedResult = item;
      this.hiddenModal();
    },

    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdown;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownMenu = false;
        }
      } catch (error) {}
    },

    initialModal() {
      this.$refs['modal-lab-order-kits'].show();
    },
    hiddenModal() {
      this.$refs['modal-lab-order-kits'].hide()
      this.modalObject.showModal = false;
    },
  },
  computed: {
    selectedResult() {
      return this.$store.state.daResult.selectedResult;
    },
    resultList() {
      return this.$store.state.daResult.resultList;
    },
  },
  watch: {
    selectedResult(newVal, oldVal) {
      if(newVal.id !== oldVal.id) {
        this.$store.commit('region/setSelectRegion', newVal.presetRegionId);
        this.$store.commit('period/setInitialSelectedPeriod', newVal.dataVersion);
        this.$store.commit('mixedModePeriod/setInitialSelectedPeriod', newVal.dataVersion);
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('region/fetchRegionGroupList');
    await this.$store.dispatch('region/fetchRegionList');
    await this.$store.dispatch('period/fetchPeriods');
    await this.$store.dispatch('daResult/fetchResultList');
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
  mounted() {
    this.initialModal()
  },
}
</script>

<style lang="scss" scoped>
.mobile-select-kit-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: var(--lab-black);
    font-size: 1.2rem;
  }
}

.desc {
  font-size: 0.85rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.kits-body {
  max-height: 300px;
  overflow: scroll;
  color: var(--lab-stieglitz-silver);
}
.mobile-kit-item {
  padding: 0.8rem 0;
  .label {
    font-size: 1rem;
  }
}

.dark-layout {
  .mobile-select-kit-header h4 {
    color: var(--lab-lago-blue-color);
  }
}
</style>
