<template>
  <a @click="onChangeToggle()">
    <label class="lab-switch">
      
      <input type="checkbox" v-model="isDark">
      
      <span class="slider round"></span>
      <feather-icon v-if="this.isDark"
          icon="SunIcon"
          class="icon-sun"
          size="16"
        />
      <feather-icon v-if="!this.isDark"
        class="icon-moon"
        icon="MoonIcon"
        size="16"
      />
    </label>
  </a>
</template>

<script>
export default {
  data() {
    return {
      isDark: false,
    };
  },
  methods: {
    onChangeToggle() {
      const skin = this.isDark ? 'light' : 'dark';
      this.$store.commit('appConfig/UPDATE_SKIN', skin);
    },
  },
  computed: {
    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
  },
  mounted() {
    this.isDark = this.skin === 'dark';
  },
}
</script>

<style lang="scss" scoped>
.lab-switch {
  position: relative;
}
.lab-switch .slider:before {
  background-color: var(--lab-black-70);
}
.icon-moon{
  color: #000;
  position: absolute;
  top: 4.5px;
  right: 7.5px;
}
.icon-sun{
  color: #fff;
  position: absolute;
  top: 4.5px;
  left: 7.5px;
}
// .dark-layout .lab-switch .slider {
//   background-color: var(--lab-black-70) !important;
// }
</style>
