import store from '@/store';
import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService';

const ModernGDCalculateWatcher = {
  isRunning: false,
  unwatchers: [],
  
  init() {
    this.watchSelectedResultState();
    this.watchSelectedFilterSourceDatasState();

    this.RunModernResults();
  },

  watchSelectedResultState() {
    const unwatch = store.watch(
      (state) => state.daResult.selectedResult,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.RunModernResults();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchSelectedFilterSourceDatasState() {
    const unwatch = store.watch(
      (state) => state.modernProxSourceData.selectedFilterSourceDatas,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.RunModernResults();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  cleanup() {
    this.unwatchers.forEach(unwatch => unwatch());
    this.unwatchers = [];
    
    this.isRunning = false;
  },

  async RunModernResults() {
    try {
      if (!(store.state.daResult.selectedResult.id)) {
        return;
      }
      
      if (this.isRunning) {
        return;
      }
      this.isRunning = true;
      
      const dataVersion = store.state.daResult.selectedResult.dataVersion;

      const tagetResolveSource = CalculateFMCService.codeDataToArray(store.state.daResult.selectedResult.targetCoordinate);
      if (tagetResolveSource.isSuccess) {
        CalculateFMCService.onSetTarget(tagetResolveSource.result);
      }

      const sourceDataResult = await store.dispatch('modernProxSourceData/fetchModernProxSourceData', dataVersion);
      if (!sourceDataResult) {
        this.isRunning = false;
        return;
      }

      const resolveSource = CalculateFMCService.codeDataToArray(sourceDataResult.sourceData);
      if (resolveSource.isSuccess) {
        const filterSourceDatas = store.state.modernProxSourceData.selectedFilterSourceDatas;
        if (filterSourceDatas.length) {
          const filtered = resolveSource.result.filter(x => filterSourceDatas.includes(x[0]));
          CalculateFMCService.onSetSource(filtered);
        } else {
          CalculateFMCService.onSetSource(resolveSource.result);
        }
      }

      CalculateFMCService.dataObject.maxDistOutPut = 50;
      const calculatedResult = await CalculateFMCService.calculateDistance(0);
     
      const listOfSourceData = calculatedResult.map(item => item.title);
      const uiDetailDataResult = await store.dispatch('modernProxResult/fetchModernProxDetails', { listOfSourceData, dataVersion });
      if (!uiDetailDataResult) {
        this.isRunning = false;
        return;
      }
     
      calculatedResult.forEach(resultItem => {
        resultItem.tTitle = resultItem.title.replaceAll('*', ' ');

        const detailItem = uiDetailDataResult.find(x => x.sourceCode === resultItem.title);
        if (detailItem) {
          resultItem.id = detailItem.id;
          resultItem.latitude = detailItem.latitude;
          resultItem.longitude = detailItem.longitude;
          resultItem.sourceCode = detailItem.sourceCode;
        }
      });

      store.commit('modernProxResult/setUiResults', calculatedResult);
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }

    this.isRunning = false;
  },
};

export default ModernGDCalculateWatcher;