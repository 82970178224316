<template>
  <div class="result-list-container">
    <template v-if="uiResults && !$store.getters.getLoader">
      <div>
        <div class="fit-container">
          <span>Genetic fit: </span>
          <span :style="{ color: CalculateModernPopColor(uiResults.result) }">{{ uiResults.result }} ({{fitTitle(uiResults.result)}})</span>
          <span @click="geneticFitInfoModal.showModal = true" class="open-info-modal-btn">
            <sup class="subtitle-info-button-area">
              <InfoIcon />
            </sup>
          </span>
        </div>
        <div class="pop-view">
          <div v-for="itemAncestry in uiResults.outPuts"
              :key="itemAncestry.resultsTable" 
              @click="onOpenDetailPage(itemAncestry)"
              class="pop-view-item" >
            <div class="d-flex">
              <b-media-aside class="mr-75">
                <b-avatar
                  rounded
                  size="70"
                  :src="itemAncestry.logoUrl"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h6 class="mb-0">
                  {{ itemAncestry.resultsTable.replaceAll('*', ' ') }}
                </h6>
              </b-media-body>
            </div>
            <div class="d-flex align-items-center lab-p-8">
              <span class="mr-75 percent-txt" :style="{'color': skin === 'light' ? '#595959' : itemAncestry.color }"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
              <vue-apex-charts
                type="radialBar"
                height="40"
                width="40"
                :options="onGetChartOption(itemAncestry.color)"
                :series="[itemAncestry.currentResult.toFixed(1)]"
              />
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mt-4">
          <div class="d-flex align-items-center mt-1">
            <span class="lab-switch-desc mr-1">Show all populations in this era </span> 
            <label class="lab-switch">
              <input type="checkbox" v-model="$store.state.periodicalResult.showAllEra">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <GeneticFitInfoModal v-if="geneticFitInfoModal.showModal" :modalObject="geneticFitInfoModal"/>
    </template>
    <SpinnerContainer v-else />
    <PeriodicalPopDetailModal class="periodical-detail-modal" v-if="periodicalPopDetailModal.showModal" :modalObject="periodicalPopDetailModal" />
  </div>
</template>

<script>
import { CalculateModernPopColor } from '@/common/custom-service/dna-source/utils'
import VueApexCharts from 'vue-apexcharts'
import InfoIcon from '@/layouts/icons/InfoIcon.vue';
import GeneticFitInfoModal from '../modals/GeneticFitInfoModal.vue';
import PeriodicalPopDetailModal from '../modals/PeriodicalProximityModal.vue';

export default {
  components: {
    VueApexCharts,
    InfoIcon,
    GeneticFitInfoModal,
    PeriodicalPopDetailModal
  },
  props: {
  },
  data() {
    return {
      geneticFitInfoModal: {
        showModal: false,
      },
      periodicalPopDetailModal: {
        showModal: false,
        item: null,
      }
    }
  },
  methods: {
    /* eslint-disable */
    onGetChartOption(color) {
      const barColor = color ? color : '#00ADB5';
      return {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [barColor],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: this.ddAlpha(color, 0.1),
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      };
    },
    ddAlpha(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
    fitTitle(value) {
      if(value > 0 && value <= 3) {
        return 'Very close';
      }
      if(value > 3 && value <= 5) {
        return 'Close';
      }
      if(value > 5 && value <= 6) {
        return 'Moderate';
      }
      return 'Distant';
    },
    CalculateModernPopColor,

    onOpenDetailPage(item) {
      this.periodicalPopDetailModal.item = item;
      this.periodicalPopDetailModal.showModal = true;
    },
  },
  computed: {
    uiResults() {
      return this.$store.getters['periodicalResult/getUiResults'];
    },
    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.result-list-container {
  padding: 28px;

  @media screen and (max-width: 1079px) {
    overflow-y: hidden;
    overflow-x: hidden !important;
  }
  
  
}
</style>
