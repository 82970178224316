import ApiService from '@/common/api.service';

/* eslint-disable */
const getDefaultState = () => {
  return {
    selectedRegion: null,

    regionDatas: [],
  }
}

const state = getDefaultState()

const getters = {
  getRegionDatas(state) {
    return state.regionDatas;
  },

  getSelectedRegion() {
    return state.selectedRegion;
  },
}

const mutations = {
  setSelectRegion(state, data) {
    state.selectedRegion = data;
  },
  setRegionDatas(state, data) {
    state.regionDatas = data;
  },
}

const actions = {
  async fetchRegionDatas(context, { selectedRegionId, dataVersion }) {
    try {
      const response = await ApiService.get('data/region/pca/', { selectedRegionId, dataVersion }, this);
      if (response.statusCode === 200) {
        context.commit('setRegionDatas', response.result.regionList)
        context.commit('setSelectRegion', response.result.selectedPcaRegion)
      } else {
        console.error('Error fetching data:', response.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
