<template>
  <div class="pca-plot-region">
    <label for="custom-dropdown" class="dropdown-label mb-1">Narrow down by region</label>

    <div class="lab-dropdown sm region mb-1">
      <div class="lab-dropdown-btn" @click="isShowDropdownGroup = !isShowDropdownGroup" ref="dropdownParentRegion">
        <span class="lab-dropdown-btn-value">
          {{ selectedRegionGroup.grupName || 'Global' }}
        </span>
        <span class="lab-dropdown-btn-icon">
          <DropdownOneIcon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownGroup}">
        <ul>
          <li v-for="(item, index) in regionGroupList"
              :key="index"
              @click="onSetRegionGrup(item, index)">
              {{ item.grupName }}
          </li>
        </ul>
      </div>
    </div>
    
    <div class="lab-dropdown sm region mb-1">
      <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownRegion">
        <span class="lab-dropdown-btn-value small-area">
          {{ selectedRegion.title }}
        </span>
        <span class="lab-dropdown-btn-icon">
          <DropdownOneIcon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
        <ul>
          <li v-for="(item, index) in regionList"
              :key="index"
              @click="onSetRegion(item)">
              {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShowDropdownGroup: false,
      isShowDropdown: false,

      selectedRegionGroup: { id: 0, grupName: '' },
      selectedRegion: { id: 0, title: '' },
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownParentRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownGroup = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },

    onSetRegionGrup(regionGrup) {
      this.selectedRegionGroup = regionGrup;
      this.selectedRegion = { id: 0, title: '' };
    },

    onSetRegion(region) {
      this.selectedRegion = region;
      this.$store.commit('pcaRegion/setSelectRegion', this.selectedRegion.id);
    },

    onLoadRegions() {
      const query = {
        selectedRegionId: this.selectedResult.presetRegionId,
      };

      this.$store.dispatch('pcaRegion/fetchRegionDatas', query).then(() => {
        this.selectedRegionGroup = this.$store.state.pcaRegion.selectedRegionGroup;
        this.selectedRegion = this.$store.state.pcaRegion.selectedRegion;
      });
    },
  },
  computed: {
    selectedResult() {
      return this.$store.state.daResult.selectedResult;
    },

    regionGroupList() {
      return this.$store.getters['pcaRegion/getRegionGroupList'].filter(x => x.dataVersion === this.$store.getters['daResult/dataVersion']);
    },
    regionList() {
      const regionList = this.$store.getters['pcaRegion/getRegionList'];
      return regionList.filter(x => x.presetRegionGrupId === this.selectedRegionGroup.id);
    },
  },
  watch: {
    selectedResult(newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.onLoadRegions();
      }
    }
  },
  mounted() {
    this.onLoadRegions();
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>

.pca-plot-region {
  .lab-dropdown-content {
    max-height: 250px;
  }
}

.dark-layout {
  .dropdown-select {
    border: 2px solid #1bddcc99!important;
  }
}

.small-area {
  font-size: 1rem!important;
}

</style>
