import ApiService from '@/common/api.service';

/* eslint-disable */
const getDefaultState = () => {
  return {
    periods: [],
    selectedPeriod: { id: 0 },
  }
}

const state = getDefaultState()

const getters = {
  getPeriods(state) {
    return state.periods;
  },
}

const mutations = {
  setPeriods(state, data) {
    state.periods = data.result;
  },

  setSelectedPeriod(state, period) {
    state.selectedPeriod = period;
  },
  setInitialSelectedPeriod(state, dataVersion) {
    const periods = state.periods.filter(x => x.dataVersion == dataVersion);
    if (periods.length) {
      state.selectedPeriod = periods[0];
    }
  },
}

const actions = {
  async fetchPeriods(context) {
    try {
      if (context.state.periods.length > 0) {
        return;
      }
     
      const response = await ApiService.get('data/SupervisedPeriod', {}, this);
      if (response.statusCode === 200) {
        context.commit('setPeriods', response)
      } else {
        console.error('Error fetching data:', response.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
