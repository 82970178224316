import store from '@/store';
import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService'

const SampleDatabaseWatcher = {
  unwatchers: [],

  init() {
    store.dispatch('sampleDatabase/fetchLocationData')
    this.onLoadSampleList();
    this.watchSampleTypeState();
    this.watchFilterQuery();
  },

  watchSampleTypeState() {
    const unwatch = store.watch(
      (state) => state.sampleDatabase.selectedSampleType,
      async (newValue, oldValue) => {
        if (newValue !== oldValue) {
          await store.dispatch('sampleDatabase/fetchLocationData')
          await this.onLoadSampleList();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchFilterQuery() {
    const unwatch = store.watch(
      (state) => [
        state.sampleDatabase.filterQuery.location,
        state.sampleDatabase.filterQuery.Search,
        state.sampleDatabase.filterQuery.PageIndex,
        state.sampleDatabase.filterQuery.PageSize,
        state.sampleDatabase.filterQuery.Count,
      ],
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.onLoadSampleList();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  onLoadSampleList() {
    if (store.state.sampleDatabase.selectedSampleType === 'ANCIENT') {
      this.onLoadAncientSampleList();
    } else if (store.state.sampleDatabase.selectedSampleType === 'ANCIENT-INDIVIDUAL') {
      this.onLoadIndividualAncientSampleList();
    } else if (store.state.sampleDatabase.selectedSampleType === 'MODERN') {
      this.onLoadModernSampleList();
    }
  },

  async onLoadAncientSampleList() {
    try {
      const sourceDataResult = await store.dispatch('ancientDatabase/fetchAncientDatabaseData', store.state.sampleDatabase.filterQuery);
      if (!sourceDataResult) {
        return;
      }
      
      store.commit('ancientDatabase/setAncientDatabaseList', sourceDataResult.data);
      store.commit('sampleDatabase/setFilterQuery', sourceDataResult)
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }
  },

  async onLoadIndividualAncientSampleList() {
    try {
      const sourceDataResult = await store.dispatch('ancientIndividualDatabase/fetchDatabaseData', store.state.sampleDatabase.filterQuery);
      if (!sourceDataResult) {
        return;
      }
      store.commit('ancientIndividualDatabase/setAncientIndividualDatabaseList', sourceDataResult.data);
      store.commit('sampleDatabase/setFilterQuery', sourceDataResult)
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }
  },

  async onLoadModernSampleList() {
    /* eslint-disable */
    try {
      const sourceDataResult = await store.dispatch('modernProxSourceData/fetchModernProxSourceData', 'D-02');
      if (!sourceDataResult) {
        return;
      }
      const resolveSource = CalculateFMCService.codeDataToArray(sourceDataResult.sourceData);
      if (!resolveSource.isSuccess) {
        return;
      }
      
      let moderPopList = resolveSource.result.map((x) => {
        return {
          title: x[0]
        }
      });

      let search = store.state.sampleDatabase.filterQuery.Search;
      if (!(search === null || search === '')) {
        search = search.replaceAll(/ /g, '*');
        moderPopList = moderPopList.filter(x => x.title.toLowerCase().includes(search.toLowerCase()));
      }

      store.commit('modernDatabase/setModernDatabaseList', moderPopList);
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }
  },
  
  cleanup() {
    this.unwatchers.forEach(unwatch => unwatch());
    this.unwatchers = [];
    
    this.isRunning = false;
  },
};

export default SampleDatabaseWatcher;
