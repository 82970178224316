/* eslint-disable */

// euclidean
export const euclideanDistance = (vec1, vec2) => {
  // vec1[0] ve vec2[0] isim/etiket -> bu nedenle hesaplamayı [1..n] arasındaki kolonlarda yapacağız
  const len = Math.min(vec1.length, vec2.length) - 1;
  let sumOfSquares = 0;

  for (let i = 1; i <= len; i++) {
    const diff = vec1[i] - vec2[i];
    sumOfSquares += diff * diff;
  }

  return Math.sqrt(sumOfSquares);
}

// FMC
export const generateRandomRange = (min, max) => {
  return Math.floor(Math.random() * (max - min) ) + min;
}

export const subDataArray = (array1, array2) => {
  return array1.map((value, index) => value - array2[index]);
}

export const addDataArray = (array1, array2) => {
  return array1.map((value, index) => value + array2[index]);
}

export const callArraySum = (arr) => {
  return arr.reduce((t, n) => t+n);
}

export const clusterResults = (resultsDatas, sourceLength) => {
  let i, popTitles, addedTitles;
  for (i = 0; i < sourceLength; i++) {
    popTitles = resultsDatas[i][0].split(":");
    resultsDatas[i][0] = popTitles[0];
  }
  resultsDatas.sort( function(a, b) {
    return a[0].localeCompare(b[0]);
  });
  for (i = sourceLength - 2; i > -1; i--) {
    if (resultsDatas[i][0] == resultsDatas[i + 1][0]) {
      addedTitles = resultsDatas[i][0];
      resultsDatas[i] = addDataArray(resultsDatas[i],resultsDatas[i + 1]);
      resultsDatas[i][0] = addedTitles;
      resultsDatas.splice(i + 1, 1);
    }
  }
  return resultsDatas;
}

export const divideToNestTarget = (targetData, number, dims) => {
  const target = targetData[0].slice();
  target.shift();
  for (let i = 0; i < dims; i++) {
      target[i] = target[i] / number;
  }
  return target;
}

export const divideToNestSource = (sourceData, sourceNumber, number, dims) => {
  let i, j, tempLine;
  const source = Array(sourceNumber);
  for (i = 0; i < sourceNumber; i++) {
      tempLine = sourceData[i].slice();
      tempLine.shift();
      source[i] = tempLine.slice();
      for (j = 0; j < dims; j++) {
          source[i][j] = source[i][j] / number;
      }
  }
  return source;
}

export const callResultData = (result, sourceData, sourceLength) => {
  const resultData = Array(sourceLength)
  for (let i = 0; i < sourceLength; i++) {
    resultData[i] = Array(2);
    resultData[i][0] = sourceData[i][0];
    resultData[i][1] = result.points[i];
  }
  return resultData;
};

export const callResultDataRdc = (result) => {
  let sourceLength = result.points.length;
  const resultData = Array(sourceLength)
  for (let i = 0; i < sourceLength; i++) {
    resultData[i] = Array(2);
    resultData[i][0] = result.titles[i];
    resultData[i][1] = result.points[i];
  }
  return resultData;
};

export const callResponseData = (result, resultData) => {
  const response = {
    result: (100 * result.dist).toFixed(3),
    outPuts: [],
  };
  for (let i = 0; i < resultData.length; i++) {
    if (resultData[i][1] != 0) {
      response.outPuts.push({
        currentResult: resultData[i][1] * 100,
        resultsTable: resultData[i][0],
      });
    }
  }

  return response;
};

// runCustomMCAlgorithm
export const runCustomMCAlgorithm = (dataParams, checkParams, extraParams) => {
  let arrangements = Array(); 
  const loopSize = Math.ceil((dataParams.sourceCodeNumber * checkParams.iterationFactor) / 4);
  const points = Array(dataParams.sourceCodeNumber).fill(0);
  const longestNumber = 100000000000000000;
  
  checkDistanceColumnMultiSub(dataParams, checkParams, extraParams);
  let currentNests = Array(extraParams.nstSize).fill(-1);
  currentNests = makeRandomNests(currentNests, dataParams.sourceCodeNumber);
  let currentPoints = makePointsFromNests(currentNests, dataParams, extraParams.codeMetrics);
  let currentDists = runDistance(currentPoints);

  let nextNests = null, nextPoints = null, nextDists = null;
  for (let i=0; i< loopSize; i++) {
    nextNests = makeRandomNests(currentNests, dataParams.sourceCodeNumber);
    for (let j = 0; j < extraParams.nstSize; j++) {
      nextPoints = subDataArray(currentPoints, dataParams.sourceCodData[currentNests[j]]);
      nextPoints = addDataArray(nextPoints, dataParams.sourceCodData[nextNests[j]]);
      nextDists = runDistance(nextPoints);
      if (nextDists < currentDists) {
        currentNests[j] = nextNests[j];
        currentPoints = nextPoints;
        currentDists = nextDists;
      }
    }
  }
  
  fillPoints(points, currentNests, extraParams.nstSize);
  fillArrangements(arrangements, points, dataParams.sourceCodeNumber);

  let arrangeNumber = arrangements.length;
  let prevDists;
  function reRunArrange() {
    currentDists = Math.round(longestNumber * currentDists);
    do {
      prevDists = currentDists;
      for (let i = arrangeNumber - 1; i > -1; i--) {
          if (arrangements[i][1] > 0) {
              for (let j = 0; j < arrangeNumber; j++) {
                  if (i == j) { continue; }
                  nextPoints = subDataArray(currentPoints, dataParams.sourceCodData[arrangements[i][0]]);
                  nextPoints = addDataArray(nextPoints, dataParams.sourceCodData[arrangements[j][0]]);
                  nextDists = Math.round(longestNumber * runDistance(nextPoints));
                  if (nextDists < currentDists) {
                      arrangements[i][1]--;
                      arrangements[j][1]++;
                      currentPoints = nextPoints;
                      currentDists = nextDists;
                      break;
                  }
              }
          }
      }
    }
    while (currentDists < prevDists);
  }
  reRunArrange();
  for (let i = 0; i < arrangeNumber; i++) {
    points[arrangements[i][0]] = arrangements[i][1];
  }

  if (checkParams.columnDFactor && checkParams.recalculate) {
    extraParams.codeMetrics--;
    currentPoints.pop();
    currentDists = runDistance(currentPoints);
    for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
      dataParams.sourceCodData[i].pop();
    }
    arrangements = [];
    for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
      if (points[i] > 0) {
        arrangements.push([i, points[i]]);
      }
    }
    arrangements.sort((a, b) => b[1] - a[1]);
    arrangeNumber = arrangements.length;
    reRunArrange();
    for (let i = 0; i < arrangeNumber; i++) {
        points[arrangements[i][0]] = arrangements[i][1];
    }
  }
  for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
    points[i] = points[i] / extraParams.nstSize;
  }
  if (checkParams.columnDFactor && !checkParams.recalculate) { currentPoints.pop(); }
  currentDists = runDistance(currentPoints);
  
  return { 
    dist: Number(Math.sqrt(currentDists).toFixed(8)),
    points: points,
  };
}

export const checkDistanceColumnMultiSub = (dataParams, checkParams, extraParams) => {
  if (checkParams.columnDFactor) {
    checkParams.columnDFactor = checkParams.columnDFactor/ 8;
    extraParams.codeMetrics = extraParams.codeMetrics + 1;
    for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
      dataParams.sourceCodData[i] = subDataArray(dataParams.sourceCodData[i], dataParams.targetCode);
      dataParams.sourceCodData[i].push(checkParams.columnDFactor * Math.sqrt(runDistance(dataParams.sourceCodData[i])));
    }
  }
  else {
    for (let i = 0; i < dataParams.sourceCodeNumber; i++) {
      dataParams.sourceCodData[i] = subDataArray(dataParams.sourceCodData[i], dataParams.targetCode);
    }
  }
}

export const runDistance = (paramsData) => {
  let result = paramsData.map((element) => element ** 2);
  if (result) {
    result = result.reduce((t, n) => t+n)
  }
  return result;
}

export const makeRandomNests = (prevNests, sourceCodeNumber) => {
  let newNests = Array(prevNests.length);
  for(let i = 0; i < prevNests.length; i++) {
    newNests[i] = generateRandomRange(0, sourceCodeNumber);
    while (newNests[i] == prevNests[i]) {
      newNests[i] = generateRandomRange(0, sourceCodeNumber);
    }
  }
  return newNests;
}

export const makePointsFromNests = (nests, dataParams, codeMetrics) => {
  let newPoints = Array(codeMetrics).fill(0), tempPoint;
  for(let i = 0; i < nests.length; i++) {
    tempPoint = dataParams.sourceCodData[nests[i]].slice();
    newPoints = addDataArray(newPoints, tempPoint);
  }
  return newPoints;
}

export const fillPoints = (points, nests, nstSize) => {
  for (let i = 0; i < nstSize; i++) {
    points[nests[i]] += 1; 
  }
}

export const fillArrangements = (ranks, points, sourceCodeNumber) => {
  for (let i = 0; i < sourceCodeNumber; i++) {
    if (points[i] > 0) {
      ranks.push([i, points[i]]);
    }
  }
  ranks.sort((a, b) => b[1] - a[1]);
}

// runABByPopNumber
export const runABByPopNumber = (dataParams, checkParams, extraParams, numberOfPopulation) => {
  let titleArr = [];
  let idArray = [];
  let currentResult = undefined, nextResult = undefined;
  
  for (let i = 0, tempArr; i < dataParams.sourceCodeNumber; i++) {
    tempArr = [dataParams.sourceCodeArray[i][0].split(':').shift(), dataParams.sourceCodeArray[i][0]];
    dataParams.sourceCodData[i] = tempArr.concat(dataParams.sourceCodData[i]);
  }

  dataParams.sourceCodData = clusterArray(dataParams.sourceCodData);
  for(let i in dataParams.sourceCodData) {
    titleArr.push([]);
    for(let j in dataParams.sourceCodData[i]) {
      dataParams.sourceCodData[i][j].shift();
      titleArr[i].push(dataParams.sourceCodData[i][j].shift());
      idArray.push(i);
    }
  }

  let sourceLength = dataParams.sourceCodData.length;
  let initData = [];
  for(let countIndex = 0; countIndex < sourceLength; countIndex++) {
    initData.push(countIndex);
  }

  let initDataResult = [];
  runInitDataMCAlgorithms(initData, initDataResult, idArray, dataParams, extraParams.codeMetrics);
  initDataResult = clusterResults(initDataResult, initDataResult.length)
  initDataResult.sort((a,b) => b[1] - a[1]);

  let newTitleArr = [];
  let newSourceData = [];
  for (let item in initDataResult) {
    if (Number(initDataResult[item][1]) > 0.02) {
        newSourceData.push(dataParams.sourceCodData[Number(initDataResult[item][0])]);
        newTitleArr.push(titleArr[Number(initDataResult[item][0])]);
    } else {
        break;
    }
  }

  dataParams.sourceCodData = newSourceData;
  titleArr = newTitleArr;
  sourceLength = dataParams.sourceCodData.length;

  let actualNestLength = extraParams.nstSize;
  let currentData = [];
  let nextData = undefined;
  if (sourceLength <= numberOfPopulation) {
    for (let i = 0; i < sourceLength; i++) {
      currentData.push(i);
    }
    return CompleteProcess();
  } else {
    for (let i = 0; i < numberOfPopulation; i++) {
      currentData.push(i);
    }
  }

  let counter = 0;
  let copyDataParams = {
    targetCode: dataParams.targetCode,
  };
  function runMCAlgorithm(setToRun) {
    counter++;
    let currentSource = [];
    for (let item of setToRun) {
        currentSource = currentSource.concat(dataParams.sourceCodData[item]);
    }
    
    if (!copyDataParams) {
      copyDataParams = {
        targetCode: dataParams.targetCode,
      };
    }
    copyDataParams.sourceCodData = currentSource;
    copyDataParams.sourceCodeNumber = currentSource.length;
    
    return runCustomMCAlgorithm(copyDataParams, checkParams, extraParams);
  }
  
  const storeSet = currentData;
  let runs = [];
  for (let i = 0, n = 30 + sourceLength; i < n; i++) {
      currentData = storeSet.slice();
      currentResult = runMCAlgorithm(currentData);
      extraParams.nstSize = 35;
      for (let i = 0, n = Math.ceil(sourceLength); i < n; i++) {
          for (let j = 0; j < numberOfPopulation; j++) {
              nextData = currentData.slice();
              nextData[j] = callNewPopulation(nextData[j], currentData, sourceLength);
              nextResult = runMCAlgorithm(nextData);
              if (nextResult.dist < currentResult.dist) {
                  currentResult = nextResult;
                  currentData = nextData;
              }
          }
      }
      runs.push([currentResult.dist, currentData.slice()]);
  }
  runs.sort((a, b) => a[0] - b[0]);
  currentData = runs[0][1];
  function CompleteProcess() {
    extraParams.nstSize = actualNestLength;
    currentResult = runMCAlgorithm(currentData);
    currentResult.titles = callNames(titleArr, currentData);
    currentResult.sourceLength = sourceLength;
    currentResult.counter = counter;
    return currentResult;
  };

  return CompleteProcess();
}

export const clusterArray = (arr) => {
  let sortArr = arr.slice(), clusterArr = [];
  sortArr.sort((a, b) => a[0].localeCompare(b[0]));
  let name = null, i = 0, j = -1;
  for (i = 0; i < sortArr.length; i++) {
      if (sortArr[i][0] != name) {
          j++;
          name = sortArr[i][0];
          clusterArr.push([]);
      }
      clusterArr[j].push(sortArr[i]);
  }
  return clusterArr;
}

export const runInitDataMCAlgorithms = (initData, initDataResult, idArray, dataParams, codeMetrics) => {
  let initResult = [
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeMetrics, 0.5, true),
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeMetrics, 0, false),
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeMetrics, 1, true),
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeMetrics,  0, false),
    runDATAMCAlgorithm(dataParams, initData, 50, 5, codeMetrics, 2, true),
    runDATAMCAlgorithm(dataParams, initData, 500, 2, codeMetrics, 0, false)
  ];
  for (let item in idArray) {
      for (let item2 in initResult) {
        initDataResult.push([idArray[item], initResult[item2].points[item]]);
      }
  }
}

export const runDATAMCAlgorithm = (dataParams, initData, nstSize, iterationFactor, codeMetrics, columnDFactor, recalculate) => {
  let copyDataparams = {
    targetCode: dataParams.targetCode,
  };

  let currentSource = [];
  for (let item of initData) {
      currentSource = currentSource.concat(dataParams.sourceCodData[item]);
  }
  copyDataparams.sourceCodData = currentSource;
  copyDataparams.sourceCodeNumber = currentSource.length;
  let extraParams = { nstSize: nstSize, codeMetrics: codeMetrics };
  let checkParams = { iterationFactor: iterationFactor, columnDFactor: columnDFactor, recalculate: recalculate };
  return runCustomMCAlgorithm(copyDataparams, checkParams, extraParams);
}

export const callNames = (titleArr, setToDataRun) => {
  let names = [];
  for (let item of setToDataRun) {
    names = names.concat(titleArr[item]);
  }
  return names;
}

export const callNewPopulation = (currentDataItem, currentData, sourceLength) => {
  let newPop = generateRandomRange(0, sourceLength);
  while (newPop == currentDataItem || currentData.includes(newPop)) {
      newPop = generateRandomRange(0, sourceLength);
  }
  return newPop;
}
