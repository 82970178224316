<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.65 39.76">
    <defs>
    </defs>
    <g>
      <path class="cls-1" d="M19.14,13.99H10.32c-.19,0-.33-.15-.33-.33s.15-.33,.33-.33h8.82c.44,0,.44,.66,0,.66Z"/>
      <path class="cls-1" d="M17.64,16.73h-5.82c-.44,0-.44-.67,0-.67h5.82c.43,0,.44,.66,0,.67Z"/>
      <path class="cls-1" d="M16.74,22.2h-4.01c-.44,0-.44-.67,0-.67h4.01c.44,0,.44,.67,0,.67h0Z"/>
      <path class="cls-1" d="M18.96,24.94H10.5c-.44,0-.44-.67,0-.67h8.46c.43,0,.44,.66,0,.67Z"/>
      <path class="cls-1" d="M18.96,27.68H10.5c-.44,0-.44-.67,0-.67h8.46c.43,0,.44,.66,0,.67Z"/>
      <path class="cls-1" d="M16.74,30.42h-4.01c-.44,0-.44-.67,0-.67h4.01c.44,0,.44,.67,0,.67h0Z"/>
      <path class="cls-1" d="M17.64,35.89h-5.82c-.44,0-.44-.67,0-.67h5.82c.43,0,.44,.66,0,.67Z"/>
      <path class="cls-1" d="M19.14,38.64H10.32c-.19,0-.33-.15-.33-.33s.15-.33,.33-.33h8.82c.44,0,.44,.66,0,.66h0Z"/>
    </g>
    <g>
      <path class="cls-1" d="M8.53,13.06c.03-1.15,1.72-1.15,1.75,0,0,2.57,2.11,4,4.45,5.44-.55,.34-1.11,.67-1.66,1.03-2.33-1.49-4.54-3.3-4.54-6.47h0Z"/>
      <path class="cls-1" d="M20.93,25.98c.04,7.02-10.51,7.04-10.65,12.92-.02,1.15-1.72,1.16-1.75,0,.01-3.93,3.35-5.73,6.2-7.49,2.35-1.43,4.45-2.86,4.45-5.44s-2.11-4-4.45-5.44c.55-.34,1.11-.68,1.66-1.02,2.33,1.48,4.54,3.29,4.54,6.47Z"/>
    </g>
    <g>
      <path class="cls-1" d="M20.93,13.06c-.01,3.93-3.35,5.73-6.2,7.49-2.35,1.43-4.45,2.86-4.45,5.44s2.11,4,4.45,5.44c-.55,.35-1.11,.67-1.66,1.03-2.32-1.48-4.53-3.29-4.53-6.46,.01-3.93,3.35-5.73,6.2-7.49,2.35-1.43,4.45-2.86,4.45-5.44,.02-1.16,1.72-1.16,1.75,0Z"/>
      <path class="cls-1" d="M20.93,38.9c-.03,1.15-1.72,1.15-1.75,0,0-2.57-2.11-4-4.45-5.44,.55-.34,1.11-.67,1.66-1.02,2.33,1.48,4.54,3.29,4.54,6.46Z"/>
    </g>
    <g>
      <path class="cls-1" d="M21.27,11.97l-1.16,1.16c-.08,.08-.08,.22,0,.31,.04,.04,.1,.06,.15,.06s.11-.02,.15-.06l1.15-1.15s-.1-.09-.15-.14c-.06-.06-.11-.11-.16-.17h0Z"/>
      <path class="cls-1" d="M26.98,6.12c-.44,0-1.31,.04-2.3,.27v2.17l2.44-2.44s-.09,0-.14,0h0Z"/>
      <path class="cls-1" d="M27.45,6.41l-2.46,2.46h2.2c.03-.14,.06-.29,.09-.44,.18-.99,.17-1.78,.17-2.02Z"/>
      <path class="cls-1" d="M23.25,10.6h3.33c.05-.09,.09-.19,.13-.29,.14-.32,.26-.66,.36-1.02h-2.52l-1.3,1.3Z"/>
      <path class="cls-1" d="M24.25,6.5c-.47,.13-.91,.3-1.3,.5v3.3s1.3-1.3,1.3-1.3v-2.49Z"/>
      <path class="cls-1" d="M21.57,12.28c.52,.46,1.19,.71,1.89,.71,.77,0,1.49-.3,2.04-.84,.32-.32,.6-.69,.85-1.11h-3.53l-1.24,1.24Z"/>
      <path class="cls-1" d="M22.51,7.23c-.41,.24-.77,.52-1.08,.84-.54,.54-.84,1.27-.84,2.04,0,.69,.24,1.34,.68,1.86l1.24-1.24v-3.49Z"/>
    </g>
    <g>
      <path class="cls-1" d="M14.69,8.3v1.64c0,.12,.1,.22,.22,.22,.06,0,.11-.02,.15-.06s.06-.09,.06-.15v-1.63c-.07,0-.13,0-.2,0-.08,0-.16,0-.23,0h0Z"/>
      <path class="cls-1" d="M14.59,.13c-.31,.31-.9,.95-1.43,1.81l1.54,1.54V.03s-.06,.06-.1,.1h0Z"/>
      <path class="cls-1" d="M15.12,0V3.48s1.56-1.56,1.56-1.56c-.08-.13-.16-.25-.25-.37-.57-.83-1.13-1.38-1.31-1.55Z"/>
      <path class="cls-1" d="M15.12,5.94l2.36-2.36c-.03-.1-.07-.2-.11-.3-.13-.32-.28-.65-.46-.98l-1.78,1.78v1.84Z"/>
      <path class="cls-1" d="M12.92,2.33c-.24,.43-.43,.85-.57,1.27l2.33,2.33v-1.84s-1.76-1.76-1.76-1.76Z"/>
      <path class="cls-1" d="M15.12,8.31c.69-.05,1.34-.34,1.84-.84,.54-.54,.84-1.27,.84-2.04,0-.45-.06-.92-.18-1.38l-2.5,2.5v1.76Z"/>
      <path class="cls-1" d="M12.22,4.08c-.12,.46-.17,.91-.17,1.36,0,.77,.3,1.49,.84,2.04,.49,.49,1.12,.78,1.8,.83v-1.76s-2.47-2.47-2.47-2.47Z"/>
    </g>
    <g>
      <path class="cls-1" d="M7.82,11.6c-.05,.06-.1,.12-.16,.17-.05,.05-.1,.09-.15,.14l1.15,1.15s.1,.06,.15,.06,.11-.02,.15-.06c.08-.08,.08-.22,0-.31l-1.16-1.16h0Z"/>
      <path class="cls-1" d="M2.11,5.75c-.05,0-.1,0-.14,0l2.44,2.44v-2.17c-.99-.23-1.86-.27-2.3-.27h0Z"/>
      <path class="cls-1" d="M1.81,8.06c.03,.15,.06,.3,.09,.44h2.2S1.64,6.04,1.64,6.04c0,.24-.01,1.03,.17,2.02Z"/>
      <path class="cls-1" d="M4.53,8.93H2.01c.1,.36,.22,.7,.36,1.02,.04,.1,.09,.19,.13,.29h3.33s-1.3-1.3-1.3-1.3Z"/>
      <path class="cls-1" d="M4.84,8.62l1.3,1.3v-3.3c-.4-.2-.83-.36-1.3-.5v2.49Z"/>
      <path class="cls-1" d="M6.27,10.67H2.74c.25,.42,.53,.79,.85,1.11,.54,.54,1.27,.84,2.04,.84,.7,0,1.37-.25,1.89-.71l-1.24-1.24Z"/>
      <path class="cls-1" d="M6.58,10.36l1.24,1.24c.44-.52,.68-1.17,.68-1.86,0-.77-.3-1.49-.84-2.04-.32-.32-.68-.59-1.08-.84v3.49Z"/>
    </g>
    <g>
      <path class="cls-1" d="M20.52,21.49l-1.58,.42c-.12,.03-.18,.15-.15,.27,.02,.06,.05,.1,.1,.13s.11,.04,.16,.02l1.58-.42c-.02-.06-.04-.13-.06-.19-.02-.08-.04-.15-.05-.23h0Z"/>
      <path class="cls-1" d="M28.39,19.28c-.38-.22-1.15-.62-2.12-.92l-1.09,1.88,3.33-.89s-.07-.04-.12-.07h0Z"/>
      <path class="cls-1" d="M28.65,19.76l-3.36,.9,1.91,1.1c.1-.11,.2-.22,.3-.33,.65-.76,1.04-1.45,1.16-1.67Z"/>
      <path class="cls-1" d="M22.92,21.29l2.89,1.67c.09-.06,.17-.12,.26-.18,.28-.21,.56-.44,.82-.7l-2.19-1.26-1.78,.48Z"/>
      <path class="cls-1" d="M25.84,18.24c-.47-.12-.93-.19-1.38-.22l-1.65,2.86,1.78-.48,1.25-2.16Z"/>
      <path class="cls-1" d="M20.63,21.91c.23,.66,.68,1.21,1.28,1.56,.67,.38,1.44,.49,2.18,.29,.44-.12,.87-.3,1.29-.53l-3.06-1.77-1.7,.46Z"/>
      <path class="cls-1" d="M23.96,18.01c-.47,0-.93,.07-1.36,.18-.74,.2-1.36,.68-1.75,1.34-.35,.6-.46,1.28-.34,1.96l1.7-.45,1.75-3.03Z"/>
    </g>
    <g>
      <path class="cls-1" d="M8.13,22.22c-.01,.08-.03,.15-.05,.23-.02,.06-.04,.13-.06,.19l1.58,.42c.06,.02,.12,0,.16-.02s.09-.07,.1-.13c.03-.12-.04-.23-.15-.27l-1.58-.42h0Z"/>
      <path class="cls-1" d="M.26,20.01s-.09,.05-.12,.07l3.33,.89-1.09-1.88c-.97,.29-1.74,.7-2.12,.92h0Z"/>
      <path class="cls-1" d="M1.16,22.16c.1,.12,.2,.23,.3,.33l1.91-1.1-3.36-.9c.11,.21,.51,.9,1.16,1.67Z"/>
      <path class="cls-1" d="M3.95,21.55l-2.19,1.26c.27,.26,.54,.49,.82,.7,.09,.06,.17,.12,.26,.18l2.89-1.67-1.78-.48Z"/>
      <path class="cls-1" d="M4.06,21.13l1.78,.48-1.65-2.86c-.44,.03-.9,.1-1.38,.22l1.25,2.16Z"/>
      <path class="cls-1" d="M6.33,22.19l-3.06,1.77c.42,.24,.85,.42,1.29,.53,.74,.2,1.52,.1,2.18-.29,.61-.35,1.06-.9,1.28-1.56l-1.7-.46Z"/>
      <path class="cls-1" d="M6.44,21.77l1.7,.45c.12-.67,0-1.36-.34-1.96-.38-.67-1.01-1.14-1.75-1.34-.43-.12-.88-.18-1.36-.18l1.75,3.03Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
    className: {
      default: 'normal',
      type: String,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
  width: 25px!important;
  transform: scale(1.25);
}

.cls-1 {
  fill: #2d2d2d;
}
.dark-layout {
  .cls-1 {
    fill: #1bbdcc;
  }
}

</style>
