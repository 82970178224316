import Vue from 'vue'
import Vuex from 'vuex'

// General
import app from './app';
import loader from './app/loader.module';
import appConfig from './app-config';
import utils from './utils/utils.module';
import verticalMenu from './vertical-menu';

import notification from './general-store/notification/notification.module';
import ticket from './general-store/ticket/ticket.module';

import account from './general-store/account/account.module'
import profileSettings from './general-store/profile-settings/profilesettings.module';
import products from './general-store/product/product.module';
import orders from './general-store/order/order.module';

import deepACheckout from './general-store/checkout/deepACheckout.module'
import traitCheckout from './general-store/checkout/traitCheckout.module'
import g25PartnerCheckout from './general-store/checkout/g25PartnerCheckout.module';

import updateV2 from './general-store/update-v2/updateV2.module';

// deep-ancestry
import daResult from './deep-ancestry/daResult.module';
import region from './deep-ancestry/shared/region.module';

import period from './deep-ancestry/periodical/period.module';
import periodSourceData from './deep-ancestry/periodical/periodSourceData.module';
import periodicalResult from './deep-ancestry/periodical/periodicalResult.module';

import hgFarmerSourceData from './deep-ancestry/hg-farmer/hgFarmerSourceData.module';
import hgFarmerResult from './deep-ancestry/hg-farmer/hgFarmerResult.module';

import mixedModePeriod from './deep-ancestry/unsupervised/mixedModePeriod.module';
import mixedModeResult from './deep-ancestry/unsupervised/mixedModeResult.module';

import geneticDistance from './deep-ancestry/genetic-distance/geneticDistance.module';
import ancientProxSourceData from './deep-ancestry/genetic-distance/ancient/ancientProxSourceData.module';
import ancientProxResult from './deep-ancestry/genetic-distance/ancient/ancientProxResult.module';
import ancientProxSampleCoordinate from './deep-ancestry/genetic-distance/ancient/ancientProxSampleCoordinate.module';
import modernProxSourceData from './deep-ancestry/genetic-distance/modern/modernProxSourceData.module';
import modernProxResult from './deep-ancestry/genetic-distance/modern/modernProxResult.module';

import pcaRegion from './deep-ancestry/pca-plots/pcaRegion.module';
import pcaSourceData from './deep-ancestry/pca-plots/pcaSourceData.module';

import diy from './deep-ancestry/diy/diy.module';
import diySourceData from './deep-ancestry/diy/diySourceData.module';
import diyGDSourceData from './deep-ancestry/diy/diyGDSourceData.module';

import sampleDatabase from './deep-ancestry/sample-database/sampleDatabase.module';
import ancientDatabase from './deep-ancestry/sample-database/ancientDatabase.module';
import ancientIndividualDatabase from './deep-ancestry/sample-database/ancientIndividualDatabase.module';
import modernDatabase from './deep-ancestry/sample-database/modernDatabase.module';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    // General
    app,
    loader,
    appConfig,
    verticalMenu,

    notification,
    ticket,
    
    account,
    profileSettings,
    products,
    orders,

    deepACheckout,
    traitCheckout,
    g25PartnerCheckout,

    updateV2,

    // Shared
    daResult,
    region,

    // Periodical
    period,
    periodSourceData,
    periodicalResult,

    // Hg Farmer
    hgFarmerSourceData,
    hgFarmerResult,

    // unsupervised
    mixedModePeriod,
    mixedModeResult,

    // GeneticDistance
    geneticDistance,
    ancientProxSourceData,
    ancientProxResult,
    ancientProxSampleCoordinate,
    modernProxSourceData,
    modernProxResult,

    // PCA plot
    pcaRegion,
    pcaSourceData,

    // DIY
    diy,
    diySourceData,
    diyGDSourceData,

    // Sample Database
    sampleDatabase,
    ancientDatabase,
    ancientIndividualDatabase,
    modernDatabase,

    utils,
  },
  strict: process.env.DEV,
})
