<template>
  <div>
    <div class="checkout-card" v-if="screen === 'DESKTOP'">
      <div class="row confirm-dp-container">
        <div class="col-lg-6" v-if="isUserLoggedIn === false">
          <div class="confirm-card">
             <account ref="account"/>
             <check-box-confirm ref="checkboxConfirmAuth"/>
          </div>
        </div>
        <div class="col-lg-6" :class="{ 'col-lg-12' : isUserLoggedIn}">
          <div class="confirm-card">
            <div class="nearly-done">
              <h4>Nearly Done</h4>
              <p>Select an ethnicity for each kit to assign a default region. Don’t worry, this won’t affect your results. </p>
            </div>
            <kits-ethnicity />
            <payment ref="payment" class="mt-3"/>
            <check-box-confirm ref="checkboxConfirm"  v-if="isUserLoggedIn === true"/>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="step-buttons">
          <button class="lab-btn next valid" @click="confirm()" >
            <spinner-loader />
            Confirm
          </button>
        </div>
      </div>
    </div>

    <div class="checkout-card-mobile" v-if="screen === 'MOBILE'">
      <div class="title">
        <h4>Nearly done....</h4>
      </div>
      <div class="desc">
        <p>Select an ethnicity for each kit to assign a default region. Don’t worry, this won’t affect your results. </p>
      </div>
      <div class="mobile-body">
        <kits-ethnicity />
      </div>
      <div>
        <payment ref="payment"/>
      </div>
      <div>
        <check-box-confirm ref="checkboxConfirm" />
      </div>
      <div class="step-buttons-mobile">
        <button class="lab-btn back" @click="prev()">Back</button>
        <button class="lab-btn next valid" @click="confirm()" :disabled="isStartLoading">
          <spinner-loader />
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service';
import { DEEP_ANCESTRY_CHECKOUT_STEPS } from '@/common/config';

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Account from './Account.vue';
import KitsEthnicity from './KitsEthnicity.vue';
import Payment from './Payment.vue';
import CheckBoxConfirm from './others/CheckBoxConfirm.vue';

export default {
  components: {
    Account,
    KitsEthnicity,
    Payment,
    CheckBoxConfirm,
  },
  props: {
    currentRouteData: {
      type: Object,
    },
    screen: {
      type: String,
      default: 'DESKTOP',
    },
  },
  data() {
    return {
      DEEP_ANCESTRY_CHECKOUT_STEPS,
      isValidUser: false,
      isValid: true,

      checkoutDataResponse: { statusCode: 200 },
      isStartLoading: false,
    }
  },
  methods: {
    checkOrderWarning(orderData) {
      orderData.kits.forEach(fileItem => {
        if (fileItem.presetEthnicityId === null) {
          fileItem.isEmptyPresetEthnicityId = true;
          this.isValid = false;
        }
      });

      if (orderData.isConfirmPrivacyPalicy === false) {
        this.isValid = false;
        if (this.isUserLoggedIn) {
          this.$refs.checkboxConfirm.validateCheckbox();
        } else {
          this.$refs.checkboxConfirmAuth.validateCheckbox();
        }
      }
    },

    checkUserWarning(userData) {
      if (!userData.userEmail) {
        this.isValid = false;
      }
      if (!userData.firstName) {
        this.isValid = false;
      }
      if (!userData.lastName) {
        this.isValid = false;
      }
      if (!userData.password) {
        this.isValid = false;
      }
    },

    confirm() {
      /* eslint-disable */
      if(this.isStartLoading === true) {
        return;
      }
      this.isStartLoading = true;

      this.isValid = true;
      const orderData = this.$store.getters['g25PartnerCheckout/getCreateOrderData'];
      let queryParams = this.$store.getters['g25PartnerCheckout/getCheckoutQuery'];

      this.checkOrderWarning(orderData);

      if (this.isValid != true) {
        this.isStartLoading = false;
        return;
      }

      let userData = null;
      if (this.isUserLoggedIn === false) {
        userData = this.$store.getters['account/getUserData'];
        this.checkUserWarning(userData);
        if(this.isValid != true) {
          this.isStartLoading = false;
          return;
        }
      }

      this.checkUserEmail(userData, () => {
        this.$refs.payment.payWithCard((paymentResult) => {
          if (paymentResult.error) {
            AlertService.warning(paymentResult.error.message);
            this.isStartLoading = false;
            return;
          } else {
            if (paymentResult.paymentIntent.status !== 'succeeded') {
              this.isStartLoading = false;
              return;
            }
          }

          queryParams = this.$store.getters['g25PartnerCheckout/getCheckoutQuery'];
          queryParams.user = userData;
          this.saveCheckout(queryParams);
        });
      });
    },

    saveCheckout(queryParams) {
      this.$store.dispatch('g25PartnerCheckout/saveChecout', queryParams)
        .then(dataResponse => {
          this.isStartLoading = false;
          this.checkoutDataResponse = dataResponse;

          if (dataResponse.statusCode === 200 || dataResponse.statusCode === 1009) {
            this.checkLogin(queryParams.user, () => {
              this.$store.commit('g25PartnerCheckout/resetOrderState');
              this.$router.push({ name: 'dashboard' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Successful',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.$t(dataResponse.message),
                    },
                  });

                  if (dataResponse.statusCode === 1009) {
                    AlertService.error('Your order has been successfully received. But there is a error with your uploaded files. please try to re-upload the files.');
                  }
                });
            });

          } else {
            AlertService.error(dataResponse.message);
          }
        });
    },

    checkLogin(userData, callback) {
      if (userData) {
        useJwt.login({
          userNameOrEmail: userData.userEmail,
          password: userData.password,
          rememberMe: true,
        })
          .then(loginResponse => {
            const userData = loginResponse.result
            useJwt.setToken(loginResponse.result.token)
            useJwt.setRefreshToken(loginResponse.result.token)
            useJwt.setUserData(userData)
            this.$store.commit('account/setIsLoggedIn', true);
            if (typeof callback === 'function') {
              callback();
            }
          });
      } else {
        callback();
      }
    },

    checkUserEmail(userData, callback) {
      if (userData) {
        ApiService.post(`user/checkemail`, { email: userData.userEmail }, this)
        .then(response => {
          if (response.statusCode === 200) {
            if (typeof callback === 'function') {
              callback();
            }
          } else {
            this.isStartLoading = false;
            this.checkoutDataResponse = response;
          }
        });
      } else {
        if (typeof callback === 'function') {
          callback();
        }
      }
    },

    initValidator() {
      this.$refs.account.$refs.accountValidation.validate().then(success => {
        this.isValidUser = success;
      });
    },
    validator() {
      try {
        this.initValidator();
        this.$nextTick(() => {
          this.$watch(
            () => this.$refs.account.$refs.accountValidation.validate(),
            (newValue) => {
              newValue.then(success => {
                this.isValidUser = success;
              });
            },
          );
        });
      }
      catch {
      }
    },

    prev() {
      this.$router.push(`/checkout/dp/${this.DEEP_ANCESTRY_CHECKOUT_STEPS.UPLOAD}/${this.currentRouteData.productCode}`);
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
    isValidKits() {
      /* eslint-disable */
      const kits = this.$store.getters['deepACheckout/getCreateOrderData'].kits;

      return !kits.find(x => x.presetEthnicityId == null);
    },
    productData() {
      return this.$store.getters['deepACheckout/getProductData'];
    },
  },
  mounted() {
    this.validator();
  },
}
</script>

<style lang="scss" scoped>
.checkout-card {
  max-width: 1100px;
}
.confirm-dp-container {
  padding: 1rem;
}

.checkout-card-mobile {
  .title {
    text-align: center;
  }
  .desc {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    p {
      font-size: 0.95rem;
      color: var(--lab-gray-75);
    }
  }

  .mobile-body {
    min-height: 150px;
  }
}

.nearly-done {
  padding: 0.2rem 1.5rem;
  h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  p {
    margin-top: 20px;
    line-height: 1rem;
    font-size: 0.7rem;
    color: var(--lab-gray-75);
  }
}

.confirm-card {
  min-width: 450px
}
</style>
