<template>
    <div class="top-header-container">
      <h4 class="report-title">
        Unsupervised Analysis
        <span @click="unsupervisedAnalysisInfoModal.showModal = true" class="open-info-modal-btn" v-if="false">
          <sup class="title-info-button-area">
            <InfoIcon />
          </sup>
        </span>
      </h4>
      <hr />
      <p class="description">
        The "Unsupervised Analysis" section displays the most suitable 2 and 3 ancient populations or samples that can represent your genetic profile. There are 50 different combinations are listed from best fitted to less.  The populations listed do not necessarily imply direct descent; the section aims to provide insights into the composition of your genetic makeup.
      </p>
      <hr/>
      <h5 class="report-subtitle mb-1">Select the mode</h5>
      <UnsupervisedAnalysisSelectMode class="mb-3"/>
      <UnsupervisedAnalysisPeriods class="mb-1" v-if="$store.state.mixedModePeriod.selectedReport === 'Ancient'" />
      <UnsupervisedAnalysisInfoModal v-if="unsupervisedAnalysisInfoModal.showModal" :modalObject="unsupervisedAnalysisInfoModal"/>
    </div>
  </template>
  
<script>
import InfoIcon from '@/layouts/icons/InfoIcon.vue';
import UnsupervisedAnalysisInfoModal from './modals/UnsupervisedAnalysisInfoModal.vue';
import UnsupervisedAnalysisPeriods from './details/UnsupervisedAnalysisPeriods.vue';
import UnsupervisedAnalysisSelectMode from './details/UnsupervisedAnalysisSelectMode.vue';

export default {
    components: {
      InfoIcon,
      UnsupervisedAnalysisInfoModal,
      UnsupervisedAnalysisPeriods,
      UnsupervisedAnalysisSelectMode,
    },
    data() {
      return {
        unsupervisedAnalysisInfoModal: {
          showModal: false,
        },
          openDetail: false,
      }
    }
}
</script>

<style lang="scss" scoped>
.top-header-container {
  padding: 28px;
  background-color: var(--bg-card-color);
}

.open-select-region {
  cursor: pointer;
  text-decoration: underline;
  color: var(--lab-main-color);
}
</style>