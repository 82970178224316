<template>
  <div class="result-list-container">
    <div class="model-view-item">
      <div class="model-view-item-header d-flex align-items-center justify-content-space-between">
        <div id="menu-area" class="menu-content w-50">
          <ul class="menu-items">
            <li :key="tab.Id"  v-for="(tab, index) in tabs" class="menu-item-li">
              <a class="menu-link dp-menu-item" :key="index" :class="{ 'active':  tab.Id === $store.state.diySourceData.selectedTab, 'passed':  checkTab(tab.Id)  }">{{ tab.name }}</a>
            </li>
          </ul>
        </div>
        <div class="nav-area justify-content-end">
          <div class="left-area d-flex justify-content-end" v-if="$store.state.diySourceData.selectedTab === 'RESULT' || $store.state.diySourceData.selectedTab === 'SOURCE'">
            <button class="lab-btn w-10" @click="prevTab()"><feather-icon icon="ArrowLeftIcon" class="mr-05" size="20"/>Back</button>
          </div>
          <div class="right-area d-flex justify-content-end" v-if="$store.state.diySourceData.selectedTab === 'TARGET'">
            <button class="lab-btn w-10" :disabled="checkLenTargetSelected()" @click="nextTab()">Next</button>
          </div>
          <div class="right-area d-flex justify-content-end" v-if="$store.state.diySourceData.selectedTab === 'SOURCE'">
            <button class="lab-btn w-10" :disabled="checkLenSourceSelected()" @click="calculate()">Calculate</button>
          </div>
        </div>
      </div>
      <div>
      </div>
      <div class="model-view-item-description" v-if="$store.state.diySourceData.selectedTab === 'TARGET'">
        Please select the target population or kit(s) from the list below that you want to model. You can choose multiple populations or kits.
        Once you've made your selections, click the “Next” button at the top right to proceed to source selection.
      </div>
      <div class="model-view-item-description" v-if="$store.state.diySourceData.selectedTab === 'SOURCE'">
        Select your source population/samples that you want to use as a source for your selected targets then hit the “Calculate” button.
      </div>
      <div class="model-view-item-content d-flex justify-content-space-between">
        <DiyToolsTargetView v-if="$store.state.diySourceData.selectedTab === 'TARGET'" />
        <DiyToolsSourceView v-if="$store.state.diySourceData.selectedTab === 'SOURCE'" />
        <DiyToolsResultsView v-if="$store.state.diySourceData.selectedTab === 'RESULT'" />
      </div>
      <div class="model-view-item-footer d-flex justify-content-space-between">
        
      </div>
    </div>
  </div>
</template>


<script>
import diySourceDataService from '../services/diySourceDataService';
import DiyToolsResultsView from './DiyToolsResultsView.vue';
import DiyToolsSourceView from './DiyToolsSourceView.vue';
import DiyToolsTargetView from './DiyToolsTargetView.vue';
import diyCalculateService from '../services/diyCalculateService';

export default {
  components: {
    DiyToolsTargetView,
    DiyToolsResultsView,
    DiyToolsSourceView,
  },
  data() {
    return {
      tabs: [
        { name: '1. Target', Id: 'TARGET' },
        { name: '2. Source', Id: 'SOURCE' },
        { name: '3. Results', Id: 'RESULT' },
      ],
      selectedTab: "TARGET",
    }
  },
  computed: {
    targetSelectedList() {
      return this.$store.state.diySourceData.targetSelectedList;
    },
    sourceSelectedList() {
      return this.$store.state.diySourceData.sourceSelectedList;
    },
  },
  methods: {
    selectTab(Id) {
      this.$store.state.diySourceData.selectedTab = Id;
    },
    nextTab() {
      if (this.$store.state.diySourceData.selectedTab === 'TARGET') {
        this.$store.state.diySourceData.selectedTab = "SOURCE";
      } else if (this.$store.state.diySourceData.selectedTab === 'SOURCE') {
        this.$store.state.diySourceData.selectedTab = "RESULT";
      }
    },
    prevTab() {
      if (this.$store.state.diySourceData.selectedTab === 'SOURCE') {
        this.$store.state.diySourceData.selectedTab = "TARGET";
      } else if (this.$store.state.diySourceData.selectedTab === 'RESULT') {
        this.$store.state.diySourceData.selectedTab = "SOURCE";
      }
    },
    checkTab(currentTab) {
      if (this.$store.state.diySourceData.selectedTab === 'RESULT' && (currentTab === 'TARGET' || currentTab === 'SOURCE' || currentTab === 'RESULT')) {
        return true;
      }
      if (this.$store.state.diySourceData.selectedTab === 'SOURCE' && (currentTab === 'TARGET' || currentTab === 'SOURCE')) {
        return true;
      }
      if (this.$store.state.diySourceData.selectedTab === 'TARGET' && (currentTab === 'TARGET')) {
        return true;
      }
      return false;
    },
    checkLenTargetSelected() {
      if (this.targetSelectedList.length > 0) {
        return false;
      }
      return true;
    },
    checkLenSourceSelected() {
      if (this.sourceSelectedList.length > 1) {
        return false;
      }
      return true;
    },
    calculate() {
      diyCalculateService.runCalculate(this.$store.state.diySourceData.populationNumber);
      this.$store.state.diySourceData.selectedTab = 'RESULT';
    },
  },
  async mounted() {
    this.selectTab(this.selectedTab);
    await diySourceDataService.loadSourceDatas();
  }
}
</script>


<style lang="scss" scoped>
.progress-bar {
  transition : width 0.5s linear!important;
}
.result-list-container {
  padding: 28px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 140%;
  padding: 3rem 1rem;


  @media screen and (max-width: 1079px) {
    overflow-y: hidden;
    overflow-x: hidden !important;
  }

  .model-view-header {
    display: flex;
    flex-direction: row;
    width: 75%;
  }
  .model-latest-version {
    width: 50%;
  }
  .model-genetic-fit {
    width: 50%;
  }
  .text-end {
    float:right;
  }
  
  .model-view-item {
    width: 75%;
    background-color: var(--bg-card-color);
    border: var(--lab-card-border);
    box-shadow: var(--lab-card-shadow);
    border-radius: 10pt;
    margin-bottom: 15px;
    flex-direction: column;
    padding: 60px 40px;
    padding-bottom: 70px;
  }
  .model-view-item-donut {
    width: 45%;
  }
  .model-view-item-header {
    width: 100%;
    justify-content: space-between;
  }
  .model-view-item-content {
    width: 100%;
    height: 90%;
  }
  .model-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .model-view-progress {
    width: 90%;
    margin-right: 10px;
  }
  .model-view-percentage {
    font-size: 0.9em;
    font-weight: 600;
  }
  .btn-outline-light {
    border: 1px solid #ddd !important;
    background-color: transparent;
  }
  .model-view-item-description {
    margin-top:30px;
    width: 80%;
    font-size: 0.8rem;
  }

  .model-view .model-view-item {
    height: 100%;
    display: flex;
    justify-content: space-between;
    transition: all 0.25s linear;
    }
    .model-item-row-container {
        width: 75%;
        display: flex;
        flex-direction: column;
    }
}
.menu-items {
    position: relative;
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0; 
    padding: 0;
    // border-bottom: 2pt solid var(--lab-hr-color);
    flex-direction: row;
    justify-content: space-between;

    .menu-item-li {
      flex: 0 0 auto;
      width: 30%;
      padding: 10px 0px;
      margin: 0 5px;
      color: white;
      text-align: left;
      border-radius: 5px;
      cursor: pointer;
      white-space: nowrap;
      
    }

    .menu-link {
      position: relative;
      display: flex;
      align-items: center; 
      gap: 8px;
      margin-right: 10px;
      text-decoration: none;
      color: var(--lab-diy-tab-color);
      font-size: 1rem;
      font-weight: 600;
      text-align: left;
      display: block;
      &.active {
        color: var(--lab-diy-tab-passed-color);
      }
      &.passed {
        color: var(--lab-diy-tab-passed-color);
      }
      &:hover::after,
      &.active::after {
        width: 110%;
        left: -5%; 
        opacity: 1;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 0;
        height: 4px;
        // border-bottom: 2pt solid var(--lab-main-color);
        height: 14px;
        background-color: transparent; 
        transition: width 0.3s ease, opacity 0.3s ease;
        opacity: 0; 
      }
    }
  }

  .nav-area {
    display: flex;
    width: 30%;
    flex-direction: row;
  }
  .left-area{
    width: 49%;
  }
  .right-area{
    width: 49%;
  }
  button[disabled] {
    background:#6e8081;
  }
</style>