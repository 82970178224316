<template>
  <div class="dashboard-card">
    <div class="dashboard-card-title">
      <h5>HELLO!</h5>
      <p>
        We’ve made some internal updates and enhancements to our system. To continue receiving updates, all our previous customers are required to upgrade to version 2. This upgrade is free of charge and will require you to re-upload your raw data files.
      </p>
      <p>
        Please note that the new coordinates will not be compatible with the old “G25” coordinates. You may also notice some missing samples, which will be addressed in a major update we are actively working on.
      </p>
      <p>
        If you wish to save your old coordinates, please click the “Download Old Coordinates” button below before proceeding with the upgrade.
      </p>
      <p>
        Thank you for your understanding and support as we work to improve your experience!
      </p>
    </div>
    <div class="download">
      <button class="lab-btn lab-btn-success ml-1" @click="openDownloadCoordinateModal()"> Download Old Coordinates </button>
    </div>
    <div class="footer">
      <button class="lab-btn lab-btn-success ml-1" @click="openUpgradePage()"> 
        Start uploading files <TopArrowIcon />
      </button>
    </div>
    <DownloadCoordinatesModal v-if="modalDownloadCoordinates.showModal" :modalObject="modalDownloadCoordinates"/>
  </div>
</template>

<script>
import TopArrowIcon from '@/layouts/icons/TopArrowIcon.vue';
import DownloadCoordinatesModal from '@/views/results/deep-ancestry/download-coordinates/modals/DownloadCoordinatesModal.vue'

export default {
  components: {
    TopArrowIcon,
    DownloadCoordinatesModal,
  },
  data() {
    return {      
      modalDownloadCoordinates: {
        item: null,
        showModal: false,
      },
    }
  },
  methods: {
    openUpgradePage() {
      this.$store.state.updateV2.selectedPage = 'UPGRADE';
    },
    openDownloadCoordinateModal() {
      this.modalDownloadCoordinates.showModal = true;
    },
  },
  computed: {
  },
  created() {
  },
};
</script>

<style scoped lang="scss">
.dashboard-card {
  max-width: 800px;

  p {
    color: var(--lab-carbon-fiber) !important;
  }
}

.dark-layout {
  .dashboard-card {
    p {
      color: var(--lab-light-house) !important;
    }
  }
}

.dashboard-card-body {
  max-height: 300px;
  overflow-x: hidden!important;
}

.download {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 30px 30px;

  button {
    background-color: #6BD67B !important;
  }
}
</style>
