<template>
  <div class="lab-layout">
    <AppTopHeader class="lab-layout-desktop-header" v-if="isUserLoggedIn"/>
    <mobile-lab-header class="lab-layout-mobile-header" v-if="isUserLoggedIn"/>
    
    <template v-if="isUserLoggedIn">
      <AppMenuHeader v-if="$store.state.appConfig.windowWidth >= 1079" /> 
    </template>
    
    <div class="body" :class="{ 'deep-ancestry-body': $route.meta.layout === 'deep-ancestry' }">
      <router-view class="router-main"/>
    </div>
    
    <mobile-lab-bottom-navbar class="lab-layout-mobile" ref="mobile_lab_bottom_navbar" v-if="isUserLoggedIn"/>
    <mobile-lab-sidebar class="lab-layout-mobile" v-if="isUserLoggedIn"/>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import AppTopHeader from '../components/AppTopHeader.vue';
import AppMenuHeader from '../components/AppMenuHeader.vue';
import MobileLabHeader from '../components/mobile-layout-components/MobileLabHeader.vue';
import MobileLabBottomNavbar from '../components/mobile-layout-components/MobileLabBottomNavbar.vue';
import MobileLabSidebar from '../components/mobile-layout-components/MobileLabSidebar.vue';

export default {
  components: {
    AppTopHeader,
    AppMenuHeader,
    MobileLabHeader,
    MobileLabBottomNavbar,
    MobileLabSidebar,
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
  },
  setup() {
    const {
      skin,
      navbarType,
      routerTransition,
      isNavMenuHidden,
      contentWidth,
    } = useAppConfig()

    return {
      skin,
      navbarType,
      isNavMenuHidden,
      routerTransition,
      contentWidth,
    }
  },
}
</script>

<style lang="scss">
.lab-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    padding: 26px;
    position: relative;
  }

  .deep-ancestry-body {
    padding: 0;
  }
}

.lab-layout-mobile-header {
  -webkit-box-shadow: 0px 2px 17px 0px var(--lab-mobile-header-shadow-color);
  -moz-box-shadow: 0px 2px 17px 0px  var(--lab-mobile-header-shadow-color);
  box-shadow: 0px 2px 17px 0px var(--lab-mobile-header-shadow-color);
  background-color: var(--lab-mobile-header-color)!important;
}


@media screen and (max-width: 1079px) {
  .lab-layout-desktop-header {
    display: none !important;
  }
}

@media screen and (min-width: 1079px) {
  .lab-layout-mobile-header, .lab-layout-mobile {
    display: none !important;
  }
}

</style>
