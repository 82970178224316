<template>
  <nav class="mobile-lab-header">
    <header class="header">
      <div v-if="$route.meta.parentCode === 'DASHBOARD'" class="back">
        <button class="lab-btn lab-btn-back" @click="$router.push({ name: 'dashboard'})" > 
          <!-- <feather-icon icon="ArrowLeftIcon" size="20"/> -->
          <BackArrowIcon class="back-arrow-icon" />
        </button>
      </div>
      <div v-if="$route.meta.parentCode === 'DEEP-ANCESTRY'" class="back">
        <button class="lab-btn lab-btn-back" @click="$router.push({ name: 'dashboard-da'})" > 
          <BackArrowIcon class="back-arrow-icon" />
        </button>
      </div>
      <div class="icon-text">
        <img :src="iconUrl">
      </div>
    </header>
  </nav>
</template>

<script>
import BackArrowIcon from '../icons/BackArrowIcon.vue';

export default {
  components: { 
    BackArrowIcon
  },
  props: {
  },
  data() {
    return {
      menuIconUrl: 'https://image.illustrativedna.com/assets/icons/menu/',
      isHiddenMenuExpand: true,
      hoverMenuCode: '',
    }
  },  
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin;
    },
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },
    iconUrl() {
      /* eslint-disable */
      return 'https://image.illustrativedna.com/assets/icons/' + this.theme + '/icon-text.svg';
    },
    iconToggleUrl() {
      /* eslint-disable */
      return this.isHiddenMenuExpand ? 'https://image.illustrativedna.com/assets/icons/'+this.theme+'/menu-toggle-right.svg' : 'https://image.illustrativedna.com/assets/icons/'+this.theme+'/menu-toggle-left.svg';
    }
  },
  methods: {
    onToggleMenu() {
      this.isHiddenMenuExpand = !this.isHiddenMenuExpand;
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-lab-header {
  position: fixed;
  height: 60px;
  width: 100%;
  padding: 7px 15px;
  background: var(--lab-mobile-header-color);
  transition: var(--lab-trans-05);
  z-index: 1001;
  text-align: center;

  .header {
    position: relative;
    .back {
      position: absolute;
      top: 8px;
    }
  } 
}

.mobile-lab-header img {
  width: 80 !important;
  height: 45px !important;
}

.back-arrow-icon {
  height: 1.1rem;
}

.lab-btn-back {
  background-color: transparent;
}

</style>
