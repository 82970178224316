import { render, staticRenderFns } from "./OriginBreakdownInfoModal.vue?vue&type=template&id=5e49f279&scoped=true&"
import script from "./OriginBreakdownInfoModal.vue?vue&type=script&lang=js&"
export * from "./OriginBreakdownInfoModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e49f279",
  null
  
)

export default component.exports