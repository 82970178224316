<template>
  <div class="da-container">
    <div class="periodical" v-if="$store.state.appConfig.windowWidth >= 1079">
      <div class="result-container cs-scroll-style">
        <PeriodicalTopHeader />
        <PeriodicalPopView />
      </div>
      <div class="map-container">
        <PeriodicalMap />
      </div>
    </div>

    <div class="periodical-mobile" v-else>
      <div class="mobile-result-container">
        <PeriodicalMap class="mobile-map"/>
        <div class="mobile-container"
          :style="{ transform: 'translateY(' + contentPosition + 'px)' }">
          <div  :class="{'scrollable-wrapper': contentPosition === 0}">
            <div @touchstart="startDrag"
                @touchmove="onDrag"
                @touchend="endDrag">
                <PeriodicalTopHeader class="mobile-header" :contentPosition="contentPosition"/>
            </div>
            
            <PeriodicalPopView />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PeriodicalCalculateWatcher from './Services/PeriodicalCalculateWatcher'

import PeriodicalTopHeader from './PeriodicalTopHeader.vue';
import PeriodicalPopView from './details/PeriodicalPopView.vue';
import PeriodicalMap from './maps/PeriodicalMap.vue';

export default {
  components: {
    PeriodicalTopHeader,
    PeriodicalPopView,
    PeriodicalMap,
  },
  data() {
    return {
      startY: 0,
      contentPosition: 250,
      minPosition: 0,
      maxPosition: 250,
    };
  },
  methods: {
    startDrag(event) {
      this.startY = event.touches[0].clientY - this.contentPosition;
    },
    onDrag(event) {
      let newY = event.touches[0].clientY - this.startY;
      if (newY < this.minPosition) newY = this.minPosition;
      if (newY > this.maxPosition) newY = this.maxPosition;
      if (newY >= 0) {
        this.contentPosition = newY;
      }
    },
    endDrag() {
      if (this.contentPosition < 245) {
        this.contentPosition = 0;
      }
    },
  },
  mounted() {
    PeriodicalCalculateWatcher.init();
    document.body.classList.add('fix-scroll');
  },
  beforeDestroy() {
    PeriodicalCalculateWatcher.cleanup();
    document.body.classList.remove('fix-scroll');
  },
};
</script>

<style lang="scss" scoped>
.periodical {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .result-container {
    width: 30%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    height: 100%;
    box-sizing: border-box;
  }
  
  .map-container {
    width: 70%;
    height: 100%;
    position: relative;
  }
}

.periodical-mobile {
  height: 100vh;
  .mobile-result-container {
    flex-direction: column;
    display: flex;
    height: 100%;
    box-sizing: border-box;

    .mobile-map {
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .mobile-container {
      display: flex;
      flex-direction: column;
      position: fixed;
      left: 0;
      bottom: 0;
      top: 60px;
      width: 100%;
      height: calc(100% - 100px);
      transition: transform 0.3s ease;
      will-change: transform;

      .scrollable-wrapper {
        overflow-y: auto;
        background-color: var(--bg-body-color);
      }
    }
  }
}

</style>
