<template>
    <div class="diy-view-container">
      <div class="diy-view-column w-60">
        <div class="lab-dropdown mb-1">
          <label for="custom-dropdown" class="dropdown-label mb-1">Filter Source type</label>
          <div class="row">
            <div class="col-sm-6">
              <div class="lab-dropdown-btn" @click="isDropdownOpen = !isDropdownOpen" ref="dropdownType">
              <span class="lab-dropdown-btn-value">
                {{ filteredSourceType || 'All' }}
              </span>
              <span class="lab-dropdown-btn-icon">
                <DropdownOneIcon />
              </span>
              </div>
              <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isDropdownOpen}">
                <ul>
                  <li v-for="item in sourceTypes"
                      :key="item.id"
                      @click="$store.state.diySourceData.filteredSourceType = item.id">
                      {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-6">
              <b-form-input v-model="searchQuery" id="searchSelectedList" :placeholder="$t('Search')" autocomplete="off" />
            </div>
        </div>
        </div>

        <ul class="diy-targets-menu arrow-scroll-info cs-scroll-style" id="source-unselected-list" @wheel="onScroll(`source-unselected-list`)"  @scroll="onScroll(`source-unselected-list`)">
          <template v-if="sourceUnSelectedList && !$store.getters.getLoader">
            <li v-for="item in sourceUnSelectedList" :key="item.Id">
            <div class="diy-group" @click="toggleGroup(item)">
              <feather-icon
                  icon="ChevronDownIcon"
                  class="cursor-pointer lab-text-steel"
                  size="25"
                  v-if="isOpened(item)"
                />
              <feather-icon
                icon="ChevronRightIcon"
                class="cursor-pointer lab-text-steel"
                size="25"
                v-else
              /> 
              
              <div class="diy-name">
                {{ item.groupName.replaceAll('*', ' ') }}
              </div>
              <!-- <span class="select-all-items" v-if="isOpened(item)" @click="selectAll(item)" alt="Select all">
                Select All
                <SelectAllIcon class="ml-1"/>
              </span> -->
            </div>
            <ul class="diy-targets-sub-menu cs-scroll-style" v-show="isOpened(item)">
              <li class="select-all-li"  @click="selectAll(item)" v-if="item.list.length > 1">
                <span>Select all</span> 
                <input type="checkbox" id="checkbox" :value="false" @change="forceFalse" :checked="false" />
              </li>
              
              <li v-for="subItem in item.list" :key="subItem.name" @click="moveToSelected(subItem)">
                <div class="diy-group p-0">
                  <feather-icon
                    icon="ChevronRightIcon"
                    class="cursor-pointer lab-text-steel"
                    size="25"
                    />
                  <div class="diy-name">
                    {{ replaceName(subItem.name.replaceAll(item.groupName + ':', '')) }}
                  </div>
                </div>
              </li>
            </ul>
          </li>
          </template>
          <SpinnerContainer v-else />
        </ul>
      </div>
      <div class="diy-view-column border-diy"></div>
      <div class="diy-view-column w-40">
        <label for="custom-dropdown" class="dropdown-label mb-1">Population limit</label>
        <div class="sources-description mb-1">
          You can  limit the number of source populations to be used in the calculation. I.e if you have 10 populations selected, you can force the algorithm to use the best fitting 3, 4 or 5 populations
among them. This can help you to receive more refined results.
        </div>
        <div class="lab-dropdown sm region">
        <div class="lab-dropdown-btn" @click="isDropDown = !isDropDown" ref="dropdownReport">
          <span class="lab-dropdown-btn-value">
            {{ $store.state.diySourceData.populationNumber || 'No limit' }}
          </span>
          <span class="lab-dropdown-btn-icon">
            <DropdownOneIcon />
          </span>
        </div>
        <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isDropDown}">
          <ul>
            <li v-for="item in limitTypes"
                :key="item.id"
                @click="$store.state.diySourceData.populationNumber = item.value">
                {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
        <p class="selection-info p-1">
          Selected populations are listed here. Click on to unselect.
        </p>
        <div class="d-block p-1" v-if="sourceSelectedList.length > 0">
          <button class="lab-btn lab-btn-remove-all p-1" @click="removeAllSelected">Remove All</button>
        </div>
        <ul class="diy-targets-menu cs-scroll-style">
          <li v-for="item in sourceSelectedList" class="select" :key="item.Id" @click="moveToUnselected(item)">
             {{ replaceName(item.name) }}
          </li>
        </ul>
      </div>
    </div>
  </template>
      
      
  <script>
  import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';
  import SelectAllIcon from '@/layouts/icons/SelectAllIcon.vue';
  
  export default {
    components: {
      DropdownOneIcon,
      // SelectAllIcon
    },
    data() {
      return {
        isDropDown: false,
        isDropdownOpen: false,
        searchQuery: '',
        noSelect: false,
        openedGroupList: [],
        sourceTypes: [
          { id: 'ANCIENT_POP', name: 'Ancient Populations' },
          { id: 'MODERN_POP', name: 'Modern Populations' },
        ],
        selectedPopNumber: 0,
        limitTypes: [
          { value: 0, name: 'No limit' },
          { value: 3, name: '3' },
          { value: 4, name: '4' },
          { value: 5, name: '5' },
        ]
      }
    },
    methods: {
      documentClick(e) {
        /* eslint-disable */
        try {
          const el = this.$refs.dropdownType;
          const target = e.target;
          if (el !== target && !el.contains(target)) {
            this.isDropdownOpen = false;
          }
        } catch (error) {}
        try {
          const el = this.$refs.dropdownReport;
          const target = e.target;
          if (el !== target && !el.contains(target)) {
            this.isDropDown = false;
          }
        } catch (error) {}
      },
      replaceName(name) {
        return name.replaceAll('*', ' ').replaceAll(':', ': ').replaceAll('_scaled', '');
      },
      forceFalse(event) {
        event.target.checked = false;
      },
      toggleGroup(item) {
        const findOpened = this.openedGroupList.find(x => x === item.groupName);
        if (findOpened) {
          const index = this.openedGroupList.indexOf(findOpened);

          if (index > -1) {
            this.openedGroupList.splice(index, 1);
          }
        } else {
          this.openedGroupList.push(item.groupName);
        }
      },
      isOpened(item) {
        return this.openedGroupList.find(x => x === item.groupName);
      },
  
      moveToSelected(item) {
        const index = this.$store.state.diySourceData.sourceUnSelectedList.indexOf(item);
        if (index > -1) {
          this.$store.state.diySourceData.sourceUnSelectedList.splice(index, 1);
          // this.sourceSelectedList.push(item);
          this.sourceSelectedList.unshift(...[item]);
        } else {
          console.warn("Item not found in source list");
        }
      },

      selectAll(item) {
        for(let i=0; i < item.list.length; i++) {
          this.moveToSelected(item.list[i]);
        }
        this.noSelect = false;
      },

      moveToUnselected(item) {
        const index = this.sourceSelectedList.indexOf(item);
        if (index > -1) {
          this.sourceSelectedList.splice(index, 1);
          this.$store.state.diySourceData.sourceUnSelectedList.push(item);
        } else {
          console.warn("Item not found in source list");
        }
      },
      onScroll(name) {
        const element = document.getElementById(`${name}`);
        if (element) {
          this.$store.state.utils.checkIfScrolledToBottom(element);
        }
      },
      removeAllSelected() {
        for(let i=0; i < this.sourceSelectedList.length; i++) {
          this.$store.state.diySourceData.sourceUnSelectedList.push(this.sourceSelectedList[i]);
        }
        this.$store.state.diySourceData.sourceSelectedList = [];
      }
    },
    computed: {
      filteredSourceType() {
        const type = this.$store.state.diySourceData.filteredSourceType;
        return this.sourceTypes.find(x => x.id === type).name;
      },
  
      sourceUnSelectedList() {
        const dataList = this.$store.state.diySourceData.sourceUnSelectedList.filter(x => x.type === this.$store.state.diySourceData.filteredSourceType && x.name.toLowerCase().includes(this.searchQuery.toLowerCase())).sort((a, b) => {
          let comp = 0;
          if (a.name < b.name) {
            comp = -1;
          } else if (a.name > b.name) {
            comp = 1;
          }
          return comp;
        });
        return dataList.reduce((acc, item) => {
            const group = acc.find(g => g.groupName === item.groupName);
            if (group) {
              group.list.push(item);
            } else {
              acc.push({
                groupName: item.groupName,
                list: [item]
              });
            }
            return acc;
          }, []);
      },
      sourceSelectedList() {
        return this.$store.state.diySourceData.sourceSelectedList;
      },
    },
    mounted() {
    },
    beforeMount() {
      document.addEventListener("click", this.documentClick);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.documentClick);
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .sources-description {
    font-size: 0.8rem;
  }

  </style>