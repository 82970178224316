<template>
  <nav class="menu-header" :class="{ fixed: isFixed }">
    <div class="menu-content">
      <!-- <div class="logo" @click="$router.push({name: 'dashboard'})">
        <img :src="iconUrl" alt="Logo" />
      </div> -->
      <ul class="menu-items">
        <li class="mr-2">
          <router-link class="menu-link" :class="{ 'active': $route.meta.code == 'DASHBOARD'}" to="/">
            <HomeIcon />
            Dashboard
          </router-link>
        </li>
        <li>
          <router-link class="menu-link" :class="{ 'active': $route.meta.layout == 'deep-ancestry'}" to="/deep-ancestry/periodical" >
            <DeepAncestryIcon />
            DeepAncestry<sup class="trade-mark-text">TM</sup>
          </router-link>
        </li>
      </ul>
      <div class="" v-if="hasV1OrderResult">
        <button class="lab-btn update-button" @click="$router.push({ name: 'dashboard-upgradev2' })"><UpgradeIcon /> Upgrade to v.2</button>
      </div>
    </div>
  </nav>
</template>

<script>
import HomeIcon from './icons/HomeIcon.vue';
import DeepAncestryIcon from './icons/DeepAncestryIcon.vue';
import UpgradeIcon from '../icons/UpgradeIcon.vue';

export default {
  name: 'MenuHeader',
  components: {
    HomeIcon,
    DeepAncestryIcon,
    UpgradeIcon,
  },
  data() {
    return {
      isFixed: false,

      menuIconUrl: 'https://image.illustrativedna.com/assets/icons/menu/',
    };
  },
  methods: {
  },
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin;
    },
    hasV1OrderResult() {
      return this.$store.state.daResult.resultList.find(x => x.dataVersion === 'D-01');
    },
  },
};
</script>

<style lang="scss">

.menu-header {
  background-color: var(--bg-card-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 100%;
  top: 0;
  z-index: 100;
  position: sticky;

  .menu-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    height: 75px;
    margin: 0 auto; 
    margin-bottom: 0px;
    border-bottom: 4px solid var(--lab-hr-color);

    .lab-btn {
      padding: 0.45rem 0.85rem;
    }
  }
  .logo {
    cursor: pointer;
  }
  .logo img {
    height: 40px;
  }

  .menu-items {
    position: relative;
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0; 
    padding: 0;


    .menu-link {
      position: relative;
      display: flex;
      align-items: center; 
      gap: 8px;
      text-decoration: none;
      color: var(--lab-header-menu-color);
      font-size: 0.8rem;
      font-weight: 600;
      svg {
        width: 30px;
        height: 30px;
      }

      .cls-1 {
        fill: var(--lab-header-menu-color);
      }

      &:hover,
      &.active {
        color: var(--lab-main-color);

        
        .cls-1 {
          fill: var(--lab-main-color); 
          transition: fill 0.3s ease;
        }
      }
      &:hover::after,
      &.active::after {
        width: 110%;
        left: -5%; 
        opacity: 1;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 0;
        height: 4px;
        background-color: var(--lab-main-color); 
        transition: width 0.3s ease, opacity 0.3s ease;
        opacity: 0; 
      }
    }
  }

  .update-button {
    // background-color: #6BD67B;
    background-color: var(--lab-main-color);
  }
}

.trade-mark-text {
  left: -0.7em;
  top: -0.4em;
  font-size: 0.6em;
}
</style>
