/* eslint-disable */
import ApiService from "@/common/api.service";

const getDefaultState = () => {
  return {
    uiResults: null,

    savedUiDetails: {},
  }
}

const state = getDefaultState()

const getters = {
  getUiResults(state) {
    return state.uiResults;
  },
}

const mutations = {
  setUiResults(state, data) {
    state.uiResults = data;
  },
}

const actions = {
  async fetchModernProxDetails(context, { listOfSourceData, dataVersion }) {
    try {
      if (!listOfSourceData) {
        return;
      }
      const saveDetail = context.state.savedUiDetails[listOfSourceData.join(',')];
      if (saveDetail) {
        return saveDetail;
      } else {
        const response = await ApiService.post('data/ModernProxDetail', { listOfSourceData, dataVersion }, this);
        
        if (response.statusCode === 200) {
          context.state.savedUiDetails[listOfSourceData.join(',')] = response.result;
          return context.state.savedUiDetails[listOfSourceData.join(',')];
        } else {
          throw error;
        }
      }
      
    } catch (error) {
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
