<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="genetic-similarities-modal"
      hide-footer
      hide-header
      scrollable
      @hidden="hiddenModal"
      size="md"
    >
     <div class="modal-close-container">
      <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
     </div>
     <div class="info-modal-container">
        <div class="info-modal-header">
          <h5>What are Genetic Similarities?</h5>
        </div>
        <p class="description">
          The Genetic Similarity reports show the ancient populations that best represent your genetic profile. 
          These reports compare your DNA to one or more ancient populations that are most similar to you genetically. 
          You can use the tabs to toggle between the reports
        </p>
        <p class="description">
          The populations listed do not necessarily imply direct descent. The reports aim to provide insight into the composition of your genetic makeup.
        </p>
     </div>
     <div class="model-info-bottom">
      <div></div>
      <button class="lab-btn info-modal-btn single-btn" @click="hiddenModal()">Got it</button>
     </div>
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';

export default {
  components: {
    CloseModalIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      sampleInfo: null,
    }
  },
  methods: {
    initialModal() {
      this.$refs['genetic-similarities-modal'].show();
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['genetic-similarities-modal'].hide();
    },
  },
  computed: {
  },
  mounted() {
    this.initialModal()
  },
};
</script>
