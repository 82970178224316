/* eslint-disable */
import ApiService from "@/common/api.service";

const getDefaultState = () => {
  return {
    savedSourceDatas: {},

    selectedFilterSourceDatas: [],
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
}

const actions = {
  async fetchModernProxSourceData(context, dataVersion) {
    try {
      const saveDetail = context.state.savedSourceDatas[dataVersion];
      if (saveDetail) {
        return saveDetail;
      } else {
        const response = await ApiService.get(`data/ModernSouceData/${dataVersion}`, {}, this);
        if (response.statusCode === 200) {
          context.state.savedSourceDatas[dataVersion] = response.result;
          return response.result;
        } else {
          throw error;
        }
      }
    } catch (error) {
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
