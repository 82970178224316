import ApiService from '@/common/api.service';

/* eslint-disable */
const getDefaultState = () => {
  return {
    selectedResult: { id: 0, },
    resultList: [],
  }
}

const state = getDefaultState()

const getters = {
  dataVersion(state) {
    return state.selectedResult.dataVersion;
  },
}

const mutations = {
}

const actions = {
  async fetchResultList(context) {
    try {
      if (context.state.selectedResult.length) {
        return;
      }
      const response = await ApiService.get('OrderResult', {}, this);
      if (response.statusCode === 200) {
        context.state.resultList = response.result;
        if (context.state.resultList.length) {
          context.state.selectedResult = context.state.resultList[0]
        }
      } else {
        console.error('Error fetching data:', response.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
