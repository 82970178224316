<template>
    <div class="encyclopedia-detail dashboard-card cs-scroll-style" v-if="popData != null">
      <div class="encyclopedia-detail-header">
        <button class="lab-btn lab-btn-back" @click="back()"> 
          X
        </button>
      </div>
      <div class="encyclopedia-detail-body cs-scroll-style">
        <b-row>
          <b-col cols="12" md="12" lg="12"  v-if="selectedModule === 'ANCIENT'">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Population' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.title}}
              </div>
            </div>
  
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Date' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.date}}
              </div>
            </div>
  
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Location' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.location}}
              </div>
            </div>
  
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Research' }}</h5>
              </div>
              <hr />
              <div class="desc">
                <a :href="popData.research" class="researchlink" target="_blank">See</a>
              </div>
            </div>
          </b-col>
  
          <b-col cols="12" md="12" lg="12"  v-if="selectedModule === 'ANCIENT-INDIVIDUAL'">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'ID' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.sampleID}}
              </div>
            </div>
  
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Date' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.date}}
              </div>
            </div>
  
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Population' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.name}}
              </div>
            </div>
  
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Location' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.location}}
              </div>
            </div>
  
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Research' }}</h5>
              </div>
              <hr />
              <div class="desc">
                <a :href="popData.researchLink" class="researchlink" target="_blank">{{popData.research}}</a>
              </div>
            </div>
          </b-col>
  
          <b-col cols="12" md="12" lg="12"  v-if="selectedModule === 'MODERN'">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Population' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.replaceAll('*', ' ')}}
              </div>
            </div>
          </b-col>
  
          <b-col cols="12" md="12" lg="12">
            <div class="title-header">
              <h5>{{ 'Genetic Profile' }}</h5>
            </div>
              <hr />
            <div class="desc p-1">
              <div v-for="itemAncestry in farmerHgResultList.outPuts" :key="itemAncestry.resultsTable" class="farmer-hgs mb-1">
                <span><b>{{ itemAncestry.resultsTable.replaceAll('*', ' ') }}:</b></span>
                <span :style="{color: itemAncestry.color}" class="ml-50"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
              </div>
              <h5 v-if="farmerHgResultList.outPuts === 0" class="unavailable">Unavailable</h5>
            </div>
          </b-col>
          <b-col cols="12" md="12" lg="12">
            <div class="title-header closes-pop">
              <h5>{{ 'Genetically Closest Populations' }}</h5>
              <!-- <label class="lab-switch xsm">
                <input type="checkbox" v-model="isModernClosest">
                <span class="slider round"></span>
              </label> -->
            </div>
              <hr />
              <div class="distance-container">
            <table class="table distance-table ">
              <thead>
                  <tr>
                    <th scope="col">Distance</th>
                    <th scope="col">Modern population</th>
                  </tr>
              </thead>
              <tbody class="cs-scroll-style">
                  <tr v-for="distanceItem in distanceList" :key="distanceItem.title">
                    <td><span class="td-size" :style="{ color: distanceItem.color }"> {{ distanceItem.distance }}</span></td>
                    <td>{{distanceItem.title.replaceAll('*', ' ')}}</td>
                  </tr>
              </tbody>
            </table>
          </div>
          </b-col>
        </b-row>
  
        <hr v-if="selectedModule === 'ANCIENT' || selectedModule === 'ANCIENT-INDIVIDUAL'" class="ancient-hr"/>
  
        <b-row v-if="selectedModule === 'ANCIENT'">
          <b-col cols="12" md="12" lg="12">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Sample(s)' }}</h5>
              </div>
              <hr />
              <div class="desc">
                <ul>
                  <li v-for="item in samples" :key="item">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
  
          <b-col cols="12" md="12" lg="12">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Y-DNA' }}</h5>
              </div>
              <hr />
              <div class="desc">
                <ul>
                  <li v-for="item in yDna" :key="item">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
  
          <b-col cols="12" md="12" lg="12">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'mtDNA' }}</h5>
              </div>
              <hr />
              <div class="desc">
                <ul>
                  <li v-for="item in mtDna" :key="item">
                    {{item}}
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>
        
        <b-row v-if="selectedModule === 'ANCIENT-INDIVIDUAL'">
          <b-col cols="12" md="12" lg="12">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Y-DNA' }}</h5>
              </div>
              <hr />
              <div class="desc">
                <span v-if="popData.yDNAISOGG.length > 1 && popData.yDNATM.length > 1">{{popData.yDNAISOGG}} ({{popData.yDNATM}})</span>
                <span v-else-if="popData.yDNAISOGG.length > 1">{{popData.yDNAISOGG}}</span>
                <span v-else-if="popData.yDNATM.length > 1">{{popData.yDNATM}}</span>
              </div>
            </div>
          </b-col>
  
          <b-col cols="12" md="12" lg="12">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'mtDNA' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.mtDNA}}
              </div>
            </div>
          </b-col>
        </b-row>
  
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    components: {},
    props: {
      // popData: {
      //   type: Object,
      //   default: null,
      // },
    },
    data() {
      return {
        isModernClosest: true,
        farmerHGsResult: [],
        distanceResult: [],
        modernFields: [
          {
            // A virtual column with custom formatter
            key: 'distance',
            label: 'distance',
          },
          {
            // A regular column with custom formatter
            key: 'title',
            label: 'Population',
          },
        ],
      }
    },
    methods: {
      back() {
        this.$store.state.ancientIndividualDatabase.sampleDetail = null;
      },
    },
    computed: {
      popData() {
        return this.$store.state.ancientIndividualDatabase.sampleDetail;
      },
      selectedModule() {
        return this.$store.state.sampleDatabase.selectedSampleType;
      },
      samples() {
        return this.popData.samples.split(',');
      },
      mtDna() {
        return this.popData.mtDna.split(',');
      },
      yDna() {
        return this.popData.yDna.split(',');
      },
      distanceList() {
        return this.$store.state.sampleDatabase.calculatedDistance;
      },
      farmerHgResultList() {
        return this.$store.state.sampleDatabase.calculatedFarmerHgResult;
      },
    },
    mounted() {
      
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .encyclopedia-detail {
    border-radius: 0;
    z-index: 1000;
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    .encyclopedia-detail-body {
      overflow: auto !important;
      overflow-x: hidden !important;
    }
  }
  @media screen and (min-width: 1079px) {
    .encyclopedia-detail {
      height: calc(100vh - 220px) !important;
      overflow-y: auto;
    }
  }

  @media screen and (max-width: 1079px) {
    .encyclopedia-detail {
      overflow-y: auto;
      height: 530px;
      width: 250px;
    }
  }
  .encyclopedia-detail {
    hr {
      margin-top: 0.7rem !important;
      margin-bottom: 0rem !important;
    }
    .ancient-hr {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  
    .encyclopedia-detail-header {
      display: flex;
      justify-content: end;
      align-items: center;
      padding: 0.3rem;
    }
  
    .encyclopedia-detail-body {
      padding: 1.5rem;
  
      .closes-pop h5 {
        font-size: 0.7rem;
      }
      .title-header {
        display: flex
      }
      .title-header::before {
        content: "";
        height: 15px;
        width: 5px;
      }
  
      .pop-desc {
        margin-bottom: 10px;
      }
  
      h5 {
        font-size: 0.8rem;
        color: var(--lab-carbon-fiber);
      }
      .desc {
        padding: 0.8rem 1rem;
        color: var(--lab-stieglitz-silver);
        font-size: 0.7rem;
  
        ul {
          padding: 0 1rem;
          li::marker {
            font-size: 1.2rem;
            color: var(--lab-g25-supplementary-color);
          }
        }
      }
    }
  
    .unavailable {
      font-size: 0.7rem !important;
    }
  }
  
  .td-size {
    font-size: 0.8rem !important;
  }
  
  .lab-btn-back {
    padding: 0.4rem 0.9rem 0.4rem 0.8rem;
    background-color: var(--lab-light-house);
    color: var(--lab-stieglitz-silver);
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  .title-header::before {
    margin-right: 5px;
    background-color: var(--lab-main-color);
  }
  
  .dark-layout {
    .encyclopedia-detail .encyclopedia-detail-body .desc {
    }
    .encyclopedia-detail .encyclopedia-detail-body h5 {
    }
    .encyclopedia-detail .encyclopedia-detail-body h5 {
    }
  
    .lab-btn-back {
      background-color: var(--lab-neverything);
      color: var(--lab-doctor);
    }
  }
  </style>
  