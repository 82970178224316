<template>
    <div class="g25-main-icon">
      <svg id="light" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 40" v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>
        </defs>
        <g id="Layer_1-2" data-name="Layer_1">
          <g id="_242602712" data-name=" 242602712">
            <path id="_242596040" data-name=" 242596040" class="cls-1" d="M.35,31.53c-.19,0-.35-.16-.35-.35V4.8c0-1.28,.5-2.48,1.41-3.39C2.32,.5,3.52,0,4.8,0H28.33C28.95,0,29.46,.51,29.46,1.13V27.54c0,.19-.16,.35-.35,.35s-.35-.16-.35-.35V1.13c0-.24-.19-.43-.43-.43H4.8c-1.09,0-2.12,.43-2.89,1.2-.78,.78-1.2,1.8-1.2,2.89V31.18c0,.19-.15,.35-.35,.35H.35Z"/>
            <path id="_242603648" data-name=" 242603648" class="cls-1" d="M4.8,27.89c-.19,0-.35-.16-.35-.35V.35c0-.19,.16-.35,.35-.35s.35,.16,.35,.35V27.54c0,.19-.16,.35-.35,.35Z"/>
            <path id="_242603456" data-name=" 242603456" class="cls-1" d="M5.89,35.48h-1.75C1.86,35.48,0,33.62,0,31.33s1.86-4.14,4.14-4.14H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H4.14c-1.9,0-3.44,1.54-3.44,3.44s1.54,3.44,3.44,3.44h1.75c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Zm23.22,0H12.69c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603600" data-name=" 242603600" class="cls-1" d="M26.24,31.55H4.27c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H26.24c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603432" data-name=" 242603432" class="cls-1" d="M29.11,35.48c-.12,0-.23-.06-.3-.16-1.8-2.89-2.38-5.12,.03-7.99,.12-.15,.35-.17,.49-.04,.15,.13,.17,.35,.04,.49-2.16,2.57-1.68,4.43,.03,7.17,.1,.16,.05,.38-.11,.48-.06,.04-.12,.05-.19,.05h0Z"/>
            <path id="_242603576" data-name=" 242603576" class="cls-1" d="M12.69,40c-.1,0-.2-.04-.27-.12l-3.13-3.62-3.14,3.62c-.1,.11-.25,.15-.39,.1s-.23-.18-.23-.33v-8.45c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v7.51l2.79-3.21c.07-.08,.16-.12,.26-.12,.1,0,.2,.04,.26,.12l2.78,3.21v-7.5c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v8.45c0,.15-.09,.28-.23,.33-.04,.01-.08,.02-.12,.02h0Z"/>
            <path id="_242603552" data-name=" 242603552" class="cls-1" d="M21.09,26.12H12.82c-.1,0-.19-.04-.25-.11-.07-.07-.1-.16-.1-.25,.07-2.24,3.51-5.81,4.56-6.85,.23-.22,.49-.46,.77-.72,1.56-1.42,3.69-3.37,2.81-5.54-.87-2.31-4.15-4.76-4.18-4.79,0,0-.01,0-.01-.01-.92-.76-1.71-1.6-2.36-2.49-1.23-1.69-1.52-3.02-1.54-3.16,0-.01,0-.03,0-.04,0-.04,0-.08,0-.12,.01-.05,.04-.1,.07-.14,.03-.04,.07-.07,.12-.09,.03-.02,.07-.03,.11-.03,.01,0,.03,0,.04,0h8.19c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h-7.73c.17,.5,.54,1.41,1.31,2.47,.61,.84,1.36,1.63,2.23,2.36,.23,.17,3.48,2.63,4.41,5.09,1.06,2.61-1.38,4.84-2.99,6.31-.27,.25-.53,.49-.75,.7-1.83,1.81-3.97,4.39-4.31,6.01h7.88c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Z"/>
            <path id="_242603168" data-name=" 242603168" class="cls-1" d="M21.09,26.12c-.19,0-.34-.15-.35-.34-.05-1.6-2.41-4.47-4.41-6.43-.2-.2-.45-.42-.7-.66-1.61-1.47-4.04-3.69-2.98-6.31,.92-2.45,4.18-4.91,4.41-5.08,3.11-2.58,3.65-5.22,3.65-5.24,.04-.19,.22-.31,.41-.28,.19,.04,.31,.22,.28,.41-.02,.12-.58,2.9-3.9,5.66,0,0-.01,0-.02,.01-.03,.02-3.31,2.48-4.18,4.78-.88,2.18,1.24,4.12,2.8,5.53,.26,.24,.51,.46,.72,.67,1.07,1.05,4.55,4.63,4.62,6.91,0,.19-.15,.36-.34,.36h-.01Z"/>
            <path id="_242603024" data-name=" 242603024" class="cls-1" d="M19.6,5.46h-5.28c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.28c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603240" data-name=" 242603240" class="cls-1" d="M20.35,11.8h-6.83c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h6.83c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603000" data-name=" 242603000" class="cls-1" d="M12.75,13.81c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h8.42c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H12.75Z"/>
            <path id="_242603360" data-name=" 242603360" class="cls-1" d="M20.66,15.83h-7.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h7.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242602856" data-name=" 242602856" class="cls-1" d="M19.65,22.99h-5.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
          </g>
        </g>
      </svg>
      <svg id="dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 40" v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>
        </defs>
        <g id="Layer_1-2" data-name="Layer_1">
          <g id="_242602712" data-name=" 242602712">
            <path id="_242596040" data-name=" 242596040" class="cls-1" d="M.35,31.53c-.19,0-.35-.16-.35-.35V4.8c0-1.28,.5-2.48,1.41-3.39C2.32,.5,3.52,0,4.8,0H28.33C28.95,0,29.46,.51,29.46,1.13V27.54c0,.19-.16,.35-.35,.35s-.35-.16-.35-.35V1.13c0-.24-.19-.43-.43-.43H4.8c-1.09,0-2.12,.43-2.89,1.2-.78,.78-1.2,1.8-1.2,2.89V31.18c0,.19-.15,.35-.35,.35H.35Z"/>
            <path id="_242603648" data-name=" 242603648" class="cls-1" d="M4.8,27.89c-.19,0-.35-.16-.35-.35V.35c0-.19,.16-.35,.35-.35s.35,.16,.35,.35V27.54c0,.19-.16,.35-.35,.35Z"/>
            <path id="_242603456" data-name=" 242603456" class="cls-1" d="M5.89,35.48h-1.75C1.86,35.48,0,33.62,0,31.33s1.86-4.14,4.14-4.14H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H4.14c-1.9,0-3.44,1.54-3.44,3.44s1.54,3.44,3.44,3.44h1.75c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Zm23.22,0H12.69c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603600" data-name=" 242603600" class="cls-1" d="M26.24,31.55H4.27c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H26.24c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603432" data-name=" 242603432" class="cls-1" d="M29.11,35.48c-.12,0-.23-.06-.3-.16-1.8-2.89-2.38-5.12,.03-7.99,.12-.15,.35-.17,.49-.04,.15,.13,.17,.35,.04,.49-2.16,2.57-1.68,4.43,.03,7.17,.1,.16,.05,.38-.11,.48-.06,.04-.12,.05-.19,.05h0Z"/>
            <path id="_242603576" data-name=" 242603576" class="cls-1" d="M12.69,40c-.1,0-.2-.04-.27-.12l-3.13-3.62-3.14,3.62c-.1,.11-.25,.15-.39,.1s-.23-.18-.23-.33v-8.45c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v7.51l2.79-3.21c.07-.08,.16-.12,.26-.12,.1,0,.2,.04,.26,.12l2.78,3.21v-7.5c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v8.45c0,.15-.09,.28-.23,.33-.04,.01-.08,.02-.12,.02h0Z"/>
            <path id="_242603552" data-name=" 242603552" class="cls-1" d="M21.09,26.12H12.82c-.1,0-.19-.04-.25-.11-.07-.07-.1-.16-.1-.25,.07-2.24,3.51-5.81,4.56-6.85,.23-.22,.49-.46,.77-.72,1.56-1.42,3.69-3.37,2.81-5.54-.87-2.31-4.15-4.76-4.18-4.79,0,0-.01,0-.01-.01-.92-.76-1.71-1.6-2.36-2.49-1.23-1.69-1.52-3.02-1.54-3.16,0-.01,0-.03,0-.04,0-.04,0-.08,0-.12,.01-.05,.04-.1,.07-.14,.03-.04,.07-.07,.12-.09,.03-.02,.07-.03,.11-.03,.01,0,.03,0,.04,0h8.19c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h-7.73c.17,.5,.54,1.41,1.31,2.47,.61,.84,1.36,1.63,2.23,2.36,.23,.17,3.48,2.63,4.41,5.09,1.06,2.61-1.38,4.84-2.99,6.31-.27,.25-.53,.49-.75,.7-1.83,1.81-3.97,4.39-4.31,6.01h7.88c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Z"/>
            <path id="_242603168" data-name=" 242603168" class="cls-1" d="M21.09,26.12c-.19,0-.34-.15-.35-.34-.05-1.6-2.41-4.47-4.41-6.43-.2-.2-.45-.42-.7-.66-1.61-1.47-4.04-3.69-2.98-6.31,.92-2.45,4.18-4.91,4.41-5.08,3.11-2.58,3.65-5.22,3.65-5.24,.04-.19,.22-.31,.41-.28,.19,.04,.31,.22,.28,.41-.02,.12-.58,2.9-3.9,5.66,0,0-.01,0-.02,.01-.03,.02-3.31,2.48-4.18,4.78-.88,2.18,1.24,4.12,2.8,5.53,.26,.24,.51,.46,.72,.67,1.07,1.05,4.55,4.63,4.62,6.91,0,.19-.15,.36-.34,.36h-.01Z"/>
            <path id="_242603024" data-name=" 242603024" class="cls-1" d="M19.6,5.46h-5.28c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.28c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603240" data-name=" 242603240" class="cls-1" d="M20.35,11.8h-6.83c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h6.83c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603000" data-name=" 242603000" class="cls-1" d="M12.75,13.81c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h8.42c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H12.75Z"/>
            <path id="_242603360" data-name=" 242603360" class="cls-1" d="M20.66,15.83h-7.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h7.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242602856" data-name=" 242602856" class="cls-1" d="M19.65,22.99h-5.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
          </g>
        </g>
      </svg>
      <svg id="hover" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 40" v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>
        </defs>
        <g id="Layer_1-2" data-name="Layer_1">
          <g id="_242602712" data-name=" 242602712">
            <path id="_242596040" data-name=" 242596040" class="cls-1" d="M.35,31.53c-.19,0-.35-.16-.35-.35V4.8c0-1.28,.5-2.48,1.41-3.39C2.32,.5,3.52,0,4.8,0H28.33C28.95,0,29.46,.51,29.46,1.13V27.54c0,.19-.16,.35-.35,.35s-.35-.16-.35-.35V1.13c0-.24-.19-.43-.43-.43H4.8c-1.09,0-2.12,.43-2.89,1.2-.78,.78-1.2,1.8-1.2,2.89V31.18c0,.19-.15,.35-.35,.35H.35Z"/>
            <path id="_242603648" data-name=" 242603648" class="cls-1" d="M4.8,27.89c-.19,0-.35-.16-.35-.35V.35c0-.19,.16-.35,.35-.35s.35,.16,.35,.35V27.54c0,.19-.16,.35-.35,.35Z"/>
            <path id="_242603456" data-name=" 242603456" class="cls-1" d="M5.89,35.48h-1.75C1.86,35.48,0,33.62,0,31.33s1.86-4.14,4.14-4.14H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H4.14c-1.9,0-3.44,1.54-3.44,3.44s1.54,3.44,3.44,3.44h1.75c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Zm23.22,0H12.69c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603600" data-name=" 242603600" class="cls-1" d="M26.24,31.55H4.27c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H26.24c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603432" data-name=" 242603432" class="cls-1" d="M29.11,35.48c-.12,0-.23-.06-.3-.16-1.8-2.89-2.38-5.12,.03-7.99,.12-.15,.35-.17,.49-.04,.15,.13,.17,.35,.04,.49-2.16,2.57-1.68,4.43,.03,7.17,.1,.16,.05,.38-.11,.48-.06,.04-.12,.05-.19,.05h0Z"/>
            <path id="_242603576" data-name=" 242603576" class="cls-1" d="M12.69,40c-.1,0-.2-.04-.27-.12l-3.13-3.62-3.14,3.62c-.1,.11-.25,.15-.39,.1s-.23-.18-.23-.33v-8.45c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v7.51l2.79-3.21c.07-.08,.16-.12,.26-.12,.1,0,.2,.04,.26,.12l2.78,3.21v-7.5c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v8.45c0,.15-.09,.28-.23,.33-.04,.01-.08,.02-.12,.02h0Z"/>
            <path id="_242603552" data-name=" 242603552" class="cls-1" d="M21.09,26.12H12.82c-.1,0-.19-.04-.25-.11-.07-.07-.1-.16-.1-.25,.07-2.24,3.51-5.81,4.56-6.85,.23-.22,.49-.46,.77-.72,1.56-1.42,3.69-3.37,2.81-5.54-.87-2.31-4.15-4.76-4.18-4.79,0,0-.01,0-.01-.01-.92-.76-1.71-1.6-2.36-2.49-1.23-1.69-1.52-3.02-1.54-3.16,0-.01,0-.03,0-.04,0-.04,0-.08,0-.12,.01-.05,.04-.1,.07-.14,.03-.04,.07-.07,.12-.09,.03-.02,.07-.03,.11-.03,.01,0,.03,0,.04,0h8.19c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h-7.73c.17,.5,.54,1.41,1.31,2.47,.61,.84,1.36,1.63,2.23,2.36,.23,.17,3.48,2.63,4.41,5.09,1.06,2.61-1.38,4.84-2.99,6.31-.27,.25-.53,.49-.75,.7-1.83,1.81-3.97,4.39-4.31,6.01h7.88c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Z"/>
            <path id="_242603168" data-name=" 242603168" class="cls-1" d="M21.09,26.12c-.19,0-.34-.15-.35-.34-.05-1.6-2.41-4.47-4.41-6.43-.2-.2-.45-.42-.7-.66-1.61-1.47-4.04-3.69-2.98-6.31,.92-2.45,4.18-4.91,4.41-5.08,3.11-2.58,3.65-5.22,3.65-5.24,.04-.19,.22-.31,.41-.28,.19,.04,.31,.22,.28,.41-.02,.12-.58,2.9-3.9,5.66,0,0-.01,0-.02,.01-.03,.02-3.31,2.48-4.18,4.78-.88,2.18,1.24,4.12,2.8,5.53,.26,.24,.51,.46,.72,.67,1.07,1.05,4.55,4.63,4.62,6.91,0,.19-.15,.36-.34,.36h-.01Z"/>
            <path id="_242603024" data-name=" 242603024" class="cls-1" d="M19.6,5.46h-5.28c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.28c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603240" data-name=" 242603240" class="cls-1" d="M20.35,11.8h-6.83c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h6.83c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603000" data-name=" 242603000" class="cls-1" d="M12.75,13.81c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h8.42c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H12.75Z"/>
            <path id="_242603360" data-name=" 242603360" class="cls-1" d="M20.66,15.83h-7.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h7.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242602856" data-name=" 242602856" class="cls-1" d="M19.65,22.99h-5.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
          </g>
        </g>
      </svg>
      <svg id="hover_dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 40" v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>
        </defs>
        <g id="Layer_1-2" data-name="Layer_1">
          <g id="_242602712" data-name=" 242602712">
            <path id="_242596040" data-name=" 242596040" class="cls-1" d="M.35,31.53c-.19,0-.35-.16-.35-.35V4.8c0-1.28,.5-2.48,1.41-3.39C2.32,.5,3.52,0,4.8,0H28.33C28.95,0,29.46,.51,29.46,1.13V27.54c0,.19-.16,.35-.35,.35s-.35-.16-.35-.35V1.13c0-.24-.19-.43-.43-.43H4.8c-1.09,0-2.12,.43-2.89,1.2-.78,.78-1.2,1.8-1.2,2.89V31.18c0,.19-.15,.35-.35,.35H.35Z"/>
            <path id="_242603648" data-name=" 242603648" class="cls-1" d="M4.8,27.89c-.19,0-.35-.16-.35-.35V.35c0-.19,.16-.35,.35-.35s.35,.16,.35,.35V27.54c0,.19-.16,.35-.35,.35Z"/>
            <path id="_242603456" data-name=" 242603456" class="cls-1" d="M5.89,35.48h-1.75C1.86,35.48,0,33.62,0,31.33s1.86-4.14,4.14-4.14H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H4.14c-1.9,0-3.44,1.54-3.44,3.44s1.54,3.44,3.44,3.44h1.75c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Zm23.22,0H12.69c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603600" data-name=" 242603600" class="cls-1" d="M26.24,31.55H4.27c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H26.24c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603432" data-name=" 242603432" class="cls-1" d="M29.11,35.48c-.12,0-.23-.06-.3-.16-1.8-2.89-2.38-5.12,.03-7.99,.12-.15,.35-.17,.49-.04,.15,.13,.17,.35,.04,.49-2.16,2.57-1.68,4.43,.03,7.17,.1,.16,.05,.38-.11,.48-.06,.04-.12,.05-.19,.05h0Z"/>
            <path id="_242603576" data-name=" 242603576" class="cls-1" d="M12.69,40c-.1,0-.2-.04-.27-.12l-3.13-3.62-3.14,3.62c-.1,.11-.25,.15-.39,.1s-.23-.18-.23-.33v-8.45c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v7.51l2.79-3.21c.07-.08,.16-.12,.26-.12,.1,0,.2,.04,.26,.12l2.78,3.21v-7.5c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v8.45c0,.15-.09,.28-.23,.33-.04,.01-.08,.02-.12,.02h0Z"/>
            <path id="_242603552" data-name=" 242603552" class="cls-1" d="M21.09,26.12H12.82c-.1,0-.19-.04-.25-.11-.07-.07-.1-.16-.1-.25,.07-2.24,3.51-5.81,4.56-6.85,.23-.22,.49-.46,.77-.72,1.56-1.42,3.69-3.37,2.81-5.54-.87-2.31-4.15-4.76-4.18-4.79,0,0-.01,0-.01-.01-.92-.76-1.71-1.6-2.36-2.49-1.23-1.69-1.52-3.02-1.54-3.16,0-.01,0-.03,0-.04,0-.04,0-.08,0-.12,.01-.05,.04-.1,.07-.14,.03-.04,.07-.07,.12-.09,.03-.02,.07-.03,.11-.03,.01,0,.03,0,.04,0h8.19c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h-7.73c.17,.5,.54,1.41,1.31,2.47,.61,.84,1.36,1.63,2.23,2.36,.23,.17,3.48,2.63,4.41,5.09,1.06,2.61-1.38,4.84-2.99,6.31-.27,.25-.53,.49-.75,.7-1.83,1.81-3.97,4.39-4.31,6.01h7.88c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Z"/>
            <path id="_242603168" data-name=" 242603168" class="cls-1" d="M21.09,26.12c-.19,0-.34-.15-.35-.34-.05-1.6-2.41-4.47-4.41-6.43-.2-.2-.45-.42-.7-.66-1.61-1.47-4.04-3.69-2.98-6.31,.92-2.45,4.18-4.91,4.41-5.08,3.11-2.58,3.65-5.22,3.65-5.24,.04-.19,.22-.31,.41-.28,.19,.04,.31,.22,.28,.41-.02,.12-.58,2.9-3.9,5.66,0,0-.01,0-.02,.01-.03,.02-3.31,2.48-4.18,4.78-.88,2.18,1.24,4.12,2.8,5.53,.26,.24,.51,.46,.72,.67,1.07,1.05,4.55,4.63,4.62,6.91,0,.19-.15,.36-.34,.36h-.01Z"/>
            <path id="_242603024" data-name=" 242603024" class="cls-1" d="M19.6,5.46h-5.28c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.28c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603240" data-name=" 242603240" class="cls-1" d="M20.35,11.8h-6.83c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h6.83c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603000" data-name=" 242603000" class="cls-1" d="M12.75,13.81c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h8.42c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H12.75Z"/>
            <path id="_242603360" data-name=" 242603360" class="cls-1" d="M20.66,15.83h-7.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h7.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242602856" data-name=" 242602856" class="cls-1" d="M19.65,22.99h-5.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
          </g>
        </g>
      </svg>
      <svg id="mobile" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.46 40" v-if="device == 'MOBILE'">
        <defs>
        </defs>
        <g id="Layer_1-2" data-name="Layer_1">
          <g id="_242602712" data-name=" 242602712">
            <path id="_242596040" data-name=" 242596040" class="cls-1" d="M.35,31.53c-.19,0-.35-.16-.35-.35V4.8c0-1.28,.5-2.48,1.41-3.39C2.32,.5,3.52,0,4.8,0H28.33C28.95,0,29.46,.51,29.46,1.13V27.54c0,.19-.16,.35-.35,.35s-.35-.16-.35-.35V1.13c0-.24-.19-.43-.43-.43H4.8c-1.09,0-2.12,.43-2.89,1.2-.78,.78-1.2,1.8-1.2,2.89V31.18c0,.19-.15,.35-.35,.35H.35Z"/>
            <path id="_242603648" data-name=" 242603648" class="cls-1" d="M4.8,27.89c-.19,0-.35-.16-.35-.35V.35c0-.19,.16-.35,.35-.35s.35,.16,.35,.35V27.54c0,.19-.16,.35-.35,.35Z"/>
            <path id="_242603456" data-name=" 242603456" class="cls-1" d="M5.89,35.48h-1.75C1.86,35.48,0,33.62,0,31.33s1.86-4.14,4.14-4.14H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H4.14c-1.9,0-3.44,1.54-3.44,3.44s1.54,3.44,3.44,3.44h1.75c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Zm23.22,0H12.69c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H29.11c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603600" data-name=" 242603600" class="cls-1" d="M26.24,31.55H4.27c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35H26.24c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603432" data-name=" 242603432" class="cls-1" d="M29.11,35.48c-.12,0-.23-.06-.3-.16-1.8-2.89-2.38-5.12,.03-7.99,.12-.15,.35-.17,.49-.04,.15,.13,.17,.35,.04,.49-2.16,2.57-1.68,4.43,.03,7.17,.1,.16,.05,.38-.11,.48-.06,.04-.12,.05-.19,.05h0Z"/>
            <path id="_242603576" data-name=" 242603576" class="cls-1" d="M12.69,40c-.1,0-.2-.04-.27-.12l-3.13-3.62-3.14,3.62c-.1,.11-.25,.15-.39,.1s-.23-.18-.23-.33v-8.45c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v7.51l2.79-3.21c.07-.08,.16-.12,.26-.12,.1,0,.2,.04,.26,.12l2.78,3.21v-7.5c0-.19,.16-.35,.35-.35s.35,.16,.35,.35v8.45c0,.15-.09,.28-.23,.33-.04,.01-.08,.02-.12,.02h0Z"/>
            <path id="_242603552" data-name=" 242603552" class="cls-1" d="M21.09,26.12H12.82c-.1,0-.19-.04-.25-.11-.07-.07-.1-.16-.1-.25,.07-2.24,3.51-5.81,4.56-6.85,.23-.22,.49-.46,.77-.72,1.56-1.42,3.69-3.37,2.81-5.54-.87-2.31-4.15-4.76-4.18-4.79,0,0-.01,0-.01-.01-.92-.76-1.71-1.6-2.36-2.49-1.23-1.69-1.52-3.02-1.54-3.16,0-.01,0-.03,0-.04,0-.04,0-.08,0-.12,.01-.05,.04-.1,.07-.14,.03-.04,.07-.07,.12-.09,.03-.02,.07-.03,.11-.03,.01,0,.03,0,.04,0h8.19c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h-7.73c.17,.5,.54,1.41,1.31,2.47,.61,.84,1.36,1.63,2.23,2.36,.23,.17,3.48,2.63,4.41,5.09,1.06,2.61-1.38,4.84-2.99,6.31-.27,.25-.53,.49-.75,.7-1.83,1.81-3.97,4.39-4.31,6.01h7.88c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35h0Z"/>
            <path id="_242603168" data-name=" 242603168" class="cls-1" d="M21.09,26.12c-.19,0-.34-.15-.35-.34-.05-1.6-2.41-4.47-4.41-6.43-.2-.2-.45-.42-.7-.66-1.61-1.47-4.04-3.69-2.98-6.31,.92-2.45,4.18-4.91,4.41-5.08,3.11-2.58,3.65-5.22,3.65-5.24,.04-.19,.22-.31,.41-.28,.19,.04,.31,.22,.28,.41-.02,.12-.58,2.9-3.9,5.66,0,0-.01,0-.02,.01-.03,.02-3.31,2.48-4.18,4.78-.88,2.18,1.24,4.12,2.8,5.53,.26,.24,.51,.46,.72,.67,1.07,1.05,4.55,4.63,4.62,6.91,0,.19-.15,.36-.34,.36h-.01Z"/>
            <path id="_242603024" data-name=" 242603024" class="cls-1" d="M19.6,5.46h-5.28c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.28c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603240" data-name=" 242603240" class="cls-1" d="M20.35,11.8h-6.83c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h6.83c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242603000" data-name=" 242603000" class="cls-1" d="M12.75,13.81c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h8.42c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35H12.75Z"/>
            <path id="_242603360" data-name=" 242603360" class="cls-1" d="M20.66,15.83h-7.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h7.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
            <path id="_242602856" data-name=" 242602856" class="cls-1" d="M19.65,22.99h-5.39c-.19,0-.35-.16-.35-.35s.16-.35,.35-.35h5.39c.19,0,.35,.16,.35,.35s-.16,.35-.35,.35Z"/>
          </g>
        </g>
      </svg>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>

#mobile {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#light {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#dark {
  .cls-1 {
    fill: #f9f9f9;
  }
}

#hover {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}

#hover_dark {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}

.dp-menu-item:hover, .dp-menu-item.active {
  #hover, #hover_dark {
    display: block;
  }
  #light, #dark {
    display: none;
  }
}
</style>
