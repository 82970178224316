<template>
  <header class="main-header">
    <div class="header-content">
      <div class="head-content-left">
        <div class="logo" @click="$router.push({name: 'dashboard'})">
          <img :src="iconUrl" alt="Logo" />
        </div>
      </div>
      <div class="head-content-right">
        <LabKitDropdown />
        <LabDarkToggler class="mr-2"/>
        <LabUserDropdown />
      </div>
    </div>
  </header>
</template>

<script>
import LabUserDropdown from './layout-components/LabUserDropdown.vue';
import LabDarkToggler from './layout-components/LabDarkToggler.vue';
import LabKitDropdown from './layout-components/LabKitDropdown.vue';

export default {
  components: {
    LabUserDropdown,
    LabDarkToggler,
    LabKitDropdown,
  },
  computed: {
    iconUrl() {
      /* eslint-disable */
      return 'https://image.illustrativedna.com/assets/icons/' + this.theme + '/icon-text.svg';
    },
    theme() {
      return "dark";
      // return this.$store.state.appConfig.layout.skin;
    },
  },
  methods: {
    openSettings() {
      // Ayarlar menüsünü açma işlemleri
      alert('Ayarlar butonuna tıklandı!');
    },
  },
};
</script>

<style scoped lang="scss">
.main-header {
  background-color: var(--lab-black-65);
  color: var(--lab-white-100);
  padding: 10px 20px;
  position: relative;
  z-index: 1003;
  .logo {
    cursor: pointer;
  }
  .logo img {
    height: 40px;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-content-right {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
}
</style>
