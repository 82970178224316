<template>
    <div class="result-list-container">
      <template v-if="uiResults && !$store.getters.getLoader">
        <div>
          <div class="pop-view">
            <table class="dashboard-table">
                <tbody>
                    <tr v-for="(itemAncestry, index) in uiResults" :key="itemAncestry.title">
                        <td><span class="population-index-txt">{{index + 1}}</span></td>
                        <td>{{itemAncestry.title.replaceAll("*", " ")}}</td>
                        <td class="text-right"><span class="distance-value-txt" :style="{ color: itemAncestry.color }">{{itemAncestry.distance}}</span></td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
        <GeneticFitInfoModal v-if="geneticFitInfoModal.showModal" :modalObject="geneticFitInfoModal"/>
      </template>
      <SpinnerContainer v-else />
    </div>
  </template>
  
  <script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        geneticFitInfoModal: {
          showModal: false,
        },
      }
    },
    methods: {
    },
    computed: {
      uiResults() {
        return this.$store.getters['modernProxResult/getUiResults'];
      },
    },
    mounted() {
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .result-list-container {
    background: var(--bg-card-color);
    padding: 28px;
    padding-top:0px;
  
    @media screen and (max-width: 1079px) {
      overflow-y: hidden;
      overflow-x: hidden !important;
    }

    .distance-value-txt {
      font-size:0.9em!important;
      color:#8FC46C;
    }
    .population-index-txt {
      font-weight: 600;
    }
  }
  </style>
  