/*eslint-disable*/
const hgFarmerMapHelper = {
  //create a global variable that will point to the tooltip in the DOM
  tipObj: null,

  //offset along x and y in px
  offset: {
    x: 5,
    y: -60
  },

  /********************************************************************
   * injectTooltip(e,data)
   * inject the custom tooltip into the DOM
   ********************************************************************/
  injectTooltip(event, data) {
    if (!this.tipObj && event) {
      const valueColor = (data.color) ? data.color : "#393939"
      //create the tooltip object
      this.tipObj = document.createElement("div");
      this.tipObj.style.background = "white";
      this.tipObj.style.color = "#393939";
      this.tipObj.style.borderRadius = "5px";
      this.tipObj.style.padding = "10px";
      this.tipObj.style.fontFamily = "Arial,Helvetica";
      this.tipObj.style.textAlign = "center";
      this.tipObj.style.fontWeight = "600";
      this.tipObj.style.zIndex = "999999";
      this.tipObj.innerHTML = data.name + ' <span style="color: ' + valueColor+ '">' + data.ancestryValue + '%</span>';

      //position it
      this.tipObj.style.position = "fixed";
      this.tipObj.style.top = event.originalEvent.clientY + this.offset.y + "px";
      this.tipObj.style.left = event.originalEvent.clientX + this.offset.x + "px";

      //add it to the body
      document.body.appendChild(this.tipObj);
    }
  },

  /********************************************************************
   * moveTooltip(e)
   * update the position of the tooltip based on the event data
   ********************************************************************/
  moveTooltip(event) {
    if (this.tipObj && event) {
      //position it
      this.tipObj.style.top = event.originalEvent.clientY + this.offset.y + "px";
      this.tipObj.style.left = event.originalEvent.clientX + this.offset.x + "px";
    }
  },

  /********************************************************************
   * deleteTooltip(e)
   * delete the tooltip if it exists in the DOM
   ********************************************************************/
  deleteTooltip() {
    if (this.tipObj) {
      //delete the tooltip if it exists in the DOM
      document.body.removeChild(this.tipObj);
      this.tipObj = null;
    }
  }
}

export default hgFarmerMapHelper
