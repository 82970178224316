import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService'
import store from '@/store';

const diyGDSourceDataService = {
   /* eslint-disable */
  async loadSourceDatas() {
    const sourceData = await store.dispatch('diySourceData/fetchDiySourceData', 'D-02');
    await store.dispatch('daResult/fetchResultList');

    const modernSouceDataList = CalculateFMCService.codeDataToArray(sourceData.diyModernSourceData);
    const ancientSouceDataList = CalculateFMCService.codeDataToArray(sourceData.diyAncientSourceData);

    const targetList = [];
    
    modernSouceDataList.result.forEach(item => {
      let groupName = this.groupName(item[0]);
      targetList.push({ name: item[0], type:  "MODERN_POP", groupName, coordinate: item.join(",") });
    });
    ancientSouceDataList.result.forEach(item => {
      let groupName = this.groupName(item[0]);
      targetList.push({ name: item[0], type:  "ANCIENT_POP", groupName, coordinate: item.join(",") });
    });

    store.state.diyGDSourceData.souceDataList["MODERN_POP"] = modernSouceDataList.result;
    store.state.diyGDSourceData.souceDataList["ANCIENT_POP"] = ancientSouceDataList.result;

    store.state.diyGDSourceData.targetUnSelectedList = targetList;
  },

  groupName(id) {
    const parts = id.split(':');
    if (parts.length > 1) {
      return parts[0] + ':' + parts[1];
    } else {
      return parts[0]; 
    }
  },
};

export default diyGDSourceDataService;