/* eslint-disable */
import ApiService from '@/common/api.service';

const getDefaultState = () => {
  return {
    results: null,
    uiResults: null,
    geoJson: [],

    savedUiDetails: {},

    isOpenedHgFarmerDetail: false,
    
    openedHgFarmerDetail: { id: 0, },
    openedHgFarmerDetails: {},
  }
}

const state = getDefaultState()

const getters = {
  getResults(state) {
    return state.results;
  },
  getUiResults(state) {
    return state.uiResults;
  },

  getGeoJson(state) {
    return state.geoJson;
  },
}

const mutations = {
  setResults(state, data) {
    state.results = data;
  },
  setUiResults(state, data) {
    state.uiResults = data;
  },

  setGeoJson(state, data) {
    state.geoJson = data;
  },
}

const actions = {
  async fetchHgFarmerDetails(context, { groupedSourceCodes, dataVersion}) {
    try {
      if (!groupedSourceCodes) {
        return;
      }
      const saveDetail = context.state.savedUiDetails[groupedSourceCodes.join(',')]
      if (saveDetail) {
        return saveDetail;
      } else {
        const response = await ApiService.postSilent('data/HgFarmerDetail', { listOfSourceData: groupedSourceCodes, dataVersion }, this);
        if (response.statusCode === 200) {
          context.state.savedUiDetails[groupedSourceCodes.join(',')] = response.result;
          return response.result;
        } else {
          throw error;
        }
      }
      
    } catch (error) {
      throw error;
    }
  },

  async fetchHgFarmerDetail(context, id) {
    try {
      const response = await ApiService.get(`data/HgFarmerDetail/${id}`, {}, this);
      if (response.statusCode === 200) {
        context.state.openedHgFarmerDetail = response.result;
        return response.result;
      } else {
        throw error;
      }
      
    } catch (error) {
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
