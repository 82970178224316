<template>
  <div>
    <b-col cols="12" xl="8" md="8" class="mx-auto">
      <div class="notification-card dashboard-card">
        <h4 class="mb-3">Notifications</h4>

        <div v-for="item in allGeneralNotifications" :key="item.code">
          <div class="notification-item">
            <div class="item-body">
              <div class="title-item right">
                <h5>Title</h5>
                <span>{{item.title}}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="title-item">
                  <h5>Date</h5>
                  <span>{{item.dtDateLabel}}</span>
                </div>
                <div class="icon-item">
                  <div class="title-item text-center">
                    <h5>Detail</h5>
                    <span class="icon-btn" @click="item.isOpenDesc = !item.isOpenDesc"> 
                      <circle-plus-icon v-show="!item.isOpenDesc"/>
                      <circle-x-icon v-show="item.isOpenDesc"/>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="notification-desc" v-show="item.isOpenDesc">
            {{item.description}}
          </div>
          <hr />
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import CirclePlusIcon from '@/layouts/icons/CirclePlusIcon.vue';
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';

export default {
  components: {
    CirclePlusIcon,
    CircleXIcon,
  },
  computed: {
    allGeneralNotifications() {
      return this.$store.getters['notification/getAllGeneralNotifications'];
    },
  },
  methods: {
    fetchNotifications() {
      this.$store.dispatch('notification/fetchAllGeneralNotifications', {});
    },
  },
  created() {
    this.fetchNotifications();
  },
}
</script>

<style lang="scss" scoped>
.notification-card {
  padding: 1rem 2rem !important;
  h4 {
    color: var(--lab-black);
  }
  .notification-item {
    margin: 2rem 0;

    .item-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .right {
      width: 50%;
    }
    .title-item {
      h5 {
        color: var(--lab-black-65);
        font-size: 1rem;
        margin-bottom: 15px;
      }
      span {
        color: var(--lab-stieglitz-silver);
        font-size: 0.9rem;
      }
    }

    .icon-item {
      display: flex;
      align-items: center;
      justify-content: end;
      text-align: left;
      width: 150px;

      .icon-btn {
        cursor: pointer;
      }
    }
  }

  .notification-desc {
    padding: 1rem;
    color: var(--lab-stieglitz-silver);
    font-size: 0.9rem;
  }
}

.dark-layout {
  .notification-card h4 {
    color: var(--lab-main-color);
  }
  .notification-card .title-item  {
    color: var(--lab-main-color);
  }
  .notification-card .title-item h5 {
    color: var(--lab-main-color);
  }
  .notification-card .title-item  span {
    color: var(--lab-doctor);
  }
  .notification-card .notification-desc {
     color: var(--lab-doctor);
  }
}

@media screen and (max-width: 1079px) {
  .notification-item .item-body {
    display: block !important;
    .right {
      width: 100%;
      margin-bottom: 10px !important;
    }

    .title-item h5 {
      margin-bottom: 5px !important;
    } 
  }
}
</style>
