import ApiService from '@/common/api.service';

/* eslint-disable */
const getDefaultState = () => {
  return {
    regionGroupList: [],
    regionList: [],

    selectedRegionGroup: { id: 0, grupName: '' },
    selectedRegion: { id: 0, title: '' },
  }
}

const state = getDefaultState()

const getters = {
  getRegionList(state) {
    return state.regionList;
  },

  getRegionGroupList() {
    return state.regionGroupList;
  },
}

const mutations = {
  setSelectRegion(state, regionId) {
    const region = state.regionList.find(x => x.id === regionId);
    if (region) {
      const regionGroup = state.regionGroupList.find(x => x.id == region.presetRegionGrupId);
      state.selectedRegion = region;
      state.selectedRegionGroup = regionGroup;
    }
  },
}

const actions = {
  async fetchRegionList(context) {
    try {
      const response = await ApiService.getSilent('data/Region', {}, this);
      if (response.statusCode === 200) {
        context.state.regionList = response.result;
      } else {
        console.error('Error fetching data:', response.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },

  async fetchRegionGroupList(context) {
    try {
      const response = await ApiService.getSilent('data/Region/groups', {}, this);
      if (response.statusCode === 200) {
        context.state.regionGroupList = response.result;
      } else {
        console.error('Error fetching data:', response.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
