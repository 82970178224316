<template>
  <div class="sample-type-dropdown">
    <div class="lab-dropdown sm region">
      <label for="custom-dropdown" class="dropdown-label mb-1">Filter by location  </label>
      <div class="lab-dropdown-btn" @click="isDropdownOpen = !isDropdownOpen" ref="dropdownReport">
        <span class="lab-dropdown-btn-value">
          {{ selectedLocation || 'All Locations' }}
        </span>
        <span class="lab-dropdown-btn-icon">
          <DropdownOneIcon/>
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isDropdownOpen}">
        <ul>
          <li v-for="(item) in locationList"
              :key="item"
              @click="selectLocation(item)">
              {{ item === '' ? 'All Locations' : item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownReport;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isDropdownOpen = false;
        }
      } catch (error) {}
    },
    applySelectedLocation() {
      this.$store.commit('sampleDatabase/setDefaultQuery');
      this.$store.state.sampleDatabase.filterQuery.location = this.selectedLocation;
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectLocation(item) {
      this.isDropdownOpen = false;
      this.$store.state.sampleDatabase.filterQuery.location = item;
    },
  },
  computed: {
    locationList() {
      return this.$store.state.sampleDatabase.locationList;
    },
    selectedLocation() {
      return this.$store.state.sampleDatabase.filterQuery.location;
    }
  },
  mounted() {
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
.sample-type-dropdown {
  .dropdown-container {
    padding: 0;
    width: 100%;

    .select-custom-dropdown {
      width: 100%;
    }
  }
}
.lab-dropdown .lab-dropdown-btn {
    padding: 10px 15px !important;
}
</style>
