export const ParseHgFarmerResult = (result) => {
  const tempResults = JSON.parse(JSON.stringify(result));
  
  tempResults.outPuts = [];
  result.outPuts.forEach(value => {
    const splitValue = value.resultsTable.split(':');
    if (splitValue.length > 0) {
      const findValue = tempResults.outPuts.find(x => x.resultsTable === splitValue[0]);
      if (findValue) {
        findValue.currentResult += value.currentResult;
      } else {
        value.resultsTable = splitValue[0];
        tempResults.outPuts.push(value);
      }
    }
  });
  tempResults.outPuts.sort((a, b) => b.currentResult - a.currentResult);

  return tempResults;
}