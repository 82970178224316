<template>
  <div class="dc-container cs-scroll-style">
    <div class="dc-container-content">
      <div class="dashboard-card">
        <div class="dashboard-card-body">
          <DownloadCoordView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadCoordView from './DownloadCoordView.vue';

export default {
components: {
  DownloadCoordView
},
data() {
  return {

  }
},
}

</script>

<style lang="scss" scoped>
.dc-container {
  width: 100%;
  height: calc(100vh - 220px)!important;
  overflow: hidden;
  overflow-y: auto;
  padding: 2rem;
}
.dc-container-content {
  margin: auto;
  width: 900px;
  max-width: 900px!important;
}
@media screen and (max-width: 1079px) {
  .dc-container {
    margin-top:60px;
    overflow:hidden;
    overflow-y:auto;
    height: auto!important;
    margin-bottom:60px;
    padding:10px;
  }
  .dc-container-content {
    width: 100%;
    max-width: 100%;
    word-break: break-all;
  }
  .data-sheet a{
    font-size:0.9rem!important;
  }
}
</style>