<template>
  <div class="deep-ancestry-layout">
    <template v-if="selectedResultStatus === 2 || selectedResultStatus === 3">
      <div class="da-card-container" v-if="$store.state.appConfig.windowWidth <= 1079">
        <div class="da-card-row">
          <a class="da-card" @click="navigateTo(menu.PERIODICAL)">
            <div class="da-card-image">
              <PeriodicalMenuIcon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
            </div>
            <div class="da-card-title">
              Periodical Breakdown
            </div>
          </a>
          <a class="da-card" @click="navigateTo(menu.HGFARMER)">
            <div class="da-card-image">
              <HgFarmerMenuIcon  :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
            </div>
            <div class="da-card-title">
              Hunter-Farmer
            </div>
          </a>
        </div>
        <div class="da-card-row" >
          <a class="da-card" @click="navigateTo(menu.UNSUPERVISEDANALYSIS)">
            <div class="da-card-image">
              <UnsupervisedAnalysisMenuIcon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
            </div>
            <div class="da-card-title">
              Unsupervised Analysis
            </div>
          </a>
          <a class="da-card" @click="navigateTo(menu.GENETICDISTANCES)">
            <div class="da-card-image">
              <GeneticDistMenuIcon  :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
            </div>
            <div class="da-card-title">
              Closest Populations
            </div>
          </a>
        </div>
        <div class="da-card-row">
          <a class="da-card" @click="navigateTo(menu.PCAPLOTS)">
            <div class="da-card-image">
              <PcaPlotsMenuIcon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
            </div>
            <div class="da-card-title">
              PCA Plots
            </div>
          </a>
          <a class="da-card" @click="navigateTo(menu.DIYTOOLS)">
            <div class="da-card-image">
              <DiyToolsMenuIcon  :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
            </div>
            <div class="da-card-title">
              DIY Tools
            </div>
          </a>
        </div>
        <div class="da-card-row">
          <a class="da-card" @click="navigateTo(menu.ENCYCLOPEDIA)">
            <div class="da-card-image">
              <EncyclopediaMenuIcon :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
            </div>
            <div class="da-card-title">
              Encyclopedia
            </div>
          </a>
          <a class="da-card" @click="navigateTo(menu.DOWNLOADCOORDINATES)">
            <div class="da-card-image">
              <DownloadCoordsMenuIcon  :variant="$store.state.appConfig.layout.skin" device="MOBILE"/>
            </div>
            <div class="da-card-title">
              Download Coordinates
            </div>
          </a>
        </div>
      </div>
    </template>
    <DeepAncestryNoResultPage v-else :orderResultStatus="selectedResultStatus"/>
  </div>
</template>

<script>
import PeriodicalMenuIcon from './icons/menu/PeriodicalMenuIcon.vue';
import HgFarmerMenuIcon from './icons/menu/HgFarmerMenuIcon.vue';
import UnsupervisedAnalysisMenuIcon from './icons/menu/UnsupervisedAnalysisMenuIcon.vue';
import PcaPlotsMenuIcon from './icons/menu/PcaPlotsMenuIcon.vue';
import EncyclopediaMenuIcon from './icons/menu/EncyclopediaMenuIcon.vue';
import GeneticDistMenuIcon from './icons/menu/GeneticDistMenuIcon.vue';
import DownloadCoordsMenuIcon from './icons/menu/DownloadCoordsMenuIcon.vue';
import DiyToolsMenuIcon from './icons/menu/DiyToolsMenuIcon.vue';
import menuConfig from './routesMenu';
import DeepAncestryNoResultPage from './DeepAncestryNoResultPage.vue';

export default {
  components: {
    PeriodicalMenuIcon,
    HgFarmerMenuIcon,
    UnsupervisedAnalysisMenuIcon,
    PcaPlotsMenuIcon,
    EncyclopediaMenuIcon,
    GeneticDistMenuIcon,
    DiyToolsMenuIcon,
    DownloadCoordsMenuIcon,
    DeepAncestryNoResultPage,
  },
  methods: {
    navigateTo(routePath) {
      this.$router.push({ name: routePath })
    }
  },
  computed: {
    menu() {
      return menuConfig.menu; // Make menu reactive via computed
    },
    selectedResultStatus() {
      const selectedResult = this.$store.state.daResult.selectedResult;
      if (selectedResult) {
        return selectedResult.orderResultStatus;
      }

      return 0;
    },
  },
}
</script>

<style lang="scss">
.deep-ancestry-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.da-card-container {
  margin-top:60px;
}

.da-container {
  height: calc(100vh - 0px);
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.da-card-row {
  display: flex;
  width: 100%;
  margin-bottom: 20pt;
}

.da-card {
  --card-width: 50%; /* CSS variable */
  width: 50%;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  .da-card-image {
    border: 2pt solid var(--lab-da-dashboard-card-color);
    border-radius: 5px;
    width: 70%;
    text-align: center;
    padding: 10px 0;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    transition: 0.3s;

    svg {
      width: 70%!important;
      height: 70%!important;
      path {
        fill: var(--lab-da-dashboard-card-color)!important;
        transition: 0.3s!important;
      }
      rect {
        fill: var(--lab-da-dashboard-card-color)!important;
        transition: 0.3s!important;
      }
      circle {
        fill: var(--lab-da-dashboard-card-color)!important;
        transition: 0.3s!important;
      }
    }
  }

}

.da-card:hover {
  .da-card-image {
    border: 2pt solid #1bbdc1!important;
  }
  svg {
    path {
      fill: #1bbdc1!important;
    }
    rect {
      fill: #1bbdc1!important;
    }
    circle {
      fill: #1bbdc1!important;
    }
  }
  color:#1bbdc1!important;
}
.da-card-title {
  margin-top: 15pt;
  text-align: center;
  font-weight: 600;
  font-size: 0.9em;
  transition: 0.3s;
}

</style>
