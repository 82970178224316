<template>
  <div>
    <div v-if="getOpenMap && $store.state.appConfig.windowWidth >= 1079">
        <AncientIndividualMap />
    </div>
    <div v-else>
      <div class="table-container" v-if="$store.state.appConfig.windowWidth >= 1079">
        <div v-if="getOpenMap">
          <AncientIndividualMap/>
        </div>
        <div v-else>
          <div class="sample-table-list" v-if="$store.state.ancientIndividualDatabase.sampleDetail === null">
            <table class="sample-table">
              <thead>
                <tr>
                  <th class="col-1" scope="col">ID</th>
                  <th class="col-1" scope="col">Date</th>
                  <th class="col-1" scope="col" width="30">Label</th>
                  <th class="col-1" scope="col">Y-DNA</th>
                  <th class="col-1" scope="col">mtDNA</th>
                  <th class="col-1" scope="col">location</th>
                  <th class="col-1 text-center" scope="col">Details</th>
                </tr>
              </thead>
              <tbody class="cs-scroll-style">
                <template v-if="popList && !$store.getters.getLoader">
                  <tr v-for="item in popList" :key="item.id">
                  <td class="col-1">{{item.sampleID}}</td>
                  <td class="col-1">
                    {{item.date}}
                  </td>
                  <td class="col-1" width="30">{{item.name}}</td>
                  <td class="col-1">
                    <span v-if="item.yDNAISOGG.length > 1 && item.yDNATM.length > 1">{{item.yDNAISOGG}} ({{item.yDNATM}})</span>
                    <span v-else-if="item.yDNAISOGG.length > 1">{{item.yDNAISOGG}}</span>
                    <span v-else-if="item.yDNATM.length > 1">{{item.yDNATM}}</span>
                  </td>
                  <td>{{item.mtDNA}}</td>
                  <td>{{item.location}}</td>
                  <!-- <td class="text-center">{{ item.samples.split(',').length }}</td> -->
                  <!-- <td class="text-center">{{item.yDna.split(',').length}}</td> -->
                  <!-- <td class="text-center">{{item.mtDna.split(',').length}}</td> -->
                  <td class="text-center">
                    <span class="table-action table-action-edit lab-p-8" @click="openDetail(item)">
                      <feather-icon
                        icon="ArrowRightIcon"
                        class="cursor-pointer lab-text-steel"
                        size="25"
                      />
                    </span>
                  </td>
                </tr>
                </template>
                <SpinnerContainer v-else />
              </tbody>
            </table>
            <div class="pagination-container">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" >
                  <span class="text-muted">
                    Showing 
                    {{ (pagination.PageIndex - 1) * pagination.PageSize }} to 
                    {{ pagination.PageIndex * pagination.PageSize }} of {{ pagination.Count }} entries</span>
                </b-col>

                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="pagination.PageIndex"
                    :total-rows="pagination.Count"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="custom-pagination"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>

              </b-row>
            </div>
          </div>
          
          <AncientIndividualSampleDetail class="sample-table-detail" v-else/>
        </div>
      </div>
    
      <div class="table-container" v-else>
        <div v-if="getOpenMap">
          <AncientIndividualMap/>
        </div>
        <div v-else>
          <div class="encyclopedia-mobile" >
          <div v-for="(item, index) in popList" :key="item.id">
            <div class="encyclopedia-mobile-item dashboard-card">
              <div>
                <div class="mobile-item-top">
                  <div class="item-info left-info">
                    <h5>Label</h5>
                    <h6>{{ item.name }} ({{item.yDNATM}},{{item.yDNAISOGG}})</h6>
                  </div>
                  <div class="item-info right-info">
                    <h5>ID</h5>
                    <h6>{{item.sampleID}}</h6>
                  </div>
                </div>
                <div class="mobile-item-bottom">
                  <div class="item-info left-info">
                    <h5>Date</h5>
                    <h6>{{item.date}}</h6>
                  </div>
                  <div class="item-info right-info">
                    <h5>Location</h5>
                    <h6>{{item.location}}</h6>
                  </div>
                </div>
              </div>
              <div class="mobile-encyclopedia-icon">
                <span class="cursor-pointer"  @click="openDetailMobile(item, index)"> 
                  <circle-plus-icon v-show="!item.isOpenDetail" className="encyclopedia"/>
                  <circle-x-icon v-show="item.isOpenDetail" className="encyclopedia"/>
                </span>
              </div>
            </div>

            <AncientIndividualSampleDetailMobile v-if="$store.state.ancientIndividualDatabase.sampleDetail && item.isOpenDetail" />
          </div>
        </div>

        <div class="mt-4">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1" >
              <span class="text-muted">
                Showing 
                {{ (pagination.PageIndex - 1) * pagination.PageSize }} to 
                {{ pagination.PageIndex * pagination.PageSize }} of {{ pagination.Count }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
            <b-pagination
                v-model="pagination.PageIndex"
                :total-rows="pagination.Count"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="custom-pagination"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CirclePlusIcon from '@/layouts/icons/CirclePlusIcon.vue';
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';
import AncientIndividualSampleDetail from './AncientIndividualSampleDetail.vue';
import AncientIndividualSampleDetailMobile from './AncientIndividualSampleDetailMobile.vue';
import AncientIndividualMap from './map/AncientIndividualMap.vue';

export default {
  components: {
    AncientIndividualSampleDetail,
    AncientIndividualSampleDetailMobile,
    CirclePlusIcon,
    CircleXIcon,
    AncientIndividualMap
  },
  data() {
    return {
    }
  },
  methods: {
    openDetail(item) {
      this.$store.state.ancientIndividualDatabase.sampleDetail = item;
    },
    openDetailMobile(item, index) {
      item.isOpenDetail = !item.isOpenDetail;
      if (item.isOpenDetail) {
        this.$store.state.ancientIndividualDatabase.sampleDetail = item;
      } else {
        this.$store.state.ancientIndividualDatabase.sampleDetail = null;
      }

      this.popList.forEach((element, indexItem) => {
        if (indexItem !== index) {
          element.isOpenDetail = false;
        }
      });
    },
  },
  computed: {
    pagination() {
      return this.$store.state.sampleDatabase.filterQuery;
    },
    popList() {
      return this.$store.getters['ancientIndividualDatabase/getAncientIndividualDatabaseList'];
    },
    getOpenMap() {
      return this.$store.state.ancientIndividualDatabase.openMap;
    }
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>

.dark-layout {
  .table-action {
    background:#13181c !important;
    color:#7a7a7a!important;
  }
}

</style>
