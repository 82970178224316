/* eslint-disable */
import ApiService from '@/common/api.service';

const getDefaultState = () => {
  return {
    results: {},

    uiResults: null,

    currentResult: null,
  }
}

const state = getDefaultState()

const getters = {  
  getUiResults(state) {
    return state.uiResults;
  },
}

const mutations = {
  setUiResults(state, data) {
    state.uiResults = data;
  },
}

const actions = {
  async fetchMixedModeResults(context, orderResultId) {
    try {
      const findResult = context.state.results[orderResultId];
      if (findResult) {
        return findResult;
      }

      const response = await ApiService.get(`data/MixedModeResult/${orderResultId}`, {}, this);
      if (response.statusCode === 200) {
        context.state.results[orderResultId] = response.result;
        return response.result;
      } else {
        console.error('Error fetching data:', response.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
