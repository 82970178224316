import { render, staticRenderFns } from "./DropdownIcon.vue?vue&type=template&id=79379703&scoped=true&"
import script from "./DropdownIcon.vue?vue&type=script&lang=js&"
export * from "./DropdownIcon.vue?vue&type=script&lang=js&"
import style0 from "./DropdownIcon.vue?vue&type=style&index=0&id=79379703&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79379703",
  null
  
)

export default component.exports