import router from '@/router'

import useJwt from '@/auth/jwt/useJwt'
import AlertService from './alert.service'

const ApiService = {
  get(resource, params, instance) {
    this.setInterceptor(instance)
    return useJwt.axiosIns.get(resource, { params }).catch(error => {
      this.controlErrorResponse(error, instance)
      return Promise.reject(error)
    })
  },

  getSilent(resource, params, instance) {
    this.setSilentInterceptor(instance)
    return useJwt.axiosIns.get(resource, { params }).catch(error => {
      this.controlErrorResponse(error, instance)
      return Promise.reject(error)
    })
  },

  post(resource, params, instance) {
    this.setInterceptor(instance)
    return useJwt.axiosIns.post(`${resource}`, params).catch(error => {
      this.controlErrorResponse(error, instance)
      return Promise.reject(error)
    })
  },

  postSilent(resource, params, instance) {
    this.setSilentInterceptor(instance)
    return useJwt.axiosIns.post(`${resource}`, params).catch(error => {
      this.controlErrorResponse(error, instance)
      return Promise.reject(error)
    })
  },

  upload(resource, formData, instance) {
    this.setInterceptor(instance)
    return useJwt.axiosIns.post(`${resource}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .catch(error => {
              this.controlErrorResponse(error, instance)
            })
  },

  download(resource, params, instance) {
    return useJwt.axiosIns({
              url: `${resource}`,
              method: 'GET',
              responseType: 'blob',
            }).catch(error => {
              this.controlErrorResponse(error, instance)
            })
  },

  update(resource, slug, params) {
    return useJwt.axiosIns.put(`${resource}/${slug}`, params)
  },

  put(resource, params) {
    return useJwt.axiosIns.put(`${resource}`, params)
  },

  delete(resource) {
    return useJwt.axiosIns.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  },

  controlErrorResponse(response, instance) {
    if (!instance.commit) instance = instance.$store
    instance.commit('setLoader', false)
    const instanceObject = instance._vm ? instance._vm : instance;
    
    if (response && response.status === 401) {
      router.push({ name: 'login' })
    } else {
      AlertService.error(response)
    }
  },

  setInterceptor(instance) {
    if (!instance.commit) instance = instance.$store
    instance.commit('setLoader', true)
    useJwt.axiosIns.interceptors.response.use(response => {
      instance.commit('setLoader', false)
      return response;
    }, error => Promise.reject(error))
  },

  setSilentInterceptor(instance) {
    /* eslint-disable */
    if (!instance.commit) instance = instance.$store
    useJwt.axiosIns.interceptors.response.use(response => {
      return response;
    }, error => Promise.reject(error))
  },
}

export default ApiService
