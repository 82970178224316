<template>
  <div class="ancient-individual-map" :style="mapStyle">
    <SpinnerContainer v-if="isLoading === true" />
    <l-map ref="ancientIndividualMap" @ready="LoadFullscreen()" :options="{ attributionControl: false }" v-if="isLoading === false"
      :zoom="zoom" :maxZoom="maxZoom" :minZoom="minZoom" :center="center">
      <l-tile-layer :url="mapStyleUrl" :zIndex="zIndex"></l-tile-layer>
      <l-circle-marker v-for="(circle, index) in circles" :key="index"
        :className="circle.sampleID"
        :lat-lng="circle.center"
        :radius="circle.radius"
        :weight="1"
        :fillColor="'#7C3592'"
        :color="'#000'"
        :fillOpacity="1"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
        @mousemove="mouseMove"
        @click="mouseEnter"
      />
    </l-map>
    <encyclopedia-detail :popData="popData" v-if="popData != null"/>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'
import { LMap, LTileLayer, LCircleMarker } from 'vue2-leaflet';

import EncyclopediaDetail from './EncyclopediaDetailMap.vue';

import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

export default {
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
    // EncyclopediaDetail,
    EncyclopediaDetail
  },
  props: {
  },
  data() {
    return {
      zoom: 5,
      maxZoom: 10,
      minZoom: this.$store.state.appConfig.windowWidth >= 1079 ? 2 : 1,
      zIndex: 4,
      center: [44.312223, 30.046882],
      circles: [],
      
      tipObj: null,
      offset: {
        x: 5,
        y: -75,
      },
      mapWidth: 'auto',
      mapHeight: this.$store.state.appConfig.windowWidth >= 1079 ? '100vh' : '530px',

      isLoading: false,
      popData: null,
    }
  },
  methods: {
    mouseOver(event) {
      /* eslint-disable*/
      const targets = event.target.options.className;
      const findAncientPop = this.circles.find(popValue => targets === popValue.sampleID );
      
      this.tipObj = document.createElement("div");
      this.tipObj.style.background = "white";
      this.tipObj.style.color = "#393939";
      this.tipObj.style.borderRadius = "5px";
      this.tipObj.style.padding = "8px";
      this.tipObj.style.fontFamily = "Arial,Helvetica";
      this.tipObj.style.fontWeight = "600";
      this.tipObj.style.zIndex = "1000000000";
      if(findAncientPop) {
        this.tipObj.innerHTML = 'Population: <span style="font-weight: 400">' + findAncientPop.name + '</span>' +
                                '<br/>Sample: <span style="font-weight: 400;">' + findAncientPop.sampleID + '</span>' +
                                '<br/>Date: <span style="font-weight: 400;">' + findAncientPop.date + '</span>' +
                                '<br/>yDNA: <span style="font-weight: 400;">' + this.getYDna(findAncientPop) + '</span>' +
                                '<br/>mtDNA: <span style="font-weight: 400;">' + findAncientPop.mtDNA + '</span>';
      }

      //position it
      this.tipObj.style.position = "fixed";
      this.tipObj.style.top = event.originalEvent.clientY + this.offset.y + "px";
      this.tipObj.style.left = event.originalEvent.clientX + this.offset.x + "px";

      //add it to the body
      document.body.appendChild(this.tipObj);
      
      event.target.setStyle({ weight: 2 });
    },
    mouseOut(event) {
      event.target.setStyle({ weight: 0.5 });
      if (this.tipObj) {
        //delete the tooltip if it exists in the DOM
        document.body.removeChild(this.tipObj);
        this.tipObj = null;
      }
    },
    mouseMove(event) {
      if (this.tipObj && event) {
        this.tipObj.style.top = event.originalEvent.clientY + this.offset.y + "px";
        this.tipObj.style.left = event.originalEvent.clientX + this.offset.x + "px";
      }
    },
    mouseEnter(event) {
      this.popData = null;
      const targets = event.target.options.className;
      const findAncientPop = this.circles.find(popValue => targets === popValue.sampleID );

      if (findAncientPop) {
        ApiService.get(`data/InvidualAncientSampleDatabase/${findAncientPop.id}`, {}, this).then(response => {
          if (response.statusCode === 200) {
            document.body.removeChild(this.tipObj);
            this.tipObj = null;
            this.popData = response.result;
            this.$store.state.ancientIndividualDatabase.sampleDetail = response.result;
          }
        });
      }
    },

    getYDna(findAncientPop) {
      let yDna = '';
      if (findAncientPop.yDNAISOGG.length > 1 && findAncientPop.yDNATM.length > 1) {
        yDna = `${findAncientPop.yDNAISOGG} (${findAncientPop.yDNATM})`;
      } else if (findAncientPop.yDNAISOGG.length > 1) {
        yDna = findAncientPop.yDNAISOGG;
      } else if (findAncientPop.yDNATM.length > 1) {
        yDna = findAncientPop.yDNATM;
      }

      return yDna;
    },

    onLoadCoordinates() {
      this.isLoading = true;
      this.circles = [];

      const query = {
        location: this.changePageLocation,
        search: this.changePageSearch,
      };

      ApiService.get('data/InvidualAncientSampleDatabase/coordinates', query, this).then(response => {
          if (response.statusCode === 200) {
            response.result.forEach(item => {
              const findCircle = this.circles.find(x => x.latitude == item.latitude & x.longitude == item.longitude);
             
              if (findCircle == null && !(item.latitude == null || item.longitude == null || item.latitude == '' || item.longitude == '')) {
                this.circles.push({
                  id: item.id,
                  center: [Number(item.latitude), Number(item.longitude)],
                  radius: 6,
                  name: item.name,
                  sampleID: item.sampleID,
                  date: item.date,
                  yDNATM: item.yDNATM,
                  yDNAISOGG: item.yDNAISOGG,
                  mtDNA: item.mtDNA,
                });
              }
            });
          }
          this.isLoading = false;
      });
    },
    
    LoadFullscreen() {
      if (!process.server) {
        this.zoom = this.$store.state.appConfig.windowWidth >= 1079 ? 3 : 2.3;
        const map = this.$refs.ancientIndividualMap.mapObject;
        map.addControl(new window.L.Control.Fullscreen());
      }
    },
  },
  computed: {
    mapStyle() {
      return {
        width: this.mapWidth,
        height: this.mapHeight,
      };
    },
    mapStyleUrl() {
      return this.skin === 'light' ? 'https://api.maptiler.com/maps/ccd93165-ea57-413a-9f2d-3d6d7b4bf930/{z}/{x}/{y}.png?key=3Xf2PvM2xLutU2kB81Cl'
        : 'https://api.maptiler.com/maps/ccd93165-ea57-413a-9f2d-3d6d7b4bf930/{z}/{x}/{y}.png?key=3Xf2PvM2xLutU2kB81Cl';
    },
    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
    pagination() {
      return this.$store.getters['sampleDatabase/getFilterQuery'];
    },
    changePageLocation() {
      return this.pagination.location;
    },    
    changePageSearch() {
      return this.pagination.Search;
    },
  },
  watch: {
    changePageLocation() {
      this.onLoadCoordinates();
    },    
    changePageSearch() {
      this.onLoadCoordinates();
    },
  },
  mounted() {
    /* eslint-disable*/
    this.onLoadCoordinates();
  },
}

</script>

<style lang="scss" scoped>
.ancient-individual-map {
  position: relative;
  width: 100%!important;
  height: calc(100vh)!important;
}
@media screen and (max-width: 1079px) {
  .ancient-individual-map {
    position: relative;
    width: 100%!important;
    max-height: 530px!important;
  }
}

</style>
