<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="select-region-modal"
      hide-footer
      hide-header
      @hidden="hiddenModal"
      size="md"
    >
     <div class="modal-close-container">
      <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
     </div>
     <div class="select-region-modal-body info-modal-container">
        <div>
          <div class="info-modal-header">
            <h5>Change the region</h5>
          </div>
          <div class="third-info-content">
            <p class="description">
              Your current default region is assigned to you to best represent your chosen ethnicity. You may change this region below. 
            </p>
            <p class="description">
              The algorithm will attempt to model you with the reference populations belongs to the 
              selected region. Selecting regions that are irrelevant to your known 
              ethno-geographical background will produce less accurate and meaningles results. 
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="lab-dropdown sm region">
            <div class="lab-dropdown-btn" @click="isShowDropdownGroup = !isShowDropdownGroup" ref="dropdownRegionGrup">
              <span class="lab-dropdown-btn-value">
                {{ selectedRegionGroup.grupName.length > 16 ? selectedRegionGroup.grupName.slice(0, 16) + '..' : selectedRegionGroup.grupName}}
              </span>
              <span class="lab-dropdown-btn-icon">
                <DropdownOneIcon />
              </span>
            </div>
            <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdownGroup}">
              <ul>
                <li v-for="regionItem in regionGroupList"
                    :key="regionItem.id"
                    @click="onSetRegionGrup(regionItem)">
                    {{ regionItem.grupName }}
                </li>
              </ul>
            </div>
          </div>
          <div class="lab-dropdown sm region custom-margin-right mr-0">
            <div class="lab-dropdown-btn" @click="isShowDropdown = !isShowDropdown" ref="dropdownRegion">
              <span class="lab-dropdown-btn-value">
                {{ selectedRegion.title.length > 16 ? selectedRegion.title.slice(0, 16) + '..' : selectedRegion.title}}
              </span>
              <span class="lab-dropdown-btn-icon">
                <DropdownOneIcon />
              </span>
            </div>
            <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isShowDropdown}">
              <ul>
                <li v-for="regionItem in regionList"
                    :key="regionItem.id"
                    @click="onSetRegion(regionItem)">
                    {{ regionItem.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
     </div>

     <div class="model-info-bottom mt-4">
      <div></div>
      <button class="lab-btn single-btn" @click="applySelectedRegion()">Apply</button>
     </div>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'

import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    CloseModalIcon,
    DropdownOneIcon
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShowDropdownGroup: false,
      isShowDropdown: false,

      selectedRegionGroup: { id: 0, grupName: '' },
      selectedRegion: { id: 0, title: '' },
    }
  },
  methods: {
    initialModal() {
      this.$refs['select-region-modal'].show();
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['select-region-modal'].hide();
    },

    applySelectedRegion() {
      if (!this.selectedRegion.id) {
        AlertService.warning('Please select a region');
        return;
      }
      this.$store.commit('region/setSelectRegion', this.selectedRegion.id);
      this.hiddenModal();
    },

    onSetRegionGrup(regionGrup) {
      this.selectedRegionGroup = regionGrup;
      this.selectedRegion = { id: 0, title: '' };
    },
    onSetRegion(region) {
      this.selectedRegion = region;
    },

    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownRegionGrup;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownGroup = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownRegion;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdown = false;
        }
      } catch (error) {}
    },
  },
  computed: {
    regionGroupList() {
      return this.$store.getters['region/getRegionGroupList'].filter(x => x.dataVersion === this.$store.getters['daResult/dataVersion']);
    },
    regionList() {
      const regionList = this.$store.getters['region/getRegionList'];

      return regionList.filter(x => x.presetRegionGrupId === this.selectedRegionGroup.id);
    },
  },
  mounted() {
    this.initialModal();

    this.selectedRegionGroup = this.$store.state.region.selectedRegionGroup;
    this.selectedRegion = this.$store.state.region.selectedRegion;
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
.select-region-modal-body {
  position: relative;
}
.custom-margin-left {
  margin-left: 1.5rem !important;
}
.lab-dropdown.region {
  width: 49%;
}
.lab-dropdown .lab-dropdown-btn {
    padding: 10px 15px !important;
}
@media only screen and (max-width: 1079px) {
  .select-region-modal-body {
    display: block;
  }
  .lab-dropdown-content.show {
    bottom: 35px !important;
  }
  
}
</style>
