<template>
  <div id="periodical-map">
    <l-map ref="breakdownMap" :options="{ attributionControl: false }" 
      style="height: 100%; width: 100%;" @ready="onMapReady"
      :zoom="zoom" :maxZoom="maxZoom" :minZoom="minZoom" :center="center">
      <l-tile-layer :url="mapStyleUrl" :zIndex="zIndex"></l-tile-layer>
      <l-geo-json 
        v-for="item in geoJsonList"
        :key="item.sourceCode"
        :geojson="item.geoData" 
        :optionsStyle="item.geoJsonStyle"
        :options="item.options"
      >
      </l-geo-json>
    </l-map>

    <PeriodicalPopDetailModal class="periodical-detail-modal" v-if="periodicalPopDetailModal.showModal" :modalObject="periodicalPopDetailModal" />
  </div>
</template>

<script>
import { LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
import L from 'leaflet';
import periodicalMapHelper from './periodicalMapHelper'
import PeriodicalPopDetailModal from '../modals/PeriodicalProximityModal.vue';

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    PeriodicalPopDetailModal,
  },
  props: {
  },
  data() {
    return {
      zoom: 3,
      maxZoom: 6,
      minZoom: 2,
      zIndex: 4,
      center: [44.312223, 30.046882],
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      map: null, // Store the map instance here
      
      periodicalPopDetailModal: {
        showModal: false,
        item: null,
      },
    }
  },
  methods: {
    onMapReady(mapInstance) {
      this.map = mapInstance;
    },
    onOpenDetailPage(item) {
      this.$store.dispatch('periodicalResult/fetchSupervisedPeriodDetail', item.id);
      this.periodicalPopDetailModal.item = item;
      this.periodicalPopDetailModal.showModal = true;
    },
  },
  computed: {
    geoJsonList() {
      /* eslint-disable*/
      let tempOnOpenDetailPage = this.onOpenDetailPage;
      const geoJson = this.$store.getters['periodicalResult/getGeoJson']
      const geoList = geoJson.map(item => {
        if(typeof item.geoData == 'string')
          item.geoData = JSON.parse(item.geoData);
        
        let opacity = parseFloat(item.ancestryValue) / 100;
        opacity = opacity === 0 ? 0.30 : (opacity > 0.5 ? 0.5 : (opacity < 0.07 ? 0.07 : opacity));
        item.geoJsonStyle = {
          fillColor: item.color,
          color: item.color,
          weight: 0,
          fillOpacity: opacity,
        };

        item.options = {
          onEachFeature: function(feature, layer) {
            if (feature.properties) {
              layer.on('mouseover', function(e) { 
                periodicalMapHelper.injectTooltip(e, item);
              });
              layer.on('mousemove', function(e) { 
                periodicalMapHelper.moveTooltip(e);
              });
              layer.on('mouseout', function() {  
                periodicalMapHelper.deleteTooltip(); 
              });
              layer.on('click', function() {  
                tempOnOpenDetailPage(item);
              });
            }
          },
        };

        return item;
      });

      return geoList;
    },
    mapStyleUrl() {
      return this.skin === 'light' ? 'https://api.maptiler.com/maps/092b6e96-2ed2-450b-9a86-cd56747f2afd/{z}/{x}/{y}.png?key=3Xf2PvM2xLutU2kB81Cl'
        : 'https://api.maptiler.com/maps/9f6e4689-7660-405b-aa35-dd5a135f7495/{z}/{x}/{y}.png?key=3Xf2PvM2xLutU2kB81Cl';
    },
    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
    proximityId() {
      return this.$store.state.periodicalResult.openedPeriodicalDetail.id;
    },
  },
  watch: {
    proximityId(newId) {
      if (this.map) {
        if (newId) {
          const item = this.geoJsonList.find((item) => item.id === newId);
          if (item && item.geoData) {
            const layer = L.geoJSON(item.geoData);
            const center = layer.getBounds().getCenter();
            const desiredZoomLevel = 5; // Adjust this value as needed
            this.map.setView(center, desiredZoomLevel);
          }
        } else {
          this.map.setView(this.center, this.zoom);
        }
      }
    },
  },
}

</script>

<style lang="scss" scoped>
#periodical-map {
  width: 100% !important;
  height: 100% !important;

  @media screen and (max-width: 1079px) {
    height: 260px !important;
  }
}
</style>
