<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.55 23.55">
  <defs>
  </defs>
  <g id="Glyph">
    <path class="cls-1" d="M23.46,10.47c-.11-.98-.93-1.72-1.91-1.72h-.69c-.14-.45-.33-.87-.58-1.27l.53-.54c.7-.7,.76-1.81,.13-2.57-.53-.67-1.14-1.27-1.81-1.81-.77-.63-1.88-.57-2.58,.13l-.47,.5c-.41-.22-.85-.39-1.3-.49v-.73c0-.98-.74-1.81-1.72-1.91-.86-.09-1.72-.09-2.58,0-.98,.11-1.72,.93-1.72,1.91v.69c-.45,.14-.87,.33-1.27,.58l-.53-.53c-.7-.7-1.82-.76-2.58-.13-.66,.54-1.26,1.14-1.78,1.81-.63,.77-.57,1.88,.13,2.58l.5,.47c-.22,.41-.39,.85-.49,1.3h-.75c-.98,0-1.81,.74-1.91,1.72-.09,.86-.09,1.72,0,2.58,.11,.98,.93,1.72,1.91,1.72h.69c.14,.45,.33,.87,.58,1.27l-.53,.53c-.7,.7-.76,1.82-.13,2.58,.53,.67,1.14,1.27,1.81,1.81,.77,.63,1.88,.57,2.58-.13l.47-.5c.41,.22,.85,.39,1.3,.49v.75c0,.98,.74,1.81,1.72,1.91,.86,.09,1.72,.09,2.58,0,.98-.11,1.72-.93,1.72-1.91v-.69c.44-.14,.87-.34,1.27-.58l.53,.53c.7,.7,1.82,.76,2.58,.13,.67-.53,1.27-1.14,1.81-1.81,.63-.76,.57-1.88-.13-2.57l-.5-.48c.22-.41,.39-.85,.49-1.3h.75c.98,0,1.81-.74,1.91-1.72,.09-.86,.09-1.72,0-2.58l-.02-.02Zm-11.7,5.67c-2.42,0-4.38-1.96-4.38-4.38,0-2.42,1.96-4.38,4.38-4.38,2.42,0,4.38,1.96,4.38,4.38h0c0,2.42-1.96,4.38-4.38,4.38h0Z"/>
  </g>
</svg>
</template>

<script>
export default {
  components: {
  },
  props: {
    className: {
      default: 'normal',
      type: String,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}

.cls-1 {
  fill: #2d2d2d;
}

.dark-layout {
  .cls-1, .cls-2 {
    fill: var(--lab-white-100) !important;
  }
}
</style>
