<template>
  <nav class="dp-menu-header" :class="{ fixed: isFixed }">
    <div class="menu-content" id="menu-area"  @scroll="checkArrow()" @wheel="onScrollWheel">
      <button class="menu-arrow left" id="left-arrow" @click="scrollMenu(-250)">
        <ArrowMenuIcon :variant="$store.state.appConfig.layout.skin" device="DESKTOP" />
      </button>
      <ul class="menu-items">
        <li class="menu-item-li"  >
          <router-link draggable="false" class="menu-link dp-menu-item" :class="{ 'active': $route.meta.code == 'PERIODICAL'}"  :to="{ name: menu.PERIODICAL}">
            <PeriodicalMenuIcon class="dp-icon" :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            Periodical Breakdown
          </router-link>
        </li>
        <li class="menu-item-li">
          <router-link draggable="false" class="menu-link dp-menu-item" :class="{ 'active': $route.meta.code == 'HG-FARMER'}" :to="{ name: menu.HGFARMER }" >
            <HgFarmerMenuIcon class="dp-icon" :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            Hunter-Gatherer & Farmer
          </router-link>
        </li>
        <li class="menu-item-li">
          <router-link draggable="false" class="menu-link dp-menu-item" :class="{ 'active': $route.meta.code == 'UNSUPERVISED-ANALYSIS'}" :to="{ name: menu.UNSUPERVISEDANALYSIS }" >
            <UnsupervisedAnalysisMenuIcon class="dp-icon" :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            Unsupervised Analysis
          </router-link>
        </li>
        <li class="menu-item-li">
          <router-link draggable="false" class="menu-link dp-menu-item" :class="{ 'active': $route.meta.code == 'GENETIC-DISTANCES'}" :to="{ name: menu.GENETICDISTANCES }" >
            <GeneticDistMenuIcon class="dp-icon" :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            Closest Populations
          </router-link>
        </li>
        <li class="menu-item-li">
          <router-link draggable="false" class="menu-link dp-menu-item" :class="{ 'active': $route.meta.code == 'PCA-PLOTS'}" :to="{ name: menu.PCAPLOTS }" >
            <PcaPlotsMenuIcon class="dp-icon" :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            PCA Plots
          </router-link>
        </li>
        <li class="menu-item-li">
          <router-link draggable="false" class="menu-link dp-menu-item" :class="{ 'active': $route.meta.code == 'DIY-TOOLS'}" :to="{ name: menu.DIYTOOLS }" >
            <DiyToolsMenuIcon class="dp-icon" :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            DIY Tools
          </router-link>
        </li>
        <li class="menu-item-li">
          <router-link draggable="false" class="menu-link dp-menu-item" :class="{ 'active': $route.meta.code == 'SAMPLE-DATABASE'}" :to="{ name: menu.ENCYCLOPEDIA }"  >
            <EncyclopediaMenuIcon class="dp-icon" :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            Encyclopedia
          </router-link>
        </li>
        <li class="menu-item-li">
          <router-link draggable="false" class="menu-link dp-menu-item" :class="{ 'active': $route.meta.code == 'DOWNLOAD-COORDINATES'}" :to="{ name: menu.DOWNLOADCOORDINATES }" >
            <DownloadCoordsMenuIcon class="dp-icon" :variant="$store.state.appConfig.layout.skin" device="DESKTOP"/>
            Download Coordinates
          </router-link>
        </li>
      </ul>
      <button class="menu-arrow right" id="right-arrow" @click="scrollMenu(250)">
        <ArrowMenuIcon :variant="$store.state.appConfig.layout.skin" device="DESKTOP" />
      </button>
      <div class="">
      </div>
    </div>
    
  </nav>
</template>

<script>
import PeriodicalMenuIcon from './icons/menu/PeriodicalMenuIcon.vue';
import HgFarmerMenuIcon from './icons/menu/HgFarmerMenuIcon.vue';
import UnsupervisedAnalysisMenuIcon from './icons/menu/UnsupervisedAnalysisMenuIcon.vue';
import PcaPlotsMenuIcon from './icons/menu/PcaPlotsMenuIcon.vue';
import EncyclopediaMenuIcon from './icons/menu/EncyclopediaMenuIcon.vue';
import GeneticDistMenuIcon from './icons/menu/GeneticDistMenuIcon.vue';
import DiyToolsMenuIcon from './icons/menu/DiyToolsMenuIcon.vue';
import DownloadCoordsMenuIcon from './icons/menu/DownloadCoordsMenuIcon.vue';
import ArrowMenuIcon from './icons/menu/ArrowMenuIcon.vue';
import menuConfig from './routesMenu'

export default {
  components: {
    ArrowMenuIcon,
    PeriodicalMenuIcon,
    HgFarmerMenuIcon,
    UnsupervisedAnalysisMenuIcon,
    PcaPlotsMenuIcon,
    EncyclopediaMenuIcon,
    GeneticDistMenuIcon,
    DiyToolsMenuIcon,
    DownloadCoordsMenuIcon,
  },
  data() {
    return {
      isFixed: false,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
    };
  },
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin;
    },
    iconUrl() {
      /* eslint-disable */
      return 'https://image.illustrativedna.com/assets/icons/' + this.theme + '/icon-text.svg';
    },
  },

  beforeUnmount(){
    this.checkArrow();
  },

  mounted () {
    this.defineListeners();
    this.checkArrow();
  },

  methods: {
    defineListeners () {
      const menu = document.getElementById('menu-area');

      menu.addEventListener('mousedown', (e) => {
        this.isDragging = true;
        this.startX = e.pageX - menu.offsetLeft;
        this.scrollLeft = menu.scrollLeft;
        menu.style.cursor = 'grabbing';
      });
      menu.addEventListener('touchstart', (e) => {
        this.startX = e.touches[0].pageX - menu.offsetLeft;
        this.scrollLeft = menu.scrollLeft;
      });
      menu.addEventListener('mousemove', (e) => {
        if (!this.isDragging) return;
        const x = e.pageX - menu.offsetLeft;
        const walk = x - this.startX;
        menu.scrollLeft = this.scrollLeft - walk;
      });
      menu.addEventListener('touchmove', (e) => {
        const x = e.touches[0].pageX - menu.offsetLeft;
        const walk = x - this.startX;
        this.menu.scrollLeft = this.scrollLeft - walk;
      });
      // Touch and Mouse Up
      menu.addEventListener('mouseup', () => {
          this.isDragging = false;
          menu.style.cursor = 'grab';
      });

      menu.addEventListener('mouseleave', () => {
          this.isDragging = false;
          menu.style.cursor = 'grab';
      });

      menu.addEventListener('touchend', () => {
        this.isDragging = false;
      });
    },
    update() {
      // Güncelleme işlemleri
      alert('Update butonuna tıklandı!');
    },
    scrollMenu(distance) {
      const menu = document.getElementById('menu-area');
      menu.scrollBy({ left: distance, behavior: 'smooth' });
    },
    checkArrow() {
      const menu = document.getElementById('menu-area');
      const leftArrow = document.getElementById('left-arrow');
      const rightArrow = document.getElementById('right-arrow');
      leftArrow.style.display = menu.scrollLeft > 0 ? 'block' : 'none';
      rightArrow.style.display = menu.scrollLeft < menu.scrollWidth - menu.clientWidth ? 'block' : 'none';
    },
    onScrollWheel(event) {
      const menu = document.getElementById('menu-area');
      menu.scrollLeft += event.deltaY * 0.5; // Adjust scroll speed here
      this.checkArrow();
    },
    getPath(routeName){
      return routesMenu[routeName];
    }
  },

  computed: {
    menu() {
      return menuConfig.menu; // Make menu reactive via computed
    },
  },
};
</script>

<style lang="scss" scoped>
.dp-icon {
  width: 30px;
  height: 30px;
}
.dp-menu-header {
  // border-top: 2px solid var(--lab-hr-color);
  background-color: var(--bg-card-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 100%;
  min-height: 63px;
  top: 0;
  z-index: 1002;
  position: sticky;
  display: flex;
  align-items: end;
  justify-content: start;
  line-height: 1;

  .menu-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    user-select: none;
  }

  .logo img {
    height: 40px;
  }

  .menu-arrow {
    z-index:5;
    position: absolute;
    flex: 0 0 auto;
    cursor: pointer;
    font-size: 24px;
    border: none;
    top: 5;
    width: 50px;
    height: 50px;
  }


  .menu-arrow.left {
    left: 0px;
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, var(--bg-card-color) 60%);
    transform: rotate(180deg);
  }
  .menu-arrow.right {
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, var(--bg-card-color) 60%);
    right: 0;
  }
  .menu-arrow:hover {
    background:var(--bg-menu-arrow-hover);
    border-radius: 50%;
  }

  .menu-items {
    position: relative;
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0; 
    padding: 0;
    border-bottom: 4px solid var(--lab-hr-color);

    .menu-item-li {
      flex: 0 0 auto;
      padding: 20px 20px;
      margin: 0 5px;
      color: white;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      white-space: nowrap;
    }


    .menu-link {
      position: relative;
      display: flex;
      align-items: center; 
      gap: 8px;
      margin-right: 10px;
      text-decoration: none;
      color: var(--lab-header-menu-color);
      font-size: 0.8rem;
      font-weight: 600;

      

      &:hover,
      &.active {
        color: var(--lab-main-color);
      }
      &:hover::after,
      &.active::after {
        width: 115%;
        left: -7.5%; 
        opacity: 1;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 0;
        height: 4px;
        border-bottom: 3pt solid var(--lab-main-color);
        border-radius: 0px;
        height: 180%;
        background-color: transparent; 
        transition: width 0.3s ease, opacity 0.3s ease;
        opacity: 0; 
      }
    }
  }

  .update-button {
    // background-color: #6BD67B;
    background-color: var(--lab-main-color);
  }
}

.g25-main-icon svg {
    height: 25px!important;
    
}

.menu-arrow {
  svg {
        height: 10px!important;
    }
}
</style>
