<template>
  <div>
    <button class="lab-btn lab-btn-outline-seccondary"
      v-if="hasUpdate">
      Latest Version
    </button>
    <button class="lab-btn lab-btn-outline-success" @click="onUpdateResult()"
      v-else>
      Update Available
    </button>
  </div>
</template>
  
<script>
import ApiService from '@/common/api.service';
import AlertService from '@/common/alert.service'

export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    onUpdateResult() {
      const query = {
        orderResultId: this.$store.state.daResult.selectedResult.id,
      };
      ApiService.post('data/MixedModeResult/update', query, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$store.state.daResult.selectedResult.orderResultStatus = 3;
            AlertService.success(response.message);
          } else {
            AlertService.warning(this.$t(response.message));
          }
        });
    },
  },
  computed: {
    hasUpdate() {
      const currentResult = this.$store.state.mixedModeResult.currentResult;
      const period = this.$store.state.mixedModePeriod.selectedPeriod;
      
      if (!currentResult) {
        return false;
      }
      return period.sourceCodeVersion === currentResult.mixedModeVersion;
    },
  },
  mounted() {
  },
}
</script>
  
<style lang="scss" scoped>
</style>
  