<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-delete"
      scrollable
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      hide-footer
      hide-header
      size="sm"
    >
      <div class="confirm-modal">
        <div class="title mt-2 mb-2">
          <h4>
            <warning-alert-icon />
            WARNING!
          </h4>
        </div>
        <div class="message">
          <p>
            You are about to delete your account permanently. This action is <b>IRREVERSIBLE</b>.
          </p>
        </div>
        <div class="text-right mt-2">
          <button class="lab-btn lab-btn-lg lab-btn-info" type="submit" @click.prevent="confirmPasswordModal"> I UNDERSTAND </button>
          <button class="lab-btn lab-btn-lg ml-2 lab-btn-outline-mellow" @click="hiddenModal()"> Cancel </button>
        </div>
      </div>
    </b-modal>
    <confirm-password-popup v-if="confirmModalObject.showModal" :modalObject="confirmModalObject"/>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service';
import useJwt from '@/auth/jwt/useJwt'

import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';
import WarningAlertIcon from '@/layouts/icons/WarningAlertIcon.vue';

import ConfirmPasswordPopup from '@/components/ConfirmPasswordPopup.vue';

export default {
  components: {
    WarningAlertIcon,
    ConfirmPasswordPopup,
  },
  props: {
    type: {
      type: String,
    },
    modalObject: {
      type: Object,
    },
  },
  data() {
    return {
      confirmModalObject: {
        item: { password: null },
        showModal: false,
        onSuccess: this.handleOk,
      },
    }
  },
  methods: {
    initialModal() {
      this.$refs['modal-delete'].show();
    },
    confirmPasswordModal() {
      this.confirmModalObject.showModal = true;
    },
    handleOk(callback) {
      this.$store.dispatch('profileSettings/deleteAccount', this.confirmModalObject.item)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });

            this.hiddenModal();
            callback(true);
            this.logout();
          } else {
            AlertService.error(response.message)
          }
        });
    },
    hiddenModal() {
      this.$refs['modal-delete'].hide()
      this.modalObject.showModal = false;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.confirm-modal {
  .title {
    text-align: center;
    h4 {
      color: var(--lab-black-65);
      font-size: 1.4rem;
    }
  }

  .message p {
    color: var(--lab-stieglitz-silver);
    font-size: 0.8rem;
  }
}

.dark-layout .confirm-modal .title h4 {
  color: var(--lab-mellow-melon);
}

.dark-layout .confirm-modal .message p {
  color: var(--lab-doctor);
}
</style>
