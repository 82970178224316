<template>
  <div class="full-layout">
    <router-view class="router-main"/>
  </div>
</template>

<script>

export default {
  components: {
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_SKIN', 'light');
  },
}
</script>

<style lang="scss">
.full-layout {
  position: relative;
  height: 100vh;
}
// @media screen and (max-width: 1079px) {
//   .full-layout {
//     background-color: #fff !important;
//   }
// }
</style>
