<template>
  <div class="promotion-code-container">
    <b-input-group class="promotion-code">
      <b-form-input placeholder="Promotion Code" autocomplete="off" v-model="promotionData.promotionCode" :disabled="promotionDetailGuid !== null"/>
      <b-input-group-append>
        <b-button size="sm" variant="primary" class="promotion-code-btn" 
                  @click="applyPromotion" :disabled="promotionData.promotionCode === null || promotionData.isApplyingPromotion">
          <b-spinner v-if="promotionData.isApplyingPromotion" small />
          Apply
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <small class="lab-text-success" v-if="promotionDetailGuid !== null"> Promotion code applied </small>
  </div>
</template>

<script>
/* eslint-disable */
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

export default {
  directives: {
  },
  props: {
    checkoutModule: {
      type: String,
      default: 'deepACheckout',
    },
  },
  data() {
    return { 
      promotionData: {
        promotionCode: null,
        isApplyingPromotion: false,
      },
    }
  },
  methods: {
    applyPromotion() {
      const orderData = this.$store.getters[`${this.checkoutModule}/getCreateOrderData`];
      const findPrice = this.productData.productPrices.find(x => x.productCount === orderData.kitList.length);
      const query = {
        promotionCode: this.promotionData.promotionCode,
        orderAmount: findPrice.price.toString().replace(',', '.'),
      };

      this.promotionData.isApplyingPromotion = true;
      ApiService.post('promotion/apply/code', query, this).then(response => {
          if (response.statusCode === 200) {
            response.result.discountedAmount = response.result.discountedAmount.toString();
            this.$store.commit(`${this.checkoutModule}/setCreateOrderData`, { key: 'promotionDetailGuid', value: response.result.promotionDetailGuid });
            this.$store.commit(`${this.checkoutModule}/setCreateOrderData`, { key: 'discountSubtotal', value: response.result.discountedAmount.replace('.', ',') });
            this.$store.commit(`${this.checkoutModule}/setCreateOrderData`, { key: 'subtotal', value: findPrice.price.toString().replace('.', ',') });

            AlertService.success('Promotion code applied')
            window.location.reload();
          } else {
            AlertService.warning(response.message)
          }

          this.promotionData.promotionCode = null;
          this.promotionData.isApplyingPromotion = false;
      });
    },
  },
  computed: {
    productData () {
      return this.$store.getters[`${this.checkoutModule}/getProductData`];
    },
    promotionDetailGuid() {
      return this.$store.getters[`${this.checkoutModule}/getCreateOrderData`].promotionDetailGuid;
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.promotion-code-container {
  width: 200px;
  padding-left: 1rem; 
}
.promotion-code {
  margin-top: 2rem;
  box-shadow: none !important;
  .input-group {
    box-shadow: none !important;
  }
  .form-control {
    height: 2.2rem;
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 1.45;
  }
  .form-control:focus {
    border: 2px solid var(--lab-minted-elegance-color);
    box-shadow: none !important;
  }

  .promotion-code-btn {
    background-color: var(--lab-minted-elegance-color) !important;
    border-color: var(--lab-minted-elegance-color) !important;
    box-shadow: none !important;
    font-size: 0.7rem;
  }
}
</style>
