<template>
  <div class="g25-main-icon">
    <svg id="light" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g>
        <path class="cls-1" d="M39.41,35.86l-10.33-10.33-.08,.11c-.12,.17-.25,.32-.39,.46l10.28,10.28c.5,.5,.5,1.31,0,1.81l-.71,.71c-.24,.24-.56,.37-.9,.37s-.66-.13-.9-.37l-11.57-11.57-.68-.68-.09-.09-.45-.45-.07-.07-1.82-1.82L12.34,14.86l2.52-2.52,12.27,12.27c.45,0,.91,.1,1.34,.3L14.86,11.3l-3.56,3.56,.18,.18,11.56,11.56,.52,.52,12.29,12.29c.38,.38,.89,.59,1.42,.59s1.04-.21,1.42-.59l.71-.71c.78-.78,.78-2.06,0-2.85Z"/>
        <path class="cls-1" d="M11.15,15.07c-.06,0-.12,0-.18,0-.61-.05-1.16-.37-1.52-.87-.21-.3-.41-.61-.59-.92-.57-1-1.5-1.74-2.62-2.09l-.1-.03c-1.48-.46-2.69-1.55-3.31-2.97L.11,1.85C-.11,1.35,0,.77,.39,.39,.77,0,1.35-.11,1.85,.11l6.33,2.74c1.43,.62,2.51,1.82,2.97,3.3l.03,.11c.35,1.12,1.09,2.05,2.09,2.62,.31,.18,.62,.38,.92,.59,.5,.35,.82,.91,.87,1.52,.05,.61-.17,1.2-.6,1.63l-1.85,1.85c-.39,.39-.91,.6-1.46,.6ZM1.32,.73c-.15,0-.29,.06-.41,.18-.18,.18-.22,.42-.12,.65L3.52,7.89c.53,1.23,1.57,2.16,2.85,2.56l.1,.03c1.29,.4,2.37,1.26,3.04,2.42,.17,.29,.35,.58,.55,.86,.23,.32,.59,.53,.98,.56,.39,.03,.78-.11,1.05-.38l1.85-1.85c.28-.28,.42-.66,.39-1.05-.03-.39-.24-.75-.56-.98-.28-.2-.57-.38-.86-.55-1.16-.66-2.02-1.74-2.42-3.03l-.03-.11c-.4-1.28-1.33-2.32-2.56-2.85L1.56,.78c-.08-.03-.16-.05-.24-.05Z"/>
      </g>
      <path class="cls-1" d="M29.92,9.86h-.9v-.9c0-2.12,1.72-3.84,3.84-3.84h.9v.9c0,2.12-1.72,3.84-3.84,3.84Zm-.17-.73h.17c1.72,0,3.11-1.4,3.11-3.11v-.17h-.17c-1.72,0-3.11,1.4-3.11,3.11v.17Z"/>
      <path class="cls-1" d="M29.75,9.86h-.9c-2.12,0-3.84-1.72-3.84-3.84v-.9h.9c2.12,0,3.84,1.72,3.84,3.84v.9Zm-4.01-4.01v.17c0,1.72,1.4,3.11,3.11,3.11h.17v-.17c0-1.72-1.4-3.11-3.11-3.11h-.17Z"/>
      <path class="cls-1" d="M29.62,15.85c-.11,0-.22,0-.34-.01l-1.06-.09,.09-1.06c.18-2.11,2.05-3.68,4.16-3.49l1.06,.09-.09,1.06c-.17,2-1.86,3.51-3.83,3.51Zm-.6-.77l.33,.03c1.71,.15,3.22-1.12,3.37-2.83l.03-.33-.33-.03c-1.71-.15-3.22,1.12-3.37,2.83l-.03,.33Z"/>
      <path class="cls-1" d="M28.72,15.83l-1.06-.09c-1.02-.09-1.95-.57-2.61-1.36s-.97-1.78-.88-2.81l.09-1.06,1.06,.09c1.02,.09,1.95,.57,2.61,1.36,.66,.79,.97,1.78,.88,2.81l-.09,1.06Zm-3.79-4.52l-.03,.33c-.07,.83,.18,1.63,.72,2.27s1.28,1.03,2.11,1.1l.33,.03,.03-.33c.07-.83-.18-1.63-.72-2.27-.53-.64-1.28-1.03-2.11-1.1l-.33-.03Z"/>
      <path class="cls-1" d="M28.44,21.78c-.44,0-.88-.07-1.31-.23l-1-.36,.36-1c.35-.97,1.06-1.74,1.99-2.17,.93-.43,1.97-.48,2.94-.13h0l1,.36-.36,1c-.57,1.56-2.05,2.53-3.62,2.53Zm-1.37-1.03l.31,.11c1.61,.59,3.4-.25,3.99-1.86l.11-.31-.31-.11c-.78-.28-1.63-.25-2.38,.1s-1.32,.97-1.61,1.76l-.11,.31Z"/>
      <path class="cls-1" d="M26.55,21.42l-1-.36c-.97-.35-1.74-1.06-2.17-1.99-.43-.93-.48-1.97-.13-2.94l.36-1,1,.36c.97,.35,1.74,1.06,2.17,1.99,.43,.93,.48,1.97,.13,2.94l-.36,1Zm-2.5-5.35l-.11,.31c-.28,.78-.25,1.63,.1,2.38s.97,1.32,1.76,1.61l.31,.11,.11-.31c.28-.78,.25-1.63-.1-2.38s-.97-1.32-1.76-1.61l-.31-.11Z"/>
      <path class="cls-1" d="M28.61,24.57c-.57-.4-1.22-.63-1.89-.68-.32-.03-.65-.01-.98,.05,0,0,0,0-.01,0-.26,.05-.51,.12-.75,.22-.15,.06-.3,.13-.44,.21-.42,.23-.79,.54-1.1,.91-.07,.08-.12,.16-.18,.24l-.17,.24-.24,.34-.18,.26-.02,.03,.5,.35,.37,.26c.18,.12,.36,.23,.56,.33,.51,.25,1.07,.37,1.64,.37,.22,0,.45-.02,.67-.06,.77-.13,1.46-.49,2.01-1.02,.17-.17,.33-.35,.47-.56h0s.61-.87,.61-.87l-.87-.61Zm-.34,1.06c-.12,.17-.25,.32-.39,.46-.44,.43-1,.71-1.62,.82-.82,.14-1.65-.04-2.33-.52l-.25-.18h-.02s.19-.28,.19-.28c.05-.08,.11-.15,.17-.22,.6-.71,1.48-1.1,2.37-1.1,.45,0,.91,.1,1.34,.3,.15,.07,.31,.16,.45,.26l.27,.19-.11,.16-.08,.11Z"/>
      <path class="cls-1" d="M24.57,22.91c-.02-.25-.07-.5-.13-.75-.23-.82-.74-1.56-1.49-2.09l-.87-.61-.14,.2-.47,.67c-.59,.84-.81,1.86-.64,2.87,.03,.17,.07,.34,.12,.5,.25,.8,.76,1.49,1.46,1.98l.15,.11,.1,.07,.51,.35,.11,.08,.11-.15,.3-.42,.21-.3c.05-.07,.09-.14,.13-.21,.1-.16,.19-.33,.26-.51,.12-.27,.2-.56,.25-.85,.05-.32,.07-.64,.04-.96Zm-2.5-2.15l.19-.27,.27,.19c1.2,.84,1.63,2.39,1.11,3.69-.06,.14-.12,.28-.2,.41-.04,.08-.09,.15-.14,.22l-.19,.27h-.02s-.06-.06-.06-.06l-.19-.13c-1.41-.99-1.75-2.93-.76-4.33Z"/>
      <path class="cls-1" d="M20.78,32.91c-.11,0-.23,0-.34-.01-1.02-.09-1.95-.57-2.61-1.36l-.68-.81,.81-.68c.79-.66,1.78-.98,2.81-.89,1.02,.09,1.95,.57,2.61,1.36l.68,.81-.81,.68c-.7,.59-1.56,.9-2.47,.9Zm-2.6-2.1l.21,.25c.53,.64,1.28,1.03,2.11,1.1,.83,.07,1.63-.18,2.27-.72l.25-.21-.21-.25c-.53-.64-1.28-1.03-2.11-1.1-.83-.07-1.63,.18-2.27,.72l-.25,.21Z"/>
      <path class="cls-1" d="M17.6,31.27l-.68-.81c-1.36-1.62-1.15-4.05,.47-5.42l.81-.68,.68,.81c1.36,1.62,1.15,4.05-.47,5.42l-.81,.68Zm.51-5.88l-.25,.21c-.64,.53-1.03,1.28-1.1,2.11-.07,.83,.18,1.63,.72,2.27l.21,.25,.25-.21c1.31-1.1,1.49-3.07,.38-4.38l-.21-.25Z"/>
      <path class="cls-1" d="M14.44,36.74c-.44,0-.89-.08-1.32-.23-.96-.35-1.74-1.06-2.17-1.99l-.45-.96,.96-.45c.93-.43,1.97-.48,2.94-.13,.96,.35,1.74,1.06,2.17,1.99l.45,.96-.96,.45c-.52,.24-1.07,.36-1.62,.36Zm-2.96-2.83l.14,.3c.35,.75,.97,1.32,1.76,1.61,.78,.28,1.63,.25,2.38-.11l.3-.14-.14-.3c-.35-.75-.97-1.32-1.76-1.61-.78-.28-1.63-.25-2.38,.11l-.3,.14Z"/>
      <path class="cls-1" d="M10.77,34.2l-.45-.96c-.9-1.92-.06-4.21,1.86-5.11l.96-.45,.45,.96c.9,1.92,.06,4.21-1.86,5.11l-.96,.45Zm2.02-5.55l-.3,.14c-1.55,.73-2.23,2.58-1.5,4.13l.14,.3,.3-.14c.75-.35,1.32-.97,1.61-1.76,.28-.78,.25-1.63-.11-2.38l-.14-.3Z"/>
      <path class="cls-1" d="M7.64,34.25c-.2,0-.36-.15-.37-.35,0-.2,.15-.37,.35-.38,5.83-.26,10.62-2.5,14.24-6.68,6.77-7.8,7.32-19.79,7.17-21.33-.02-.2,.13-.38,.33-.4,.2-.02,.38,.13,.4,.33,.15,1.58-.41,13.89-7.34,21.88-3.76,4.33-8.72,6.66-14.76,6.93,0,0-.01,0-.02,0Z"/>
    </svg>
    <svg id="dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g>
        <path class="cls-1" d="M39.41,35.86l-10.33-10.33-.08,.11c-.12,.17-.25,.32-.39,.46l10.28,10.28c.5,.5,.5,1.31,0,1.81l-.71,.71c-.24,.24-.56,.37-.9,.37s-.66-.13-.9-.37l-11.57-11.57-.68-.68-.09-.09-.45-.45-.07-.07-1.82-1.82L12.34,14.86l2.52-2.52,12.27,12.27c.45,0,.91,.1,1.34,.3L14.86,11.3l-3.56,3.56,.18,.18,11.56,11.56,.52,.52,12.29,12.29c.38,.38,.89,.59,1.42,.59s1.04-.21,1.42-.59l.71-.71c.78-.78,.78-2.06,0-2.85Z"/>
        <path class="cls-1" d="M11.15,15.07c-.06,0-.12,0-.18,0-.61-.05-1.16-.37-1.52-.87-.21-.3-.41-.61-.59-.92-.57-1-1.5-1.74-2.62-2.09l-.1-.03c-1.48-.46-2.69-1.55-3.31-2.97L.11,1.85C-.11,1.35,0,.77,.39,.39,.77,0,1.35-.11,1.85,.11l6.33,2.74c1.43,.62,2.51,1.82,2.97,3.3l.03,.11c.35,1.12,1.09,2.05,2.09,2.62,.31,.18,.62,.38,.92,.59,.5,.35,.82,.91,.87,1.52,.05,.61-.17,1.2-.6,1.63l-1.85,1.85c-.39,.39-.91,.6-1.46,.6ZM1.32,.73c-.15,0-.29,.06-.41,.18-.18,.18-.22,.42-.12,.65L3.52,7.89c.53,1.23,1.57,2.16,2.85,2.56l.1,.03c1.29,.4,2.37,1.26,3.04,2.42,.17,.29,.35,.58,.55,.86,.23,.32,.59,.53,.98,.56,.39,.03,.78-.11,1.05-.38l1.85-1.85c.28-.28,.42-.66,.39-1.05-.03-.39-.24-.75-.56-.98-.28-.2-.57-.38-.86-.55-1.16-.66-2.02-1.74-2.42-3.03l-.03-.11c-.4-1.28-1.33-2.32-2.56-2.85L1.56,.78c-.08-.03-.16-.05-.24-.05Z"/>
      </g>
      <path class="cls-1" d="M29.92,9.86h-.9v-.9c0-2.12,1.72-3.84,3.84-3.84h.9v.9c0,2.12-1.72,3.84-3.84,3.84Zm-.17-.73h.17c1.72,0,3.11-1.4,3.11-3.11v-.17h-.17c-1.72,0-3.11,1.4-3.11,3.11v.17Z"/>
      <path class="cls-1" d="M29.75,9.86h-.9c-2.12,0-3.84-1.72-3.84-3.84v-.9h.9c2.12,0,3.84,1.72,3.84,3.84v.9Zm-4.01-4.01v.17c0,1.72,1.4,3.11,3.11,3.11h.17v-.17c0-1.72-1.4-3.11-3.11-3.11h-.17Z"/>
      <path class="cls-1" d="M29.62,15.85c-.11,0-.22,0-.34-.01l-1.06-.09,.09-1.06c.18-2.11,2.05-3.68,4.16-3.49l1.06,.09-.09,1.06c-.17,2-1.86,3.51-3.83,3.51Zm-.6-.77l.33,.03c1.71,.15,3.22-1.12,3.37-2.83l.03-.33-.33-.03c-1.71-.15-3.22,1.12-3.37,2.83l-.03,.33Z"/>
      <path class="cls-1" d="M28.72,15.83l-1.06-.09c-1.02-.09-1.95-.57-2.61-1.36s-.97-1.78-.88-2.81l.09-1.06,1.06,.09c1.02,.09,1.95,.57,2.61,1.36,.66,.79,.97,1.78,.88,2.81l-.09,1.06Zm-3.79-4.52l-.03,.33c-.07,.83,.18,1.63,.72,2.27s1.28,1.03,2.11,1.1l.33,.03,.03-.33c.07-.83-.18-1.63-.72-2.27-.53-.64-1.28-1.03-2.11-1.1l-.33-.03Z"/>
      <path class="cls-1" d="M28.44,21.78c-.44,0-.88-.07-1.31-.23l-1-.36,.36-1c.35-.97,1.06-1.74,1.99-2.17,.93-.43,1.97-.48,2.94-.13h0l1,.36-.36,1c-.57,1.56-2.05,2.53-3.62,2.53Zm-1.37-1.03l.31,.11c1.61,.59,3.4-.25,3.99-1.86l.11-.31-.31-.11c-.78-.28-1.63-.25-2.38,.1s-1.32,.97-1.61,1.76l-.11,.31Z"/>
      <path class="cls-1" d="M26.55,21.42l-1-.36c-.97-.35-1.74-1.06-2.17-1.99-.43-.93-.48-1.97-.13-2.94l.36-1,1,.36c.97,.35,1.74,1.06,2.17,1.99,.43,.93,.48,1.97,.13,2.94l-.36,1Zm-2.5-5.35l-.11,.31c-.28,.78-.25,1.63,.1,2.38s.97,1.32,1.76,1.61l.31,.11,.11-.31c.28-.78,.25-1.63-.1-2.38s-.97-1.32-1.76-1.61l-.31-.11Z"/>
      <path class="cls-1" d="M28.61,24.57c-.57-.4-1.22-.63-1.89-.68-.32-.03-.65-.01-.98,.05,0,0,0,0-.01,0-.26,.05-.51,.12-.75,.22-.15,.06-.3,.13-.44,.21-.42,.23-.79,.54-1.1,.91-.07,.08-.12,.16-.18,.24l-.17,.24-.24,.34-.18,.26-.02,.03,.5,.35,.37,.26c.18,.12,.36,.23,.56,.33,.51,.25,1.07,.37,1.64,.37,.22,0,.45-.02,.67-.06,.77-.13,1.46-.49,2.01-1.02,.17-.17,.33-.35,.47-.56h0s.61-.87,.61-.87l-.87-.61Zm-.34,1.06c-.12,.17-.25,.32-.39,.46-.44,.43-1,.71-1.62,.82-.82,.14-1.65-.04-2.33-.52l-.25-.18h-.02s.19-.28,.19-.28c.05-.08,.11-.15,.17-.22,.6-.71,1.48-1.1,2.37-1.1,.45,0,.91,.1,1.34,.3,.15,.07,.31,.16,.45,.26l.27,.19-.11,.16-.08,.11Z"/>
      <path class="cls-1" d="M24.57,22.91c-.02-.25-.07-.5-.13-.75-.23-.82-.74-1.56-1.49-2.09l-.87-.61-.14,.2-.47,.67c-.59,.84-.81,1.86-.64,2.87,.03,.17,.07,.34,.12,.5,.25,.8,.76,1.49,1.46,1.98l.15,.11,.1,.07,.51,.35,.11,.08,.11-.15,.3-.42,.21-.3c.05-.07,.09-.14,.13-.21,.1-.16,.19-.33,.26-.51,.12-.27,.2-.56,.25-.85,.05-.32,.07-.64,.04-.96Zm-2.5-2.15l.19-.27,.27,.19c1.2,.84,1.63,2.39,1.11,3.69-.06,.14-.12,.28-.2,.41-.04,.08-.09,.15-.14,.22l-.19,.27h-.02s-.06-.06-.06-.06l-.19-.13c-1.41-.99-1.75-2.93-.76-4.33Z"/>
      <path class="cls-1" d="M20.78,32.91c-.11,0-.23,0-.34-.01-1.02-.09-1.95-.57-2.61-1.36l-.68-.81,.81-.68c.79-.66,1.78-.98,2.81-.89,1.02,.09,1.95,.57,2.61,1.36l.68,.81-.81,.68c-.7,.59-1.56,.9-2.47,.9Zm-2.6-2.1l.21,.25c.53,.64,1.28,1.03,2.11,1.1,.83,.07,1.63-.18,2.27-.72l.25-.21-.21-.25c-.53-.64-1.28-1.03-2.11-1.1-.83-.07-1.63,.18-2.27,.72l-.25,.21Z"/>
      <path class="cls-1" d="M17.6,31.27l-.68-.81c-1.36-1.62-1.15-4.05,.47-5.42l.81-.68,.68,.81c1.36,1.62,1.15,4.05-.47,5.42l-.81,.68Zm.51-5.88l-.25,.21c-.64,.53-1.03,1.28-1.1,2.11-.07,.83,.18,1.63,.72,2.27l.21,.25,.25-.21c1.31-1.1,1.49-3.07,.38-4.38l-.21-.25Z"/>
      <path class="cls-1" d="M14.44,36.74c-.44,0-.89-.08-1.32-.23-.96-.35-1.74-1.06-2.17-1.99l-.45-.96,.96-.45c.93-.43,1.97-.48,2.94-.13,.96,.35,1.74,1.06,2.17,1.99l.45,.96-.96,.45c-.52,.24-1.07,.36-1.62,.36Zm-2.96-2.83l.14,.3c.35,.75,.97,1.32,1.76,1.61,.78,.28,1.63,.25,2.38-.11l.3-.14-.14-.3c-.35-.75-.97-1.32-1.76-1.61-.78-.28-1.63-.25-2.38,.11l-.3,.14Z"/>
      <path class="cls-1" d="M10.77,34.2l-.45-.96c-.9-1.92-.06-4.21,1.86-5.11l.96-.45,.45,.96c.9,1.92,.06,4.21-1.86,5.11l-.96,.45Zm2.02-5.55l-.3,.14c-1.55,.73-2.23,2.58-1.5,4.13l.14,.3,.3-.14c.75-.35,1.32-.97,1.61-1.76,.28-.78,.25-1.63-.11-2.38l-.14-.3Z"/>
      <path class="cls-1" d="M7.64,34.25c-.2,0-.36-.15-.37-.35,0-.2,.15-.37,.35-.38,5.83-.26,10.62-2.5,14.24-6.68,6.77-7.8,7.32-19.79,7.17-21.33-.02-.2,.13-.38,.33-.4,.2-.02,.38,.13,.4,.33,.15,1.58-.41,13.89-7.34,21.88-3.76,4.33-8.72,6.66-14.76,6.93,0,0-.01,0-.02,0Z"/>
    </svg>
    <svg id="hover" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="variant == 'light' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g>
        <path class="cls-1" d="M39.41,35.86l-10.33-10.33-.08,.11c-.12,.17-.25,.32-.39,.46l10.28,10.28c.5,.5,.5,1.31,0,1.81l-.71,.71c-.24,.24-.56,.37-.9,.37s-.66-.13-.9-.37l-11.57-11.57-.68-.68-.09-.09-.45-.45-.07-.07-1.82-1.82L12.34,14.86l2.52-2.52,12.27,12.27c.45,0,.91,.1,1.34,.3L14.86,11.3l-3.56,3.56,.18,.18,11.56,11.56,.52,.52,12.29,12.29c.38,.38,.89,.59,1.42,.59s1.04-.21,1.42-.59l.71-.71c.78-.78,.78-2.06,0-2.85Z"/>
        <path class="cls-1" d="M11.15,15.07c-.06,0-.12,0-.18,0-.61-.05-1.16-.37-1.52-.87-.21-.3-.41-.61-.59-.92-.57-1-1.5-1.74-2.62-2.09l-.1-.03c-1.48-.46-2.69-1.55-3.31-2.97L.11,1.85C-.11,1.35,0,.77,.39,.39,.77,0,1.35-.11,1.85,.11l6.33,2.74c1.43,.62,2.51,1.82,2.97,3.3l.03,.11c.35,1.12,1.09,2.05,2.09,2.62,.31,.18,.62,.38,.92,.59,.5,.35,.82,.91,.87,1.52,.05,.61-.17,1.2-.6,1.63l-1.85,1.85c-.39,.39-.91,.6-1.46,.6ZM1.32,.73c-.15,0-.29,.06-.41,.18-.18,.18-.22,.42-.12,.65L3.52,7.89c.53,1.23,1.57,2.16,2.85,2.56l.1,.03c1.29,.4,2.37,1.26,3.04,2.42,.17,.29,.35,.58,.55,.86,.23,.32,.59,.53,.98,.56,.39,.03,.78-.11,1.05-.38l1.85-1.85c.28-.28,.42-.66,.39-1.05-.03-.39-.24-.75-.56-.98-.28-.2-.57-.38-.86-.55-1.16-.66-2.02-1.74-2.42-3.03l-.03-.11c-.4-1.28-1.33-2.32-2.56-2.85L1.56,.78c-.08-.03-.16-.05-.24-.05Z"/>
      </g>
      <path class="cls-1" d="M29.92,9.86h-.9v-.9c0-2.12,1.72-3.84,3.84-3.84h.9v.9c0,2.12-1.72,3.84-3.84,3.84Zm-.17-.73h.17c1.72,0,3.11-1.4,3.11-3.11v-.17h-.17c-1.72,0-3.11,1.4-3.11,3.11v.17Z"/>
      <path class="cls-1" d="M29.75,9.86h-.9c-2.12,0-3.84-1.72-3.84-3.84v-.9h.9c2.12,0,3.84,1.72,3.84,3.84v.9Zm-4.01-4.01v.17c0,1.72,1.4,3.11,3.11,3.11h.17v-.17c0-1.72-1.4-3.11-3.11-3.11h-.17Z"/>
      <path class="cls-1" d="M29.62,15.85c-.11,0-.22,0-.34-.01l-1.06-.09,.09-1.06c.18-2.11,2.05-3.68,4.16-3.49l1.06,.09-.09,1.06c-.17,2-1.86,3.51-3.83,3.51Zm-.6-.77l.33,.03c1.71,.15,3.22-1.12,3.37-2.83l.03-.33-.33-.03c-1.71-.15-3.22,1.12-3.37,2.83l-.03,.33Z"/>
      <path class="cls-1" d="M28.72,15.83l-1.06-.09c-1.02-.09-1.95-.57-2.61-1.36s-.97-1.78-.88-2.81l.09-1.06,1.06,.09c1.02,.09,1.95,.57,2.61,1.36,.66,.79,.97,1.78,.88,2.81l-.09,1.06Zm-3.79-4.52l-.03,.33c-.07,.83,.18,1.63,.72,2.27s1.28,1.03,2.11,1.1l.33,.03,.03-.33c.07-.83-.18-1.63-.72-2.27-.53-.64-1.28-1.03-2.11-1.1l-.33-.03Z"/>
      <path class="cls-1" d="M28.44,21.78c-.44,0-.88-.07-1.31-.23l-1-.36,.36-1c.35-.97,1.06-1.74,1.99-2.17,.93-.43,1.97-.48,2.94-.13h0l1,.36-.36,1c-.57,1.56-2.05,2.53-3.62,2.53Zm-1.37-1.03l.31,.11c1.61,.59,3.4-.25,3.99-1.86l.11-.31-.31-.11c-.78-.28-1.63-.25-2.38,.1s-1.32,.97-1.61,1.76l-.11,.31Z"/>
      <path class="cls-1" d="M26.55,21.42l-1-.36c-.97-.35-1.74-1.06-2.17-1.99-.43-.93-.48-1.97-.13-2.94l.36-1,1,.36c.97,.35,1.74,1.06,2.17,1.99,.43,.93,.48,1.97,.13,2.94l-.36,1Zm-2.5-5.35l-.11,.31c-.28,.78-.25,1.63,.1,2.38s.97,1.32,1.76,1.61l.31,.11,.11-.31c.28-.78,.25-1.63-.1-2.38s-.97-1.32-1.76-1.61l-.31-.11Z"/>
      <path class="cls-1" d="M28.61,24.57c-.57-.4-1.22-.63-1.89-.68-.32-.03-.65-.01-.98,.05,0,0,0,0-.01,0-.26,.05-.51,.12-.75,.22-.15,.06-.3,.13-.44,.21-.42,.23-.79,.54-1.1,.91-.07,.08-.12,.16-.18,.24l-.17,.24-.24,.34-.18,.26-.02,.03,.5,.35,.37,.26c.18,.12,.36,.23,.56,.33,.51,.25,1.07,.37,1.64,.37,.22,0,.45-.02,.67-.06,.77-.13,1.46-.49,2.01-1.02,.17-.17,.33-.35,.47-.56h0s.61-.87,.61-.87l-.87-.61Zm-.34,1.06c-.12,.17-.25,.32-.39,.46-.44,.43-1,.71-1.62,.82-.82,.14-1.65-.04-2.33-.52l-.25-.18h-.02s.19-.28,.19-.28c.05-.08,.11-.15,.17-.22,.6-.71,1.48-1.1,2.37-1.1,.45,0,.91,.1,1.34,.3,.15,.07,.31,.16,.45,.26l.27,.19-.11,.16-.08,.11Z"/>
      <path class="cls-1" d="M24.57,22.91c-.02-.25-.07-.5-.13-.75-.23-.82-.74-1.56-1.49-2.09l-.87-.61-.14,.2-.47,.67c-.59,.84-.81,1.86-.64,2.87,.03,.17,.07,.34,.12,.5,.25,.8,.76,1.49,1.46,1.98l.15,.11,.1,.07,.51,.35,.11,.08,.11-.15,.3-.42,.21-.3c.05-.07,.09-.14,.13-.21,.1-.16,.19-.33,.26-.51,.12-.27,.2-.56,.25-.85,.05-.32,.07-.64,.04-.96Zm-2.5-2.15l.19-.27,.27,.19c1.2,.84,1.63,2.39,1.11,3.69-.06,.14-.12,.28-.2,.41-.04,.08-.09,.15-.14,.22l-.19,.27h-.02s-.06-.06-.06-.06l-.19-.13c-1.41-.99-1.75-2.93-.76-4.33Z"/>
      <path class="cls-1" d="M20.78,32.91c-.11,0-.23,0-.34-.01-1.02-.09-1.95-.57-2.61-1.36l-.68-.81,.81-.68c.79-.66,1.78-.98,2.81-.89,1.02,.09,1.95,.57,2.61,1.36l.68,.81-.81,.68c-.7,.59-1.56,.9-2.47,.9Zm-2.6-2.1l.21,.25c.53,.64,1.28,1.03,2.11,1.1,.83,.07,1.63-.18,2.27-.72l.25-.21-.21-.25c-.53-.64-1.28-1.03-2.11-1.1-.83-.07-1.63,.18-2.27,.72l-.25,.21Z"/>
      <path class="cls-1" d="M17.6,31.27l-.68-.81c-1.36-1.62-1.15-4.05,.47-5.42l.81-.68,.68,.81c1.36,1.62,1.15,4.05-.47,5.42l-.81,.68Zm.51-5.88l-.25,.21c-.64,.53-1.03,1.28-1.1,2.11-.07,.83,.18,1.63,.72,2.27l.21,.25,.25-.21c1.31-1.1,1.49-3.07,.38-4.38l-.21-.25Z"/>
      <path class="cls-1" d="M14.44,36.74c-.44,0-.89-.08-1.32-.23-.96-.35-1.74-1.06-2.17-1.99l-.45-.96,.96-.45c.93-.43,1.97-.48,2.94-.13,.96,.35,1.74,1.06,2.17,1.99l.45,.96-.96,.45c-.52,.24-1.07,.36-1.62,.36Zm-2.96-2.83l.14,.3c.35,.75,.97,1.32,1.76,1.61,.78,.28,1.63,.25,2.38-.11l.3-.14-.14-.3c-.35-.75-.97-1.32-1.76-1.61-.78-.28-1.63-.25-2.38,.11l-.3,.14Z"/>
      <path class="cls-1" d="M10.77,34.2l-.45-.96c-.9-1.92-.06-4.21,1.86-5.11l.96-.45,.45,.96c.9,1.92,.06,4.21-1.86,5.11l-.96,.45Zm2.02-5.55l-.3,.14c-1.55,.73-2.23,2.58-1.5,4.13l.14,.3,.3-.14c.75-.35,1.32-.97,1.61-1.76,.28-.78,.25-1.63-.11-2.38l-.14-.3Z"/>
      <path class="cls-1" d="M7.64,34.25c-.2,0-.36-.15-.37-.35,0-.2,.15-.37,.35-.38,5.83-.26,10.62-2.5,14.24-6.68,6.77-7.8,7.32-19.79,7.17-21.33-.02-.2,.13-.38,.33-.4,.2-.02,.38,.13,.4,.33,.15,1.58-.41,13.89-7.34,21.88-3.76,4.33-8.72,6.66-14.76,6.93,0,0-.01,0-.02,0Z"/>
    </svg>
    <svg id="hover_dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="variant == 'dark' && device == 'DESKTOP'">
      <defs>
      </defs>
      <g>
        <path class="cls-1" d="M39.41,35.86l-10.33-10.33-.08,.11c-.12,.17-.25,.32-.39,.46l10.28,10.28c.5,.5,.5,1.31,0,1.81l-.71,.71c-.24,.24-.56,.37-.9,.37s-.66-.13-.9-.37l-11.57-11.57-.68-.68-.09-.09-.45-.45-.07-.07-1.82-1.82L12.34,14.86l2.52-2.52,12.27,12.27c.45,0,.91,.1,1.34,.3L14.86,11.3l-3.56,3.56,.18,.18,11.56,11.56,.52,.52,12.29,12.29c.38,.38,.89,.59,1.42,.59s1.04-.21,1.42-.59l.71-.71c.78-.78,.78-2.06,0-2.85Z"/>
        <path class="cls-1" d="M11.15,15.07c-.06,0-.12,0-.18,0-.61-.05-1.16-.37-1.52-.87-.21-.3-.41-.61-.59-.92-.57-1-1.5-1.74-2.62-2.09l-.1-.03c-1.48-.46-2.69-1.55-3.31-2.97L.11,1.85C-.11,1.35,0,.77,.39,.39,.77,0,1.35-.11,1.85,.11l6.33,2.74c1.43,.62,2.51,1.82,2.97,3.3l.03,.11c.35,1.12,1.09,2.05,2.09,2.62,.31,.18,.62,.38,.92,.59,.5,.35,.82,.91,.87,1.52,.05,.61-.17,1.2-.6,1.63l-1.85,1.85c-.39,.39-.91,.6-1.46,.6ZM1.32,.73c-.15,0-.29,.06-.41,.18-.18,.18-.22,.42-.12,.65L3.52,7.89c.53,1.23,1.57,2.16,2.85,2.56l.1,.03c1.29,.4,2.37,1.26,3.04,2.42,.17,.29,.35,.58,.55,.86,.23,.32,.59,.53,.98,.56,.39,.03,.78-.11,1.05-.38l1.85-1.85c.28-.28,.42-.66,.39-1.05-.03-.39-.24-.75-.56-.98-.28-.2-.57-.38-.86-.55-1.16-.66-2.02-1.74-2.42-3.03l-.03-.11c-.4-1.28-1.33-2.32-2.56-2.85L1.56,.78c-.08-.03-.16-.05-.24-.05Z"/>
      </g>
      <path class="cls-1" d="M29.92,9.86h-.9v-.9c0-2.12,1.72-3.84,3.84-3.84h.9v.9c0,2.12-1.72,3.84-3.84,3.84Zm-.17-.73h.17c1.72,0,3.11-1.4,3.11-3.11v-.17h-.17c-1.72,0-3.11,1.4-3.11,3.11v.17Z"/>
      <path class="cls-1" d="M29.75,9.86h-.9c-2.12,0-3.84-1.72-3.84-3.84v-.9h.9c2.12,0,3.84,1.72,3.84,3.84v.9Zm-4.01-4.01v.17c0,1.72,1.4,3.11,3.11,3.11h.17v-.17c0-1.72-1.4-3.11-3.11-3.11h-.17Z"/>
      <path class="cls-1" d="M29.62,15.85c-.11,0-.22,0-.34-.01l-1.06-.09,.09-1.06c.18-2.11,2.05-3.68,4.16-3.49l1.06,.09-.09,1.06c-.17,2-1.86,3.51-3.83,3.51Zm-.6-.77l.33,.03c1.71,.15,3.22-1.12,3.37-2.83l.03-.33-.33-.03c-1.71-.15-3.22,1.12-3.37,2.83l-.03,.33Z"/>
      <path class="cls-1" d="M28.72,15.83l-1.06-.09c-1.02-.09-1.95-.57-2.61-1.36s-.97-1.78-.88-2.81l.09-1.06,1.06,.09c1.02,.09,1.95,.57,2.61,1.36,.66,.79,.97,1.78,.88,2.81l-.09,1.06Zm-3.79-4.52l-.03,.33c-.07,.83,.18,1.63,.72,2.27s1.28,1.03,2.11,1.1l.33,.03,.03-.33c.07-.83-.18-1.63-.72-2.27-.53-.64-1.28-1.03-2.11-1.1l-.33-.03Z"/>
      <path class="cls-1" d="M28.44,21.78c-.44,0-.88-.07-1.31-.23l-1-.36,.36-1c.35-.97,1.06-1.74,1.99-2.17,.93-.43,1.97-.48,2.94-.13h0l1,.36-.36,1c-.57,1.56-2.05,2.53-3.62,2.53Zm-1.37-1.03l.31,.11c1.61,.59,3.4-.25,3.99-1.86l.11-.31-.31-.11c-.78-.28-1.63-.25-2.38,.1s-1.32,.97-1.61,1.76l-.11,.31Z"/>
      <path class="cls-1" d="M26.55,21.42l-1-.36c-.97-.35-1.74-1.06-2.17-1.99-.43-.93-.48-1.97-.13-2.94l.36-1,1,.36c.97,.35,1.74,1.06,2.17,1.99,.43,.93,.48,1.97,.13,2.94l-.36,1Zm-2.5-5.35l-.11,.31c-.28,.78-.25,1.63,.1,2.38s.97,1.32,1.76,1.61l.31,.11,.11-.31c.28-.78,.25-1.63-.1-2.38s-.97-1.32-1.76-1.61l-.31-.11Z"/>
      <path class="cls-1" d="M28.61,24.57c-.57-.4-1.22-.63-1.89-.68-.32-.03-.65-.01-.98,.05,0,0,0,0-.01,0-.26,.05-.51,.12-.75,.22-.15,.06-.3,.13-.44,.21-.42,.23-.79,.54-1.1,.91-.07,.08-.12,.16-.18,.24l-.17,.24-.24,.34-.18,.26-.02,.03,.5,.35,.37,.26c.18,.12,.36,.23,.56,.33,.51,.25,1.07,.37,1.64,.37,.22,0,.45-.02,.67-.06,.77-.13,1.46-.49,2.01-1.02,.17-.17,.33-.35,.47-.56h0s.61-.87,.61-.87l-.87-.61Zm-.34,1.06c-.12,.17-.25,.32-.39,.46-.44,.43-1,.71-1.62,.82-.82,.14-1.65-.04-2.33-.52l-.25-.18h-.02s.19-.28,.19-.28c.05-.08,.11-.15,.17-.22,.6-.71,1.48-1.1,2.37-1.1,.45,0,.91,.1,1.34,.3,.15,.07,.31,.16,.45,.26l.27,.19-.11,.16-.08,.11Z"/>
      <path class="cls-1" d="M24.57,22.91c-.02-.25-.07-.5-.13-.75-.23-.82-.74-1.56-1.49-2.09l-.87-.61-.14,.2-.47,.67c-.59,.84-.81,1.86-.64,2.87,.03,.17,.07,.34,.12,.5,.25,.8,.76,1.49,1.46,1.98l.15,.11,.1,.07,.51,.35,.11,.08,.11-.15,.3-.42,.21-.3c.05-.07,.09-.14,.13-.21,.1-.16,.19-.33,.26-.51,.12-.27,.2-.56,.25-.85,.05-.32,.07-.64,.04-.96Zm-2.5-2.15l.19-.27,.27,.19c1.2,.84,1.63,2.39,1.11,3.69-.06,.14-.12,.28-.2,.41-.04,.08-.09,.15-.14,.22l-.19,.27h-.02s-.06-.06-.06-.06l-.19-.13c-1.41-.99-1.75-2.93-.76-4.33Z"/>
      <path class="cls-1" d="M20.78,32.91c-.11,0-.23,0-.34-.01-1.02-.09-1.95-.57-2.61-1.36l-.68-.81,.81-.68c.79-.66,1.78-.98,2.81-.89,1.02,.09,1.95,.57,2.61,1.36l.68,.81-.81,.68c-.7,.59-1.56,.9-2.47,.9Zm-2.6-2.1l.21,.25c.53,.64,1.28,1.03,2.11,1.1,.83,.07,1.63-.18,2.27-.72l.25-.21-.21-.25c-.53-.64-1.28-1.03-2.11-1.1-.83-.07-1.63,.18-2.27,.72l-.25,.21Z"/>
      <path class="cls-1" d="M17.6,31.27l-.68-.81c-1.36-1.62-1.15-4.05,.47-5.42l.81-.68,.68,.81c1.36,1.62,1.15,4.05-.47,5.42l-.81,.68Zm.51-5.88l-.25,.21c-.64,.53-1.03,1.28-1.1,2.11-.07,.83,.18,1.63,.72,2.27l.21,.25,.25-.21c1.31-1.1,1.49-3.07,.38-4.38l-.21-.25Z"/>
      <path class="cls-1" d="M14.44,36.74c-.44,0-.89-.08-1.32-.23-.96-.35-1.74-1.06-2.17-1.99l-.45-.96,.96-.45c.93-.43,1.97-.48,2.94-.13,.96,.35,1.74,1.06,2.17,1.99l.45,.96-.96,.45c-.52,.24-1.07,.36-1.62,.36Zm-2.96-2.83l.14,.3c.35,.75,.97,1.32,1.76,1.61,.78,.28,1.63,.25,2.38-.11l.3-.14-.14-.3c-.35-.75-.97-1.32-1.76-1.61-.78-.28-1.63-.25-2.38,.11l-.3,.14Z"/>
      <path class="cls-1" d="M10.77,34.2l-.45-.96c-.9-1.92-.06-4.21,1.86-5.11l.96-.45,.45,.96c.9,1.92,.06,4.21-1.86,5.11l-.96,.45Zm2.02-5.55l-.3,.14c-1.55,.73-2.23,2.58-1.5,4.13l.14,.3,.3-.14c.75-.35,1.32-.97,1.61-1.76,.28-.78,.25-1.63-.11-2.38l-.14-.3Z"/>
      <path class="cls-1" d="M7.64,34.25c-.2,0-.36-.15-.37-.35,0-.2,.15-.37,.35-.38,5.83-.26,10.62-2.5,14.24-6.68,6.77-7.8,7.32-19.79,7.17-21.33-.02-.2,.13-.38,.33-.4,.2-.02,.38,.13,.4,.33,.15,1.58-.41,13.89-7.34,21.88-3.76,4.33-8.72,6.66-14.76,6.93,0,0-.01,0-.02,0Z"/>
    </svg>
    <svg id="mobile" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="device == 'MOBILE'">
      <defs>
      </defs>
      <g>
        <path class="cls-1" d="M39.41,35.86l-10.33-10.33-.08,.11c-.12,.17-.25,.32-.39,.46l10.28,10.28c.5,.5,.5,1.31,0,1.81l-.71,.71c-.24,.24-.56,.37-.9,.37s-.66-.13-.9-.37l-11.57-11.57-.68-.68-.09-.09-.45-.45-.07-.07-1.82-1.82L12.34,14.86l2.52-2.52,12.27,12.27c.45,0,.91,.1,1.34,.3L14.86,11.3l-3.56,3.56,.18,.18,11.56,11.56,.52,.52,12.29,12.29c.38,.38,.89,.59,1.42,.59s1.04-.21,1.42-.59l.71-.71c.78-.78,.78-2.06,0-2.85Z"/>
        <path class="cls-1" d="M11.15,15.07c-.06,0-.12,0-.18,0-.61-.05-1.16-.37-1.52-.87-.21-.3-.41-.61-.59-.92-.57-1-1.5-1.74-2.62-2.09l-.1-.03c-1.48-.46-2.69-1.55-3.31-2.97L.11,1.85C-.11,1.35,0,.77,.39,.39,.77,0,1.35-.11,1.85,.11l6.33,2.74c1.43,.62,2.51,1.82,2.97,3.3l.03,.11c.35,1.12,1.09,2.05,2.09,2.62,.31,.18,.62,.38,.92,.59,.5,.35,.82,.91,.87,1.52,.05,.61-.17,1.2-.6,1.63l-1.85,1.85c-.39,.39-.91,.6-1.46,.6ZM1.32,.73c-.15,0-.29,.06-.41,.18-.18,.18-.22,.42-.12,.65L3.52,7.89c.53,1.23,1.57,2.16,2.85,2.56l.1,.03c1.29,.4,2.37,1.26,3.04,2.42,.17,.29,.35,.58,.55,.86,.23,.32,.59,.53,.98,.56,.39,.03,.78-.11,1.05-.38l1.85-1.85c.28-.28,.42-.66,.39-1.05-.03-.39-.24-.75-.56-.98-.28-.2-.57-.38-.86-.55-1.16-.66-2.02-1.74-2.42-3.03l-.03-.11c-.4-1.28-1.33-2.32-2.56-2.85L1.56,.78c-.08-.03-.16-.05-.24-.05Z"/>
      </g>
      <path class="cls-1" d="M29.92,9.86h-.9v-.9c0-2.12,1.72-3.84,3.84-3.84h.9v.9c0,2.12-1.72,3.84-3.84,3.84Zm-.17-.73h.17c1.72,0,3.11-1.4,3.11-3.11v-.17h-.17c-1.72,0-3.11,1.4-3.11,3.11v.17Z"/>
      <path class="cls-1" d="M29.75,9.86h-.9c-2.12,0-3.84-1.72-3.84-3.84v-.9h.9c2.12,0,3.84,1.72,3.84,3.84v.9Zm-4.01-4.01v.17c0,1.72,1.4,3.11,3.11,3.11h.17v-.17c0-1.72-1.4-3.11-3.11-3.11h-.17Z"/>
      <path class="cls-1" d="M29.62,15.85c-.11,0-.22,0-.34-.01l-1.06-.09,.09-1.06c.18-2.11,2.05-3.68,4.16-3.49l1.06,.09-.09,1.06c-.17,2-1.86,3.51-3.83,3.51Zm-.6-.77l.33,.03c1.71,.15,3.22-1.12,3.37-2.83l.03-.33-.33-.03c-1.71-.15-3.22,1.12-3.37,2.83l-.03,.33Z"/>
      <path class="cls-1" d="M28.72,15.83l-1.06-.09c-1.02-.09-1.95-.57-2.61-1.36s-.97-1.78-.88-2.81l.09-1.06,1.06,.09c1.02,.09,1.95,.57,2.61,1.36,.66,.79,.97,1.78,.88,2.81l-.09,1.06Zm-3.79-4.52l-.03,.33c-.07,.83,.18,1.63,.72,2.27s1.28,1.03,2.11,1.1l.33,.03,.03-.33c.07-.83-.18-1.63-.72-2.27-.53-.64-1.28-1.03-2.11-1.1l-.33-.03Z"/>
      <path class="cls-1" d="M28.44,21.78c-.44,0-.88-.07-1.31-.23l-1-.36,.36-1c.35-.97,1.06-1.74,1.99-2.17,.93-.43,1.97-.48,2.94-.13h0l1,.36-.36,1c-.57,1.56-2.05,2.53-3.62,2.53Zm-1.37-1.03l.31,.11c1.61,.59,3.4-.25,3.99-1.86l.11-.31-.31-.11c-.78-.28-1.63-.25-2.38,.1s-1.32,.97-1.61,1.76l-.11,.31Z"/>
      <path class="cls-1" d="M26.55,21.42l-1-.36c-.97-.35-1.74-1.06-2.17-1.99-.43-.93-.48-1.97-.13-2.94l.36-1,1,.36c.97,.35,1.74,1.06,2.17,1.99,.43,.93,.48,1.97,.13,2.94l-.36,1Zm-2.5-5.35l-.11,.31c-.28,.78-.25,1.63,.1,2.38s.97,1.32,1.76,1.61l.31,.11,.11-.31c.28-.78,.25-1.63-.1-2.38s-.97-1.32-1.76-1.61l-.31-.11Z"/>
      <path class="cls-1" d="M28.61,24.57c-.57-.4-1.22-.63-1.89-.68-.32-.03-.65-.01-.98,.05,0,0,0,0-.01,0-.26,.05-.51,.12-.75,.22-.15,.06-.3,.13-.44,.21-.42,.23-.79,.54-1.1,.91-.07,.08-.12,.16-.18,.24l-.17,.24-.24,.34-.18,.26-.02,.03,.5,.35,.37,.26c.18,.12,.36,.23,.56,.33,.51,.25,1.07,.37,1.64,.37,.22,0,.45-.02,.67-.06,.77-.13,1.46-.49,2.01-1.02,.17-.17,.33-.35,.47-.56h0s.61-.87,.61-.87l-.87-.61Zm-.34,1.06c-.12,.17-.25,.32-.39,.46-.44,.43-1,.71-1.62,.82-.82,.14-1.65-.04-2.33-.52l-.25-.18h-.02s.19-.28,.19-.28c.05-.08,.11-.15,.17-.22,.6-.71,1.48-1.1,2.37-1.1,.45,0,.91,.1,1.34,.3,.15,.07,.31,.16,.45,.26l.27,.19-.11,.16-.08,.11Z"/>
      <path class="cls-1" d="M24.57,22.91c-.02-.25-.07-.5-.13-.75-.23-.82-.74-1.56-1.49-2.09l-.87-.61-.14,.2-.47,.67c-.59,.84-.81,1.86-.64,2.87,.03,.17,.07,.34,.12,.5,.25,.8,.76,1.49,1.46,1.98l.15,.11,.1,.07,.51,.35,.11,.08,.11-.15,.3-.42,.21-.3c.05-.07,.09-.14,.13-.21,.1-.16,.19-.33,.26-.51,.12-.27,.2-.56,.25-.85,.05-.32,.07-.64,.04-.96Zm-2.5-2.15l.19-.27,.27,.19c1.2,.84,1.63,2.39,1.11,3.69-.06,.14-.12,.28-.2,.41-.04,.08-.09,.15-.14,.22l-.19,.27h-.02s-.06-.06-.06-.06l-.19-.13c-1.41-.99-1.75-2.93-.76-4.33Z"/>
      <path class="cls-1" d="M20.78,32.91c-.11,0-.23,0-.34-.01-1.02-.09-1.95-.57-2.61-1.36l-.68-.81,.81-.68c.79-.66,1.78-.98,2.81-.89,1.02,.09,1.95,.57,2.61,1.36l.68,.81-.81,.68c-.7,.59-1.56,.9-2.47,.9Zm-2.6-2.1l.21,.25c.53,.64,1.28,1.03,2.11,1.1,.83,.07,1.63-.18,2.27-.72l.25-.21-.21-.25c-.53-.64-1.28-1.03-2.11-1.1-.83-.07-1.63,.18-2.27,.72l-.25,.21Z"/>
      <path class="cls-1" d="M17.6,31.27l-.68-.81c-1.36-1.62-1.15-4.05,.47-5.42l.81-.68,.68,.81c1.36,1.62,1.15,4.05-.47,5.42l-.81,.68Zm.51-5.88l-.25,.21c-.64,.53-1.03,1.28-1.1,2.11-.07,.83,.18,1.63,.72,2.27l.21,.25,.25-.21c1.31-1.1,1.49-3.07,.38-4.38l-.21-.25Z"/>
      <path class="cls-1" d="M14.44,36.74c-.44,0-.89-.08-1.32-.23-.96-.35-1.74-1.06-2.17-1.99l-.45-.96,.96-.45c.93-.43,1.97-.48,2.94-.13,.96,.35,1.74,1.06,2.17,1.99l.45,.96-.96,.45c-.52,.24-1.07,.36-1.62,.36Zm-2.96-2.83l.14,.3c.35,.75,.97,1.32,1.76,1.61,.78,.28,1.63,.25,2.38-.11l.3-.14-.14-.3c-.35-.75-.97-1.32-1.76-1.61-.78-.28-1.63-.25-2.38,.11l-.3,.14Z"/>
      <path class="cls-1" d="M10.77,34.2l-.45-.96c-.9-1.92-.06-4.21,1.86-5.11l.96-.45,.45,.96c.9,1.92,.06,4.21-1.86,5.11l-.96,.45Zm2.02-5.55l-.3,.14c-1.55,.73-2.23,2.58-1.5,4.13l.14,.3,.3-.14c.75-.35,1.32-.97,1.61-1.76,.28-.78,.25-1.63-.11-2.38l-.14-.3Z"/>
      <path class="cls-1" d="M7.64,34.25c-.2,0-.36-.15-.37-.35,0-.2,.15-.37,.35-.38,5.83-.26,10.62-2.5,14.24-6.68,6.77-7.8,7.32-19.79,7.17-21.33-.02-.2,.13-.38,.33-.4,.2-.02,.38,.13,.4,.33,.15,1.58-.41,13.89-7.34,21.88-3.76,4.33-8.72,6.66-14.76,6.93,0,0-.01,0-.02,0Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>

#mobile {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#light {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#dark {
  .cls-1 {
    fill: #f9f9f9;
  }
}

#hover {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}

#hover_dark {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}

.dp-menu-item:hover, .dp-menu-item.active {
  #hover, #hover_dark {
    display: block;
  }
  #light, #dark {
    display: none;
  }
}
</style>
