<template>
  <div class="mobile-dashboard-service">
    <div class="dashboard-card-title">
      <h3>Services</h3>
    </div>
    
    <b-row class="mt-1">
      <b-col cols="12">
        <div class="service-item dashboard-card" @click="$router.push({ name: 'dashboard-da' })">
          <DeepAncestryIcon class="deep-ancestry-image" />
          <div class="text">DeepAncestry<sup class="trade-mark-text">TM</sup></div>
        </div>
      </b-col>
      <!-- <b-col cols="6">
        <div class="service-item dashboard-card" @click="$router.push({ name: 'dashboard-traits' })">
          <b-img
            :src="'https://api.illustrativedna.com/assets/icons/mobile/trait-health.svg'"
          />
          <div class="text g25-text">TRAITS & HEALTHS</div>
        </div>
      </b-col>
      <b-col cols="12 mt-2">
        <div class="service-item dashboard-card d-flex align-items-center justify-content-start" @click="$router.push({ name: 'dashboard-ibd' })">
          <b-img
            :src="'https://api.illustrativedna.com/assets/icons/mobile/ibd.svg'"
          />
          <div class="text main-textml-3">POP-WISE IBD</div>
        </div>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import DeepAncestryIcon from '@/layouts/components/icons/DeepAncestryIcon.vue'

export default {
  components: {
    DeepAncestryIcon
  },
  data() {
    return {
    }
  },
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin === 'light' ? 'active' : 'normal';
    },
  },
  created() {

  },
}
</script>

<style scoped lang="scss">
.mobile-dashboard-service h3 {
  color: var(--lab-black);
  font-weight: 600;
}

.service-item {
  text-align: center;
  padding: 10px;
}

.service-item img {
  width: 50px !important;
  height: 50px !important;
}

.service-item .text {
  font-size: 1rem;
  font-weight: bold;
  color: var(--lab-black);
}

.dark-layout {
  .mobile-dashboard-service h3 {
    color: var(--lab-main-color);
  }

  
}
.deep-ancestry-image {
  fill: #fff!important;
  height: 50px;
  width: 50px;
}
.trade-mark-text {
  left: 0em;
  top: -0.6em;
  font-size: 0.6em;
}

</style>
