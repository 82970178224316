<template>
  <svg :id="$store.state.appConfig.layout.skin" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39">
    <defs>
    </defs>
    <g id="Layer_3" data-name="Layer 3">
      <g>
        <path class="cls-1" d="M19.5,39C8.75,39,0,30.25,0,19.5S8.75,0,19.5,0s19.5,8.75,19.5,19.5-8.75,19.5-19.5,19.5Zm0-36C10.4,3,3,10.4,3,19.5s7.4,16.5,16.5,16.5,16.5-7.4,16.5-16.5S28.6,3,19.5,3Z"/>
        <g id="Arrow_Arrow_Up_SM" data-name="Arrow / Arrow_Up_SM">
          <g id="Vector">
            <path class="cls-1" d="M20.9,26.5c-.36,0-.72-.14-.99-.41-.55-.55-.55-1.43,0-1.98l3.21-3.21H12.5c-.77,0-1.4-.63-1.4-1.4s.63-1.4,1.4-1.4h10.62l-3.21-3.21c-.55-.55-.55-1.43,0-1.98,.55-.55,1.43-.55,1.98,0l5.6,5.6c.14,.14,.24,.29,.31,.46,.06,.15,.1,.32,.1,.49,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.02,0,.04,0,.06,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0h0c0,.18-.04,.35-.11,.5-.06,.14-.14,.28-.25,.4-.02,.02-.04,.04-.06,.06l-5.59,5.59c-.27,.27-.63,.41-.99,.41Z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 30px;
}

.cls-2 {
  fill-rule: evenodd;
}

#dark .cls-1 {
  fill: var(--lab-main-color);
}
#light .cls-1{
  fill: var(--lab-black-65);
}
</style>
