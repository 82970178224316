<template>
    <div class="da-container">
      <div class="unsupervised-analysis" v-if="$store.state.appConfig.windowWidth >= 1079">
        <div class="result-container cs-scroll-style">
          <UnsupervisedAnalysisTopHeader />
        
        </div>
        <div class="ua-container cs-scroll-style">
          <UnsupervisedPopView  />
        </div>
      </div>
  
      <div class="unsupervised-analysis-mobile" v-else>
        <div class="result-container mobile-header">
          <UnsupervisedAnalysisTopHeader />
        </div>
        <div class="result-container">
          <UnsupervisedPopView  />
        </div>
        
      </div>
    </div>
  </template>

<script>
import MixedModeDataWatcher from './services/MixedModeDataWatcher';
import UnsupervisedAnalysisTopHeader from './UnsupervisedAnalysisTopHeader.vue';
import UnsupervisedPopView from './details/UnsupervisedPopView.vue';

export default {
  components: {
    UnsupervisedAnalysisTopHeader,
    UnsupervisedPopView
  },
  data() {
    return {

    }
  },
  mounted() {
    MixedModeDataWatcher.init();
  },
  beforeDestroy() {
    MixedModeDataWatcher.cleanup();
  },
}

</script>

<style lang="scss" scoped>
.unsupervised-analysis {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .result-container {
    width: 30%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    height: 100%;
    box-sizing: border-box;
  }
  .ua-container {
    width: 70%;
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
}

@media screen and (max-width: 1079px) {
  .da-container {
    height: 100%!important;
  }
  .unsupervised-analysis-mobile {
    height: 100%!important;
  }
  .mixed-mode-container {
    height: 100%!important;
  }
}

.unsupervised-analysis-mobile {
  height: 100vh;
  .mobile-header {
    margin-top:60px;
  }
  .mobile-result-container {
    flex-direction: column;
    display: flex;
    height: 100%;
    box-sizing: border-box;

    .mobile-map {
      position: fixed;
      top: 125px;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .mobile-container {
      display: flex;
      flex-direction: column;
      position: fixed;
      left: 0;
      bottom: 0;
      top: 122px;
      width: 100%;
      height: calc(100% - 122px);
      transition: transform 0.3s ease;
      will-change: transform;

      .scrollable-wrapper {
        overflow-y: auto;
        background-color: var(--bg-body-color);
      }
    }
  }
}
</style>