<template>
  <div>
    <div class="menu-area" v-if="$store.state.diySourceData.selectedTab === 'TARGET'">
      <div class="menu-header">
        <span>1. Target</span>
        <button class="lab-btn w-10" :disabled="checkLenTargetSelected()" @click="nextTab()">Next</button>
      </div>
      <div class="menu-content">
        <p>
          Please select the target population or kit(s) from the list below that you want to model. You can choose multiple populations or kits. Once you've made your selections, click the “Next” button to proceed to source selection.
        </p>
      </div>
    </div>

    <div class="menu-area" v-if="$store.state.diySourceData.selectedTab === 'SOURCE'">
      <div class="menu-header">
        <span>2. Source</span>
        <button class="lab-btn w-10" :disabled="checkLenSourceSelected()" @click="calculate()">Calculate</button>
      </div>
      <div class="menu-content">
        <p>
          Select your source population/samples that you want to use as a source for your selected targets then hit the “Calculate” button.
        </p>
      </div>
    </div>

    <div class="menu-area" v-if="$store.state.diySourceData.selectedTab === 'RESULT'">
      <div class="menu-header">
        <span>3. Results</span>
        <button class="lab-btn w-10" @click="prevTab()"><feather-icon icon="ArrowLeftIcon" class="mr-05" size="20"/>Back</button>
      </div>
      <div class="menu-content"></div>
    </div>
  </div>
</template>

<script>
import diySourceDataService from '../services/diySourceDataService';
import diyCalculateService from '../services/diyCalculateService';

export default {
  components: {
    
  },
  data() {
    return {
      tabs: [
          { name: 'Target', Id: 'TARGET' },
          { name: 'Source', Id: 'SOURCE' },
          { name: 'Results', Id: 'RESULT' },
      ],
    }
  },
  computed: {
    targetSelectedList() {
      return this.$store.state.diySourceData.targetSelectedList;
    },
    sourceSelectedList() {
      return this.$store.state.diySourceData.sourceSelectedList;
    },
  },
  methods: {
    nextTab() {
      if (this.$store.state.diySourceData.selectedTab === 'TARGET') {
        this.$store.state.diySourceData.selectedTab = "SOURCE";
      } else if (this.$store.state.diySourceData.selectedTab === 'SOURCE') {
        this.$store.state.diySourceData.selectedTab = "RESULT";
      }
    },
    prevTab() {
      if (this.$store.state.diySourceData.selectedTab === 'SOURCE') {
        this.$store.state.diySourceData.selectedTab = "TARGET";
      } else if (this.$store.state.diySourceData.selectedTab === 'RESULT') {
        this.$store.state.diySourceData.selectedTab = "SOURCE";
      }
    },
    checkTab(currentTab) {
      if (this.$store.state.diySourceData.selectedTab === 'RESULT' && (currentTab === 'TARGET' || currentTab === 'SOURCE' || currentTab === 'RESULT')) {
        return true;
      }
      if (this.$store.state.diySourceData.selectedTab === 'SOURCE' && (currentTab === 'TARGET' || currentTab === 'SOURCE')) {
        return true;
      }
      if (this.$store.state.diySourceData.selectedTab === 'TARGET' && (currentTab === 'TARGET')) {
        return true;
      }
      return false;
    },
    checkLenTargetSelected() {
      if (this.targetSelectedList.length > 0) {
        return false;
      }
      return true;
    },
    checkLenSourceSelected() {
      if (this.sourceSelectedList.length > 1) {
        return false;
      }
      return true;
    },
    calculate() {
      diyCalculateService.runCalculate(this.$store.state.diySourceData.populationNumber);
      this.$store.state.diySourceData.selectedTab = 'RESULT';
    },
  },
  async mounted() {
    await diySourceDataService.loadSourceDatas();
  }
}

</script>

<style lang="scss" scoped>
.menu-area {
  background-color: var(--bg-card-color);
  margin-top: 0px;
  padding: 30px;
}
.menu-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  span {
    font-size: 1.1rem;
    color: var(--lab-diy-header-color);
  }
}
.menu-content {
  margin-top:30px;
  font-size: 0.85rem;
  font-weight: 400;
  p {
    line-height: 1.35rem!important;
  }
}
button[disabled] {
    background:#6e8081;
}
</style>