<template>
  <div class="deep-ancestry-layout">
    <template v-if="selectedResultStatus === 2 || selectedResultStatus === 3">
      <DeepAncestryMenu v-if="$store.state.appConfig.windowWidth >= 1079"/>
      <router-view class="" />
    </template>
    <DeepAncestryNoResultPage v-else :orderResultStatus="selectedResultStatus"/>
  </div>
</template>

<script>
import DeepAncestryMenu from './DeepAncestryMenu.vue';
import DeepAncestryNoResultPage from './DeepAncestryNoResultPage.vue';

export default {
  components: {
    DeepAncestryMenu,
    DeepAncestryNoResultPage,
  },
  computed: {
    selectedResultStatus() {
      const selectedResult = this.$store.state.daResult.selectedResult;
      if (selectedResult) {
        return selectedResult.orderResultStatus;
      }

      return 0;
    },
  },
}
</script>

<style lang="scss">
.deep-ancestry-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.dashboard-card-container {
  margin-top:60px;
}

.da-container {
  height: calc(100vh - 220px);
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
</style>
