
<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.33 25.33">
    <path d="M22.8,0H7.6c-1.4,0-2.53,1.14-2.53,2.53v15.2c0,1.4,1.14,2.53,2.53,2.53h15.2c1.4,0,2.53-1.14,2.53-2.53V2.53c0-1.4-1.14-2.53-2.53-2.53ZM7.6,17.73V2.53h15.2v15.2s-15.2,0-15.2,0Z"/>
    <path d="M2.53,7.6H0v15.2c0,1.4,1.14,2.53,2.53,2.53h15.2v-2.53H2.53V7.6Zm11.32,6.54Z"/>
  </svg>
</template>
  
<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 18px;
  path {
    // fill: var(--lab-main-color)!important;
    fill: var(--bg-card-font-color);
  }
}  
</style>
