/* eslint-disable */
export const CalculateModernPopColor = (value) => {
  if(value > 0 && value <= 1) {
    return '#99da44';
  }
  if(value > 1 && value <= 2) {
    return '#7abb25';
  }
  if(value > 2 && value <= 3) {
    return '#7abb25';
  }
  if(value > 3 && value <= 4) {
    return '#f8961e';
  }
  if(value > 4 && value <= 5) {
    return '#f3722c';
  }
  if(value > 5 && value <= 6) {
    return '#f94144';
  }
  if(value > 6 && value <= 7) {
    return '#168aad';
  }
  if(value > 7 && value <= 8) {
    return '#168aad';
  }
  if(value > 8 && value <= 9) {
    return '#168aad';
  }
  if(value > 9 && value <= 10) {
    return '#168aad';
  }
  return '#168aad';
}

export const CalculateModernMapPopColor = (value) => {
  if(value > 0 && value <= 0.5) {
    return '#FF0A54';
  }

  if(value > 0.5 && value <= 1) {
    return '#FF155C';
  }
  if(value > 1 && value <= 1.5) {
    return '#FF155C';
  }
  if(value > 1.5 && value <= 2.0) {
    return '#FF2B6B';
  }
  if(value > 2 && value <= 2.5) {
    return '#FF3673';
  }
  if(value > 2.5 && value <= 3) {
    return '#FF417A';
  }
  if(value > 3 && value <= 3.5) {
    return '#FF4C82';
  }
  if(value > 3.5 && value <= 4) {
    return '#FF578A';
  }
  if(value > 4 && value <= 4.5) {
    return '#FF6291';
  }
  if(value > 4.5 && value <= 5) {
    return '#FF6D99';
  }
  if(value > 5 && value <= 5.5) {
    return '#FF79A1';
  }
  if(value > 5.5 && value <= 6) {
    return '#FF84A9';
  }
  if(value > 6 && value <= 6.5) {
    return '#FF8FB0';
  }
  if(value > 6.5 && value <= 7) {
    return '#FF9AB8';
  }
  if(value > 7 && value <= 7.5) {
    return '#FFA5C0';
  }
  if(value > 7.7 && value <= 8) {
    return '#FFB0C7';
  }
  if(value > 8 && value <= 8.5) {
    return '#FFBBCF';
  }
  if(value > 8.5 && value <= 9) {
    return '#FFC6D7';
  }
  if(value > 9 && value <= 9.5) {
    return '#FFD1DE';
  }
  if(value > 9.5 && value <= 20) {
    return '#FFDCE6';
  }
  return '#FFDCE6';
}

// HEX'i RGB'ye çevirir
function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
}

// RGB'den HSL'ye çevirir
function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; 
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch(max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }
  
  return [h * 360, s * 100, l * 100];
}

// HSL'den HEX'e çevirir
function hslToHex(h, s, l) {
  h = h / 360;
  s = s / 100;
  l = l / 100;
  
  function hue2rgb(p, q, t) {
    if(t < 0) t += 1;
    if(t > 1) t -= 1;
    if(t < 1/6) return p + (q - p) * 6 * t;
    if(t < 1/2) return q;
    if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
    return p;
  }
  
  let r, g, b;
  
  if (s === 0) {
    r = g = b = l; 
  } else {
    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }
  
  const toHex = (x) => {
    const hx = Math.round(x * 255).toString(16).padStart(2, '0');
    return hx;
  };
  
  return "#" + toHex(r) + toHex(g) + toHex(b);
}

const baseColors = ['#167050', '#da1020', '#5620a6', '#df0078', '#56a019', '#9925da', '#706418', '#545454', '#2c58a1', '#bc5906', '#7f0162'];

// Base color list'ine dayanarak belirli sayıda distinct renk üretir.
export function generateDistinctColorsFromBase(count) {
  const result = [];
  const baseCount = baseColors.length;

  if (count <= baseCount) {
    // İstenen sayı base listeden az ise doğrudan ilk count adedi al
    return baseColors.slice(0, count);
  }

  // Daha fazla renk lazım ise önce tüm base renkleri ekle
  for (let i = 0; i < baseCount; i++) {
    result.push(baseColors[i]);
  }

  // Kalan renk sayısı
  const needed = count - baseCount;
  
  // Ek renkler için hue değerini adım adım kaydırıyoruz
  const hueStep = 360 / needed;

  for (let i = 0; i < needed; i++) {
    const baseIndex = i % baseCount;
    const baseHex = baseColors[baseIndex];
    const [r, g, b] = hexToRgb(baseHex);
    let [h, s, l] = rgbToHsl(r, g, b);
    
    // Hue değeri her ek renk için hueStep kadar ilerletilir
    h = (h + (hueStep * i)) % 360;
    
    // Burada dilerseniz s ve l değerlerini de az da olsa değiştirebilirsiniz.
    // Örneğin: s = Math.min(100, s + (i * 2)); veya farklı bir lojik.
    
    const newColor = hslToHex(h, s, l);
    result.push(newColor);
  }

  return result;
}
