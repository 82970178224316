<template>
  <div class="mixed-mode-container">
    <b-alert variant="info" class="text-center" show v-if="selectedResult.orderResultStatus === 3">
      <div class="alert-body lab-unsupervised-badge">
        <span><strong>These results are under process.</strong> Please check back in few hours.</span>
      </div>
    </b-alert>

    <template v-if="uiResults && !$store.getters.getLoader">
      <div class="mixedmode-container-header">
        <div class="update">
          <UnsupverisedUpdate v-if="selectedResult.orderResultStatus !== 3"/>
        </div>
        <div class="cursor-pointer" @click="geneticFitInfoModal.showModal = true" v-if="uiResults.length">      
          <span>{{'Genetic Fit'}}</span>
          <span class="open-info-modal-btn ml-01">
            <sup class="subtitle-info-button-area">
              <InfoIcon />
            </sup>
          </span>
        </div>
      </div>
      <div class="mixed-mode-card">
        <div v-for="itemAncestry in uiResults"
            :key="itemAncestry.id" 
            class="mixed-mode-card-item">
          <div class="mixedmode-header">
            <div class="text-number unsupervised-text">
              Model #{{itemAncestry.id + 1}}
            </div>
            <div class="text-fit">
              {{'Genetic Fit'}}: 
              <span :style="{ color: CalculateModernPopColor(itemAncestry.fit) }">{{ itemAncestry.fit }}</span>
            </div>
          </div>
          <div class="mixedmode-body">
            <div class="ancient-mixedmode-item" v-for="itemPop in itemAncestry.pops" :key="itemPop.name" @click="onOpenDetailPage(itemPop)">
              <div class="ancient-left-side">
                <b-media-aside class="mr-auto ancient-img" v-if="$store.state.mixedModePeriod.selectedReport === 'Ancient'">
                  <b-avatar
                    rounded
                    size="70"
                    :src="itemPop.logoUrl"
                  />
                </b-media-aside>
                <div class="text-avatar" :style="{color: itemPop.color, }" v-else>
                  {{itemPop.name[0]}}
                </div>

                <div class="ancient-text-progress ml-2">
                  <div class="text">
                    {{ itemPop.name.replaceAll('*', ' ') }}
                  </div>
                  <div class="progress" :style="{ backgroundColor: ddAlpha(itemPop.color, 0.1) , width: '100%' }">
                    <div class="progress-fill" :style="{ backgroundColor: itemPop.color, width: itemPop.value + '%' }"></div>
                  </div>
                </div>
              </div>

              <div class="ancient-pop" :style="{'color': skin === 'light' ? '#595959' : itemPop.color }">
                {{ itemPop.value }}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <SpinnerContainer v-else />

    <UAGeneticFitInfoModal v-if="geneticFitInfoModal.showModal" :modalObject="geneticFitInfoModal"/>
    <GDAncientProximityModal v-if="popDetailModal.showModal" :modalObject="popDetailModal" />
  </div>
</template>

<script>
import { CalculateModernPopColor } from '@/common/custom-service/dna-source/utils';
import InfoIcon from '@/layouts/icons/InfoIcon.vue';
import UAGeneticFitInfoModal from '../modals/UAGeneticFitInfoModal.vue';
import GDAncientProximityModal from '../../genetic-similarities/Modals/GDAncientProximityModal.vue';
import UnsupverisedUpdate from './UnsupverisedUpdate.vue';

export default {
  components: {
    InfoIcon,
    UAGeneticFitInfoModal,
    GDAncientProximityModal,
    UnsupverisedUpdate,
  },
  directives: {
  },
  data() {
    return {
      geneticFitInfoModal: {
        showModal: false,
      },        
      popDetailModal: {
        showModal: false,
        item: null,
      },
    }
  },
  methods: {
    CalculateModernPopColor,
    ddAlpha(color, opacity) {
      const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },

    onOpenDetailPage(item) {
      if (item.isAncient) {
        this.popDetailModal.item = item;
        this.popDetailModal.showModal = true;
      }
    },
  },
  computed: {
    uiResults() {
      return this.$store.getters['mixedModeResult/getUiResults'];
    },
    selectedResult() {
      return this.$store.state.daResult.selectedResult;
    },

    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.cs-scroll-style::-webkit-scrollbar {
  display: none;
}

.text-avatar {
  height: 26px;
  width: 12px;
}
</style>

