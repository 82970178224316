import ApiService from '@/common/api.service';

/* eslint-disable */
const getDefaultState = () => {
  return {
    selectedSampleType: 'ANCIENT-INDIVIDUAL',
    filterQuery: {
      Search: '',
      location: '',
      PageIndex: 1,
      PageSize: 20,
      Count: 200,
    },

    locationList: [],
    savedLocationList: {},

    calculatedDistance: [],
    calculatedFarmerHgResult: null,
  }
}

const state = getDefaultState()

const getters = {
  getFilterQuery(state) {
    return state.filterQuery;
  }
}

const mutations = {
  setFilterQuery(state, data) {
    state.filterQuery.PageSize = data.pageSize;
    state.filterQuery.Count = data.count;
  },
  setDefaultQuery(state) {
    state.filterQuery = {
      Search: '',
      location: '',
      PageIndex: 1,
      PageSize: 20,
      Count: 200,
    };
  },
}

const actions = {
  async fetchLocationData(context) {
    try {
      if (context.state.selectedSampleType === 'MODERN') {
        context.state.locationList = [];
        return;
      }
      const saveDetail = context.state.savedLocationList[context.state.selectedSampleType];
      
      if (saveDetail) {
        context.state.locationList = saveDetail;
      } else {
        const url = context.state.selectedSampleType === 'ANCIENT' ? 'data/AncientSampleDatabase/locations' : 'data/InvidualAncientSampleDatabase/locations';
        const response = await ApiService.get(url, {}, this);
        if (response.statusCode === 200) {
          response.result.unshift('')
          context.state.savedLocationList[context.state.selectedSampleType] = response.result;
          context.state.locationList = response.result;
        } else {
          throw error;
        }
      }
    } catch (error) {
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
