<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 21.58">
    <defs>
    </defs>
    <path class="cls-1" d="M0,10.79V1.19C0,.27,.99-.3,1.78,.16L10.09,4.96l8.31,4.8c.79,.46,.79,1.6,0,2.05l-8.31,4.8L1.78,21.42c-.79,.46-1.78-.11-1.78-1.03V10.79Z"/>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
 .cls-1 {
  fill:#fff;
 }

//  .dark-layout {
//   .cls-1 {
//     fill:#1bbdcc;
//   }
//  }
</style>
