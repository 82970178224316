<template>
  <div class="sample-database-sidebar">
    <h4 class="report-title">
      Sample Database
    </h4>
    <hr />
    <p class="description mt-2">
      Meta and genetic informations of 
      ancient and modern populations
    </p>
    <SampleTypeDropdown />
    <div class="search mb-2 mt-2">
      <label for="custom-dropdown" class="dropdown-label mb-1">Search  </label>
      <b-form-input class="d-inline-block mr-1" size="sm" placeholder="ID/Name/Haplogroup" 
        v-model="$store.state.sampleDatabase.filterQuery.Search" @input="changeSearch"/>
    </div>
    <LocationsDropdown class="mt-2"/>
    <!-- <div class="d-flex align-items-center mt-2 w-100 justify-content-between mt-3" v-if="selectedSampleType === 'ANCIENT-INDIVIDUAL'">
      <span class="lab-switch-desc ml-0" v-if="getOpenMap">Switch to list </span> 
      <span class="lab-switch-desc ml-0" v-else>Switch to map </span> 
      <label class="lab-switch ml-1">
        <input type="checkbox" v-model="$store.state.ancientIndividualDatabase.openMap">
        <span class="slider round"></span>
      </label>
    </div> -->
  </div>
</template>

<script>
import SampleTypeDropdown from './SampleTypeDropdown.vue';
import LocationsDropdown from './LocationsDropdown.vue';

export default {
  components: {
    SampleTypeDropdown,
    LocationsDropdown,
  },
  data() {
    return {
    };
  },
  methods: {
    changeSearch() {
      this.$store.state.sampleDatabase.filterQuery.PageIndex = 1;
    },
  },
  computed: {
    selectedSampleType() {
      return this.$store.state.sampleDatabase.selectedSampleType;
    },
    getOpenMap() {
      return this.$store.state.ancientIndividualDatabase.openMap;
    }
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
.sample-database-sidebar {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 28px;
  background: var(--bg-card-color);
}

@media screen and (max-width: 1079px) {
  .sample-database-sidebar {
    height: auto!important;
  }
}

@media screen and (min-width: 1079px) {
  .sample-database-sidebar {
    height: 100vh!important;
  }
}
</style>