<template>
    <div class="result-list-container">
      <template v-if="uiResults && !$store.getters.getLoader">
        <div>
          <div class="pop-view">
            <div v-for="(itemAncestry, index) in uiResults"
                :key="itemAncestry.title" 
                class="pop-view-item" @click="onOpenDetailPage(itemAncestry)">
              <div class="d-flex">
                <span class="d-flex align-items-center p-1 ps-0 population-index-txt">{{ index + 1 }}</span>
                <b-media-aside class="mr-75">
                  <b-avatar
                    rounded
                    size="55"
                    :src="itemAncestry.logoUrl"
                  />
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="mb-0">
                    {{ itemAncestry.title.replaceAll('*', ' ') }}
                  </h6>
                </b-media-body>
              </div>
              <div class="d-flex align-items-center lab-p-8">
                <span class="distance-value-txt" :style="{ color: itemAncestry.color }">{{ itemAncestry.distance }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <SpinnerContainer v-else />
      <GDAncientProximityModal v-if="popDetailModal.showModal" :modalObject="popDetailModal" />
    </div>
  </template>
  
  <script>
  import GDAncientProximityModal from '../Modals/GDAncientProximityModal.vue';

  export default {
    components: {
      GDAncientProximityModal,
    },
    props: {
    },
    data() {
      return {
        popDetailModal: {
          showModal: false,
          item: null,
        },
      }
    },
    methods: {
      onOpenDetailPage(item) {
        this.popDetailModal.item = item;
        this.popDetailModal.showModal = true;
      },
    },
    computed: {
      uiResults() {
        return this.$store.getters['ancientProxResult/getUiResults'];
      },
    },
    mounted() {
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .result-list-container {
    padding: 28px;
    padding-top:0px;
  
    @media screen and (max-width: 1079px) {
      overflow-y: hidden;
      overflow-x: hidden !important;
    }

    .distance-value-txt {
      font-size:0.9em!important;
      color:#8FC46C;
    }
    .population-index-txt {
      font-weight: 600;
    }
  }
  </style>
  