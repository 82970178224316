<template>
  <div class="sample-type-dropdown">
    <div class="lab-dropdown sm region">
      <label for="custom-dropdown" class="dropdown-label mb-1">Select Mode  </label>
      <div class="lab-dropdown-btn" @click="isDropdownOpen = !isDropdownOpen" ref="dropdownReport">
        <span class="lab-dropdown-btn-value">
          {{ selectedSampleTypeName }}
        </span>
        <span class="lab-dropdown-btn-icon">
          <!-- <dropdown-icon /> -->
          <DropdownOneIcon />
        </span>
      </div>
      <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isDropdownOpen}">
        <ul>
          <li v-for="item in sampleTypeList"
              :key="item.id"
              @click="selectSampleType(item)">
              {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isDropdownOpen: false,
      selectedSampleType: 'ANCIENT',

      sampleTypeList: [
        {
          id: 'ANCIENT',
          title: 'Ancient (Population)',
        },
        {
          id: 'MODERN',
          title: 'Modern (Population)',
        },
        {
          id: 'ANCIENT-INDIVIDUAL',
          title: 'Ancient (Individual)',
        },
      ],
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownReport;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isDropdownOpen = false;
        }
      } catch (error) {}
    },
    applySelectedSampleType() {
      this.$store.state.ancientIndividualDatabase.openMap = false;
      this.$store.state.sampleDatabase.selectedSampleType = this.selectedSampleType;
      this.$store.commit('sampleDatabase/setDefaultQuery');
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectSampleType(item) {
      this.selectedSampleType = item.id;
      this.isDropdownOpen = false;
      this.applySelectedSampleType();
    },
  },
  computed: {
    selectedSampleTypeName() {
      return this.sampleTypeList.find(x => x.id === this.selectedSampleType).title;
    },
  },
  mounted() {
    this.selectedSampleType = this.$store.state.sampleDatabase.selectedSampleType;
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>

<style lang="scss" scoped>
.sample-type-dropdown {
  .dropdown-container {
    padding: 0;
    width: 100%;

    .select-custom-dropdown {
      width: 100%;
    }
  }
}

.lab-dropdown .lab-dropdown-btn {
    padding: 10px 15px !important;
}

</style>
