import ApiService from '@/common/api.service';

/* eslint-disable */
const getDefaultState = () => {
  return {
    periods: [],
    selectedPeriod: null,

    modernPeriods: [],
    selectedModernPeriod: null,

    selectedReport: 'Ancient',
    selectedMode: 'Two Way',
  }
}

const state = getDefaultState()

const getters = {
  getPeriods(state) {
    return state.periods;
  },
}

const mutations = {
  setPeriods(state, data) {
    state.periods = data.result.filter(x => x.mixedModeType === 0);
    state.modernPeriods = data.result.filter(x => x.mixedModeType === 1);
  },

  setSelectedPeriod(state, period) {
    state.selectedPeriod = period;
  },
  setInitialSelectedPeriod(state, dataVersion) {
    try {
      const periods = state.periods.filter(x => x.dataVersion == dataVersion);
      if (periods.length) {
        state.selectedPeriod = periods[0];
      }
    } catch {
    }

    try {
      const periods = state.modernPeriods.filter(x => x.dataVersion == dataVersion);
      if (periods.length) {
        state.selectedModernPeriod = periods[0];
      }
    } catch {
    }
  },
}

const actions = {
  async fetchPeriods(context) {
    try {
      if (context.state.periods.length > 0) {
        return;
      }
      const response = await ApiService.get('data/MixedModePeriod', {}, this);
      if (response.statusCode === 200) {
        context.commit('setPeriods', response)
      } else {
        console.error('Error fetching data:', response.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
