<template>
  <div>
    <div class="d-flex periods-header">
      <div class="w-50">
        <h5 class="report-subtitle">Switch Between Periods</h5>
      </div>
      <div class="w-50">
        <div class="bg-group text-end d-flex">
          <a class="previous-button d-flex align-items-center justify-content-center" @click="onPrevPeriod()"><AnchorIcon/> </a>
          <a class="next-button d-flex align-items-center justify-content-center" @click="onNextPeriod()"><AnchorIcon/></a>
        </div>
      </div>
    </div>
    <div class="lab-timeline">
      <div class="line-item"
          v-for="periodItem in periods" :key="periodItem.id"
                :class="{'done': periodItem.number <= selectedPeriod.number,
                        'active': periodItem.id == selectedPeriod.id}">
          <div class="node">
            <span class="timeline-title sub-title">{{periodItem.name}}</span>
            <div class="tvar" @click="onChangePeriod(periodItem)"></div>
          </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import AnchorIcon from '@/layouts/components/icons/AnchorIcon.vue';

export default {
  components: {
    AnchorIcon
  },
  props: {
  },
  methods: {
    onChangePeriod(periodItem) {
      this.$store.commit('mixedModePeriod/setSelectedPeriod', periodItem);
    },
    onNextPeriod() {
      const periodList = this.periods;
      const index = periodList.indexOf(this.selectedPeriod);
      const nextIndex = index + 1;
      if (periodList[nextIndex]) {
        this.$store.commit('mixedModePeriod/setSelectedPeriod', this.periods[nextIndex]);
      }
    },
    onPrevPeriod() {
      const periodList = this.periods;
      const index = periodList.indexOf(this.selectedPeriod);
      const prevIndex = index - 1;
      if (periodList[prevIndex]) {
        this.$store.commit('mixedModePeriod/setSelectedPeriod', this.periods[prevIndex]);
      }
    }
  },
  computed: {
    selectedPeriod() {
      return this.$store.state.mixedModePeriod.selectedPeriod;
    },
    periods() {
      return this.$store.getters['mixedModePeriod/getPeriods'].filter(x => x.dataVersion === this.$store.getters['daResult/dataVersion']);
    },
  },
  async mounted() {
    await this.$store.dispatch('mixedModePeriod/fetchPeriods');
    this.$store.commit('mixedModePeriod/setInitialSelectedPeriod', this.$store.getters['daResult/dataVersion']);
  },
}
</script>
  
<style lang="scss" scoped>
  .periods-header {
    align-items: center;
  }
  btn {
    font-size: 0.9em!important;
  }
  .text-end {
    float:right;
  }
  .previous-button, .next-button {
    border-radius: 5px;
    padding:5px 10px;
  }
  .previous-button {
    font-size: 0.9em;
    background: #1bbdc1;
    margin-right: 5px;
  }
  .next-button {
    font-size: 0.9em;
    background: #1bbdc1;
  }
  .next-button svg, .previous-button svg {
    width: 0.7em;
  } 
  .next-button svg {
    transform: rotate(180deg);
  }
</style>
  