import AlertService from '@/common/alert.service'
import {
  resetSeed,
  reducedRegression,
  euclideanDistance,
} from './CalculateFMCUtils';
import { CalculateModernPopColor } from './utils'

/* eslint-disable */
const CalculateFMCService = { 
  dataArrays: {
    sourceCodeArray: [],
    targetCodeArray: [],
    sourceCodeNumber: null,
    targetCodeNumber: null,
  },
  dataObject: {
    numberOfPopulation: 0,
  },

  onSetSource(sourceArray) {
    this.dataArrays.sourceCodeArray = sourceArray;
    this.dataArrays.sourceCodeNumber = sourceArray.length;
  },

  onSetTarget(targetArray) {
    this.dataArrays.targetCodeArray = targetArray;
    this.dataArrays.targetCodeNumber = targetArray.length;
  },

  codeDataToArray(codeData) {
    if (codeData === null || codeData === '') {
      AlertService.error('The selected kit coordinate is not valid. Please contact support.')
      return;
    }
    const response = {
      isSuccess: true,
      message: '',
      result: [],
    };

    const lines = codeData.trim().split("\n");

    const parsedData = lines.map(line => line.split(","));
  
    parsedData.forEach(row => {
      for (let i = 1; i < row.length; i++) {
        row[i] = parseFloat(row[i]);
      }
    });
    
    response.result = parsedData;
    return response;
  },

  calculateDistance() {
    // Hedefin ilk satırını referans alıyoruz
    const targetRow = this.dataArrays.targetCodeArray[0]; // Tek satırlık CSV de olabilir
    const distanceResults = [];

    for (let i = 0; i < this.dataArrays.sourceCodeArray.length; i++) {
      const sourceRow = this.dataArrays.sourceCodeArray[i];
      const dist = euclideanDistance(sourceRow, targetRow);

      distanceResults.push({
        title: sourceRow[0],
        distance: dist
      });
    }

    // son işlem
    const results = distanceResults
      .sort((a, b) => a.distance - b.distance)
      .slice(0, this.dataObject.maxDistOutPut)
      .map(item => ({
        color: CalculateModernPopColor((item.distance * 100).toFixed(3)),
        distance: (item.distance * 100).toFixed(3),
        title: item.title
      }));
    
    return results;
  },

  runABAlgorithm() {
    // Her hesaplamada aynı seed ile başlayalım.
    resetSeed();
    
    const sourceNames = this.dataArrays.sourceCodeArray.map(item => item[0]);
    const sources = this.dataArrays.sourceCodeArray.map(item => item.slice(1));
    const target = this.dataArrays.targetCodeArray.map(item => item.slice(1));
    
    let npop = this.dataObject.numberOfPopulation;
    let cycleMultiplier = (sources.length > 1000) ? 2 : 1;

    let [bestFit, bestModel] = reducedRegression(sources, sourceNames, target[0], npop, cycleMultiplier);

    // "outPuts" dizisinin her elemanında 
    //   currentResult -> (yüzde)
    //   resultsTable  -> sourceName
    let outPuts = [];
    for(let [idx, perc] of bestModel){
      outPuts.push({
        currentResult: perc * 100,
        resultsTable: sourceNames[idx]
      });
    }
    
    // final dönüş objesi:
    return {
      result: (bestFit * 100).toFixed(3),
      outPuts: outPuts
    };
  },
}

export default CalculateFMCService;
