<template>
  <div class="w-100">
    <div class="diy-view-container">
      <div id="menu-area" class="menu-content mb-3 mt-3" v-if="$store.state.appConfig.windowWidth < 1079">
        <ul class="menu-items">
          <li :key="tab"  v-for="tab in tabs" class="menu-item-li">
            <a class="menu-link dp-menu-item" 
              :class="{ 'active':  tab === activeTab }" @click="activeTab = tab">
              {{ tab }}
            </a>
          </li>
        </ul>
        <a class="filter-button"><FilterIcon class="filter-icon"></FilterIcon></a>
      </div>
      <div class="diy-view-column w-50" v-if="controlForMobile('Modern')">
        <h4 class="diy-gd-header" v-if="$store.state.appConfig.windowWidth >= 1079">Modern</h4>
        <div class="genetic-distance-container">
          <div class="genetic-distance-table arrow-scroll-info" v-for="item in modernResults" :key="item.name" :id="`${item.name}-modern-scroll`">
            <div class="header">
              <span>Target: {{ replaceName(item.name) }}</span>
            </div>
            <table class="table genetic-distance-body-table cs-scroll-style" :id="`${item.name}-modern`" @wheel="onScroll(`${item.name}-modern`)"  @scroll="onScroll(`${item.name}-modern`)">
              <tbody>
                <tr class="d-flex" v-for="(itemDistance, index) in item.calculatedResults" :key="itemDistance.title">
                  <td class="col-10">{{index + 1}}. {{ replaceName(itemDistance.title)}}</td>
                  <td class="distance col-2 text-right"  :style="{ color: itemDistance.color }">{{ itemDistance.distance }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="diy-view-column border-diy"></div>

      <div class="diy-view-column w-50" v-if="controlForMobile('Ancient')">
        <h4 class="diy-gd-header" v-if="$store.state.appConfig.windowWidth >= 1079">Ancient</h4>
        <div class="genetic-distance-container cs-scroll-style">
          <div class="genetic-distance-table arrow-scroll-info" v-for="item in ancientResults" :key="item.name" :id="`${item.name}-ancient-scroll`">
            <div class="header">
              <span>Target: {{ replaceName(item.name) }}</span>
            </div>
            <table class="table genetic-distance-body-table cs-scroll-style" :id="`${item.name}-ancient`" @wheel="onScroll(`${item.name}-ancient`)"  @scroll="onScroll(`${item.name}-ancient`)">
              <tbody>
                <tr class="d-flex" v-for="(itemDistance, index) in item.calculatedResults" :key="itemDistance.title">
                  <td class="col-10">{{index + 1}}. {{ replaceName(itemDistance.title)}}</td>
                  <td class="distance col-2 text-right" :style="{ color: itemDistance.color }">{{ itemDistance.distance }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  components: {
    // DropdownOneIcon,
  },
  data() {
    return {
      tabs: ['Modern', 'Ancient'],
      activeTab: 'Modern'
    }
  },
  methods: {
    replaceName(name) {
      const pop = name.replaceAll('*', ' ').replaceAll('_scaled', '');
      const popSplit = pop.split(':');
      return popSplit.slice(-2).join(": ");
    },
    controlForMobile(tab) {
      if (this.$store.state.appConfig.windowWidth < 1079) {
        if (tab === this.activeTab) {
          return true;
        }
        return false;
      }
      return true;
    },
    onScroll(name) {
      const element = document.getElementById(`${name}`);
      const parentElement = document.getElementById(`${name}-scroll`);
      if (element) {
        this.$store.state.utils.checkIfScrolledToBottom(element, parentElement);
      }
    },
  },
  computed: {
    modernResults() {
      return this.$store.state.diyGDSourceData.calculatedResults.filter(x => x.type === 'MODERN_POP');
    },
    ancientResults() {
      return this.$store.state.diyGDSourceData.calculatedResults.filter(x => x.type === 'ANCIENT_POP');
    },
  },
  mounted() {
    // this.checkScroll();
  },
}
</script>

<style lang="scss" scoped>
.diy-view-container {
  gap: 0px;
  height: auto;
}

.genetic-distance-container {
  .genetic-distance-table {
    width: 100%;
    margin: 0 auto;
    word-break: break-all;
    padding: 25px;
    font-size: 0.8rem;

    @media screen and (max-width: 1079px) {
      padding:0px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      padding: 10px 0px;
      font-weight: bold;
      color: var(--lab-diy-header-font-color);
    }

    .genetic-distance-body {
      overflow-y: auto;
      overflow-x: hidden;
      user-select: none;
      max-height: 200px;
    }

    .genetic-distance-body-table {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      user-select: none;
      max-height: 400px;
      padding-right: 15px;

      td {
        text-overflow: ellipsis;
        padding: 15px 0px;
        justify-content: center;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: none;
      }
    }
    .distance {
      font-weight: bold;
    }
  }
}

.menu-items {
  position: relative;
  list-style: none;
  display: flex;
  justify-content: start;
  gap: 20px;
  margin: 0; 
  padding: 0;
  border-bottom: 2px solid var(--lab-hr-color);

  .menu-item-li {
    width: 49%;
    flex: 0 0 auto;
    padding: 10px 0px;
    // margin: 0 5px;
    color: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
  }

  .menu-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 8px;
    margin-right: 10px;
    text-decoration: none;
    color: var(--lab-header-menu-color);
    font-size: 1rem;
    font-weight: 600;

    &:hover,
    &.active {
      color: var(--lab-main-color);
    }
    &:hover::after,
    &.active::after {
      width: 110%;
      left: -5%; 
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 0;
      height: 4px;
      border-bottom: 2pt solid var(--lab-main-color);
      height: 14px;
      background-color: transparent; 
      transition: width 0.3s ease, opacity 0.3s ease;
      opacity: 0; 
    }
  }
}

 .diy-gd-header {
  font-size:1rem;
  text-align: center;
  color: var(--lab-diy-header-font-color);
 }
  .p-15 {
    padding-right:15px;
  }
</style>
