<template>
  <div class="update-container">
    <InfoV2 v-if="selectedPage === 'INFO'"/>
    <UpgradeToV2 v-if="selectedPage === 'UPGRADE'"/>
  </div>
</template>

<script>
import InfoV2 from './pages/InfoV2.vue';
import UpgradeToV2 from './pages/UpgradeToV2.vue';

export default {
  components: {
    InfoV2,
    UpgradeToV2,
  },
  data() {
    return {
    }
  },
  computed: {
    selectedPage() {
      return this.$store.state.updateV2.selectedPage;
    },
  },
  mounted() {
    this.$store.state.updateV2.selectedPage = 'INFO';
  },
}
</script>

<style scoped lang="scss">
.update-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1079px) {
  .update-container {
    margin-top:60px;
  }
}

</style>
