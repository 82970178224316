import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService'
import store from '@/store';

const diyCalculateService = {
  /* eslint-disable */
  async runCalculate(popNumber) {
    try {
      CalculateFMCService.dataObject.numberOfPopulation = popNumber;
      const diySourceData = store.state.diySourceData;
      diySourceData.calculatedResults = [];
      this.setSourceCodeArray(diySourceData);
  
      diySourceData.targetSelectedList.forEach(targetItem => {
        this.setTarget(targetItem.coordinate);
        const calculatedResult = CalculateFMCService.runABAlgorithm();
        const grouped = this.groupResultList(calculatedResult);
        grouped.name = targetItem.name;
        diySourceData.calculatedResults.push(grouped);
      });
    }
    catch {

    }
    CalculateFMCService.dataObject.numberOfPopulation = 0;
  },

  setTarget(coordinate) {
    const tagetResolveSource = CalculateFMCService.codeDataToArray(coordinate);
    if (tagetResolveSource.isSuccess) {
      CalculateFMCService.onSetTarget(tagetResolveSource.result);
    }
  },
  setSourceCodeArray(diySourceData) {
    const filteredSourceCodeArray =[...this.filterArray(diySourceData.souceDataList['MODERN_POP'], diySourceData.sourceSelectedList, 'MODERN_POP'), 
                                    ...this.filterArray(diySourceData.souceDataList['ANCIENT_POP'], diySourceData.sourceSelectedList, 'ANCIENT_POP')];

    CalculateFMCService.onSetSource(filteredSourceCodeArray);
  },

  filterArray(array, selectedList, type) {
    return array.filter(item => {
      if (selectedList.find(x => x.name === item[0] && x.type === type)) {
        return true;
      }
      return false;
    });
  },

  groupResultList(result) {
    const tempResults = JSON.parse(JSON.stringify(result));
    tempResults.outPuts = [];
    result.outPuts.forEach(value => {
      const splitValue = value.resultsTable.split(':');
      if (splitValue.length > 0) {
        let firstGroup = tempResults.outPuts.find(x => x.resultsTable === splitValue[0]);
        if (firstGroup) {
          firstGroup.currentResult += value.currentResult;
        } else {
          firstGroup = { resultsTable: splitValue[0], currentResult: value.currentResult, subResult: [] };
          tempResults.outPuts.push(firstGroup);
        }

        let secondGroup = firstGroup.subResult.find(x => x.resultsTable === splitValue[1]);
        if (secondGroup) {
          secondGroup.currentResult += value.currentResult;
        } else {
          secondGroup = { resultsTable: splitValue[1], currentResult: value.currentResult, subResult: [] };
          firstGroup.subResult.push(secondGroup);
        }

        let thirdGroup = secondGroup.subResult.find(x => x.resultsTable === splitValue[2]);
        if (thirdGroup) {
          thirdGroup.currentResult += value.currentResult;
        } else {
          thirdGroup = { resultsTable: splitValue[2], currentResult: value.currentResult, subResult: [] };
          secondGroup.subResult.push(thirdGroup);
        }
      }
    });
    tempResults.outPuts.sort((a, b) => b.currentResult - a.currentResult);

    return tempResults;
  }
};

export default diyCalculateService;
