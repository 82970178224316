<template>
  <div class="">
    <div class="sample-database-detail dashboard-card">
      <div class="sample-database-detail-header">
        <BackButton :clickMethod="back" class="mb-3"/>
      </div>
      <div class="sample-database-detail-body cs-scroll-style">
        <b-row>
          <b-col cols="12" md="3" lg="3">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Population' }}</h5>
              </div>
              <div class="desc">
                {{popData.title}}
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="5" lg="5">
            <div class="title-header">
              <h5>{{ 'Genetic Profile' }}</h5>
            </div>
            <div class="p-1">
              <h5 v-if="!farmerHgResultList">Unavailable</h5>
              <template v-else>
                <div v-for="itemAncestry in farmerHgResultList.outPuts" :key="itemAncestry.resultsTable" class="farmer-hgs mb-1">
                  <span class="label">{{ itemAncestry.resultsTable.replaceAll('*', ' ') }} :</span>
                  <span class="percent"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
                </div>
              </template>
            </div>
          </b-col>
          <b-col cols="12" md="4" lg="4">
            <div class="title-header">
              <h5>{{ 'Genetically Closest Populations' }}</h5>
            </div>
            <div class="distance-container">
              <table class="table table-bordered distance-table">
                <thead>
                    <tr>
                      <th scope="col">Distance</th>
                      <th scope="col">Modern population</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="distanceItem in distanceList" :key="distanceItem.title">
                      <td><span class="td-size" :style="{ color: distanceItem.color }"> {{ distanceItem.distance }}</span></td>
                      <td>{{distanceItem.title.replaceAll('*', ' ')}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';

export default {
  components: {
    BackButton,
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    back() {
      this.$store.state.modernDatabase.sampleDetail = null
    },
  },
  computed: {
    popData() {
      return this.$store.state.modernDatabase.sampleDetail;
    },
    distanceList() {
      return this.$store.state.sampleDatabase.calculatedDistance;
    },
    farmerHgResultList() {
      return this.$store.state.sampleDatabase.calculatedFarmerHgResult;
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>

</style>
