<template>
    <div class="g25-main-icon">
      <svg id="light" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.56 40" v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.1,.1,.19,.22,.28,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.12-.1,.22-.18,.22-.28,0,0,0,0,0-.01,0-.19,.15-.35,.35-.35,.19,0,.35,.15,.36,.34,0,0,0,0,0,.01,0,.19,0,.38,0,.57,0,10.64-8.64,19.28-19.28,19.28S0,31.36,0,20.72c0-.19,0-.38,0-.57h0Zm37.85,.99c-.38,.24-.95,.48-1.67,.69-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.72-.22-1.28-.45-1.67-.69,.22,10.06,8.46,18.16,18.58,18.16s18.35-8.1,18.58-18.16Z"/>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.11,.11,.19,.24,.3,.23,.17,.56,.34,.99,.5,2.17,.81,6.61,1.42,12.08,1.67,.12,0,.23,.07,.29,.18s.06,.23,0,.34c-.33,.61-.75,1.42-.86,2.06-.02,.14-.04,.27-.02,.38,.01,.09,.04,.16,.11,.2,.21,.1,.59,.14,1.02,.17,.76,.07,1.67,.11,2.31,.32,.45,.14,.78,.38,.93,.69,.15,.31,.14,.74-.21,1.31-.72,1.16-.71,2.12-.28,3.29,.31,.84,.81,1.77,1.41,2.94,.2,.39,.23,.72,.16,1-.1,.42-.43,.74-.86,.93-.38,.17-.83,.24-1.21,.21-.29-.02-.54-.1-.71-.21-.2-.13-.5-.48-.84-.91-.45-.57-.98-1.27-1.43-1.65-.08-.07-.16-.12-.23-.16-.02-.01-.05-.02-.07-.03,0,0-.02,0-.03,0-.19,.23-.37,.51-.58,.75-.26,.3-.57,.53-.96,.6-.48,.08-1.12-.08-2.02-.82-.48-.4-.92-.58-1.34-.56-.66,.04-1.24,.54-1.84,1.27-.06,.07-.15,.12-.25,.13s-.19-.03-.26-.09C2.41,31.41,0,26.34,0,20.72c0-.19,0-.38,0-.57h0Zm.7,.99c.11,5.12,2.31,9.74,5.76,13.03,.67-.75,1.36-1.2,2.1-1.24,.57-.03,1.17,.18,1.83,.72,.66,.54,1.1,.73,1.45,.67,.23-.04,.4-.19,.55-.37,.2-.23,.38-.51,.57-.74,.3-.37,.78-.33,1.31,.11,.48,.41,1.05,1.15,1.53,1.75,.28,.35,.52,.65,.68,.77,.09,.06,.22,.08,.37,.09,.28,.02,.6-.03,.88-.15,.22-.1,.42-.24,.47-.46,.03-.15,0-.31-.1-.51-.62-1.19-1.13-2.16-1.45-3.02-.5-1.38-.51-2.53,.35-3.9,.17-.28,.25-.48,.17-.63-.08-.16-.27-.24-.51-.32-.6-.19-1.45-.22-2.16-.29-.54-.05-1.01-.12-1.27-.24-.35-.17-.52-.51-.51-.96,0-.59,.35-1.44,.71-2.16-5.31-.27-9.6-.89-11.74-1.69-.4-.15-.73-.31-.99-.47h0Z"/>
        <path class="cls-1" d="M38.16,24.68c-1.54,7.39-7.32,13.23-14.68,14.87-.17,.04-.34-.05-.4-.22-.5-1.3-.73-2.53-.65-3.44,.06-.7,.3-1.23,.69-1.52,1.21-.91,1.95-2.68,2.16-3.92,.08-.46,.1-.84,0-1.03-.31-.59-.43-1.39,0-1.91,.28-.33,.81-.58,1.74-.54,1.13,.05,2.79,0,3.92-.35,.34-.1,.63-.23,.83-.4,.14-.12,.24-.26,.23-.44-.02-.88,.36-1.33,.89-1.65,.44-.26,1.02-.4,1.54-.66,.28-.14,.7-.15,1.21-.04,.64,.14,1.45,.46,2.32,.84,.15,.07,.24,.23,.2,.39h0Zm-.75,.13c-.71-.31-1.38-.57-1.92-.68-.32-.07-.58-.1-.75-.01-.41,.2-.86,.33-1.25,.51-.45,.2-.8,.48-.79,1.15,.01,.56-.34,1-.97,1.31-1.17,.57-3.34,.67-4.75,.6-.62-.03-.99,.07-1.17,.29-.11,.13-.14,.31-.12,.48,.02,.22,.1,.45,.2,.65,.14,.28,.19,.81,.07,1.47-.23,1.38-1.08,3.35-2.43,4.36-.26,.19-.37,.56-.41,1.02-.06,.76,.12,1.77,.5,2.84,6.85-1.64,12.24-7.09,13.79-13.98h0Z"/>
        <path class="cls-1" d="M19.3,0c2.53,0,4.59,2.11,4.59,4.71,0,1.52-.72,3.37-1.83,4.6-.77,.86-1.73,1.42-2.76,1.42s-1.99-.56-2.76-1.42c-1.11-1.23-1.83-3.08-1.83-4.6C14.72,2.11,16.77,0,19.3,0h0Zm0,.7c-2.15,0-3.89,1.8-3.89,4,0,1.36,.65,3.02,1.65,4.13,.63,.7,1.4,1.19,2.24,1.19s1.61-.49,2.24-1.19c1-1.11,1.65-2.77,1.65-4.13,0-2.21-1.74-4-3.89-4Z"/>
        <path class="cls-1" d="M17.3,9.09c.58,.56,1.26,.94,2,.94s1.39-.36,1.97-.91c.09-.08,.21-.12,.33-.09,.12,.03,.21,.12,.25,.23,.37,1.17,1.25,2.33,3.05,2.77,.84,.2,1.46,.87,1.86,1.81,.56,1.28,.72,3.06,.57,4.6-.1,1.05-.34,1.98-.68,2.59-.28,.5-.64,.79-1.02,.84-1.84,.25-10.86,.25-12.7,0-.38-.05-.74-.34-1.02-.84-.34-.61-.58-1.54-.68-2.59-.15-1.54,.01-3.32,.57-4.6,.41-.93,1.03-1.6,1.86-1.81,1.82-.44,2.7-1.62,3.06-2.8,.04-.12,.13-.21,.25-.24s.24,0,.33,.09h0Zm-.09,.85c-.51,1.2-1.53,2.33-3.39,2.78-.64,.15-1.08,.7-1.39,1.4-.52,1.18-.65,2.83-.51,4.26,.09,.93,.29,1.77,.6,2.31,.15,.27,.3,.46,.51,.49,1.81,.25,10.7,.25,12.51,0,.2-.03,.36-.22,.51-.49,.3-.54,.51-1.38,.6-2.31,.14-1.43,0-3.07-.51-4.26-.31-.71-.75-1.25-1.39-1.4-1.85-.45-2.87-1.56-3.38-2.75-.62,.48-1.32,.76-2.06,.76s-1.46-.29-2.09-.79h0Z"/>
        <path class="cls-1" d="M23.25,4.23c.71-.96,1.83-1.58,3.09-1.58,2.13,0,3.87,1.77,3.87,3.97,0,1.27-.6,2.82-1.53,3.86-.65,.73-1.47,1.2-2.34,1.2-.77,0-1.49-.36-2.09-.95-.86-.82-1.49-2.09-1.7-3.27-.01-.07,0-.14,.03-.21,.39-.83,.61-1.73,.61-2.54,0-.08,0-.17,0-.25,0-.08,.02-.16,.07-.23h0Zm.64,.32c0,.05,0,.11,0,.16,0,.86-.23,1.83-.63,2.73,.2,1.01,.74,2.08,1.47,2.78,.47,.45,1.02,.75,1.61,.75,.68,0,1.3-.4,1.82-.97,.82-.91,1.35-2.27,1.35-3.39,0-1.8-1.42-3.26-3.17-3.26-.99,0-1.87,.47-2.45,1.2Z"/>
        <path class="cls-1" d="M23.37,11.69c.38-.4,.63-.87,.77-1.34,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.3,.96,1.02,1.9,2.49,2.26,.71,.17,1.24,.74,1.59,1.53,.47,1.07,.6,2.56,.48,3.85-.08,.88-.29,1.66-.57,2.17-.24,.43-.56,.68-.89,.73-.75,.1-2.96,.16-5.19,.16-.12,0-.23-.06-.3-.17-.06-.1-.07-.23-.02-.34,.6-1.22,.79-3.64,.33-5.52-.28-1.16-.8-2.12-1.7-2.34-.48-.12-.9-.28-1.28-.47-.1-.05-.17-.15-.18-.25s.02-.22,.09-.3h0Zm.82,.12c.22,.09,.46,.17,.71,.23,1.12,.27,1.86,1.4,2.21,2.85,.44,1.8,.32,4.07-.17,5.49,2,0,3.89-.06,4.57-.15,.15-.02,.26-.18,.38-.38,.25-.44,.41-1.13,.49-1.9,.11-1.17,0-2.53-.42-3.5-.25-.57-.6-1.01-1.11-1.13-1.52-.37-2.38-1.27-2.82-2.25-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.12,.26-.26,.52-.44,.76h0Z"/>
        <path class="cls-1" d="M16.05,7.44c-.21,1.18-.84,2.45-1.7,3.28-.61,.59-1.33,.95-2.1,.95-.87,0-1.69-.47-2.34-1.2-.93-1.04-1.53-2.59-1.53-3.86,0-2.19,1.74-3.97,3.87-3.97,1.27,0,2.39,.63,3.1,1.59,.05,.07,.07,.15,.07,.23,0,.08,0,.16,0,.24,0,.8,.22,1.69,.61,2.52,.03,.06,.04,.14,.03,.21Zm-.71-.02c-.4-.9-.62-1.86-.62-2.72,0-.05,0-.1,0-.15-.58-.74-1.47-1.21-2.46-1.21-1.75,0-3.17,1.47-3.17,3.26,0,1.12,.53,2.48,1.35,3.39,.51,.57,1.13,.97,1.82,.97,.59,0,1.14-.3,1.61-.75,.73-.7,1.27-1.78,1.47-2.79Z"/>
        <path class="cls-1" d="M12.14,21.08c-2.23,0-4.43-.06-5.19-.16-.33-.05-.65-.29-.89-.73-.28-.51-.49-1.29-.57-2.17-.12-1.29,.01-2.78,.48-3.85,.35-.79,.88-1.36,1.59-1.53,1.48-.36,2.21-1.32,2.5-2.28,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.14,.46,.39,.92,.76,1.31,.08,.08,.11,.19,.09,.3-.02,.11-.09,.2-.18,.25-.37,.2-.8,.36-1.28,.47-.9,.22-1.41,1.18-1.7,2.34-.46,1.87-.27,4.3,.33,5.52,.05,.11,.05,.24-.02,.34-.06,.1-.18,.17-.3,.17h0Zm-.52-.7c-.49-1.42-.62-3.69-.17-5.49,.36-1.45,1.09-2.58,2.21-2.85,.26-.06,.49-.14,.71-.23-.18-.24-.32-.49-.43-.74-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.44,.99-1.29,1.9-2.82,2.27-.51,.12-.86,.56-1.11,1.13-.42,.97-.53,2.33-.42,3.5,.07,.76,.24,1.45,.49,1.9,.11,.2,.22,.35,.38,.38,.68,.09,2.57,.14,4.57,.15h0Z"/>
        <path class="cls-1" d="M32.77,17.73c2.44,.38,4.23,.88,5.08,1.42,.49,.31,.71,.67,.71,1,0,.28-.15,.58-.49,.84-.37,.29-1.01,.58-1.88,.84-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.87-.26-1.5-.55-1.88-.84-.34-.27-.49-.56-.49-.84,0-.33,.22-.69,.71-1,.85-.54,2.64-1.03,5.08-1.42,.09-.01,.19,0,.27,.07,.08,.06,.13,.15,.14,.24,.08,.74,.24,1.4,.49,1.83,.11,.19,.22,.34,.37,.36,.74,.1,2.91,.15,5.09,.15,.13,0,.25,.08,.31,.2,.09,.19,.2,.35,.31,.46,.08,.08,.16,.13,.26,.15,1.81,.25,10.7,.25,12.51,0,.09-.01,.18-.07,.26-.15,.12-.11,.22-.27,.31-.46,.06-.12,.18-.19,.31-.2,2.19,0,4.35-.05,5.09-.15,.15-.02,.26-.17,.37-.36,.24-.42,.41-1.09,.49-1.83,.01-.1,.06-.18,.14-.24,.08-.06,.17-.08,.27-.07h0Zm.25,.75c-.11,.7-.29,1.32-.53,1.73-.24,.42-.55,.66-.88,.7-.73,.1-2.82,.16-4.98,.16-.28,.47-.63,.74-.99,.79-1.84,.25-10.86,.25-12.7,0-.37-.05-.72-.33-.99-.79-2.15,0-4.25-.06-4.98-.16-.33-.04-.64-.28-.88-.7-.24-.42-.43-1.03-.53-1.73-1.82,.3-3.23,.66-4.08,1.06-.27,.13-.48,.25-.62,.39-.08,.07-.14,.14-.14,.22,0,.11,.09,.19,.22,.29,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.13-.1,.22-.19,.22-.29,0-.08-.06-.15-.14-.22-.14-.13-.35-.26-.62-.39-.86-.4-2.26-.76-4.08-1.06h0Z"/>
      </svg>
      <svg id="dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.56 40" v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.1,.1,.19,.22,.28,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.12-.1,.22-.18,.22-.28,0,0,0,0,0-.01,0-.19,.15-.35,.35-.35,.19,0,.35,.15,.36,.34,0,0,0,0,0,.01,0,.19,0,.38,0,.57,0,10.64-8.64,19.28-19.28,19.28S0,31.36,0,20.72c0-.19,0-.38,0-.57h0Zm37.85,.99c-.38,.24-.95,.48-1.67,.69-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.72-.22-1.28-.45-1.67-.69,.22,10.06,8.46,18.16,18.58,18.16s18.35-8.1,18.58-18.16Z"/>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.11,.11,.19,.24,.3,.23,.17,.56,.34,.99,.5,2.17,.81,6.61,1.42,12.08,1.67,.12,0,.23,.07,.29,.18s.06,.23,0,.34c-.33,.61-.75,1.42-.86,2.06-.02,.14-.04,.27-.02,.38,.01,.09,.04,.16,.11,.2,.21,.1,.59,.14,1.02,.17,.76,.07,1.67,.11,2.31,.32,.45,.14,.78,.38,.93,.69,.15,.31,.14,.74-.21,1.31-.72,1.16-.71,2.12-.28,3.29,.31,.84,.81,1.77,1.41,2.94,.2,.39,.23,.72,.16,1-.1,.42-.43,.74-.86,.93-.38,.17-.83,.24-1.21,.21-.29-.02-.54-.1-.71-.21-.2-.13-.5-.48-.84-.91-.45-.57-.98-1.27-1.43-1.65-.08-.07-.16-.12-.23-.16-.02-.01-.05-.02-.07-.03,0,0-.02,0-.03,0-.19,.23-.37,.51-.58,.75-.26,.3-.57,.53-.96,.6-.48,.08-1.12-.08-2.02-.82-.48-.4-.92-.58-1.34-.56-.66,.04-1.24,.54-1.84,1.27-.06,.07-.15,.12-.25,.13s-.19-.03-.26-.09C2.41,31.41,0,26.34,0,20.72c0-.19,0-.38,0-.57h0Zm.7,.99c.11,5.12,2.31,9.74,5.76,13.03,.67-.75,1.36-1.2,2.1-1.24,.57-.03,1.17,.18,1.83,.72,.66,.54,1.1,.73,1.45,.67,.23-.04,.4-.19,.55-.37,.2-.23,.38-.51,.57-.74,.3-.37,.78-.33,1.31,.11,.48,.41,1.05,1.15,1.53,1.75,.28,.35,.52,.65,.68,.77,.09,.06,.22,.08,.37,.09,.28,.02,.6-.03,.88-.15,.22-.1,.42-.24,.47-.46,.03-.15,0-.31-.1-.51-.62-1.19-1.13-2.16-1.45-3.02-.5-1.38-.51-2.53,.35-3.9,.17-.28,.25-.48,.17-.63-.08-.16-.27-.24-.51-.32-.6-.19-1.45-.22-2.16-.29-.54-.05-1.01-.12-1.27-.24-.35-.17-.52-.51-.51-.96,0-.59,.35-1.44,.71-2.16-5.31-.27-9.6-.89-11.74-1.69-.4-.15-.73-.31-.99-.47h0Z"/>
        <path class="cls-1" d="M38.16,24.68c-1.54,7.39-7.32,13.23-14.68,14.87-.17,.04-.34-.05-.4-.22-.5-1.3-.73-2.53-.65-3.44,.06-.7,.3-1.23,.69-1.52,1.21-.91,1.95-2.68,2.16-3.92,.08-.46,.1-.84,0-1.03-.31-.59-.43-1.39,0-1.91,.28-.33,.81-.58,1.74-.54,1.13,.05,2.79,0,3.92-.35,.34-.1,.63-.23,.83-.4,.14-.12,.24-.26,.23-.44-.02-.88,.36-1.33,.89-1.65,.44-.26,1.02-.4,1.54-.66,.28-.14,.7-.15,1.21-.04,.64,.14,1.45,.46,2.32,.84,.15,.07,.24,.23,.2,.39h0Zm-.75,.13c-.71-.31-1.38-.57-1.92-.68-.32-.07-.58-.1-.75-.01-.41,.2-.86,.33-1.25,.51-.45,.2-.8,.48-.79,1.15,.01,.56-.34,1-.97,1.31-1.17,.57-3.34,.67-4.75,.6-.62-.03-.99,.07-1.17,.29-.11,.13-.14,.31-.12,.48,.02,.22,.1,.45,.2,.65,.14,.28,.19,.81,.07,1.47-.23,1.38-1.08,3.35-2.43,4.36-.26,.19-.37,.56-.41,1.02-.06,.76,.12,1.77,.5,2.84,6.85-1.64,12.24-7.09,13.79-13.98h0Z"/>
        <path class="cls-1" d="M19.3,0c2.53,0,4.59,2.11,4.59,4.71,0,1.52-.72,3.37-1.83,4.6-.77,.86-1.73,1.42-2.76,1.42s-1.99-.56-2.76-1.42c-1.11-1.23-1.83-3.08-1.83-4.6C14.72,2.11,16.77,0,19.3,0h0Zm0,.7c-2.15,0-3.89,1.8-3.89,4,0,1.36,.65,3.02,1.65,4.13,.63,.7,1.4,1.19,2.24,1.19s1.61-.49,2.24-1.19c1-1.11,1.65-2.77,1.65-4.13,0-2.21-1.74-4-3.89-4Z"/>
        <path class="cls-1" d="M17.3,9.09c.58,.56,1.26,.94,2,.94s1.39-.36,1.97-.91c.09-.08,.21-.12,.33-.09,.12,.03,.21,.12,.25,.23,.37,1.17,1.25,2.33,3.05,2.77,.84,.2,1.46,.87,1.86,1.81,.56,1.28,.72,3.06,.57,4.6-.1,1.05-.34,1.98-.68,2.59-.28,.5-.64,.79-1.02,.84-1.84,.25-10.86,.25-12.7,0-.38-.05-.74-.34-1.02-.84-.34-.61-.58-1.54-.68-2.59-.15-1.54,.01-3.32,.57-4.6,.41-.93,1.03-1.6,1.86-1.81,1.82-.44,2.7-1.62,3.06-2.8,.04-.12,.13-.21,.25-.24s.24,0,.33,.09h0Zm-.09,.85c-.51,1.2-1.53,2.33-3.39,2.78-.64,.15-1.08,.7-1.39,1.4-.52,1.18-.65,2.83-.51,4.26,.09,.93,.29,1.77,.6,2.31,.15,.27,.3,.46,.51,.49,1.81,.25,10.7,.25,12.51,0,.2-.03,.36-.22,.51-.49,.3-.54,.51-1.38,.6-2.31,.14-1.43,0-3.07-.51-4.26-.31-.71-.75-1.25-1.39-1.4-1.85-.45-2.87-1.56-3.38-2.75-.62,.48-1.32,.76-2.06,.76s-1.46-.29-2.09-.79h0Z"/>
        <path class="cls-1" d="M23.25,4.23c.71-.96,1.83-1.58,3.09-1.58,2.13,0,3.87,1.77,3.87,3.97,0,1.27-.6,2.82-1.53,3.86-.65,.73-1.47,1.2-2.34,1.2-.77,0-1.49-.36-2.09-.95-.86-.82-1.49-2.09-1.7-3.27-.01-.07,0-.14,.03-.21,.39-.83,.61-1.73,.61-2.54,0-.08,0-.17,0-.25,0-.08,.02-.16,.07-.23h0Zm.64,.32c0,.05,0,.11,0,.16,0,.86-.23,1.83-.63,2.73,.2,1.01,.74,2.08,1.47,2.78,.47,.45,1.02,.75,1.61,.75,.68,0,1.3-.4,1.82-.97,.82-.91,1.35-2.27,1.35-3.39,0-1.8-1.42-3.26-3.17-3.26-.99,0-1.87,.47-2.45,1.2Z"/>
        <path class="cls-1" d="M23.37,11.69c.38-.4,.63-.87,.77-1.34,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.3,.96,1.02,1.9,2.49,2.26,.71,.17,1.24,.74,1.59,1.53,.47,1.07,.6,2.56,.48,3.85-.08,.88-.29,1.66-.57,2.17-.24,.43-.56,.68-.89,.73-.75,.1-2.96,.16-5.19,.16-.12,0-.23-.06-.3-.17-.06-.1-.07-.23-.02-.34,.6-1.22,.79-3.64,.33-5.52-.28-1.16-.8-2.12-1.7-2.34-.48-.12-.9-.28-1.28-.47-.1-.05-.17-.15-.18-.25s.02-.22,.09-.3h0Zm.82,.12c.22,.09,.46,.17,.71,.23,1.12,.27,1.86,1.4,2.21,2.85,.44,1.8,.32,4.07-.17,5.49,2,0,3.89-.06,4.57-.15,.15-.02,.26-.18,.38-.38,.25-.44,.41-1.13,.49-1.9,.11-1.17,0-2.53-.42-3.5-.25-.57-.6-1.01-1.11-1.13-1.52-.37-2.38-1.27-2.82-2.25-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.12,.26-.26,.52-.44,.76h0Z"/>
        <path class="cls-1" d="M16.05,7.44c-.21,1.18-.84,2.45-1.7,3.28-.61,.59-1.33,.95-2.1,.95-.87,0-1.69-.47-2.34-1.2-.93-1.04-1.53-2.59-1.53-3.86,0-2.19,1.74-3.97,3.87-3.97,1.27,0,2.39,.63,3.1,1.59,.05,.07,.07,.15,.07,.23,0,.08,0,.16,0,.24,0,.8,.22,1.69,.61,2.52,.03,.06,.04,.14,.03,.21Zm-.71-.02c-.4-.9-.62-1.86-.62-2.72,0-.05,0-.1,0-.15-.58-.74-1.47-1.21-2.46-1.21-1.75,0-3.17,1.47-3.17,3.26,0,1.12,.53,2.48,1.35,3.39,.51,.57,1.13,.97,1.82,.97,.59,0,1.14-.3,1.61-.75,.73-.7,1.27-1.78,1.47-2.79Z"/>
        <path class="cls-1" d="M12.14,21.08c-2.23,0-4.43-.06-5.19-.16-.33-.05-.65-.29-.89-.73-.28-.51-.49-1.29-.57-2.17-.12-1.29,.01-2.78,.48-3.85,.35-.79,.88-1.36,1.59-1.53,1.48-.36,2.21-1.32,2.5-2.28,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.14,.46,.39,.92,.76,1.31,.08,.08,.11,.19,.09,.3-.02,.11-.09,.2-.18,.25-.37,.2-.8,.36-1.28,.47-.9,.22-1.41,1.18-1.7,2.34-.46,1.87-.27,4.3,.33,5.52,.05,.11,.05,.24-.02,.34-.06,.1-.18,.17-.3,.17h0Zm-.52-.7c-.49-1.42-.62-3.69-.17-5.49,.36-1.45,1.09-2.58,2.21-2.85,.26-.06,.49-.14,.71-.23-.18-.24-.32-.49-.43-.74-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.44,.99-1.29,1.9-2.82,2.27-.51,.12-.86,.56-1.11,1.13-.42,.97-.53,2.33-.42,3.5,.07,.76,.24,1.45,.49,1.9,.11,.2,.22,.35,.38,.38,.68,.09,2.57,.14,4.57,.15h0Z"/>
        <path class="cls-1" d="M32.77,17.73c2.44,.38,4.23,.88,5.08,1.42,.49,.31,.71,.67,.71,1,0,.28-.15,.58-.49,.84-.37,.29-1.01,.58-1.88,.84-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.87-.26-1.5-.55-1.88-.84-.34-.27-.49-.56-.49-.84,0-.33,.22-.69,.71-1,.85-.54,2.64-1.03,5.08-1.42,.09-.01,.19,0,.27,.07,.08,.06,.13,.15,.14,.24,.08,.74,.24,1.4,.49,1.83,.11,.19,.22,.34,.37,.36,.74,.1,2.91,.15,5.09,.15,.13,0,.25,.08,.31,.2,.09,.19,.2,.35,.31,.46,.08,.08,.16,.13,.26,.15,1.81,.25,10.7,.25,12.51,0,.09-.01,.18-.07,.26-.15,.12-.11,.22-.27,.31-.46,.06-.12,.18-.19,.31-.2,2.19,0,4.35-.05,5.09-.15,.15-.02,.26-.17,.37-.36,.24-.42,.41-1.09,.49-1.83,.01-.1,.06-.18,.14-.24,.08-.06,.17-.08,.27-.07h0Zm.25,.75c-.11,.7-.29,1.32-.53,1.73-.24,.42-.55,.66-.88,.7-.73,.1-2.82,.16-4.98,.16-.28,.47-.63,.74-.99,.79-1.84,.25-10.86,.25-12.7,0-.37-.05-.72-.33-.99-.79-2.15,0-4.25-.06-4.98-.16-.33-.04-.64-.28-.88-.7-.24-.42-.43-1.03-.53-1.73-1.82,.3-3.23,.66-4.08,1.06-.27,.13-.48,.25-.62,.39-.08,.07-.14,.14-.14,.22,0,.11,.09,.19,.22,.29,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.13-.1,.22-.19,.22-.29,0-.08-.06-.15-.14-.22-.14-.13-.35-.26-.62-.39-.86-.4-2.26-.76-4.08-1.06h0Z"/>
      </svg>
      <svg id="hover" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.56 40" v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.1,.1,.19,.22,.28,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.12-.1,.22-.18,.22-.28,0,0,0,0,0-.01,0-.19,.15-.35,.35-.35,.19,0,.35,.15,.36,.34,0,0,0,0,0,.01,0,.19,0,.38,0,.57,0,10.64-8.64,19.28-19.28,19.28S0,31.36,0,20.72c0-.19,0-.38,0-.57h0Zm37.85,.99c-.38,.24-.95,.48-1.67,.69-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.72-.22-1.28-.45-1.67-.69,.22,10.06,8.46,18.16,18.58,18.16s18.35-8.1,18.58-18.16Z"/>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.11,.11,.19,.24,.3,.23,.17,.56,.34,.99,.5,2.17,.81,6.61,1.42,12.08,1.67,.12,0,.23,.07,.29,.18s.06,.23,0,.34c-.33,.61-.75,1.42-.86,2.06-.02,.14-.04,.27-.02,.38,.01,.09,.04,.16,.11,.2,.21,.1,.59,.14,1.02,.17,.76,.07,1.67,.11,2.31,.32,.45,.14,.78,.38,.93,.69,.15,.31,.14,.74-.21,1.31-.72,1.16-.71,2.12-.28,3.29,.31,.84,.81,1.77,1.41,2.94,.2,.39,.23,.72,.16,1-.1,.42-.43,.74-.86,.93-.38,.17-.83,.24-1.21,.21-.29-.02-.54-.1-.71-.21-.2-.13-.5-.48-.84-.91-.45-.57-.98-1.27-1.43-1.65-.08-.07-.16-.12-.23-.16-.02-.01-.05-.02-.07-.03,0,0-.02,0-.03,0-.19,.23-.37,.51-.58,.75-.26,.3-.57,.53-.96,.6-.48,.08-1.12-.08-2.02-.82-.48-.4-.92-.58-1.34-.56-.66,.04-1.24,.54-1.84,1.27-.06,.07-.15,.12-.25,.13s-.19-.03-.26-.09C2.41,31.41,0,26.34,0,20.72c0-.19,0-.38,0-.57h0Zm.7,.99c.11,5.12,2.31,9.74,5.76,13.03,.67-.75,1.36-1.2,2.1-1.24,.57-.03,1.17,.18,1.83,.72,.66,.54,1.1,.73,1.45,.67,.23-.04,.4-.19,.55-.37,.2-.23,.38-.51,.57-.74,.3-.37,.78-.33,1.31,.11,.48,.41,1.05,1.15,1.53,1.75,.28,.35,.52,.65,.68,.77,.09,.06,.22,.08,.37,.09,.28,.02,.6-.03,.88-.15,.22-.1,.42-.24,.47-.46,.03-.15,0-.31-.1-.51-.62-1.19-1.13-2.16-1.45-3.02-.5-1.38-.51-2.53,.35-3.9,.17-.28,.25-.48,.17-.63-.08-.16-.27-.24-.51-.32-.6-.19-1.45-.22-2.16-.29-.54-.05-1.01-.12-1.27-.24-.35-.17-.52-.51-.51-.96,0-.59,.35-1.44,.71-2.16-5.31-.27-9.6-.89-11.74-1.69-.4-.15-.73-.31-.99-.47h0Z"/>
        <path class="cls-1" d="M38.16,24.68c-1.54,7.39-7.32,13.23-14.68,14.87-.17,.04-.34-.05-.4-.22-.5-1.3-.73-2.53-.65-3.44,.06-.7,.3-1.23,.69-1.52,1.21-.91,1.95-2.68,2.16-3.92,.08-.46,.1-.84,0-1.03-.31-.59-.43-1.39,0-1.91,.28-.33,.81-.58,1.74-.54,1.13,.05,2.79,0,3.92-.35,.34-.1,.63-.23,.83-.4,.14-.12,.24-.26,.23-.44-.02-.88,.36-1.33,.89-1.65,.44-.26,1.02-.4,1.54-.66,.28-.14,.7-.15,1.21-.04,.64,.14,1.45,.46,2.32,.84,.15,.07,.24,.23,.2,.39h0Zm-.75,.13c-.71-.31-1.38-.57-1.92-.68-.32-.07-.58-.1-.75-.01-.41,.2-.86,.33-1.25,.51-.45,.2-.8,.48-.79,1.15,.01,.56-.34,1-.97,1.31-1.17,.57-3.34,.67-4.75,.6-.62-.03-.99,.07-1.17,.29-.11,.13-.14,.31-.12,.48,.02,.22,.1,.45,.2,.65,.14,.28,.19,.81,.07,1.47-.23,1.38-1.08,3.35-2.43,4.36-.26,.19-.37,.56-.41,1.02-.06,.76,.12,1.77,.5,2.84,6.85-1.64,12.24-7.09,13.79-13.98h0Z"/>
        <path class="cls-1" d="M19.3,0c2.53,0,4.59,2.11,4.59,4.71,0,1.52-.72,3.37-1.83,4.6-.77,.86-1.73,1.42-2.76,1.42s-1.99-.56-2.76-1.42c-1.11-1.23-1.83-3.08-1.83-4.6C14.72,2.11,16.77,0,19.3,0h0Zm0,.7c-2.15,0-3.89,1.8-3.89,4,0,1.36,.65,3.02,1.65,4.13,.63,.7,1.4,1.19,2.24,1.19s1.61-.49,2.24-1.19c1-1.11,1.65-2.77,1.65-4.13,0-2.21-1.74-4-3.89-4Z"/>
        <path class="cls-1" d="M17.3,9.09c.58,.56,1.26,.94,2,.94s1.39-.36,1.97-.91c.09-.08,.21-.12,.33-.09,.12,.03,.21,.12,.25,.23,.37,1.17,1.25,2.33,3.05,2.77,.84,.2,1.46,.87,1.86,1.81,.56,1.28,.72,3.06,.57,4.6-.1,1.05-.34,1.98-.68,2.59-.28,.5-.64,.79-1.02,.84-1.84,.25-10.86,.25-12.7,0-.38-.05-.74-.34-1.02-.84-.34-.61-.58-1.54-.68-2.59-.15-1.54,.01-3.32,.57-4.6,.41-.93,1.03-1.6,1.86-1.81,1.82-.44,2.7-1.62,3.06-2.8,.04-.12,.13-.21,.25-.24s.24,0,.33,.09h0Zm-.09,.85c-.51,1.2-1.53,2.33-3.39,2.78-.64,.15-1.08,.7-1.39,1.4-.52,1.18-.65,2.83-.51,4.26,.09,.93,.29,1.77,.6,2.31,.15,.27,.3,.46,.51,.49,1.81,.25,10.7,.25,12.51,0,.2-.03,.36-.22,.51-.49,.3-.54,.51-1.38,.6-2.31,.14-1.43,0-3.07-.51-4.26-.31-.71-.75-1.25-1.39-1.4-1.85-.45-2.87-1.56-3.38-2.75-.62,.48-1.32,.76-2.06,.76s-1.46-.29-2.09-.79h0Z"/>
        <path class="cls-1" d="M23.25,4.23c.71-.96,1.83-1.58,3.09-1.58,2.13,0,3.87,1.77,3.87,3.97,0,1.27-.6,2.82-1.53,3.86-.65,.73-1.47,1.2-2.34,1.2-.77,0-1.49-.36-2.09-.95-.86-.82-1.49-2.09-1.7-3.27-.01-.07,0-.14,.03-.21,.39-.83,.61-1.73,.61-2.54,0-.08,0-.17,0-.25,0-.08,.02-.16,.07-.23h0Zm.64,.32c0,.05,0,.11,0,.16,0,.86-.23,1.83-.63,2.73,.2,1.01,.74,2.08,1.47,2.78,.47,.45,1.02,.75,1.61,.75,.68,0,1.3-.4,1.82-.97,.82-.91,1.35-2.27,1.35-3.39,0-1.8-1.42-3.26-3.17-3.26-.99,0-1.87,.47-2.45,1.2Z"/>
        <path class="cls-1" d="M23.37,11.69c.38-.4,.63-.87,.77-1.34,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.3,.96,1.02,1.9,2.49,2.26,.71,.17,1.24,.74,1.59,1.53,.47,1.07,.6,2.56,.48,3.85-.08,.88-.29,1.66-.57,2.17-.24,.43-.56,.68-.89,.73-.75,.1-2.96,.16-5.19,.16-.12,0-.23-.06-.3-.17-.06-.1-.07-.23-.02-.34,.6-1.22,.79-3.64,.33-5.52-.28-1.16-.8-2.12-1.7-2.34-.48-.12-.9-.28-1.28-.47-.1-.05-.17-.15-.18-.25s.02-.22,.09-.3h0Zm.82,.12c.22,.09,.46,.17,.71,.23,1.12,.27,1.86,1.4,2.21,2.85,.44,1.8,.32,4.07-.17,5.49,2,0,3.89-.06,4.57-.15,.15-.02,.26-.18,.38-.38,.25-.44,.41-1.13,.49-1.9,.11-1.17,0-2.53-.42-3.5-.25-.57-.6-1.01-1.11-1.13-1.52-.37-2.38-1.27-2.82-2.25-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.12,.26-.26,.52-.44,.76h0Z"/>
        <path class="cls-1" d="M16.05,7.44c-.21,1.18-.84,2.45-1.7,3.28-.61,.59-1.33,.95-2.1,.95-.87,0-1.69-.47-2.34-1.2-.93-1.04-1.53-2.59-1.53-3.86,0-2.19,1.74-3.97,3.87-3.97,1.27,0,2.39,.63,3.1,1.59,.05,.07,.07,.15,.07,.23,0,.08,0,.16,0,.24,0,.8,.22,1.69,.61,2.52,.03,.06,.04,.14,.03,.21Zm-.71-.02c-.4-.9-.62-1.86-.62-2.72,0-.05,0-.1,0-.15-.58-.74-1.47-1.21-2.46-1.21-1.75,0-3.17,1.47-3.17,3.26,0,1.12,.53,2.48,1.35,3.39,.51,.57,1.13,.97,1.82,.97,.59,0,1.14-.3,1.61-.75,.73-.7,1.27-1.78,1.47-2.79Z"/>
        <path class="cls-1" d="M12.14,21.08c-2.23,0-4.43-.06-5.19-.16-.33-.05-.65-.29-.89-.73-.28-.51-.49-1.29-.57-2.17-.12-1.29,.01-2.78,.48-3.85,.35-.79,.88-1.36,1.59-1.53,1.48-.36,2.21-1.32,2.5-2.28,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.14,.46,.39,.92,.76,1.31,.08,.08,.11,.19,.09,.3-.02,.11-.09,.2-.18,.25-.37,.2-.8,.36-1.28,.47-.9,.22-1.41,1.18-1.7,2.34-.46,1.87-.27,4.3,.33,5.52,.05,.11,.05,.24-.02,.34-.06,.1-.18,.17-.3,.17h0Zm-.52-.7c-.49-1.42-.62-3.69-.17-5.49,.36-1.45,1.09-2.58,2.21-2.85,.26-.06,.49-.14,.71-.23-.18-.24-.32-.49-.43-.74-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.44,.99-1.29,1.9-2.82,2.27-.51,.12-.86,.56-1.11,1.13-.42,.97-.53,2.33-.42,3.5,.07,.76,.24,1.45,.49,1.9,.11,.2,.22,.35,.38,.38,.68,.09,2.57,.14,4.57,.15h0Z"/>
        <path class="cls-1" d="M32.77,17.73c2.44,.38,4.23,.88,5.08,1.42,.49,.31,.71,.67,.71,1,0,.28-.15,.58-.49,.84-.37,.29-1.01,.58-1.88,.84-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.87-.26-1.5-.55-1.88-.84-.34-.27-.49-.56-.49-.84,0-.33,.22-.69,.71-1,.85-.54,2.64-1.03,5.08-1.42,.09-.01,.19,0,.27,.07,.08,.06,.13,.15,.14,.24,.08,.74,.24,1.4,.49,1.83,.11,.19,.22,.34,.37,.36,.74,.1,2.91,.15,5.09,.15,.13,0,.25,.08,.31,.2,.09,.19,.2,.35,.31,.46,.08,.08,.16,.13,.26,.15,1.81,.25,10.7,.25,12.51,0,.09-.01,.18-.07,.26-.15,.12-.11,.22-.27,.31-.46,.06-.12,.18-.19,.31-.2,2.19,0,4.35-.05,5.09-.15,.15-.02,.26-.17,.37-.36,.24-.42,.41-1.09,.49-1.83,.01-.1,.06-.18,.14-.24,.08-.06,.17-.08,.27-.07h0Zm.25,.75c-.11,.7-.29,1.32-.53,1.73-.24,.42-.55,.66-.88,.7-.73,.1-2.82,.16-4.98,.16-.28,.47-.63,.74-.99,.79-1.84,.25-10.86,.25-12.7,0-.37-.05-.72-.33-.99-.79-2.15,0-4.25-.06-4.98-.16-.33-.04-.64-.28-.88-.7-.24-.42-.43-1.03-.53-1.73-1.82,.3-3.23,.66-4.08,1.06-.27,.13-.48,.25-.62,.39-.08,.07-.14,.14-.14,.22,0,.11,.09,.19,.22,.29,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.13-.1,.22-.19,.22-.29,0-.08-.06-.15-.14-.22-.14-.13-.35-.26-.62-.39-.86-.4-2.26-.76-4.08-1.06h0Z"/>
      </svg>
      <svg id="hover_dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.56 40" v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.1,.1,.19,.22,.28,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.12-.1,.22-.18,.22-.28,0,0,0,0,0-.01,0-.19,.15-.35,.35-.35,.19,0,.35,.15,.36,.34,0,0,0,0,0,.01,0,.19,0,.38,0,.57,0,10.64-8.64,19.28-19.28,19.28S0,31.36,0,20.72c0-.19,0-.38,0-.57h0Zm37.85,.99c-.38,.24-.95,.48-1.67,.69-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.72-.22-1.28-.45-1.67-.69,.22,10.06,8.46,18.16,18.58,18.16s18.35-8.1,18.58-18.16Z"/>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.11,.11,.19,.24,.3,.23,.17,.56,.34,.99,.5,2.17,.81,6.61,1.42,12.08,1.67,.12,0,.23,.07,.29,.18s.06,.23,0,.34c-.33,.61-.75,1.42-.86,2.06-.02,.14-.04,.27-.02,.38,.01,.09,.04,.16,.11,.2,.21,.1,.59,.14,1.02,.17,.76,.07,1.67,.11,2.31,.32,.45,.14,.78,.38,.93,.69,.15,.31,.14,.74-.21,1.31-.72,1.16-.71,2.12-.28,3.29,.31,.84,.81,1.77,1.41,2.94,.2,.39,.23,.72,.16,1-.1,.42-.43,.74-.86,.93-.38,.17-.83,.24-1.21,.21-.29-.02-.54-.1-.71-.21-.2-.13-.5-.48-.84-.91-.45-.57-.98-1.27-1.43-1.65-.08-.07-.16-.12-.23-.16-.02-.01-.05-.02-.07-.03,0,0-.02,0-.03,0-.19,.23-.37,.51-.58,.75-.26,.3-.57,.53-.96,.6-.48,.08-1.12-.08-2.02-.82-.48-.4-.92-.58-1.34-.56-.66,.04-1.24,.54-1.84,1.27-.06,.07-.15,.12-.25,.13s-.19-.03-.26-.09C2.41,31.41,0,26.34,0,20.72c0-.19,0-.38,0-.57h0Zm.7,.99c.11,5.12,2.31,9.74,5.76,13.03,.67-.75,1.36-1.2,2.1-1.24,.57-.03,1.17,.18,1.83,.72,.66,.54,1.1,.73,1.45,.67,.23-.04,.4-.19,.55-.37,.2-.23,.38-.51,.57-.74,.3-.37,.78-.33,1.31,.11,.48,.41,1.05,1.15,1.53,1.75,.28,.35,.52,.65,.68,.77,.09,.06,.22,.08,.37,.09,.28,.02,.6-.03,.88-.15,.22-.1,.42-.24,.47-.46,.03-.15,0-.31-.1-.51-.62-1.19-1.13-2.16-1.45-3.02-.5-1.38-.51-2.53,.35-3.9,.17-.28,.25-.48,.17-.63-.08-.16-.27-.24-.51-.32-.6-.19-1.45-.22-2.16-.29-.54-.05-1.01-.12-1.27-.24-.35-.17-.52-.51-.51-.96,0-.59,.35-1.44,.71-2.16-5.31-.27-9.6-.89-11.74-1.69-.4-.15-.73-.31-.99-.47h0Z"/>
        <path class="cls-1" d="M38.16,24.68c-1.54,7.39-7.32,13.23-14.68,14.87-.17,.04-.34-.05-.4-.22-.5-1.3-.73-2.53-.65-3.44,.06-.7,.3-1.23,.69-1.52,1.21-.91,1.95-2.68,2.16-3.92,.08-.46,.1-.84,0-1.03-.31-.59-.43-1.39,0-1.91,.28-.33,.81-.58,1.74-.54,1.13,.05,2.79,0,3.92-.35,.34-.1,.63-.23,.83-.4,.14-.12,.24-.26,.23-.44-.02-.88,.36-1.33,.89-1.65,.44-.26,1.02-.4,1.54-.66,.28-.14,.7-.15,1.21-.04,.64,.14,1.45,.46,2.32,.84,.15,.07,.24,.23,.2,.39h0Zm-.75,.13c-.71-.31-1.38-.57-1.92-.68-.32-.07-.58-.1-.75-.01-.41,.2-.86,.33-1.25,.51-.45,.2-.8,.48-.79,1.15,.01,.56-.34,1-.97,1.31-1.17,.57-3.34,.67-4.75,.6-.62-.03-.99,.07-1.17,.29-.11,.13-.14,.31-.12,.48,.02,.22,.1,.45,.2,.65,.14,.28,.19,.81,.07,1.47-.23,1.38-1.08,3.35-2.43,4.36-.26,.19-.37,.56-.41,1.02-.06,.76,.12,1.77,.5,2.84,6.85-1.64,12.24-7.09,13.79-13.98h0Z"/>
        <path class="cls-1" d="M19.3,0c2.53,0,4.59,2.11,4.59,4.71,0,1.52-.72,3.37-1.83,4.6-.77,.86-1.73,1.42-2.76,1.42s-1.99-.56-2.76-1.42c-1.11-1.23-1.83-3.08-1.83-4.6C14.72,2.11,16.77,0,19.3,0h0Zm0,.7c-2.15,0-3.89,1.8-3.89,4,0,1.36,.65,3.02,1.65,4.13,.63,.7,1.4,1.19,2.24,1.19s1.61-.49,2.24-1.19c1-1.11,1.65-2.77,1.65-4.13,0-2.21-1.74-4-3.89-4Z"/>
        <path class="cls-1" d="M17.3,9.09c.58,.56,1.26,.94,2,.94s1.39-.36,1.97-.91c.09-.08,.21-.12,.33-.09,.12,.03,.21,.12,.25,.23,.37,1.17,1.25,2.33,3.05,2.77,.84,.2,1.46,.87,1.86,1.81,.56,1.28,.72,3.06,.57,4.6-.1,1.05-.34,1.98-.68,2.59-.28,.5-.64,.79-1.02,.84-1.84,.25-10.86,.25-12.7,0-.38-.05-.74-.34-1.02-.84-.34-.61-.58-1.54-.68-2.59-.15-1.54,.01-3.32,.57-4.6,.41-.93,1.03-1.6,1.86-1.81,1.82-.44,2.7-1.62,3.06-2.8,.04-.12,.13-.21,.25-.24s.24,0,.33,.09h0Zm-.09,.85c-.51,1.2-1.53,2.33-3.39,2.78-.64,.15-1.08,.7-1.39,1.4-.52,1.18-.65,2.83-.51,4.26,.09,.93,.29,1.77,.6,2.31,.15,.27,.3,.46,.51,.49,1.81,.25,10.7,.25,12.51,0,.2-.03,.36-.22,.51-.49,.3-.54,.51-1.38,.6-2.31,.14-1.43,0-3.07-.51-4.26-.31-.71-.75-1.25-1.39-1.4-1.85-.45-2.87-1.56-3.38-2.75-.62,.48-1.32,.76-2.06,.76s-1.46-.29-2.09-.79h0Z"/>
        <path class="cls-1" d="M23.25,4.23c.71-.96,1.83-1.58,3.09-1.58,2.13,0,3.87,1.77,3.87,3.97,0,1.27-.6,2.82-1.53,3.86-.65,.73-1.47,1.2-2.34,1.2-.77,0-1.49-.36-2.09-.95-.86-.82-1.49-2.09-1.7-3.27-.01-.07,0-.14,.03-.21,.39-.83,.61-1.73,.61-2.54,0-.08,0-.17,0-.25,0-.08,.02-.16,.07-.23h0Zm.64,.32c0,.05,0,.11,0,.16,0,.86-.23,1.83-.63,2.73,.2,1.01,.74,2.08,1.47,2.78,.47,.45,1.02,.75,1.61,.75,.68,0,1.3-.4,1.82-.97,.82-.91,1.35-2.27,1.35-3.39,0-1.8-1.42-3.26-3.17-3.26-.99,0-1.87,.47-2.45,1.2Z"/>
        <path class="cls-1" d="M23.37,11.69c.38-.4,.63-.87,.77-1.34,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.3,.96,1.02,1.9,2.49,2.26,.71,.17,1.24,.74,1.59,1.53,.47,1.07,.6,2.56,.48,3.85-.08,.88-.29,1.66-.57,2.17-.24,.43-.56,.68-.89,.73-.75,.1-2.96,.16-5.19,.16-.12,0-.23-.06-.3-.17-.06-.1-.07-.23-.02-.34,.6-1.22,.79-3.64,.33-5.52-.28-1.16-.8-2.12-1.7-2.34-.48-.12-.9-.28-1.28-.47-.1-.05-.17-.15-.18-.25s.02-.22,.09-.3h0Zm.82,.12c.22,.09,.46,.17,.71,.23,1.12,.27,1.86,1.4,2.21,2.85,.44,1.8,.32,4.07-.17,5.49,2,0,3.89-.06,4.57-.15,.15-.02,.26-.18,.38-.38,.25-.44,.41-1.13,.49-1.9,.11-1.17,0-2.53-.42-3.5-.25-.57-.6-1.01-1.11-1.13-1.52-.37-2.38-1.27-2.82-2.25-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.12,.26-.26,.52-.44,.76h0Z"/>
        <path class="cls-1" d="M16.05,7.44c-.21,1.18-.84,2.45-1.7,3.28-.61,.59-1.33,.95-2.1,.95-.87,0-1.69-.47-2.34-1.2-.93-1.04-1.53-2.59-1.53-3.86,0-2.19,1.74-3.97,3.87-3.97,1.27,0,2.39,.63,3.1,1.59,.05,.07,.07,.15,.07,.23,0,.08,0,.16,0,.24,0,.8,.22,1.69,.61,2.52,.03,.06,.04,.14,.03,.21Zm-.71-.02c-.4-.9-.62-1.86-.62-2.72,0-.05,0-.1,0-.15-.58-.74-1.47-1.21-2.46-1.21-1.75,0-3.17,1.47-3.17,3.26,0,1.12,.53,2.48,1.35,3.39,.51,.57,1.13,.97,1.82,.97,.59,0,1.14-.3,1.61-.75,.73-.7,1.27-1.78,1.47-2.79Z"/>
        <path class="cls-1" d="M12.14,21.08c-2.23,0-4.43-.06-5.19-.16-.33-.05-.65-.29-.89-.73-.28-.51-.49-1.29-.57-2.17-.12-1.29,.01-2.78,.48-3.85,.35-.79,.88-1.36,1.59-1.53,1.48-.36,2.21-1.32,2.5-2.28,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.14,.46,.39,.92,.76,1.31,.08,.08,.11,.19,.09,.3-.02,.11-.09,.2-.18,.25-.37,.2-.8,.36-1.28,.47-.9,.22-1.41,1.18-1.7,2.34-.46,1.87-.27,4.3,.33,5.52,.05,.11,.05,.24-.02,.34-.06,.1-.18,.17-.3,.17h0Zm-.52-.7c-.49-1.42-.62-3.69-.17-5.49,.36-1.45,1.09-2.58,2.21-2.85,.26-.06,.49-.14,.71-.23-.18-.24-.32-.49-.43-.74-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.44,.99-1.29,1.9-2.82,2.27-.51,.12-.86,.56-1.11,1.13-.42,.97-.53,2.33-.42,3.5,.07,.76,.24,1.45,.49,1.9,.11,.2,.22,.35,.38,.38,.68,.09,2.57,.14,4.57,.15h0Z"/>
        <path class="cls-1" d="M32.77,17.73c2.44,.38,4.23,.88,5.08,1.42,.49,.31,.71,.67,.71,1,0,.28-.15,.58-.49,.84-.37,.29-1.01,.58-1.88,.84-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.87-.26-1.5-.55-1.88-.84-.34-.27-.49-.56-.49-.84,0-.33,.22-.69,.71-1,.85-.54,2.64-1.03,5.08-1.42,.09-.01,.19,0,.27,.07,.08,.06,.13,.15,.14,.24,.08,.74,.24,1.4,.49,1.83,.11,.19,.22,.34,.37,.36,.74,.1,2.91,.15,5.09,.15,.13,0,.25,.08,.31,.2,.09,.19,.2,.35,.31,.46,.08,.08,.16,.13,.26,.15,1.81,.25,10.7,.25,12.51,0,.09-.01,.18-.07,.26-.15,.12-.11,.22-.27,.31-.46,.06-.12,.18-.19,.31-.2,2.19,0,4.35-.05,5.09-.15,.15-.02,.26-.17,.37-.36,.24-.42,.41-1.09,.49-1.83,.01-.1,.06-.18,.14-.24,.08-.06,.17-.08,.27-.07h0Zm.25,.75c-.11,.7-.29,1.32-.53,1.73-.24,.42-.55,.66-.88,.7-.73,.1-2.82,.16-4.98,.16-.28,.47-.63,.74-.99,.79-1.84,.25-10.86,.25-12.7,0-.37-.05-.72-.33-.99-.79-2.15,0-4.25-.06-4.98-.16-.33-.04-.64-.28-.88-.7-.24-.42-.43-1.03-.53-1.73-1.82,.3-3.23,.66-4.08,1.06-.27,.13-.48,.25-.62,.39-.08,.07-.14,.14-.14,.22,0,.11,.09,.19,.22,.29,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.13-.1,.22-.19,.22-.29,0-.08-.06-.15-.14-.22-.14-.13-.35-.26-.62-.39-.86-.4-2.26-.76-4.08-1.06h0Z"/>
      </svg>
      <svg id="mobile" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.56 40" v-if="device == 'MOBILE'">
        <defs>
        </defs>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.1,.1,.19,.22,.28,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.12-.1,.22-.18,.22-.28,0,0,0,0,0-.01,0-.19,.15-.35,.35-.35,.19,0,.35,.15,.36,.34,0,0,0,0,0,.01,0,.19,0,.38,0,.57,0,10.64-8.64,19.28-19.28,19.28S0,31.36,0,20.72c0-.19,0-.38,0-.57h0Zm37.85,.99c-.38,.24-.95,.48-1.67,.69-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.72-.22-1.28-.45-1.67-.69,.22,10.06,8.46,18.16,18.58,18.16s18.35-8.1,18.58-18.16Z"/>
        <path class="cls-1" d="M0,20.15s0,0,0-.01c0-.19,.16-.34,.36-.34s.35,.16,.35,.35c0,0,0,0,0,.01,0,.11,.11,.19,.24,.3,.23,.17,.56,.34,.99,.5,2.17,.81,6.61,1.42,12.08,1.67,.12,0,.23,.07,.29,.18s.06,.23,0,.34c-.33,.61-.75,1.42-.86,2.06-.02,.14-.04,.27-.02,.38,.01,.09,.04,.16,.11,.2,.21,.1,.59,.14,1.02,.17,.76,.07,1.67,.11,2.31,.32,.45,.14,.78,.38,.93,.69,.15,.31,.14,.74-.21,1.31-.72,1.16-.71,2.12-.28,3.29,.31,.84,.81,1.77,1.41,2.94,.2,.39,.23,.72,.16,1-.1,.42-.43,.74-.86,.93-.38,.17-.83,.24-1.21,.21-.29-.02-.54-.1-.71-.21-.2-.13-.5-.48-.84-.91-.45-.57-.98-1.27-1.43-1.65-.08-.07-.16-.12-.23-.16-.02-.01-.05-.02-.07-.03,0,0-.02,0-.03,0-.19,.23-.37,.51-.58,.75-.26,.3-.57,.53-.96,.6-.48,.08-1.12-.08-2.02-.82-.48-.4-.92-.58-1.34-.56-.66,.04-1.24,.54-1.84,1.27-.06,.07-.15,.12-.25,.13s-.19-.03-.26-.09C2.41,31.41,0,26.34,0,20.72c0-.19,0-.38,0-.57h0Zm.7,.99c.11,5.12,2.31,9.74,5.76,13.03,.67-.75,1.36-1.2,2.1-1.24,.57-.03,1.17,.18,1.83,.72,.66,.54,1.1,.73,1.45,.67,.23-.04,.4-.19,.55-.37,.2-.23,.38-.51,.57-.74,.3-.37,.78-.33,1.31,.11,.48,.41,1.05,1.15,1.53,1.75,.28,.35,.52,.65,.68,.77,.09,.06,.22,.08,.37,.09,.28,.02,.6-.03,.88-.15,.22-.1,.42-.24,.47-.46,.03-.15,0-.31-.1-.51-.62-1.19-1.13-2.16-1.45-3.02-.5-1.38-.51-2.53,.35-3.9,.17-.28,.25-.48,.17-.63-.08-.16-.27-.24-.51-.32-.6-.19-1.45-.22-2.16-.29-.54-.05-1.01-.12-1.27-.24-.35-.17-.52-.51-.51-.96,0-.59,.35-1.44,.71-2.16-5.31-.27-9.6-.89-11.74-1.69-.4-.15-.73-.31-.99-.47h0Z"/>
        <path class="cls-1" d="M38.16,24.68c-1.54,7.39-7.32,13.23-14.68,14.87-.17,.04-.34-.05-.4-.22-.5-1.3-.73-2.53-.65-3.44,.06-.7,.3-1.23,.69-1.52,1.21-.91,1.95-2.68,2.16-3.92,.08-.46,.1-.84,0-1.03-.31-.59-.43-1.39,0-1.91,.28-.33,.81-.58,1.74-.54,1.13,.05,2.79,0,3.92-.35,.34-.1,.63-.23,.83-.4,.14-.12,.24-.26,.23-.44-.02-.88,.36-1.33,.89-1.65,.44-.26,1.02-.4,1.54-.66,.28-.14,.7-.15,1.21-.04,.64,.14,1.45,.46,2.32,.84,.15,.07,.24,.23,.2,.39h0Zm-.75,.13c-.71-.31-1.38-.57-1.92-.68-.32-.07-.58-.1-.75-.01-.41,.2-.86,.33-1.25,.51-.45,.2-.8,.48-.79,1.15,.01,.56-.34,1-.97,1.31-1.17,.57-3.34,.67-4.75,.6-.62-.03-.99,.07-1.17,.29-.11,.13-.14,.31-.12,.48,.02,.22,.1,.45,.2,.65,.14,.28,.19,.81,.07,1.47-.23,1.38-1.08,3.35-2.43,4.36-.26,.19-.37,.56-.41,1.02-.06,.76,.12,1.77,.5,2.84,6.85-1.64,12.24-7.09,13.79-13.98h0Z"/>
        <path class="cls-1" d="M19.3,0c2.53,0,4.59,2.11,4.59,4.71,0,1.52-.72,3.37-1.83,4.6-.77,.86-1.73,1.42-2.76,1.42s-1.99-.56-2.76-1.42c-1.11-1.23-1.83-3.08-1.83-4.6C14.72,2.11,16.77,0,19.3,0h0Zm0,.7c-2.15,0-3.89,1.8-3.89,4,0,1.36,.65,3.02,1.65,4.13,.63,.7,1.4,1.19,2.24,1.19s1.61-.49,2.24-1.19c1-1.11,1.65-2.77,1.65-4.13,0-2.21-1.74-4-3.89-4Z"/>
        <path class="cls-1" d="M17.3,9.09c.58,.56,1.26,.94,2,.94s1.39-.36,1.97-.91c.09-.08,.21-.12,.33-.09,.12,.03,.21,.12,.25,.23,.37,1.17,1.25,2.33,3.05,2.77,.84,.2,1.46,.87,1.86,1.81,.56,1.28,.72,3.06,.57,4.6-.1,1.05-.34,1.98-.68,2.59-.28,.5-.64,.79-1.02,.84-1.84,.25-10.86,.25-12.7,0-.38-.05-.74-.34-1.02-.84-.34-.61-.58-1.54-.68-2.59-.15-1.54,.01-3.32,.57-4.6,.41-.93,1.03-1.6,1.86-1.81,1.82-.44,2.7-1.62,3.06-2.8,.04-.12,.13-.21,.25-.24s.24,0,.33,.09h0Zm-.09,.85c-.51,1.2-1.53,2.33-3.39,2.78-.64,.15-1.08,.7-1.39,1.4-.52,1.18-.65,2.83-.51,4.26,.09,.93,.29,1.77,.6,2.31,.15,.27,.3,.46,.51,.49,1.81,.25,10.7,.25,12.51,0,.2-.03,.36-.22,.51-.49,.3-.54,.51-1.38,.6-2.31,.14-1.43,0-3.07-.51-4.26-.31-.71-.75-1.25-1.39-1.4-1.85-.45-2.87-1.56-3.38-2.75-.62,.48-1.32,.76-2.06,.76s-1.46-.29-2.09-.79h0Z"/>
        <path class="cls-1" d="M23.25,4.23c.71-.96,1.83-1.58,3.09-1.58,2.13,0,3.87,1.77,3.87,3.97,0,1.27-.6,2.82-1.53,3.86-.65,.73-1.47,1.2-2.34,1.2-.77,0-1.49-.36-2.09-.95-.86-.82-1.49-2.09-1.7-3.27-.01-.07,0-.14,.03-.21,.39-.83,.61-1.73,.61-2.54,0-.08,0-.17,0-.25,0-.08,.02-.16,.07-.23h0Zm.64,.32c0,.05,0,.11,0,.16,0,.86-.23,1.83-.63,2.73,.2,1.01,.74,2.08,1.47,2.78,.47,.45,1.02,.75,1.61,.75,.68,0,1.3-.4,1.82-.97,.82-.91,1.35-2.27,1.35-3.39,0-1.8-1.42-3.26-3.17-3.26-.99,0-1.87,.47-2.45,1.2Z"/>
        <path class="cls-1" d="M23.37,11.69c.38-.4,.63-.87,.77-1.34,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.3,.96,1.02,1.9,2.49,2.26,.71,.17,1.24,.74,1.59,1.53,.47,1.07,.6,2.56,.48,3.85-.08,.88-.29,1.66-.57,2.17-.24,.43-.56,.68-.89,.73-.75,.1-2.96,.16-5.19,.16-.12,0-.23-.06-.3-.17-.06-.1-.07-.23-.02-.34,.6-1.22,.79-3.64,.33-5.52-.28-1.16-.8-2.12-1.7-2.34-.48-.12-.9-.28-1.28-.47-.1-.05-.17-.15-.18-.25s.02-.22,.09-.3h0Zm.82,.12c.22,.09,.46,.17,.71,.23,1.12,.27,1.86,1.4,2.21,2.85,.44,1.8,.32,4.07-.17,5.49,2,0,3.89-.06,4.57-.15,.15-.02,.26-.18,.38-.38,.25-.44,.41-1.13,.49-1.9,.11-1.17,0-2.53-.42-3.5-.25-.57-.6-1.01-1.11-1.13-1.52-.37-2.38-1.27-2.82-2.25-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.12,.26-.26,.52-.44,.76h0Z"/>
        <path class="cls-1" d="M16.05,7.44c-.21,1.18-.84,2.45-1.7,3.28-.61,.59-1.33,.95-2.1,.95-.87,0-1.69-.47-2.34-1.2-.93-1.04-1.53-2.59-1.53-3.86,0-2.19,1.74-3.97,3.87-3.97,1.27,0,2.39,.63,3.1,1.59,.05,.07,.07,.15,.07,.23,0,.08,0,.16,0,.24,0,.8,.22,1.69,.61,2.52,.03,.06,.04,.14,.03,.21Zm-.71-.02c-.4-.9-.62-1.86-.62-2.72,0-.05,0-.1,0-.15-.58-.74-1.47-1.21-2.46-1.21-1.75,0-3.17,1.47-3.17,3.26,0,1.12,.53,2.48,1.35,3.39,.51,.57,1.13,.97,1.82,.97,.59,0,1.14-.3,1.61-.75,.73-.7,1.27-1.78,1.47-2.79Z"/>
        <path class="cls-1" d="M12.14,21.08c-2.23,0-4.43-.06-5.19-.16-.33-.05-.65-.29-.89-.73-.28-.51-.49-1.29-.57-2.17-.12-1.29,.01-2.78,.48-3.85,.35-.79,.88-1.36,1.59-1.53,1.48-.36,2.21-1.32,2.5-2.28,.04-.12,.13-.21,.25-.24,.12-.03,.24,0,.33,.09,.47,.46,1.02,.76,1.62,.76s1.13-.29,1.59-.74c.09-.08,.21-.12,.33-.09s.21,.12,.25,.23c.14,.46,.39,.92,.76,1.31,.08,.08,.11,.19,.09,.3-.02,.11-.09,.2-.18,.25-.37,.2-.8,.36-1.28,.47-.9,.22-1.41,1.18-1.7,2.34-.46,1.87-.27,4.3,.33,5.52,.05,.11,.05,.24-.02,.34-.06,.1-.18,.17-.3,.17h0Zm-.52-.7c-.49-1.42-.62-3.69-.17-5.49,.36-1.45,1.09-2.58,2.21-2.85,.26-.06,.49-.14,.71-.23-.18-.24-.32-.49-.43-.74-.51,.38-1.08,.6-1.68,.6s-1.19-.23-1.71-.62c-.44,.99-1.29,1.9-2.82,2.27-.51,.12-.86,.56-1.11,1.13-.42,.97-.53,2.33-.42,3.5,.07,.76,.24,1.45,.49,1.9,.11,.2,.22,.35,.38,.38,.68,.09,2.57,.14,4.57,.15h0Z"/>
        <path class="cls-1" d="M32.77,17.73c2.44,.38,4.23,.88,5.08,1.42,.49,.31,.71,.67,.71,1,0,.28-.15,.58-.49,.84-.37,.29-1.01,.58-1.88,.84-3.17,.95-9.56,1.6-16.91,1.6s-13.74-.65-16.91-1.6c-.87-.26-1.5-.55-1.88-.84-.34-.27-.49-.56-.49-.84,0-.33,.22-.69,.71-1,.85-.54,2.64-1.03,5.08-1.42,.09-.01,.19,0,.27,.07,.08,.06,.13,.15,.14,.24,.08,.74,.24,1.4,.49,1.83,.11,.19,.22,.34,.37,.36,.74,.1,2.91,.15,5.09,.15,.13,0,.25,.08,.31,.2,.09,.19,.2,.35,.31,.46,.08,.08,.16,.13,.26,.15,1.81,.25,10.7,.25,12.51,0,.09-.01,.18-.07,.26-.15,.12-.11,.22-.27,.31-.46,.06-.12,.18-.19,.31-.2,2.19,0,4.35-.05,5.09-.15,.15-.02,.26-.17,.37-.36,.24-.42,.41-1.09,.49-1.83,.01-.1,.06-.18,.14-.24,.08-.06,.17-.08,.27-.07h0Zm.25,.75c-.11,.7-.29,1.32-.53,1.73-.24,.42-.55,.66-.88,.7-.73,.1-2.82,.16-4.98,.16-.28,.47-.63,.74-.99,.79-1.84,.25-10.86,.25-12.7,0-.37-.05-.72-.33-.99-.79-2.15,0-4.25-.06-4.98-.16-.33-.04-.64-.28-.88-.7-.24-.42-.43-1.03-.53-1.73-1.82,.3-3.23,.66-4.08,1.06-.27,.13-.48,.25-.62,.39-.08,.07-.14,.14-.14,.22,0,.11,.09,.19,.22,.29,.33,.26,.89,.49,1.64,.72,3.13,.94,9.44,1.57,16.71,1.57s13.58-.64,16.71-1.57c.75-.23,1.32-.46,1.64-.72,.13-.1,.22-.19,.22-.29,0-.08-.06-.15-.14-.22-.14-.13-.35-.26-.62-.39-.86-.4-2.26-.76-4.08-1.06h0Z"/>
      </svg>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
#mobile {
  .cls-1 {
    fill: #2d2d2d;
    fill-rule: evenodd;
  }
}

#light {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#dark {
  .cls-1 {
    fill: #f9f9f9;
  }
}

#hover {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}

#hover_dark {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}


.dp-menu-item:hover, .dp-menu-item.active {
  #hover, #hover_dark {
    display: block;
  }
  #light, #dark {
    display: none;
  }
}
</style>
