<template>
  <div>
    <div class="row" v-if="showResults">
      <div class="col-sm-12 diy-tools-results-header">
        <BackButton :clickMethod="back" class="mb-3"/>
      </div>
      <MobileDiyToolsResultsView />
    </div>
    <div class="row" v-else>
      <div class="col-sm-12">
        <label for="custom-dropdown" class="dropdown-label mb-1">Filter source type</label>
      </div>
      <div class="col-sm-12">
        <div class="lab-dropdown mb-1">
          <div class="lab-dropdown-btn" @click="isDropdownOpen = !isDropdownOpen" ref="dropdownType">
            <span class="lab-dropdown-btn-value">
              {{ filteredSourceType || 'All' }}
            </span>
            <span class="lab-dropdown-btn-icon">
              <DropdownOneIcon />
            </span>
          </div>
          <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isDropdownOpen}">
            <ul>
              <li v-for="item in sourceTypes"
                  :key="item.id"
                  @click="$store.state.diySourceData.filteredSourceType = item.id">
                  {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <b-form-input v-model="searchQuery" id="searchSelectedList" :placeholder="$t('Search')" autocomplete="off" />
      </div>
      <div class="col-sm-12">
        <ul class="diy-targets-menu unselected cs-scroll-style arrow-scroll-info" id="source-unselected-list" @wheel="onScroll(`source-unselected-list`)"  @scroll="onScroll(`source-unselected-list`)">
          <template v-if="sourceUnSelectedList && !$store.getters.getLoader">
            <li v-for="item in sourceUnSelectedList" :key="item.Id" @click="moveToSelected(item)">
            <div class="diy-group" @click="toggleGroup(item)">
              <feather-icon
                  icon="ChevronDownIcon"
                  class="cursor-pointer lab-text-steel"
                  size="25"
                  v-if="isOpened(item)"
                />
              <feather-icon
                icon="ChevronRightIcon"
                class="cursor-pointer lab-text-steel"
                size="25"
                v-else
              />
              <div class="diy-name">
                {{ item.groupName.replaceAll('*', ' ') }}
              </div>
            </div>
            <ul class="diy-targets-sub-menu cs-scroll-style" v-show="isOpened(item)">
              <li class="select-all-li"  @click="selectAll(item)" v-if="item.list.length > 1">
                <span>Select all</span> 
                <input type="checkbox" id="checkbox" :value="false" @change="forceFalse" :checked="false" />
              </li>
              <li v-for="subItem in item.list" :key="subItem.name" @click="moveToSelected(subItem)">
                <div class="diy-group p-0">
                  <feather-icon
                    icon="ChevronRightIcon"
                    class="cursor-pointer lab-text-steel"
                    size="25"
                    />
                  <div class="diy-name">
                    {{ subItem.name.replaceAll('*', ' ').replaceAll(item.groupName + ':', '') }}
                  </div>
                </div>
              </li>
            </ul>
            </li>
          </template>
          <SpinnerContainer v-else />
        </ul>
      </div>
      <div class="col-sm-12">
        <p class="selection-info mt-1 p-1 mb-0">
          Selected populations are listed below. Click on to unselect.
        </p>
        <div class="d-block p-1" v-if="sourceSelectedList.length > 0">
          <button class="lab-btn lab-btn-remove-all" @click="removeAllSelected">Remove All</button>
        </div>
        <ul class="diy-targets-menu cs-scroll-style">
          <li v-for="item in sourceSelectedList" class="select" :key="item.Id" @click="moveToUnselected(item)">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="col-sm-12 mt-2">
        <div class="row">
          <div class="col-6">{{ sourceSelectedList.length }} selected</div>
          <div class="col-6 text-right d-flex align-items-center justify-content-end">
            <!-- <SettingsIcon class="settings-icon" /> <span class="add-margin">Edit Selected</span> -->
          </div>
        </div>
      </div>
      <div class="col-sm-12 mt-3">
        <label for="custom-dropdown" class="dropdown-label mb-1">Population limit</label>
        <div class="sources-description mb-1">
          You can  limit the number of source populations to be used in the calculation. I.e if you have 10 populations selected, you can force the algorithm to use the best fitting 3, 4 or 5 populations
among them. This can help you to receive more refined results.
        </div>
      </div>
      <div class="col-sm-12 d-flex justify-content-center mt-2">
        
        <div class="lab-dropdown sm region w-100">
        <div class="lab-dropdown-btn" @click="isDropDown = !isDropDown" ref="dropdownReport">
          <span class="lab-dropdown-btn-value">
            {{ $store.state.diySourceData.populationNumber || 'No limit' }}
          </span>
          <span class="lab-dropdown-btn-icon">
            <DropdownOneIcon />
          </span>
        </div>
        <div class="lab-dropdown-content population-selection cs-scroll-style" :class="{ 'show': isDropDown}">
          <ul>
            <li v-for="item in limitTypes"
                :key="item.id"
                @click="$store.state.diySourceData.populationNumber = item.value">
                {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      </div>
      <!-- <div class="col-sm-12 d-flex justify-content-center mt-2">
        <button class="lab-btn run-button w-50 d-flex align-items-center justify-content-center" @click="runDiy()"><RunIcon class="run-icon" /><span>Run</span></button>
      </div> -->
    </div> 
  </div>

</template>
    
    
<script>
import SelectAllIcon from '@/layouts/icons/SelectAllIcon.vue';
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';
import RunIcon from '@/layouts/icons/RunIcon.vue';
import SettingsIcon from '@/layouts/components/icons/SettingsIcon.vue';
import BackButton from '@/components/BackButton.vue';
import MobileDiyToolsResultsView from './MobileDiyToolsResultsView.vue';

export default {
  components: {
    DropdownOneIcon,
    // RunIcon,
    // SettingsIcon,
    BackButton,
    MobileDiyToolsResultsView,
    // SelectAllIcon
  },
  data() {
    return {
      isDropdownOpen: false,
      isDropDown: false,
      searchQuery: '',
      showResults: false,
      indexMap: new Map(),
      noSelect: false,
      openedGroupList: [],
      selectedPopNumber: 0,
      limitTypes: [
        { value: 0, name: 'No limit' },
        { value: 3, name: '3' },
        { value: 4, name: '4' },
        { value: 5, name: '5' },
      ],
      sourceTypes: [
        { id: 'ANCIENT_POP', name: 'Ancient Populations' },
        { id: 'MODERN_POP', name: 'Modern Populations' },
      ]
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownType;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isDropdownOpen = false;
        }
      } catch (error) {}

      try {
        const el = this.$refs.dropdownReport;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isDropDown = false;
        }
      } catch (error) {}
    },
    forceFalse(event) {
      event.target.checked = false;
    },
    toggleGroup(item) {
      const findOpened = this.openedGroupList.find(x => x === item.groupName);
      if (findOpened) {
        const index = this.openedGroupList.indexOf(findOpened);

        if (index > -1) {
          this.openedGroupList.splice(index, 1);
        }
      } else {
        this.openedGroupList.push(item.groupName);
      }
    },
    isOpened(item) {
      return this.openedGroupList.find(x => x === item.groupName);
    },

    moveToSelected(item) {
      const index = this.$store.state.diySourceData.sourceUnSelectedList.indexOf(item);
      if (index > -1) {
        this.$store.state.diySourceData.sourceUnSelectedList.splice(index, 1);
        // this.sourceSelectedList.push(item);
        this.sourceSelectedList.unshift(...[item]);
      } else {
        console.warn("Item not found in source list");
      }
    },

    selectAll(item) {
      for(let i=0; i < item.list.length; i++) {
        this.moveToSelected(item.list[i]);
      }
    },

    moveToUnselected(item) {
      const index = this.sourceSelectedList.indexOf(item);
      if (index > -1) {
        this.sourceSelectedList.splice(index, 1);
        this.$store.state.diySourceData.sourceUnSelectedList.push(item);
      } else {
        console.warn("Item not found in source list");
      }
    },
    runDiy(){
      this.showResults = true;
      this.goToTop();
    },
    back() {
      this.showResults = false;
    },
    goToTop() {
      const element = document.querySelector(".diy-tools-container");
      const topy = element.offsetTop;
      element.scrollTo({
        top: topy,
        behavior: 'smooth',
      });
    },
    onScroll(name) {
      const element = document.getElementById(`${name}`);
      if (element) {
        this.$store.state.utils.checkIfScrolledToBottom(element);
      }
    },
    removeAllSelected() {
      for(let i=0; i < this.sourceSelectedList.length; i++) {
        this.$store.state.diySourceData.sourceUnSelectedList.push(this.sourceSelectedList[i]);
      }
      this.$store.state.diySourceData.sourceSelectedList = [];
    },
  },
  computed: {
    filteredSourceType() {
      const type = this.$store.state.diySourceData.filteredSourceType;
      return this.sourceTypes.find(x => x.id === type).name;
    },
  
    sourceUnSelectedList() {
      const dataList = this.$store.state.diySourceData.sourceUnSelectedList.filter(x => x.type === this.$store.state.diySourceData.filteredSourceType && x.name.toLowerCase().includes(this.searchQuery.toLowerCase())).sort((a, b) => {
        let comp = 0;
        if (a.name < b.name) {
          comp = -1;
        } else if (a.name > b.name) {
          comp = 1;
        }
        return comp;
      });
      return dataList.reduce((acc, item) => {
          const group = acc.find(g => g.groupName === item.groupName);
          if (group) {
            group.list.push(item);
          } else {
            acc.push({
              groupName: item.groupName,
              list: [item]
            });
          }
          return acc;
        }, []);
    },
    sourceSelectedList() {
      return this.$store.state.diySourceData.sourceSelectedList;
    },
  },
  mounted() {
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
  
}
</script>

<style lang="scss" scoped>
  .settings-icon {
    width: 1.2em;
    margin-left: 5px;
  }
  .run-button {
    background-color: #97C470;
    height: 43px;
  }
  .run-icon {
    width: 1.2em;
    margin-right:5px;
  }
  .add-margin {
    margin-left:5px;
  }

  ul.diy-targets-menu {
    li {
      font-size:.9rem;
    }
  }
  ul.diy-targets-menu.unselected {
    max-height:50vh!important;
  }

  .population-selection{
    bottom: 40px;
  }
  .selection-info {
    border-top: 3px solid #dddddd55;
    font-size:0.8rem;
  }
</style>