<template>
    <div class="g25-main-icon">
        <svg id="light" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="variant == 'light' && device == 'DESKTOP'">
          <defs>
          </defs>
          <path class="cls-1" d="M31.48,26.81H11.53l2.59-17.74L31.48,26.81Zm-17.92-1.76h13.74L15.34,12.84l-1.78,12.21Z"/>
          <path class="cls-1" d="M39.37,36.04H.66c-.36,0-.66,.3-.66,.66s.3,.66,.66,.66H10.11v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-1.98h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h6.15v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-2.66c0-.35-.28-.63-.63-.63Z"/>
          <path class="cls-1" d="M3.39,0H.85C.5,0,.22,.28,.22,.63s.28,.63,.63,.68h1.78V7.03H.66C.3,7.03,0,7.33,0,7.69s.3,.66,.66,.66h1.98v6.15H.66C.3,14.51,0,14.8,0,15.16s.3,.66,.66,.66h1.98v5.71H.66C.3,21.54,0,21.83,0,22.2s.3,.66,.66,.66h1.98v5.71H.66C.3,28.57,0,28.87,0,29.23s.3,.66,.66,.66h1.98v9.26s.41,.63,.76,.63,.63-.28,.56-.63V.63c.07-.35-.21-.63-.56-.63Z"/>
          <circle class="cls-1" cx="14.95" cy="10.99" r="2.2"/>
          <circle class="cls-1" cx="12.75" cy="25.93" r="2.2"/>
          <circle class="cls-1" cx="29.45" cy="25.93" r="2.2"/>
        </svg>
        <svg id="dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="variant == 'dark' && device == 'DESKTOP'">
          <defs>
          </defs>
          <path class="cls-1" d="M31.48,26.81H11.53l2.59-17.74L31.48,26.81Zm-17.92-1.76h13.74L15.34,12.84l-1.78,12.21Z"/>
          <path class="cls-1" d="M39.37,36.04H.66c-.36,0-.66,.3-.66,.66s.3,.66,.66,.66H10.11v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-1.98h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h6.15v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-2.66c0-.35-.28-.63-.63-.63Z"/>
          <path class="cls-1" d="M3.39,0H.85C.5,0,.22,.28,.22,.63s.28,.63,.63,.68h1.78V7.03H.66C.3,7.03,0,7.33,0,7.69s.3,.66,.66,.66h1.98v6.15H.66C.3,14.51,0,14.8,0,15.16s.3,.66,.66,.66h1.98v5.71H.66C.3,21.54,0,21.83,0,22.2s.3,.66,.66,.66h1.98v5.71H.66C.3,28.57,0,28.87,0,29.23s.3,.66,.66,.66h1.98v9.26s.41,.63,.76,.63,.63-.28,.56-.63V.63c.07-.35-.21-.63-.56-.63Z"/>
          <circle class="cls-1" cx="14.95" cy="10.99" r="2.2"/>
          <circle class="cls-1" cx="12.75" cy="25.93" r="2.2"/>
          <circle class="cls-1" cx="29.45" cy="25.93" r="2.2"/>
        </svg>
        <svg id="hover" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="variant == 'light' && device == 'DESKTOP'">
          <defs>
          </defs>
          <path class="cls-1" d="M31.48,26.81H11.53l2.59-17.74L31.48,26.81Zm-17.92-1.76h13.74L15.34,12.84l-1.78,12.21Z"/>
          <path class="cls-1" d="M39.37,36.04H.66c-.36,0-.66,.3-.66,.66s.3,.66,.66,.66H10.11v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-1.98h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h6.15v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-2.66c0-.35-.28-.63-.63-.63Z"/>
          <path class="cls-1" d="M3.39,0H.85C.5,0,.22,.28,.22,.63s.28,.63,.63,.68h1.78V7.03H.66C.3,7.03,0,7.33,0,7.69s.3,.66,.66,.66h1.98v6.15H.66C.3,14.51,0,14.8,0,15.16s.3,.66,.66,.66h1.98v5.71H.66C.3,21.54,0,21.83,0,22.2s.3,.66,.66,.66h1.98v5.71H.66C.3,28.57,0,28.87,0,29.23s.3,.66,.66,.66h1.98v9.26s.41,.63,.76,.63,.63-.28,.56-.63V.63c.07-.35-.21-.63-.56-.63Z"/>
          <circle class="cls-1" cx="14.95" cy="10.99" r="2.2"/>
          <circle class="cls-1" cx="12.75" cy="25.93" r="2.2"/>
          <circle class="cls-1" cx="29.45" cy="25.93" r="2.2"/>
        </svg>
        <svg id="hover_dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="variant == 'dark' && device == 'DESKTOP'">
          <defs>
          </defs>
          <path class="cls-1" d="M31.48,26.81H11.53l2.59-17.74L31.48,26.81Zm-17.92-1.76h13.74L15.34,12.84l-1.78,12.21Z"/>
          <path class="cls-1" d="M39.37,36.04H.66c-.36,0-.66,.3-.66,.66s.3,.66,.66,.66H10.11v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-1.98h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h6.15v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-2.66c0-.35-.28-.63-.63-.63Z"/>
          <path class="cls-1" d="M3.39,0H.85C.5,0,.22,.28,.22,.63s.28,.63,.63,.68h1.78V7.03H.66C.3,7.03,0,7.33,0,7.69s.3,.66,.66,.66h1.98v6.15H.66C.3,14.51,0,14.8,0,15.16s.3,.66,.66,.66h1.98v5.71H.66C.3,21.54,0,21.83,0,22.2s.3,.66,.66,.66h1.98v5.71H.66C.3,28.57,0,28.87,0,29.23s.3,.66,.66,.66h1.98v9.26s.41,.63,.76,.63,.63-.28,.56-.63V.63c.07-.35-.21-.63-.56-.63Z"/>
          <circle class="cls-1" cx="14.95" cy="10.99" r="2.2"/>
          <circle class="cls-1" cx="12.75" cy="25.93" r="2.2"/>
          <circle class="cls-1" cx="29.45" cy="25.93" r="2.2"/>
        </svg>
        <svg id="mobile" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" v-if="device == 'MOBILE'">
          <defs>
          </defs>
          <path class="cls-1" d="M31.48,26.81H11.53l2.59-17.74L31.48,26.81Zm-17.92-1.76h13.74L15.34,12.84l-1.78,12.21Z"/>
          <path class="cls-1" d="M39.37,36.04H.66c-.36,0-.66,.3-.66,.66s.3,.66,.66,.66H10.11v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-1.98h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h6.15v1.9c0,.35,.3,.63,.66,.63s.66-.28,.66-.63v-1.9h5.71v1.98c0,.36,.3,.66,.66,.66s.66-.3,.66-.66v-2.66c0-.35-.28-.63-.63-.63Z"/>
          <path class="cls-1" d="M3.39,0H.85C.5,0,.22,.28,.22,.63s.28,.63,.63,.68h1.78V7.03H.66C.3,7.03,0,7.33,0,7.69s.3,.66,.66,.66h1.98v6.15H.66C.3,14.51,0,14.8,0,15.16s.3,.66,.66,.66h1.98v5.71H.66C.3,21.54,0,21.83,0,22.2s.3,.66,.66,.66h1.98v5.71H.66C.3,28.57,0,28.87,0,29.23s.3,.66,.66,.66h1.98v9.26s.41,.63,.76,.63,.63-.28,.56-.63V.63c.07-.35-.21-.63-.56-.63Z"/>
          <circle class="cls-1" cx="14.95" cy="10.99" r="2.2"/>
          <circle class="cls-1" cx="12.75" cy="25.93" r="2.2"/>
          <circle class="cls-1" cx="29.45" cy="25.93" r="2.2"/>
        </svg>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>

#mobile {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#light {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#dark {
  .cls-1 {
    fill: #f9f9f9;
  }
}

#hover {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}

#hover_dark {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}


.dp-menu-item:hover, .dp-menu-item.active {
  #hover, #hover_dark {
    display: block;
  }
  #light, #dark {
    display: none;
  }
}
</style>
