import store from '@/store';
import mixedModeHelper from './mixedModeHelper';

const MixedModeDataWatcher = {
  isRunning: false,
  unwatchers: [],
  
  init() {
    this.watchSelectedResultState();
    this.watchPeriodState();
    this.watchReportState();
    this.watchModeState();

    this.RunMixedModeResults();
  },

  watchSelectedResultState() {
    const unwatch = store.watch(
      (state) => state.daResult.selectedResult,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.RunMixedModeResults();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchPeriodState() {
    const unwatch = store.watch(
      (state) => state.mixedModePeriod.selectedPeriod,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.RunMixedModeResults();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchReportState() {
    const unwatch = store.watch(
      (state) => state.mixedModePeriod.selectedReport,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.RunMixedModeResults();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchModeState() {
    const unwatch = store.watch(
      (state) => state.mixedModePeriod.selectedMode,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.RunMixedModeResults();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  cleanup() {
    this.unwatchers.forEach(unwatch => unwatch());
    this.unwatchers = [];
    
    this.isRunning = false;
  },

  async RunMixedModeResults() {
    /* eslint-disable */
    try {
      const result = store.state.daResult.selectedResult;
      const period = store.state.mixedModePeriod.selectedPeriod;
      const mode = store.state.mixedModePeriod.selectedMode;
      const report = store.state.mixedModePeriod.selectedReport;
      if (!(result.id && period)) {
        return;
      }
      
      const dataVersion = store.state.daResult.selectedResult.dataVersion;

      if (this.isRunning) {
        return;
      }
      this.isRunning = true;

      const mixedModeResults = await store.dispatch('mixedModeResult/fetchMixedModeResults', result.id);
      
      const findResult = this.GetCurrentResult(mixedModeResults, period, report);
      if (!findResult) {
        this.isRunning = false;
        store.commit('mixedModeResult/setUiResults', []);
        return;
      }
      const solvedResults = mode === 'Two Way' ? mixedModeHelper.MixedModeTwoWayConvert(findResult.twoWayResult, 50) :
                                                mixedModeHelper.MixedModeThreeWayConvert(findResult.threeWayResult, 50);
      
      if (report === 'Ancient') {
        const listOfSourceData = solvedResults.flatMap(item => item.pops.map(pop => pop.name));
        const uiDetailDataResult = await store.dispatch('ancientProxResult/fetchAncientProxDetails', { listOfSourceData, dataVersion });
        if (!uiDetailDataResult) {
          this.isRunning = false;
          return;
        }
  
        solvedResults.forEach(item => {
          item.pops.forEach(resultItem => {
            resultItem.isAncient = true;
            resultItem.tname = resultItem.name.replaceAll('*', ' ');
    
            const detailItem = uiDetailDataResult.find(x => x.sourceCode === resultItem.name);
            if (detailItem) {
              resultItem.id = detailItem.id;
              resultItem.logoUrl = detailItem.logoUrl;
              resultItem.sourceCode = detailItem.sourceCode;
            }
          });
        });
      }

      store.commit('mixedModeResult/setUiResults', solvedResults);
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }

    this.isRunning = false;
  },

  GetCurrentResult(mixedModeResults, period, report) {
    let result = null;
    if (report === 'Ancient') {
      result = mixedModeResults.find(x => x.mixedModePeriodId === period.id)
    } else {
      const modernPeriod = store.state.mixedModePeriod.selectedModernPeriod;
      result = mixedModeResults.find(x => x.mixedModePeriodId === modernPeriod.id)
    }

    store.state.mixedModeResult.currentResult = result;
    return result;
  },
};

export default MixedModeDataWatcher;