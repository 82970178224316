<template>
  <div class="sd-container">
    <div class="sample-database" v-if="$store.state.appConfig.windowWidth >= 1079">
      <div class="result-container">
        <SampleDatabaseSidebar />
      </div>
      <div class="samples-container cs-scroll-style" :style="{'justfiy-content' : getOpenMap ? 'none' : 'center', 'padding' : getOpenMap ? '0rem' : '3rem 4rem' }">
        <div class="sample-list" v-if="!getOpenMap">
          <AncientSampleList v-if="selectedSampleType === 'ANCIENT'"/>
          <ModernSampleList v-if="selectedSampleType === 'MODERN'"/>
          <AncientIndividualSampleList v-if="selectedSampleType === 'ANCIENT-INDIVIDUAL'" />
        </div>
        <div class="individual-map" v-else>
          <AncientIndividualSampleList v-if="selectedSampleType === 'ANCIENT-INDIVIDUAL'" />
        </div>
      </div>
    </div>

    <div class="sample-database-mobile" v-else>
      <SampleDatabaseSidebar class="mobile-header"/>
      <AncientSampleList v-if="selectedSampleType === 'ANCIENT'"/>
      <ModernSampleList v-if="selectedSampleType === 'MODERN'"/>
      <AncientIndividualSampleList v-if="selectedSampleType === 'ANCIENT-INDIVIDUAL'" />
    </div>
  </div>
</template>

<script>
import SampleDatabaseWatcher from './services/SampleDatabaseWatcher';
import SampleDatabaseCalculatorWatcher from './services/SampleDatabaseCalculatorWatcher';

import SampleDatabaseSidebar from './sidebar/SampleDatabaseSidebar.vue';
import AncientSampleList from './samples-list/ancient/AncientSampleList.vue';
import ModernSampleList from './samples-list/modern/ModernSampleList.vue';
import AncientIndividualSampleList from './samples-list/ancient-individual/AncientIndividualSampleList.vue';

export default {
  components: {
    SampleDatabaseSidebar,
    AncientSampleList,
    ModernSampleList,
    AncientIndividualSampleList,
  },
  data() {
    return {
    };
  },
  computed: {
    selectedSampleType() {
      return this.$store.state.sampleDatabase.selectedSampleType
    },
    getOpenMap() {
      return this.$store.state.ancientIndividualDatabase.openMap;
    }
  },
  methods: {
  },
  mounted() {
    SampleDatabaseWatcher.init()
    SampleDatabaseCalculatorWatcher.init()
  },
  beforeDestroy() {
    SampleDatabaseWatcher.cleanup();
    SampleDatabaseCalculatorWatcher.cleanup();
  },
};
</script>

<style lang="scss" scoped>
.sd-container {
  height: calc(100vh - 220px)!important;
  overflow: hidden;
}


@media screen and (max-width: 1079px) {
  .sd-container {
    height: calc(100vh - 60px)!important;
    overflow: auto;
  }
}

.sample-database {
  width: 100%;
  height: 100%;
  display: flex;
  
  .result-container {
    z-index: 1;
    display: inline-block;
    position: relative;
    width: 20%;
  }
  .samples-container {
    display: flex;
    width: 70%;
    padding: 3rem 1rem;
    flex-grow: 1;
    justify-content: center;
    position: relative;
    overflow: auto;
    overflow-x: hidden;
  }
  .sample-list {
    width: 100%;
  }
  .individual-map {
    width:100%;
  }
}
.sample-database-mobile {
  .mobile-header {
    margin-top:60px!important;
  }
}

</style>