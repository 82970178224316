/* eslint-disable */
import ApiService from '@/common/api.service';

const getDefaultState = () => {
  return {
    originalResults: null,
    groupedResults: null,
    uiResults: null,
    geoJson: [],

    savedUiDetails: {},
    showAllEra: false,
    popLimit: 0,

    isOpenedPeriodicalDetail: false,
    openedPeriodicalDetail: { id: 0, },
    openedPeriodicalDetails: {},
  }
}

const state = getDefaultState()

const getters = {
  getOriginalResults(state) {
    return state.originalResults;
  },
  getGroupedResults(state) {
    return state.groupedResults;
  },
  getUiResults(state) {
    return state.uiResults;
  },

  getGeoJson(state) {
    return state.geoJson;
  },
}

const mutations = {
  setOriginalResults(state, data) {
    state.originalResults = data;
  },
  setGroupedResults(state, data) {
    state.groupedResults = data;
  },
  setUiResults(state, data) {
    state.uiResults = data;
  },

  setGeoJson(state, data) {
    state.geoJson = data;
  },
}

const actions = {
  async fetchSupervisedPeriodDetails(context, { groupedSourceCodes, dataVersion}) {
    try {
      if (!groupedSourceCodes) {
        return;
      }
      const saveDetail = context.state.savedUiDetails[groupedSourceCodes.join(',')]
      if (saveDetail) {
        return saveDetail;
      } else {
        const response = await ApiService.post('data/SupervisedPeriodDetail', { listOfSourceData: groupedSourceCodes, dataVersion }, this);
        if (response.statusCode === 200) {
          context.state.savedUiDetails[groupedSourceCodes.join(',')] = response.result;
          return response.result;
        } else {
          throw error;
        }
      }
      
    } catch (error) {
      throw error;
    }
  },

  async fetchSupervisedPeriodDetail(context, id) {
    try {
      const findDetail = context.state.openedPeriodicalDetails[id];
      if (findDetail) {
        context.state.openedPeriodicalDetail = findDetail;
        return findDetail;
      }

      const response = await ApiService.get(`data/SupervisedPeriodDetail/${id}`, {}, this);
      if (response.statusCode === 200) {
        context.state.openedPeriodicalDetail = response.result;
        context.state.openedPeriodicalDetails[id] = response.result;
        return response.result;
      } else {
        throw error;
      }
      
    } catch (error) {
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
