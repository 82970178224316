<template>
  <div class="mobile-dashboard-store mt-4">
    <div class="dashboard-card-title">
      <h3>Store</h3>
    </div>
    
    <div class="">
      <div class="store-item dashboard-card" @click="$router.push(`/checkout/da/${DEEP_ANCESTRY_CHECKOUT_STEPS.UPLOAD}/${PRODUCT_CODES.UPLOAD_DA_RAW_DATA}`)">
        <div class="icon">
          <DeepAncestryIcon class="deep-ancestry-image" />
        </div>
        <div class="title g25-text">DEEP ANCESTRY</div>
        <div class="store-item-left">
          <div class="price g25-text">
            26.99€
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeepAncestryIcon from '@/layouts/components/icons/DeepAncestryIcon.vue'
import { DEEP_ANCESTRY_CHECKOUT_STEPS, TRAIT_CHECKOUT_STEPS, PRODUCT_CODES } from '@/common/config';

export default {
  components: {
    DeepAncestryIcon
  },
  data() {
    return {
      PRODUCT_CODES,
      DEEP_ANCESTRY_CHECKOUT_STEPS,
      TRAIT_CHECKOUT_STEPS,
    }
  },
  computed: {
    theme() {
      return this.$store.state.appConfig.layout.skin === 'light' ? 'normal' : 'active';
    },
  },
  created() {

  },
}
</script>

<style scoped lang="scss">
.mobile-dashboard-store {
  position: relative;
}
.mobile-dashboard-store h3 {
  color: var(--lab-black);
  font-weight: 600;
}

.store-item {
  position: relative;
  margin-bottom: 15px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
}

.store-item .icon img {
  width: 50px !important;
  height: 50px !important;
}

.store-item .title {
  font-weight: 700;
  font-size: 1rem;
  margin-left: 15px;
  color: var(--lab-text-color-title-01);
}

.store-item .store-item-left {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.store-item .store-item-left .buttons-group {
  margin-right: 20px;
}

.store-item .store-item-left .price {
  font-weight: bold;
  font-size: 1rem;
}

.dark-layout {
  .mobile-dashboard-store h3 {
    color: var(--lab-main-color);
  }
}

.deep-ancestry-image {
  fill: #fff!important;
  height: 50px;
  width: 50px;
}
</style>
