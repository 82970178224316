<template>
  <!-- select 2 demo -->
  <div class="periadical-detail-modal">
    <b-modal
      id="modal-center"
      ref="periodical-breakdown-pop-detail-modal"
      hide-footer
      hide-header
      scrollable
      centered
      @hidden="hiddenModal"
      size="lg"
    >
     <div class="modal-close-container">
      <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
     </div>
     <div class="info-modal-container pop-detail-modal-container" v-if="dataSample">
        <div class="row col-sm-12 mb-1">
          <h5>{{ dataSample.title }}</h5>
        </div>
        <div class="row content-area cs-scroll-style">
          <div class="col-12 col-sm-12 col-md-5">
            <b-media-aside class="proximity-modal-avatar-container">
              <b-avatar
                rounded
                size="270"
                :src="dataSample.pictureUrl"
              />
            </b-media-aside>
          </div>
          <div class="col-12 col-sm-12 col-md-7 pop-detail-modal-right cs-scroll-style">
            <div class="proximity-container-item">
              <h5>{{ 'Sub-population title' }}</h5>
              <hr/>
              <div class="pop-view">
                <div class="sub-pop-view-item" v-for="regionItem in subResults" :key="regionItem.resultName">
                  <h6>{{ regionItem.resultSubTitle.replaceAll('*', ' ') }}</h6>
                  <div class="d-flex align-items-center lab-p-8">
                    <span class="mr-75 percent-txt" :style="{'color':dataSample.color}"><b>{{ regionItem.currentResult.toFixed(1) }}%</b></span>
                    <vue-apex-charts
                      type="radialBar"
                      height="40"
                      width="40"
                      :options="onGetChartOption(dataSample.color)"
                      :series="[regionItem.currentResult.toFixed(1)]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="proximity-container-item">
              <h5>{{ 'Description' }}</h5>
              <hr/>
              <div>
                <p class="description" v-html="dataSample.description"></p>
              </div>
            </div>
            <div class="proximity-container-item">
              <h5>{{ 'Reference Samples' }}</h5>
              <hr/>
              <div>
                <p class="description" v-html="dataSample.referenceSamples"></p>
              </div>
            </div>
            <div class="proximity-container-item">
              <h5>{{ 'Research Links' }}</h5>
              <hr/>
              <div>
                <p v-html="dataSample.researchLinks"></p>
              </div>
            </div>
          </div>
        </div>
     </div>
     <div class="model-info-bottom">
      <div></div>
     </div>
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    CloseModalIcon,
    VueApexCharts
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {
          
        }
      },
    },
  },
  data() {
    return {
      subResults: [],
    }
  },
  methods: {
    async initialModal() {
      this.$refs['periodical-breakdown-pop-detail-modal'].show();
      await this.$store.dispatch('periodicalResult/fetchSupervisedPeriodDetail', this.modalObject.item.id);
      this.fetchDetail();
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['periodical-breakdown-pop-detail-modal'].hide();
    },

    /* eslint-disable */
    fetchDetail() {
      const originalResults = this.$store.getters['periodicalResult/getOriginalResults'];
      if (originalResults.outPuts) {
        let filterResults = originalResults.outPuts.filter(x => x.resultsTable.includes(this.dataSample.sourceCode));
        filterResults = filterResults.map(item => {
          if (item.resultsTable.includes(':')) {
            item.resultSubTitle = item.resultsTable.split(':')[1];
          } else {
            item.resultSubTitle = item.resultsTable;
          }
          return item;
        });

        this.subResults = JSON.parse(JSON.stringify(filterResults)).reduce((list, item) => {
          let findItem = list.find(x => x.resultSubTitle === item.resultSubTitle);
          if (findItem) {
            findItem.currentResult += item.currentResult;
          } else {
            list.push(item);
          }
          return list;
        }, []);
      }
    },
    onGetChartOption(color) {
      const barColor = color ? color : '#00ADB5';
      return {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [barColor],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: this.ddAlpha(color, 0.1),
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      };
    },
    ddAlpha(color, opacity) {
      let _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
  },
  computed: {
    dataSample() {
      return this.$store.state.periodicalResult.openedPeriodicalDetail;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  background: #ccc !important;
}
.pop-detail-modal-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}
.pop-detail-modal-body {
  display: flex;
  width: 100%;
}
.pop-detail-modal-left {
  width: 40%;
}
.pop-detail-modal-right {
  // width: 60%;
  max-height: 400px;
  overflow: auto;
}
.sub-pop-view-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem;
}

.proximity-container-item p {
  font-size: 14px!important;
}

@media screen and (max-width: 1079px) {
  .pop-detail-modal-container {
    max-height: 70vh;
    overflow-x: hidden;
  }
  .pop-detail-modal-right {
    height: auto;
    overflow:hidden;
    max-height: none;
  }
  .content-area {
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.modal-close-container {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 99;
  background: var(--bg-card-color);
}
</style>
