import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService'
import store from '@/store';

const diyGDCalculateService = {
  /* eslint-disable */
  async runCalculate() {
    try {
      CalculateFMCService.dataObject.maxDistOutPut = 50;

      const diyGDSourceData = store.state.diyGDSourceData;
      diyGDSourceData.calculatedResults = [];
      diyGDSourceData.targetSelectedList.forEach(targetItem => {
        this.setTarget(targetItem.coordinate);

        this.setSourceCodeArray(diyGDSourceData.souceDataList['MODERN_POP']);
        const calculatedModernResult = CalculateFMCService.calculateDistance(0);

        this.setSourceCodeArray(diyGDSourceData.souceDataList['ANCIENT_POP']);
        const calculatedAncientResult = CalculateFMCService.calculateDistance(0);

        diyGDSourceData.calculatedResults.push({
          name: targetItem.name,
          calculatedResults: calculatedModernResult,
          type: 'MODERN_POP'
        })

        diyGDSourceData.calculatedResults.push({
          name: targetItem.name,
          calculatedResults: calculatedAncientResult,
          type: 'ANCIENT_POP'
        })
      });
    }
    catch {}
  },

  setTarget(coordinate) {
    const tagetResolveSource = CalculateFMCService.codeDataToArray(coordinate);
    if (tagetResolveSource.isSuccess) {
      CalculateFMCService.onSetTarget(tagetResolveSource.result);
    }
  },
  setSourceCodeArray(sourceData) {
    CalculateFMCService.onSetSource(sourceData);
  },
};

export default diyGDCalculateService;
