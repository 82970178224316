/* eslint-disable */
const getDefaultState = () => {
  return {
    modernDatabaseList: [],

    sampleDetail: null,
    isModernDistance: true,
  }
}

const state = getDefaultState()

const getters = {
  getModernDatabaseList(state) {
    return state.modernDatabaseList;
  },
}

const mutations = {
  setModernDatabaseList(state, data) {
    state.modernDatabaseList = data;
  },
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
