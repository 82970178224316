/* eslint-disable */
import ApiService from '@/common/api.service';

const getDefaultState = () => {
  return {
    savedSourceDatas: {},
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
}

const actions = {
  async fetchPeriodSourceData(context, sourceDataRequest) {
    try {
      const key = `${sourceDataRequest.regionId}_${sourceDataRequest.periodId}_${sourceDataRequest.resultId}`;
      const saveDetail = context.state.savedSourceDatas[key]
      if (saveDetail) {
        return saveDetail;
      } else {
        const response = await ApiService.get('data/SupervisedPeriodSourceData', sourceDataRequest, this);
        if (response.statusCode === 200) {
          context.state.savedSourceDatas[key] = response.result;
          return response.result;
        } else {
          throw error;
        }
      }
    } catch (error) {
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
