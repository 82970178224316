export default {
  // buraya sadece route'un name property'sini ekle
  menu: {
    PERIODICAL: 'periodical',
    HGFARMER: 'hgfarmer',
    UNSUPERVISEDANALYSIS: 'unsupervised-analysis',
    GENETICDISTANCES: 'genetic-distances',
    PCAPLOTS: 'pca-plots',
    DIYTOOLS: 'diy-tools',
    ENCYCLOPEDIA: 'sample-database',
    DOWNLOADCOORDINATES: 'download-coordinates'
  }
}