<template>
  <div id="single-origin-map">
    <l-map :options="{ attributionControl: false }"
      :zoom="mapOptions.zoom" :maxZoom="mapOptions.maxZoom" :minZoom="mapOptions.minZoom" :center="mapOptions.center">
      <l-tile-layer :url="mapStyleUrl" :zIndex="mapOptions.zIndex" :zoomOffset="-1" :detectRetina="false"></l-tile-layer>
      <l-circle-marker v-for="(circle, index) in circles" :key="index"
        :className="circle.id"
        :lat-lng="circle.center"
        :radius="circle.radius"
        :fillColor="circle.color"
        :color="'#000'"
        :fillOpacity="1"
        :weight="1"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
        @mousemove="mouseMove"
        @click="mouseEnter"
      />
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LCircleMarker } from "vue2-leaflet";

import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

export default {
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
  },
  props: {
  },
  data() {
    return {
      mapOptions: {
        zoom: 3,
        maxZoom: 9,
        minZoom: 2,
        zIndex: 1,
        center: [44.312223, 30.046882],
      },

      tipObj: null,
      circles: [],
      offset: {
        x: 5,
        y: -75,
      },
    }
  },
  methods: {
    mouseOver(event) {
      /* eslint-disable*/
      const target = event.target.options.className;
      const findModernPop = this.modernPopData.find(popValue => target === popValue.title + '' );
     
      this.tipObj = document.createElement("div");
      this.tipObj.style.background = "white";
      this.tipObj.style.color = "#393939";
      this.tipObj.style.borderRadius = "5px";
      this.tipObj.style.padding = "8px";
      this.tipObj.style.fontFamily = "Arial,Helvetica";
      this.tipObj.style.fontWeight = "600";
      this.tipObj.style.zIndex = "1000000000";
      if(findModernPop) {
        this.tipObj.innerHTML = 'Population: <span style="font-weight: 400">' + findModernPop.title.replaceAll('*', ' ') + '</span>' +
                                '<br/>Genetic Distance: <span style="font-weight: 400; color:'+ findModernPop.color +'">' + findModernPop.distance + '</span>';
                                
      }

      //position it
      this.tipObj.style.position = "fixed";
      this.tipObj.style.top = event.originalEvent.clientY + this.offset.y + "px";
      this.tipObj.style.left = event.originalEvent.clientX + this.offset.x + "px";

      //add it to the body
      document.body.appendChild(this.tipObj);
      
      event.target.setStyle({ weight: 2 });
    },
    mouseOut(event) {
      event.target.setStyle({ weight: 0.5 });
      if (this.tipObj) {
        //delete the tooltip if it exists in the DOM
        document.body.removeChild(this.tipObj);
        this.tipObj = null;
      }
    },
    mouseMove(event) {
      if (this.tipObj && event) {
        this.tipObj.style.top = event.originalEvent.clientY + this.offset.y + "px";
        this.tipObj.style.left = event.originalEvent.clientX + this.offset.x + "px";
      }
    },
    mouseEnter(event) {
      const target = event.target.options.className;
      const findAncientPop = this.modernPopData.find(popValue => target === popValue.id + '' );

      this.modalObject.item.id = findAncientPop.id;
      this.modalObject.item.title = findAncientPop.title;
      this.modalObject.showModal = true;
    },

    onLoadCoordinates() {
      const tempCircles = [];
      this.modernPopData.forEach(item => {
        if (item.latitude && item.longitude) {
          tempCircles.push({
            id: item.sourceCode,
            center: this.offsetCoordinates(Number(item.longitude), Number(item.latitude), 0.001),
            radius: 6,
            color: item.color,
            opacity: this.calculateOpacity(item.distance),
          });
        }
      });
      this.circles = tempCircles.sort((a, b) => a.opacity - b.opacity);
    },

    calculateOpacity(distance) {
      const maxValue = Math.max(...this.modernPopData.map(o => o.distance));
     
      const opacity = 1 - (((distance * 100) / maxValue) / 100);
      return opacity;
    },
    offsetCoordinates(lng, lat, offset) {
      return [lng, lat];
    },
  },
  computed: {
    mapStyleUrl() {
      return this.skin === 'light' ? 'https://api.maptiler.com/maps/90f95836-b094-428a-8a4c-ddd6e0a1e144/{z}/{x}/{y}.jpg?key=3Xf2PvM2xLutU2kB81Cl'
      : 'https://api.maptiler.com/maps/e13515d6-a947-4835-b803-b271509d56be/{z}/{x}/{y}.jpg?key=3Xf2PvM2xLutU2kB81Cl';
    },
    skin() {
      return this.$store.state.appConfig.layout.skin;
    },
    modernPopData() {
      return this.$store.getters['modernProxResult/getUiResults'];
    },
  },
  watch: {
    modernPopData() {
      this.circles = [];
      this.onLoadCoordinates();
    },
  },
  mounted() {
    this.onLoadCoordinates();
  },
}

</script>

<style lang="scss" scoped>
#single-origin-map {
  height: 100vh !important;
  @media screen and (max-width: 1500px) {
    height: 100vh !important;
  }
  @media screen and (max-width: 1079px) {
    height: 260px !important;
  }
}
</style>
