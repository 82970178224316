<template>
  <svg id="layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.38 10">
    <path class="cls-1" data-name="Layer 2" d="M12.96,0l1.42,1.38L7.19,10,0,1.38,1.41,0,7.17,6.99,12.96,0Z"/>
  </svg>

</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
 .cls-1 {
  fill:#ddd;
 }

 .dark-layout {
  .cls-1 {
    fill:#1bbdcc;
  }
 }
</style>
