<template>
  <div class="lab-dropdown">
    <div class="user-dropdown" @click="isShowDropdownMenu = !isShowDropdownMenu" ref="dropdown" >
      <div class="lab-avatar">
        <b-img
          ref="previewEl"
          rounded
          :src="'https://image.illustrativedna.com/assets/icons/light/avatar.svg'"
        />
      </div>
      <div class="user-dropdown-body">
        <div class="lab-name">{{userData.fullName}}</div>
        <div class="lab-id">CID: {{userData.userName}}</div>
      </div>
      <div class="lab-user-dropdown-icon">
        <div class="points-container">
          <div class="point"></div>
          <div class="point"></div>
          <div class="point"></div>
        </div>
      </div>
    </div>
    <div class="lab-dropdown-content" :class="{ 'show': isShowDropdownMenu}">
      <div class="user-dropdown-menu">
        <div class="user-dropdown-menu-item" @click="$router.push({ name: 'profile-settings' })">
          <span class="icon">
            <ProfileSettingsIcon />
          </span>
          <span class="text">
            Settings
          </span>
        </div>
        <div class="user-dropdown-menu-item" @click="logout()">
          <span class="icon">
            <LogoutIcon />
          </span>
          <span class="text">
            Log out
          </span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ProfileSettingsIcon from '../icons/ProfileSettingsIcon.vue';
import LogoutIcon from '../icons/LogoutIcon.vue';

export default {
  components: {
    ProfileSettingsIcon,
    LogoutIcon,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isShowDropdownMenu: false,
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userData')
      this.$router.push({ name: 'login' })
    },
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdown;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isShowDropdownMenu = false;
        }
      } catch (error) {}
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  }
}
</script>

<style lang="scss" scoped>
.user-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user-dropdown .lab-user-dropdown-icon {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-dropdown .lab-avatar {
  height: 27px;
  width: 27px;
  border-radius: 10px;
  border: 2px solid var(--lab-white-80);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-dropdown .lab-avatar img {
  height: 18px;
  width: 18px;
}
.user-dropdown .user-dropdown-body {
  margin-right: 0;
  margin-left: 10px;
  width: calc(100% - 30px);
}
.user-dropdown .user-dropdown-body .lab-name {
  color: var(--lab-black);
  font-size: 0.77rem;
  font-weight: bold;
}

.points-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.point {
  width: 3px;
  height: 3px;
  background-color: var(--lab-white-100);
  border-radius: 50%; /* Make it round */
  margin: 2.5px 0; /* Adjust spacing between points */
}

.user-dropdown .user-dropdown-body .lab-id {
  font-size: 0.7rem;
  font-weight: bold;
}
.user-dropdown-menu {
  padding:12px 10px;
}

.user-dropdown-menu .user-dropdown-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 5px;
  border-radius: 5px;
  transition: var(--lab-trans-03);
}

.user-dropdown-menu .user-dropdown-menu-item:hover {
  background-color: var(--bg-card-hover-color);
}

.user-dropdown-menu svg {
  width: 18px;
  height: 18px;
}

.user-dropdown-menu .text {
  margin-left: 10px;
  color: var(--lab-black-65);
  font-weight: bold;
  font-size: 0.8rem;
}

.dark-layout {
  .user-dropdown .user-dropdown-body .lab-id {
    color: var(--lab-white-100);
  }
  .user-dropdown .user-dropdown-body .lab-name {
    color: var(--lab-white-100);
  }

  .user-dropdown-menu .text {
    color: var(--lab-white-100);
  }
  .user-dropdown-menu .cls-1,  .user-dropdown-menu .cls-2 {
    fill: var(--lab-white-100) !important;
  }
}

@media screen and (max-width: 1079px) {
  .user-dropdown .user-dropdown-body .lab-name {
    font-size: 0.9rem;
  }

  .user-dropdown .user-dropdown-body .lab-id {
    font-size: 0.9rem;
  }

  .user-dropdown .lab-avatar img {
    height: 20px;
    width: 20px;
  }

  .user-dropdown .lab-user-dropdown-icon {
    display: none;
  }

  .user-dropdown .lab-dropdown-content {
    display: none;
  }
}

</style>
