// export const API_URL = 'https://localhost:5001/api';
export const API_URL = 'https://api.illustrativedna.com/api';
// export const API_URL = 'https://apitest.illustrativedna.com/api';

export const GOOGLE_API_KEY = process.env.NODE_ENV === 'development' ? 'AIzaSyAtgBHdXZtVI3tSA2APEbloj441Bp3E35A' : 'AIzaSyCq0AU-7Vq6ggAEgfCSPIxHFQbSKseyuBk';

export const regionDefaultId = 1;
export const regionGrupDefaultId = 3;

export const PRODUCT_CODES = {
  UPLOAD_DA_COORDINATES: 'upload_coordinates',
  UPLOAD_DA_RAW_DATA: 'Upload_Raw_Data',
  UPLOAD_TRAIT_RAW_DATA: 'traits_health',
};

export const DEEP_ANCESTRY_CHECKOUT_STEPS = {
  SELECT_SERVICE: 'select-service',
  UPLOAD: 'upload',
  ACCOUNT: 'account',
  CONFIRM: 'confirm',
};

export const TRAIT_CHECKOUT_STEPS = {
  UPLOAD: 'upload',
  ACCOUNT: 'account',
  CONFIRM: 'confirm',
};
