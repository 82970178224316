<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.67 23.67">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1-2">
        <path id="Arrow_Top" data-name="Arrow Top" class="cls-1" d="M11.83,0C5.31,0,0,5.31,0,11.83s5.31,11.83,11.83,11.83,11.83-5.31,11.83-11.83S18.36,0,11.83,0Zm0,22.47c-5.87,0-10.64-4.76-10.64-10.64S5.96,1.2,11.83,1.2s10.64,4.76,10.64,10.64-4.76,10.64-10.64,10.64Zm.54-17.52c-.27-.32-.76-.36-1.08-.09-.03,.03-.06,.06-.09,.09l-5.61,6.59c-.27,.32-.23,.81,.09,1.08,.14,.12,.31,.18,.5,.18h3.19v4.97c0,.42,.34,.77,.77,.77,0,0,.01,0,.02,0l3.02-.08c.42,0,.75-.35,.75-.77v-4.88h3.42c.42,0,.77-.34,.77-.77,0-.18-.06-.36-.18-.5l-5.56-6.59Zm1.16,6.7c-.42,0-.77,.34-.77,.77v4.89l-2.24,.06v-4.95c0-.42-.34-.77-.77-.77h-2.74l4.76-5.59,4.72,5.59h-2.97Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
    className: {
      default: 'normal',
      type: String,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}

.cls-1 {
  fill: #fff;
}
</style>
