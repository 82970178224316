<template>
  <div class="result-list-container" :class="{ 'height-auto': selectedTab === 2 }">
    <div class="model-view-item">
      <div class="model-view-item-header d-flex align-items-center justify-content-space-between mb-3">
        <div id="menu-area" class="menu-content w-50">
          <ul class="menu-items">
          <li :key="tab.Id"  v-for="(tab, index) in tabs" class="menu-item-li"  :class="{ 'active':  tab.Id === selectedTab, 'passed': tab.Id <= selectedTab }">
              <a class="menu-link dp-menu-item" :key="index" 
              :class="{ 'active':  tab.Id === selectedTab, 'passed': tab.Id <= selectedTab }">{{ tab.name }}</a>
            </li>
          </ul>
        </div>
        <div class="nav-area justify-content-end">
          <div class="right-area d-flex justify-content-end" v-if="selectedTab === 1">
            <button class="lab-btn w-10" :disabled="checkLenTargetSelected()" @click="calculate()">Calculate</button>
          </div>
          <div class="left-area d-flex justify-content-end" v-if="selectedTab === 2">
            <button class="lab-btn w-10" @click="prevTab()">
              <feather-icon icon="ArrowLeftIcon" class="mr-05" size="20"/>
              Back</button>
          </div>
        </div>
      </div>
      
      <div class="model-view-item-content d-flex justify-content-space-between">
        <DiyGDToolsTargetView v-if="selectedTab === 1" />
        <DiyGDToolsResultsView v-if="selectedTab === 2" />
      </div>
    </div>
  </div>
</template>


<script>
import diyGDSourceDataService from '../services/genetic-distance/diyGDSourceDataService';
import diyGDCalculateService from '../services/genetic-distance/diyGDCalculateService';

import DiyGDToolsTargetView from './DiyGDToolsTargetView.vue';
import DiyGDToolsResultsView from './DiyGDToolsResultsView.vue';

export default {
  components: {
    DiyGDToolsTargetView,
    DiyGDToolsResultsView,
  },
  data() {
    return {
      tabs: [
        { name: '1. Target', Id: 1 },
        { name: '2. Results', Id: 2 },
      ],
    }
  },
  computed: {
    targetSelectedList() {
      return this.$store.state.diyGDSourceData.targetSelectedList;
    },
    selectedTab() {
      return this.$store.state.diyGDSourceData.selectedTab;
    },
  },
  methods: {
    nextTab() {
      this.$store.state.diyGDSourceData.selectedTab += 1;
    },
    prevTab() {
      this.$store.state.diyGDSourceData.selectedTab -= 1;
    },
    checkLenTargetSelected() {
      if (this.targetSelectedList.length > 0) {
        return false;
      }
      return true;
    },
    calculate() {
      diyGDCalculateService.runCalculate();
      this.$store.state.diyGDSourceData.selectedTab = 2;
    },
  },
  async mounted() {
    await diyGDSourceDataService.loadSourceDatas();
  }
}
</script>


<style lang="scss" scoped>
.progress-bar {
  transition : width 0.5s linear!important;
}
.height-auto {
    height: auto!important;
}
.result-list-container {
  padding: 28px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 140%;
  padding: 3rem 1rem;
  
  @media screen and (max-width: 1079px) {
    overflow-y: hidden;
    overflow-x: hidden !important;
    padding: 0;
    display: block;
    margin: 0!important;
  }

  .model-view-header {
    display: flex;
    flex-direction: row;
    width: 75%;
  }
  .model-latest-version {
    width: 50%;
  }
  .model-genetic-fit {
    width: 50%;
  }
  .text-end {
    float:right;
  }
  
  .model-view-item {
    width: 75%;
    background-color: var(--bg-card-color);
    border: var(--lab-card-border);
    box-shadow: var(--lab-card-shadow);
    border-radius: 10pt;
    margin-bottom: 15px;
    flex-direction: column;
    padding: 60px 40px;
    padding-bottom: 70px;

    @media screen and (max-width: 1079px) {
      width: 100%;
      padding: 10px 30px;
      border:none;
      border-radius: 0;
      box-shadow: none;
    }
  }

  .model-view-item-donut {
    width: 45%;
  }
  .model-view-item-header {
    width: 100%;
    justify-content: space-between;
  }
  .model-view-item-content {
    width: 100%;
    height: 90%;
  }
  .model-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .model-view-progress {
    width: 90%;
    margin-right: 10px;
  }
  .model-view-percentage {
    font-size: 0.9em;
    font-weight: 600;
  }
  .btn-outline-light {
    border: 1px solid #ddd !important;
    background-color: transparent;
  }
  .model-view-item-description {
    margin-top:30px;
    width: 80%;
    font-size: 0.8rem;
  }

  .model-view .model-view-item {
    height: 100%;
    display: flex;
    justify-content: space-between;
    transition: all 0.25s linear;
    }
    .model-item-row-container {
        width: 75%;
        display: flex;
        flex-direction: column;
    }
}
.menu-items {
  position: relative;
  list-style: none;
  display: flex;
  gap: 0px;
  margin: 0; 
  padding: 0;
  // border-bottom: 2pt solid var(--lab-hr-color);
  flex-direction: row;
  justify-content: left;

  @media screen and (max-width: 1079px) {
    gap: 0px;
  }

  .menu-item-li {
    flex: 0 0 auto;
    width: 30%;
    padding: 10px 0px;
    margin: 0 5px;
    color: white;
    text-align: left;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    @media screen and (max-width: 1079px) {
      width: 0%;
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .menu-link {
    position: relative;
    display: flex;
    align-items: center; 
    gap: 8px;
    margin-right: 10px;
    text-decoration: none;
    color: var(--lab-diy-tab-color);
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    display: block;
    &.active {
      color: var(--lab-diy-tab-passed-color);
    }
    &.passed {
      color: var(--lab-diy-tab-passed-color);
    }
    &:hover::after,
    &.active::after {
      width: 110%;
      left: -5%; 
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 0;
      height: 4px;
      // border-bottom: 2pt solid var(--lab-main-color);
      height: 14px;
      background-color: transparent; 
      transition: width 0.3s ease, opacity 0.3s ease;
      opacity: 0; 
    }

    @media screen and (max-width: 1079px) {
      display: none;
      font-size: 1.1rem;
      font-weight: 500;
      &.active {
        display: block;
      }
    }
  }
}

.nav-area {
  display: flex;
  width: 50%;
  flex-direction: row;
}
.left-area{
  width: 49%;
}
.right-area{
  width: 49%;
}
button[disabled] {
  background:#6e8081;
}
</style>