<template>
  <div class="top-header-container" :class="{'top-header-container-remove-radius' : contentPosition === -250}">
    <h4 class="report-title">
      Hunter-Gatherer and Farmer
      <span @click="hgFarmerInfoModal.showModal = true" class="open-info-modal-btn">
        <sup class="title-info-button-area">
            <InfoIcon />
        </sup>
      </span>
    </h4>
    <hr />
    <p class="description">
      The Hunter-Gatherer and Farmer report breaks down the distinct genetic origins that emerged as ancient populations transitioned from hunting and gathering to farming.
    </p>

    <hgFarmerInfoModal v-if="hgFarmerInfoModal.showModal" :modalObject="hgFarmerInfoModal"/>
  </div>
</template>

<script>
import MobileHeaderIcon from '@/layouts/icons/MobileHeaderIcon.vue';
import InfoIcon from '@/layouts/icons/InfoIcon.vue';
import HgFarmerInfoModal from './modals/HgFarmerInfoModal.vue';

export default {
  components: {
    InfoIcon,
    HgFarmerInfoModal,
  },
  props: {
    contentPosition: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      hgFarmerInfoModal: {
        showModal: false,
      },
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.top-header-container {
  padding: 28px;
  background-color: var(--bg-card-color);
}

.top-header-container-remove-radius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

@media screen and (max-width: 1079px) {
  .top-header-container {
    border-top-left-radius: 20px!important;
    border-top-right-radius: 20px!important;
  }
}

.open-select-region {
  cursor: pointer;
  text-decoration: underline;
  color: var(--lab-main-color);
}
</style>