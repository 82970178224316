import { render, staticRenderFns } from "./HgFarmerTopHeader.vue?vue&type=template&id=2107ff99&scoped=true&"
import script from "./HgFarmerTopHeader.vue?vue&type=script&lang=js&"
export * from "./HgFarmerTopHeader.vue?vue&type=script&lang=js&"
import style0 from "./HgFarmerTopHeader.vue?vue&type=style&index=0&id=2107ff99&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2107ff99",
  null
  
)

export default component.exports