<template>
  <div>
    <alert :message="warningMessage" variant="warning"/>
    
    <div class="payment-container">
      <div class="cs-payment-card mb-1" v-show="paymentStatus.isComplete === false">
        <h4>Payment</h4>
        <h4>Cost: <span class="cost">€{{subtotal}}</span></h4>
      </div>

      <div id="card-element">
      </div>

      <div class="mt-2" v-show="canPayWithWallet">
        <div id="google-pay-element"></div>
      </div>

      <div v-show="paymentStatus.isComplete">
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">{{paymentStatus.message}}</span>
          </div>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    onClickFinish: {
      type: Function,
      default() {
        return {}
      },
    },
    checkoutModule: {
      type: String,
      default: 'g25PartnerCheckout',
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stripeAPIToken: 'pk_live_51JShLKDcr8M4ba7V6MfiWHQWBLjrYJFPIf0Hae8GVS96IrLPcTkwpTTDTYmLor69KmLT1PoTfmFhKCGhpF0cQQb000dzANwogq',
      // stripeAPIToken: 'pk_test_51JShLKDcr8M4ba7VlnhOxxU3IPxsBkjfSWFxONvbYPpmKrQ5zJL0V7LV6ybQAptDWqXoHDV1XFnZvMpFFORWL9EP00hsOXv2cR',
      stripe: null,
      elements: '',
      card: '',
      walletPaymentRequest: null,
      clientSecret: null,
      paymentStatus: {
        isComplete: false,
        message: '',
      },
      isContinuePaying: false,
      canPayWithWallet: false,

      warningMessage: null,
    }
  },
  methods: {
    includeStripe( URL, callback ){
      let documentTag = document, tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    configureStripe(){
      this.stripe = Stripe(this.stripeAPIToken);

      this.elements = this.stripe.elements();

      this.card = this.elements.create('card',{ style:{
          base: {
            color: '#303238',
            fontSize: '16px',
            fontFamily: '"Open Sans", sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#CFD7DF',
            },
          },
          invalid: {
            color: '#e5424d',
            ':focus': {
              color: '#303238',
            },
          },
        }
      });
      this.card.mount('#card-element');
    },

    createPaymentIntent(callback) {
      this.warningMessage = null;

      const orderData = this.$store.getters[`${this.checkoutModule}/getCreateOrderData`];
      const subTotal = this.$store.getters[`${this.checkoutModule}/getOrderSubTotal`];
      const paymentQuery = {
        currency: 'EUR',
        paymentAmount: subTotal.toString().replace(',', '.'),
        promotionDetailGuid: orderData.promotionDetailGuid,
      };
      this.$store.dispatch(`${this.checkoutModule}/createPaymentIntent`, paymentQuery)
        .then(response => {
          if (response.statusCode === 200) {
            this.clientSecret = response.result.clientSecret;
            this.$store.commit(`${this.checkoutModule}/setCreateOrderData`, { key: 'paymentGuid', value: response.result.paymentGuid });
            callback();
          } else {
            this.warningMessage = response.message;
          }
        });
    },

    payWithCard(callback) {
      this.warningMessage = null;

      this.isContinuePaying = true;
      return this.createPaymentIntent(function() {
        this.stripe
          .confirmCardPayment(this.clientSecret, {
            payment_method: {
              card: this.card,
            }
          })
          .then(function(result) {
            if (typeof callback === 'function') {
              callback(result);
            }

            // if (result.error) {
            //   this.warningMessage = result.error.message;
            // } else {
            //   if (result.paymentIntent.status === 'succeeded') {
            //     this.paymentStatus.isComplete = true;
            //     this.paymentStatus.message = 'Thank You! Payment Received Successfully. Now your order has started to be created.';
            //     this.onClickFinish();
            //     setTimeout(() => {
            //       this.$store.commit('deepACheckout/setCreateOrderData', { key: 'isFinishedPayment', value: true });
            //     }, 1000);
            //   }
            // }
            this.isContinuePaying = false;
          }.bind(this));
      }.bind(this));
    },

    payWithGoogleWallet() {
      this.warningMessage = null;

      const subTotal = this.$store.getters[`${this.checkoutModule}/getOrderSubTotal`];
      const amount = parseFloat(subTotal.replace(',', ''));
      this.walletPaymentRequest = this.stripe.paymentRequest({
        currency: 'eur',
        country: 'EE',
        requestPayerName: true,
        requestPayerEmail: true,
        total: {
          amount: amount,
          label: "Total"
        }
      });
      
      const prButton = this.elements.create('paymentRequestButton', {
        paymentRequest: this.walletPaymentRequest,
      });

      this.walletPaymentRequest.canMakePayment().then((result) => {
        if (result) {
          prButton.mount("#google-pay-element");
          this.canPayWithWallet = true;
        } else {
          this.canPayWithWallet = false;
        }
      });

      this.walletPaymentRequest.on('paymentmethod', async (e) => {
        this.createPaymentIntent(function() {
          this.stripe.confirmCardPayment(this.clientSecret, {
              payment_method: e.paymentMethod.id,
            })
            .then(function(result) {
              if (result.error) {
                this.warningMessage = result.error.message;
              } else {
                // The payment succeeded!
                if (result.paymentIntent.status === 'succeeded') {
                  this.paymentStatus.isComplete = true;
                  this.paymentStatus.message = 'Thank You! Payment Received Successfully. Now your order has started to be created.';
                  this.onClickFinish();
                  setTimeout(() => {
                    this.$store.commit(`${this.checkoutModule}/setCreateOrderData`, { key: 'isFinishedPayment', value: true });
                  }, 1000);
                }
              }
              this.isContinuePaying = false;
            }.bind(this));
        }.bind(this));
      });
    },
  },
  computed: {
    subtotal() {
      const _subTotal = this.$store.getters[`${this.checkoutModule}/getOrderSubTotal`];
      if (this.walletPaymentRequest) {
        if (this.walletPaymentRequest.isShowing()) {
          this.walletPaymentRequest.update({
            total: {
              label: 'Total',
              amount: parseFloat(_subTotal.replace(',', '')),
            },
          });
        }
      }

      return _subTotal;
    },
  },
  mounted(){
    this.includeStripe('js.stripe.com/v3/', function(){
      this.configureStripe();
      // this.payWithGoogleWallet();
    }.bind(this));
  },
}
</script>

<style lang="scss" scoped>
.payment-container {
  padding: 0.2rem 1.5rem;

  .cs-payment-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cost {
      color:  var(--lab-minted-elegance-color);
    }
  }
}

/* Style input fields */
.pmpro_checkout-fields input[type="text"],
.pmpro_checkout-fields input[type="password"],
.pmpro_checkout-fields input[type="number"],
.pmpro_checkout-fields input[type="email"] {
  border-radius: 3px !important;
}

/* Base Stripe element styling*/
#card-element {
	background-color: white;
	padding: 15px;
	max-width: 100%;
	height: 50px;
	border: 1px solid #ececec;
  border-radius: 3px !important;
}

/* Set credit card number input field width */
form.pmpro_form #pmpro_payment_information_fields div#AccountNumber {
	max-width: 300px;
}

.StripeElement--empty {
	background-color: #fff !important;
}

/* Set expiry date input field width */
form.pmpro_form #pmpro_payment_information_fields div#Expiry {
	max-width: 100px;
}

/* Set CVC/CVV input field width */
form.pmpro_form #pmpro_payment_information_fields div#CVV {
	max-width: 80px;
}

/* Remove float applied by theme or custom CSS */
form.pmpro_form  #pmpro_payment_information_fields label{
  float: unset !important;
}
</style>
