<template>
  <div class="diy-tools-container">
    <div class="diy-tools" v-if="$store.state.appConfig.windowWidth >= 1079">
      <div class="result-container">
        <DiyToolsSidebar />
      </div>
      <div class="diy-container cs-scroll-style">
        <DiyToolsContentView v-if="$store.state.diy.selectedMode === 'ADMIXTURE'" />
        <DiyGDToolsContentView v-else />
      </div>
    </div>

    <div class="diy-tools-mobile" v-else>
      <DiyToolsSidebar />
      <template v-if="$store.state.diy.selectedMode === 'ADMIXTURE'">
        <MobileDiyToolsHeaderView />
        <MobileDiyToolsContentView />
      </template>
      <DiyGDToolsContentView v-else />
    </div>
  </div>
</template>

<script>
import DiyToolsSidebar from './DiyToolsSidebar.vue';
import DiyToolsContentView from './details/DiyToolsContentView.vue';
import MobileDiyToolsHeaderView from './details/MobileDiyToolsHeaderView.vue';
import MobileDiyToolsContentView from './details/MobileDiyToolsContentView.vue';
import DiyGDToolsContentView from './details-genetic-distance/DiyGDToolsContentView.vue';

export default {
  components: {
    DiyToolsSidebar,
    DiyToolsContentView,
    MobileDiyToolsHeaderView,
    MobileDiyToolsContentView,
    DiyGDToolsContentView,
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.diy-tools-container {
  height: calc(100vh - 220px)!important;
  overflow:hidden;
}

@media screen and (max-width: 1079px) {
  .diy-tools-container {
    height: calc(100vh - 60px)!important;
    overflow:hidden;
    overflow-y:auto;
  }
}

.diy-tools {
  width: 100%;
  height: 100%;
  display: flex;

  .result-container {
    z-index: 1;
    display: inline-block;
    position: relative;
    width: 20%;
  }
  .diy-container {
    width: 80%;
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
}

.diy-tools-mobile {
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  margin-top:60px;
}
</style>