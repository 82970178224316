import { render, staticRenderFns } from "./GeneticSimilaritiesModernPopView.vue?vue&type=template&id=5a330946&scoped=true&"
import script from "./GeneticSimilaritiesModernPopView.vue?vue&type=script&lang=js&"
export * from "./GeneticSimilaritiesModernPopView.vue?vue&type=script&lang=js&"
import style0 from "./GeneticSimilaritiesModernPopView.vue?vue&type=style&index=0&id=5a330946&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a330946",
  null
  
)

export default component.exports