<template>
    <div class="diy-tools-sidebar">
      <div class="diy-header-area">
        <h4 class="report-title">
          DIY Tools
        </h4>
        <div class="test">
          <button class="lab-btn w-10" v-if="$store.state.appConfig.windowWidth <= 1079 && $store.state.diySourceData.selectedTab === 'SOURCE' && $store.state.diy.selectedMode === 'ADMIXTURE'" @click="prevTab()">
            <feather-icon icon="ArrowLeftIcon" class="mr-05" size="20"/>Back
          </button>
        </div>
      </div>

      <hr />
      
      <p class="description mt-2">
        DIY section allows you to do your own models and experiments with all the available populations. Follow the steps shown on the right and view the results you get.
      </p>

      <div class="lab-dropdown sm region">
        <label for="custom-dropdown" class="dropdown-label mb-1">Select Mode  </label>
        <div class="lab-dropdown-btn" @click="isDropdownOpen = !isDropdownOpen" ref="dropdownReport">
          <span class="lab-dropdown-btn-value">
            {{ selectedModeName }}
          </span>
          <span class="lab-dropdown-btn-icon">
            <!-- <dropdown-icon /> -->
            <DropdownOneIcon />
          </span>
        </div>
        <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isDropdownOpen}">
          <ul>
            <li v-for="item in diyModeList"
              :key="item.id"
              @click="$store.state.diy.selectedMode = item.id">
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon,
  },
  data() {
    return {
      isDropdownOpen: false,
      diyModeList: [
        {
          id: 'ADMIXTURE',
          title: 'Admixture',
        },
        {
          id: 'MODERN',
          title: 'Genetic Distance',
        },
      ],
    };
  },
  methods: {
    back() {
      this.$router.push({ name: 'dashboard' });
    },
    prevTab() {
      this.$store.state.diySourceData.selectedTab = "TARGET";
    },

    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownReport;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isDropdownOpen = false;
        }
      } catch (error) {}
    },
  },
  computed: {
    selectedModeName() {
      return this.diyModeList.find(x => x.id === this.$store.state.diy.selectedMode).title;
    },
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
};
</script>
  
<style lang="scss" scoped>
.diy-header-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

  .diy-tools-sidebar {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    padding: 28px;
    height: 100%;
    background-color: var(--bg-card-color);
  }
  .menu-content {
  position: relative;
}

.menu-items {
  position: relative;
  list-style: none;
  display: flex;
  // gap: 20px;
  margin: 0; 
  padding: 0;
  border-bottom: 2px solid var(--lab-hr-color);;

  .menu-item-li {
    flex: 0 0 auto;
    width: 50%;
    padding: 10px 0px;
    color: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
  }

  .menu-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 8px;
    margin-right: 10px;
    text-decoration: none;
    color: var(--lab-header-menu-color);
    font-size: 1rem;
    font-weight: 600;

    &:hover,
    &.active {
      color: var(--lab-main-color);
    }
    &:hover::after,
    &.active::after {
      width: 110%;
      left: -5%; 
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 0;
      height: 4px;
      border-bottom: 2pt solid var(--lab-main-color);
      height: 14px;
      background-color: transparent; 
      transition: width 0.3s ease, opacity 0.3s ease;
      opacity: 0; 
    }
  }
}
</style>
