<template>
  <b-modal
    ref="select-pops-modern"
    title="Filter Populations"  
    cancel-variant="outline-secondary"
    @hidden="hiddenModal"
    size="lg"
    content-class="genetice-dist-modern-select"
  >
    <b-row class="p-1">
      <b-col sm="6" md="6">
        <label class="lab-checkbox-container">
          <span class="label"> {{ 'Select All' }}</span>
          <input type="checkbox" v-model="isActiveAll" @change="onToggleActive()">
          <span class="checkmark"></span>
        </label>
      </b-col>
      <b-col sm="6" md="6">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1 genetic-dist-form"
            size="sm"
            placeholder="Search..."
          />
      </b-col>
    </b-row>
    
    <div class="select-data-card cs-scroll-style">
      <div class="pop-item" :class="{'selected': item.isActive}"
        v-for="item in filteredSourceData" :key="item.name">

      <label class="lab-checkbox-container">
        <span class="label"> {{item.name.replaceAll('*', ' ')}}</span>
        <input type="checkbox" v-model="item.isActive">
        <span class="checkmark"></span>
      </label>
      </div>
    </div>

    <template #modal-footer>
      <button class="lab-btn float-right lab-btn-genetic-dist" @click="handleOk">
        {{ $t("Calculate") }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService';

export default {
  components: {
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      sourceDatas: [],
      searchQuery: '',
      isActiveAll: false,
    }
  },
  methods: {
    onToggleActive() {
      this.sourceDatas.forEach(item => {
        item.isActive = this.isActiveAll;
      });
    },
    handleOk() {
      this.$store.state.modernProxSourceData.selectedFilterSourceDatas = this.sourceDatas.filter(x => x.isActive).map(x => x.name);

      this.hiddenModal();
    },
    async initialModal() {
      /* eslint-disable */
      const dataVersion = this.$store.state.daResult.selectedResult.dataVersion;
      const sourceDataResult = await this.$store.dispatch('modernProxSourceData/fetchModernProxSourceData', dataVersion);
      if (!sourceDataResult) {
        return;
      }
      const resolveSource = CalculateFMCService.codeDataToArray(sourceDataResult.sourceData);
      if (!resolveSource.isSuccess) {
        return;
      }

      this.sourceDatas = resolveSource.result.map(item => { return { name: item[0], isActive: false, } });
      
      this.$refs['select-pops-modern'].show();
    },
    hiddenModal() {
      this.$refs['select-pops-modern'].hide()
      this.modalObject.showModal = false;
    },
  },
  computed: {
    filteredSourceData() {
      return this.sourceDatas.filter(item => item.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.select-data-card {
  height: 400px !important;
  overflow-y: scroll !important;
  .pop-item {
    padding: 1rem;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  .pop-item:hover {
    background: var(--bg-card-gray);
  }
  .pop-item.selected {
    background: var(--bg-card-gray);
  }
  .dark-layout {
    .pop-item:hover {
      background: #252B30;
    }
    .pop-item.selected {
      background: #252B30;
    }
  }
}
</style>
