import { Role } from '@/auth/utils'

export default [
  {
    path: '/order/list',
    name: 'order-list',
    component: () => import('@/views/dashboards/main-dashboard/actions/orders/MobileOrderList.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },
  {
    path: '/kits/list',
    name: 'kits-list',
    component: () => import('@/views/dashboards/main-dashboard/actions/kits/MobileKitsList.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },

  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/others/Notifications.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },

  {
    path: '/ticket/list',
    name: 'ticket-list',
    component: () => import('@/views/dashboards/main-dashboard/actions/tickets/MobileTicketList.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },
  {
    path: '/ticket/new-ticket',
    name: 'new-ticket',
    component: () => import('@/views/dashboards/main-dashboard/actions/tickets/NewTicket.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },
  {
    path: '/ticket/detail/:id',
    name: 'ticket-detail',
    component: () => import('@/views/dashboards/main-dashboard/actions/tickets/TicketDetail.vue'),
    meta: {
      authorize: [Role.Customer],
    },
  },

  {
    path: '/dashboard/upgradev2',
    name: 'dashboard-upgradev2',
    component: () => import('@/views/dashboards/update-v2/UpdateToV2.vue'),
    meta: {
      authorize: [Role.Customer],
      pageTitle: 'UPGRADE TO V2',
      code: 'DASHBOARD',
    },
  },
  
  // Checkout
  {
    path: '/checkout/select-service',
    name: 'checkout-select-service',
    component: () => import('@/views/checkouts/select-services/CheckoutSelectService.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/checkout/da/:step',
    name: 'checkout-da',
    component: () => import('@/views/checkouts/DeepAncestryCheckout/DeepACheckout.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/checkout/da/:step/:product_code',
    name: 'checkout-da',
    component: () => import('@/views/checkouts/DeepAncestryCheckout/DeepACheckout.vue'),
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/checkout/g25-partner/:link_guid',
    name: 'checkout-trait',
    component: () => import('@/views/purchase-partner/g25/PartnerCheckout.vue'),
    meta: {
      layout: 'full',
    },
  },

]
