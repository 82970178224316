<template>
  <!-- select 2 demo here-->
  <div>
    <b-modal
      ref="modal-upload-files"
      :title="title"
      hide-header
      hide-footer
      scrollable
      cancel-variant="outline-secondary"
      @hidden="hiddenModal"
      size="lg"
      centered
    >
    <div class="modal-close-container">
      <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
     </div>
     <div class="info-modal-container">
      <div>
        <div class="info-modal-header">
          <h5>Upload Order Files</h5>
        </div>
        <b-card class="dashboard-card" v-for="file in missingKits" :key="file.id">
          <div class="d-flex align-items-center">
            <div class="mr-auto d-flex align-items-center">
              <feather-icon icon="FileTextIcon" size="23"/>
              <h4 class="ml-1 mb-0">{{file.resultTitle}}</h4>
            </div>
            <div class="">
              <div class="file-upload btn-file-upload" v-if="file.fileName === ''"
                  @click="openUpload(file)">
                <feather-icon
                  icon="UploadIcon"
                  size="20"
                  class="text-primary"
                  v-if="file.isUploading === false"
                />
                <b-spinner v-if="file.isUploading" small> </b-spinner> Upload File
              </div>
              <div class="file-upload" v-if="file.fileName !== ''">
                <span v-if="file.fileName.length < 15">{{file.fileName}}</span>
                <span v-else>{{ file.fileName.substring(0,15)+".." }}</span>
                <feather-icon
                  icon="CheckIcon"
                  size="20"
                  class="lab-text-success"
                />
              </div>
            </div>
          </div>
        </b-card>
      </div>
     </div>
     
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';
import useJwt from '@/auth/jwt/useJwt'
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'
// Toast
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    CloseModalIcon
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      /* eslint-disable */
      title: 'Upload Order Files',
      missingKits: [],
    }
  },
  methods: {
    openUpload(fileItem) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';

      fileInput.addEventListener('change', event => {
        /* eslint-disable*/
        const file = event.target.files[0];

        const fileSizeInMB = file.size / (1024 * 1024); 
        if(fileSizeInMB <= 1) {
          AlertService.warning('Invalid file size...! File size must be larger than (1MB)');
          return 0;
        }
        
        fileItem.isUploading = true;

        const fileKey = this.generateFileKey();
        ApiService.get(`RawDataFile/presigned-url?fileKey=${fileKey}&contentType=${file.type}`, {}, this)
          .then(response => {
            if (response.statusCode === 200) {
              const presignedUrl = response.result;
              useJwt.axiosIns.put(presignedUrl, file, { headers: { 'Content-Type': file.type } })
                .then(() => {
                  this.saveMissedFile({
                    orderResultGuid: fileItem.guid,
                    rawFileKey: fileKey, 
                    rawFileName: file.name, 
                    rawFileFormat: file.type
                  }, fileItem);
                });
            } else {
              fileItem.isUploading = false;
              AlertService.warning(this.$t(response.message));
            }
          });
      })
      fileInput.click();
    },

    saveMissedFile(query, fileItem) {
      ApiService.post('order/kits/uploadmissedfile', query, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'CheckCircleIcon',
                variant: 'info',
              },
            });
            fileItem.isUploading = false;
            fileItem.fileName = query.rawFileName;
          } else {
            AlertService.error(response.message);
          }
          
          this.modalObject.onSuccess();
        });
    },

    generateFileKey() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    },

    initialModal() {
      this.$refs['modal-upload-files'].show();
      ApiService.get('order/kits/missedfiles', {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            response.result.forEach(value => {
              value.isUploading = false;
              value.fileName = '';
            });
            this.missingKits = response.result;
          } else {
            AlertService.error(response.message);
          }
        });
    },
    hiddenModal() {
      this.$refs['modal-upload-files'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style scoped>
.file-upload {
  font-size: 15px;
  padding: 0.5rem;
}
.btn-file-upload {
  width: 130px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #00adb53b;
  transition: all 0.05s linear;
}

.btn-file-upload:hover {
  transform: rotate(0deg) scale(1.03, 1.03);
}
</style>