<template>
  <div>
    <div class="info-modal-container">
      <div class="info-modal-header">
        <h5>Download Coordinates</h5>
      </div>
      <b-row class="p-2" v-show="orderResults.length">
        <b-col sm="6" md="6">
          <label class="lab-checkbox-container">
            <span class="label"> {{ 'Select All' }}</span>
            <input type="checkbox" v-model="isActiveAll" @change="onToggleActive()">
            <span class="checkmark"></span>
          </label>
        </b-col>
        <b-col sm="6" md="6" class="hidden-mobile">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            size="sm"
            placeholder="Search..."
          />
        </b-col>
      </b-row>
    
      <div class="data-sheet">
        <div class="data-sheet-title">Datasheet:</div>
        <div class="pop-item">
          <a class="pop-item" href="https://api.illustrativedna.com/datasheet/DeepAncestry_Modern_Averages_Spreadsheet.txt" 
              download="DeepAncestry_Modern_Averages_Spreadsheet.txt" target="_blank">
              DeepAncestry_Modern_Averages_Spreadsheet.txt
            </a>
        </div>
      </div>
      
      <div class="select-data-card cs-scroll-style">
        <template v-if="orderResults && !$store.getters.getLoader">
          <div class="pop-item" :class="{ 'selected': item.isActive }"
            v-for="item in orderResultList" :key="item.id">

            <label class="lab-checkbox-container">
              <span class="label">{{item.resultTitle}}</span>
              <input type="checkbox" v-model="item.isActive" @change="controlCheck">
              <span class="checkmark"></span>
            </label>
          </div>
        </template>
        <SpinnerContainer v-else />
      </div>

    </div>
    
    <footer class="modal-footer">
      <button class="lab-btn float-right" :class="{ 'lab-btn-passive': !hasSelected }" @click="downloadCoordinates">
          {{ $t("Download") }}
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      orderResults: [],
      searchQuery: '',
      isActiveAll: false,
    }
  },
  computed: {
    orderResultList() {
      return this.orderResults.filter(x => x.resultTitle.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    hasSelected() {
      return this.orderResults.find(x => x.isActive);
    },
  },
  methods: {
    controlCheck() {
      this.orderResults = this.orderResults.filter(item => item.resultTitle.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },

    onToggleActive() {
      this.orderResults.forEach(item => {
        item.isActive = this.isActiveAll;
      });
      this.controlCheck();
    },

    initialModal() {
      this.orderResults = this.$store.state.daResult.resultList.map(item => item);
      this.orderResults.forEach(item => {
        item.isActive = this.isActiveAll;
      });
    },

    downloadCoordinates() {
      /* eslint-disable */
      const selectedResults = this.orderResults.filter(x => x.isActive);
      if (selectedResults.length > 0) {
        let text = '';
        
        selectedResults.forEach(item => {
          if (item.targetCoordinate !== null && item.targetCoordinate !== '') {
            text += item.targetCoordinate;
          }

          if (item.dataVersion === 'D-01') {
            if (item.targetUnScaledCoordinate !== null && item.targetUnScaledCoordinate !== '') {
              text += '\n' + item.targetUnScaledCoordinate;
            }
          }
         
          text += '\n'; 
        });
        
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'coordinates');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {
        AlertService.warning('There is no selected kit')
      }
    },

    downloadFile(fileUrl, fileName) {
      fetch(fileUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response failed: ' + response.status);
          }
          return response.blob();
        })
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        })
        .catch(err => console.error('File download error:', err));
    },
  },
  mounted() {
    this.initialModal()
  },
}

</script>

<style lang="scss" scoped>
.info-modal-container {
  padding: 0.8rem;
}

.select-data-card-header {
  background-clip: border-box;
  border-radius: 0.428rem !important;
  margin-bottom: 0.7rem;
  padding: 0.5rem 1.2rem;
}

.select-data-search-input {
  float: right !important;

}

.select-data-card {
  height: 400px !important;
  overflow-y: scroll !important;
}

.pop-item {
  padding: 1rem;
  margin-bottom: 5px;
  border-radius: 5px;
}
.pop-item:hover {
  background: var(--bg-card-gray);
}
.pop-item.selected {
  background: var(--bg-card-gray);
}

.data-sheet {
  padding: 0 15px;

  .data-sheet-title {
    font-size: 14px;
    font-weight: bold;
  }
  a {
    font-size: 0.9rem;
    color: var(--lab-g25-supplementary-color);
  }
}

.dark-layout .lab-checkbox-container .label {
  color: var(--lab-g25-supplementary-color);
}
.dark-layout .download-cords-dist-modern .lab-checkbox-container input:checked ~ .checkmark {
  background-color: #13181c!important;
}
.dark-layout {
  .pop-item:hover {
    background: #13181c55!important;
  }
  .pop-item.selected {
    background: #13181c55!important;
  }
}

</style>

<style lang="scss">
.download-cords-dist-modern {
  .modal-header::before {
    background-color: var(--lab-g25-supplementary-color) !important;
  }
  .modal-header .close {
    background-color: #6fceaa4d !important;
    color: var(--lab-g25-supplementary-color) !important;
  }
  .title-header::before {
    background-color: var(--lab-g25-supplementary-color) !important;
  }
}

.download-cords-dist-modern {
  .modal-header h5 {
    color: var(--lab-g25-supplementary-color) !important;
  }
  .title-header h4 {
    color: var(--lab-g25-supplementary-color) !important;
  }
}
</style>
