import { euclideanDistance, runCustomMCAlgorithm, runABByPopNumber, callResultData, callResultDataRdc, callResponseData, subDataArray, divideToNestTarget, divideToNestSource } from './utils'
import { CalculateModernPopColor } from './dna-source/utils'

/* eslint-disable */
const CalculateResultService = { 
  dataArrays: {
    sourceCodeArray: [],
    targetCodeArray: [],
    sourceCodeNumber: null,
    targetCodeNumber: null,
  },
  dataObject: {
    codeMetrics: null,
    maxDistOutPut: 30,
    nstSize: 500,
    columnDFactor: 1,
    numberOfPopulation: 0,
    iterationFactor: 1,
    recalculate: 1,
  },

  onSetSource(sourceArray) {
    // Orijinal mantık
    this.dataArrays.sourceCodeArray = sourceArray;
    this.dataArrays.sourceCodeNumber = sourceArray.length;
    this.dataObject.codeMetrics = sourceArray[0].length - 1;
  },

  onSetTarget(targetArray) {
    // Orijinal mantık
    this.dataArrays.targetCodeArray = targetArray;
    this.dataArrays.targetCodeNumber = targetArray.length;
  },

  codeDataToArray(codeData) {
    // Orijinal mantık
    const response = {
      isSuccess: true,
      message: '',
      result: [],
    };

    const lines = codeData.trim().split("\n");

    // Her satır için virgülle böl -> 2D dizi
    const parsedData = lines.map(line => line.split(","));
  
    // Sayısal kolonları Number'a çevir (ilk kolon hariç)
    parsedData.forEach(row => {
      for (let i = 1; i < row.length; i++) {
        row[i] = parseFloat(row[i]);
      }
    });
    
    response.result = parsedData;
    return response;
  },

  calculateDistance() {
    // Hedefin ilk satırını referans alıyoruz
    const targetRow = this.dataArrays.targetCodeArray[0]; // Tek satırlık CSV de olabilir
    const distanceResults = [];

    for (let i = 0; i < this.dataArrays.sourceCodeArray.length; i++) {
      const sourceRow = this.dataArrays.sourceCodeArray[i];
      const dist = euclideanDistance(sourceRow, targetRow);

      distanceResults.push({
        title: sourceRow[0],
        distance: dist
      });
    }

    // son işlem
    const results = distanceResults
      .sort((a, b) => a.distance - b.distance)
      .slice(0, this.dataObject.maxDistOutPut)
      .map(item => ({
        color: CalculateModernPopColor((item.distance * 100).toFixed(3)),
        distance: (item.distance * 100).toFixed(3),
        title: item.title
      }));
    
    return results;
  },

  runABAlgorithm() {
    // Orijinal mantık
    let result = undefined, resultData= undefined;
    let sourceLength = this.dataArrays.sourceCodeNumber;
    const targetData = divideToNestTarget(this.dataArrays.targetCodeArray, this.dataObject.nstSize, this.dataObject.codeMetrics);
    const sourceData = divideToNestSource(this.dataArrays.sourceCodeArray, this.dataArrays.sourceCodeNumber, this.dataObject.nstSize, this.dataObject.codeMetrics);
    const adcValue = this.dataObject.columnDFactor === 1 ? false : this.dataObject.columnDFactor;

    const dataParams = {
      sourceCodData: sourceData,
      sourceCodeNumber: this.dataArrays.sourceCodeNumber,
      targetCode: targetData,
    };

    const checkParams = {
      iterationFactor: this.dataObject.iterationFactor,
      recalculate: this.dataObject.recalculate,
      columnDFactor: adcValue,
    };

    const extraParams = { 
      nstSize: this.dataObject.nstSize,
      codeMetrics: this.dataObject.codeMetrics,
    };

    if (this.dataObject.numberOfPopulation) {
      dataParams.sourceCodeArray = this.dataArrays.sourceCodeArray;
      checkParams.columnDFactor = undefined;
      result = runABByPopNumber(dataParams, checkParams, extraParams, this.dataObject.numberOfPopulation);
      resultData = callResultDataRdc(result);
    } else {
      result = runCustomMCAlgorithm(dataParams, checkParams, extraParams);
      resultData = callResultData(result, this.dataArrays.sourceCodeArray, sourceLength);
    }
    
    resultData.sort((a, b) => b[1] - a[1]);

    return callResponseData(result, resultData);
  },
}

export default CalculateResultService;
