<template>
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.79 13.34">
  <defs>
  </defs>
  <polygon class="cls-1" points="7.76 13.34 0 6.28 7.79 0 8.65 1.06 2.1 6.34 8.68 12.33 7.76 13.34"/>
  <rect class="cls-1" x="1.05" y="5.62" width="24.74" height="1.37"/>
</svg>
</template>

<script>
export default {
  // components: {
  // },
  // props: {
  //   className: {
  //     default: 'normal',
  //     type: String,
  //   },
  // },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}

.cls-1 {
  fill: #2d2d2d;
}

.dark-layout {
  .cls-1, .cls-2 {
    fill: #1bbdc1!important;
  }
}
</style>
