<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 31.25">
    <defs>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-2" d="M12.5,12.5c3.45,0,6.25-2.8,6.25-6.25S15.95,0,12.5,0,6.25,2.8,6.25,6.25s2.8,6.25,6.25,6.25Z"/>
        <path class="cls-1" d="M25,26.04c0-6.65-5.6-11.46-12.5-11.46S0,19.39,0,26.04v3.12c0,.55,.22,1.08,.61,1.47,.39,.39,.92,.61,1.47,.61H22.92c.55,0,1.08-.22,1.47-.61s.61-.92,.61-1.47v-3.12Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}

.cls-1 {
  fill-rule: evenodd;
}

.cls-1, .cls-2 {
  fill: #2d2d2d;
}

.dark-layout {
  .cls-1, .cls-2 {
    fill: var(--lab-white-100) !important;
  }
}
</style>
