import ApiService from '@/common/api.service';

/* eslint-disable */
const getDefaultState = () => {
  return {
    ancientDatabaseList: [],
    savedAncientDatabaseList: {},

    sampleDetail: null,
    isModernDistance: true,
  }
}

const state = getDefaultState()

const getters = {
  getAncientDatabaseList(state) {
    return state.ancientDatabaseList;
  },
}

const mutations = {
  setAncientDatabaseList(state, data) {
    state.ancientDatabaseList = data;
    state.sampleDetail = null;
  },
}

const actions = {
  async fetchAncientDatabaseData(context, filterQuery) {
    try {
      const key = Object.values(filterQuery).join(',');
     
      const saveDetail = context.state.savedAncientDatabaseList[key];
      if (saveDetail) {
        return saveDetail;
      } else {
        const response = await ApiService.get('data/AncientSampleDatabase', filterQuery, this);
        if (response.statusCode === 200) {
          context.state.savedAncientDatabaseList[key] = response.result;
          return response.result;
        } else {
          throw error;
        }
      }
    } catch (error) {
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
