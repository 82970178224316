import store from '@/store';
import CalculateFMCService from '@/common/custom-service/dna-source/CalculateFMCService'
import { ParsePeriodicalResult } from './utils'

const DataWatcherService = {
  isRunning: false,
  unwatchers: [],
  
  init() {
    this.watchSelectedResultState();
    this.watchPeriodState();
    this.watchRegionState();
    this.watchShowAllEraState();

    this.runPeriodicalResult();
  },

  watchSelectedResultState() {
    const unwatch = store.watch(
      (state) => state.daResult.selectedResult,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runPeriodicalResult();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchPeriodState() {
    const unwatch = store.watch(
      (state) => state.period.selectedPeriod,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runPeriodicalResult();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchRegionState() {
    const unwatch = store.watch(
      (state) => state.region.selectedRegion,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runPeriodicalResult();
        }
      }
    );
    
    this.unwatchers.push(unwatch);
  },

  watchShowAllEraState() {
    const unwatch = store.watch(
      (state) => state.periodicalResult.showAllEra,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runPeriodicalResult();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  // watchShowPopLimitState() {
  //   const unwatch = store.watch(
  //     (state) => state.periodicalResult.popLimit,
  //     (newValue, oldValue) => {
  //       if (newValue !== oldValue) {
  //         this.runPeriodicalResult();
  //       }
  //     }
  //   );

  //   this.unwatchers.push(unwatch);
  // },

  async runPeriodicalResult() {
    try {
      const result = store.state.daResult.selectedResult;
      const region = store.state.region.selectedRegion;
      const period = store.state.period.selectedPeriod;
      if (!(result.id && region.id && period)) {
        return;
      }
      
      if (this.isRunning) {
        return;
      }
      this.isRunning = true;

      const tagetResolveSource = CalculateFMCService.codeDataToArray(result.targetCoordinate);
      if (tagetResolveSource.isSuccess) {
        CalculateFMCService.onSetTarget(tagetResolveSource.result);
      }
      
      const sourceDataRequest = {
        regionId: region.id,
        periodId: period.id,
        resultId: result.id,
      };

      const sourceDataResult = await store.dispatch('periodSourceData/fetchPeriodSourceData', sourceDataRequest);
      if (!sourceDataResult) {
        this.isRunning = false;
        return;
      }
      
      const resolveSource = CalculateFMCService.codeDataToArray(sourceDataResult.sourceData);
    
      if (resolveSource.isSuccess) {
        CalculateFMCService.onSetSource(resolveSource.result);
      }

      const calculatedResult = await this.getCurrentResult();
      
      const parsedCalculatedResult = store.state.periodicalResult.showAllEra ? ParsePeriodicalResult(calculatedResult, resolveSource.result) : ParsePeriodicalResult(calculatedResult);
  
      store.commit('periodicalResult/setOriginalResults', parsedCalculatedResult.originalResults);
      store.commit('periodicalResult/setGroupedResults', parsedCalculatedResult.groupedResults);

      const groupedResults = parsedCalculatedResult.groupedResults;
      const groupedSourceCodes = groupedResults.outPuts.map(item => item.resultsTable);
      const uiDetailDataResult = await store.dispatch('periodicalResult/fetchSupervisedPeriodDetails', { groupedSourceCodes, dataVersion: store.getters['daResult/dataVersion'] });
      if (!uiDetailDataResult) {
        this.isRunning = false;
        return;
      }
      
      uiDetailDataResult.forEach(geoItem => {
        /* eslint-disable */
        let isFoundData = false;
        geoItem.title = 'Map Title Not Found';
        groupedResults.outPuts.forEach(resultItem => {
          if (resultItem.resultsTable === geoItem.sourceCode) {
            resultItem.color = geoItem.color;
            resultItem.logoUrl = geoItem.logoUrl;
            resultItem.description = geoItem.description;
            resultItem.id = geoItem.id;
            resultItem.sourceCode = geoItem.sourceCode;

            geoItem.title = resultItem.resultsTable.replaceAll('*', ' ') + ' : ' + resultItem.currentResult.toFixed(1);
            geoItem.name = resultItem.resultsTable.replaceAll('*', ' ');
            geoItem.ancestryValue = resultItem.currentResult.toFixed(1);

            isFoundData = true;
          }
        });

        if (isFoundData === false) {
          geoItem.title = geoItem.sourceCode.replaceAll('*', ' ') + ' : ' + 0;
          geoItem.name = geoItem.sourceCode.replaceAll('*', ' ');
          geoItem.ancestryValue = 0;
          geoItem.color = '#b8b8b8';
        }
      });

      store.commit('periodicalResult/setGeoJson', uiDetailDataResult);
      store.commit('periodicalResult/setUiResults', groupedResults);

    } catch (error) {
      console.error('Error handling specific state change:', error);
    }
    
    this.isRunning = false;
  },

  async getCurrentResult() {
    let results = {};
    try {
      CalculateFMCService.dataObject.numberOfPopulation = store.state.periodicalResult.popLimit;

      results = CalculateFMCService.runABAlgorithm();
    } catch (error) {
      console.error('getCurrentResult:', error);
    }
    
    CalculateFMCService.dataObject.numberOfPopulation = 0;

    return results;
  },

  cleanup() {
    this.unwatchers.forEach(unwatch => unwatch());
    this.unwatchers = [];
    
    this.isRunning = false;
  },
};

export default DataWatcherService;
