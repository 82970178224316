
<template>
  <div class="top-header-container pb-0" :class="{'top-header-container-remove-radius' : contentPosition === -250}">
    <div class="mobile-header-icon" v-if="$store.state.appConfig.windowWidth < 1079">
      <MobileHeaderIcon />
    </div>
    <h4 class="report-title">
      Closest Populations
      <span @click="geneticDistanceInfoModal.showModal = true" class="open-info-modal-btn">
        <sup class="title-info-button-area">
            <InfoIcon />
        </sup>
      </span>
    </h4>
    <hr />
    <p class="description">
      Explore up to 50 ancient or modern populations most similar to you genetically. The “Genetic Distance” score on the right sides of the each population indicates how closely your DNA matches these groups.
    </p>
    <div id="menu-area" class="menu-content">
      <ul class="menu-items">
        <li :key="tab"  v-for="tab in tabs" class="menu-item-li">
          <a class="menu-link dp-menu-item" 
            :class="{ 'active':  tab === $store.state.geneticDistance.selectedReport }" @click="$store.state.geneticDistance.selectedReport = tab">
            {{ tab }}
          </a>
        </li>
      </ul>
      <a class="filter-button" @click="filterModernPopsObject.showModal = true" v-if="'Modern' === $store.state.geneticDistance.selectedReport">
        <FilterIcon class="filter-icon"></FilterIcon>
      </a>
    </div>
    <table class="table population-header-table mb-0" v-if="'Ancient' === $store.state.geneticDistance.selectedReport">
      <thead>
        <tr>
          <td scope="col" class="col-1 text-left pl-2">#</td>
          <td scope="col" class="col-5 pl-3">Population</td>
          <td scope="col" class="col-4 text-right pr-1">Genetic Distance</td>
        </tr>
      </thead>
    </table>
    <table class="table population-header-table mb-0" v-if="'Modern' === $store.state.geneticDistance.selectedReport">
      <thead>
        <tr>
          <td scope="col" class="col-1 text-left pl-1">#</td>
          <td scope="col" class="col-5 pl-1">Population</td>
          <td scope="col" class="col-4 text-right pr-0">Genetic Distance</td>
        </tr>
      </thead>
    </table>
    <GeneticSimilaritiesInfoModal v-if="geneticDistanceInfoModal.showModal" :modalObject="geneticDistanceInfoModal"/>
    <SelectPopsModernModal  v-if="filterModernPopsObject.showModal" :modalObject="filterModernPopsObject" />
  </div>

</template>

<script>
import MobileHeaderIcon from '@/layouts/icons/MobileHeaderIcon.vue';
import InfoIcon from '@/layouts/icons/InfoIcon.vue';
import FilterIcon from '@/layouts/components/icons/FilterIcon.vue';
import GeneticSimilaritiesInfoModal from './Modals/GeneticSimilaritiesInfoModal.vue';
import SelectPopsModernModal from './Modals/SelectPopsModernModal.vue';

export default {
  props: {

  },
  components: {
    MobileHeaderIcon,
    InfoIcon,
    GeneticSimilaritiesInfoModal,
    FilterIcon,
    SelectPopsModernModal,
  },
  data() {
    return {
      geneticDistanceInfoModal: {
        showModal: false,
      },
      filterModernPopsObject: {
        showModal: false,
      },

      tabs: ['Ancient', 'Modern'],
      underlineStyle: {
        width: '0px',
        left: '0px',
        backgroundColor: '#BB4ECE',
      },
      contentPosition: {
        type: Number,
        required: false,
        default: 0,
      },
    }
  },
  methods: {
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.top-header-container {
  padding: 28px;
  background-color: var(--bg-card-color);
}

@media screen and (max-width: 600px) {
  .top-header-container {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
}
.top-header-container-remove-radius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.menu-content {
  position: relative;
}
.menu-items {
  position: relative;
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0; 
  padding: 0;
  border-bottom: 2px solid var(--lab-hr-color);;

  .menu-item-li {
    flex: 0 0 auto;
    padding: 10px 0px;
    margin: 0 5px;
    color: white;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
  }

  .menu-link {
    position: relative;
    display: flex;
    align-items: center; 
    gap: 8px;
    margin-right: 10px;
    text-decoration: none;
    color: var(--lab-header-menu-color);
    font-size: 1rem;
    font-weight: 600;

    &:hover,
    &.active {
      color: var(--lab-main-color);
    }
    &:hover::after,
    &.active::after {
      width: 110%;
      left: -5%; 
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 0;
      width: 0;
      height: 4px;
      border-bottom: 2pt solid var(--lab-main-color);
      height: 14px;
      background-color: transparent; 
      transition: width 0.3s ease, opacity 0.3s ease;
      opacity: 0; 
    }
  }
}
.open-select-region {
  cursor: pointer;
  text-decoration: underline;
  color: var(--lab-main-color);
}
.filter-button {
  position:absolute;
  top: 5px;
  right: 5px;
}
.filter-icon {
  width: 1.5em;

  svg {
    fill: #1bddcc!important;
  }
}

.population-header-table {
  font-size:13px;
  font-weight: 700;
}

</style>