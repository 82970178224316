<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <defs>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M25,12.5L15.62,3.12V7.81h-6.25v9.38h6.25v4.69l9.38-9.38Z"/>
        <path class="cls-1" d="M9.38,21.88H3.91c-.42,0-.78-.36-.78-.78V3.91c0-.42,.36-.78,.78-.78h5.47c.86,0,1.56-.7,1.56-1.56s-.7-1.56-1.56-1.56H3.91C1.75,0,0,1.75,0,3.91V21.09c0,2.15,1.75,3.91,3.91,3.91h5.47c.86,0,1.56-.7,1.56-1.56s-.7-1.56-1.56-1.56Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}

.cls-1 {
  fill: #2d2d2d;
}

.dark-layout {
  .cls-1, .cls-2 {
    fill: var(--lab-white-100) !important;
  }
}
</style>
