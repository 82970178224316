<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-download-coordiantes"
      title="Download Coordinates"  
      cancel-variant="outline-secondary"
      hide-header
      hide-footer
      @hidden="hiddenModal"
      size="lg"
      content-class="download-cords-dist-modern"
    >
      <!-- <b-alert variant="warning" show >
        <div class="alert-body">
          <span class="mr-1 mt-1"><strong>Important!</strong> You can only perform the coordinate download process for your G25 kits. This service is currently not available for Deep Ancestry.</span> 
        </div>
      </b-alert> -->
      <div class="modal-close-container">
        <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
      </div>
      <div class="info-modal-container">
        <DownloadCoordView />

      </div>

      <!-- <template #modal-footer>
        <button class="lab-btn float-right" @click="downloadCoordinates">
          {{ $t("Download") }}
        </button>
      </template> -->
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';
import AlertService from '@/common/alert.service'
import DownloadCoordView from '../DownloadCoordView.vue';

export default {
  components: {
    CloseModalIcon,
    DownloadCoordView
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      orderResults: [],
      searchQuery: '',
      isActiveAll: false,
    }
  },
  methods: {
    controlCheck() {
      this.orderResults = this.orderResults.filter(item => item.resultTitle.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    onToggleActive() {
      this.orderResults.forEach(item => {
        item.isActive = this.isActiveAll;
      });
    },
    initialModal() {
      this.orderResults = this.$store.state.daResult.resultList.map(item => item);
      this.orderResults.forEach(item => {
        item.isActive = this.isActiveAll;
      });
      this.$refs['modal-download-coordiantes'].show();
    },
    hiddenModal() {
      this.$refs['modal-download-coordiantes'].hide()
      this.modalObject.showModal = false;
    },

    downloadCoordinates() {
      /* eslint-disable */
      const selectedResults = this.orderResults.filter(x => x.isActive);
      if (selectedResults.length > 0) {
        let text = '';
        
        selectedResults.forEach(item => {
          if (item.targetCoordinate !== null && item.targetCoordinate !== '') {
            text += item.targetCoordinate;
          }

          if (item.dataVersion === 'D-01') {
            if (item.targetUnScaledCoordinate !== null && item.targetUnScaledCoordinate !== '') {
              text += '\n' + item.targetUnScaledCoordinate;
            }
          }
         
          text += '\n'; 
        });
        
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', 'coordinates');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {
        AlertService.warning('There is no selected kit')
      }
    },

    downloadFile(fileUrl, fileName) {
      fetch(fileUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response failed: ' + response.status);
          }
          return response.blob();
        })
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        })
        .catch(err => console.error('File download error:', err));
    },
  },
  computed: {
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>

</style>
