<template>
  <div class="pca-plot-sidebar">
    <h4 class="report-title">
      PCA Plots
      <span @click="pcaPlotInfoModal.showModal = true" class="open-info-modal-btn">
        <sup class="title-info-button-area">
          <InfoIcon />
        </sup>
      </span>
    </h4>
    <hr />
    <p class="description mt-2">
      Principal Component Analysis (PCA) plots provide a visualization of your genetic proximity to various ancient/modern populations.
    </p>
    <p class="description mt-2">
      Use the dropdown to customize the analysis by selecting different regions and populations, so you can focus on areas or groups most relevant to your origins.
    </p>

    <PcaPlotsRegions />

    <div class="d-flex align-items-center mt-2">
      <span class="lab-switch-desc">Show clusters toggle </span> 
      <label class="lab-switch ms-1">
        <input type="checkbox" v-model="$store.state.pcaSourceData.isEnableConvexHull">
        <span class="slider round"></span>
      </label>
    </div>

    <PcaPlotInfoModal v-if="pcaPlotInfoModal.showModal" :modalObject="pcaPlotInfoModal"/>
  </div>
</template>

<script>
import InfoIcon from '@/layouts/icons/InfoIcon.vue';
import PcaPlotsRegions from './PcaPlotsRegions.vue';
import PcaPlotInfoModal from '../modals/PcaPlotInfoModal.vue';

export default {
  components: {
    InfoIcon,
    PcaPlotsRegions,
    PcaPlotInfoModal,
  },
  data() {
    return {
      pcaPlotInfoModal: {
        showModal: false,
      },
    };
  },
  methods: {
  },
  computed: {
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
.pca-plot-sidebar {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 28px;
  height: 100%;
  background: var(--bg-card-color);
}
.lab-switch-desc {
  margin-right: 10px!important;
}
</style>