<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.04 32.06">
    <defs>
    </defs>
    <path id="primary" class="cls-1" d="M31.58,14.9L17.16,.47c-.62-.63-1.64-.63-2.27,0,0,0,0,0,0,0L.46,14.9c-.45,.46-.59,1.15-.34,1.75,.25,.6,.83,.99,1.47,.99h1.6v11.7c.06,1.56,1.37,2.78,2.93,2.72h4.28c.89,0,1.6-.72,1.6-1.6v-7.85c0-.89,.72-1.6,1.6-1.6h4.81c.89,0,1.6,.72,1.6,1.6v7.85c0,.89,.72,1.6,1.6,1.6h4.28c1.56,.05,2.87-1.16,2.93-2.72v-11.7h1.6c.65,0,1.23-.4,1.47-.99,.25-.6,.12-1.29-.34-1.75Z"/>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
    className: {
      default: 'normal',
      type: String,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 25px;
}

.cls-1 {
  fill: #2d2d2d;
}
</style>
