<template>
  <div>
    <svg id="light" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.25 26.25"  v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M0,2.6L2.53,0l15.72,13.12L2.53,26.25l-2.53-2.57L12.75,13.17,0,2.6Z"/>
    </svg>
    <svg id="dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.25 26.25"  v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M0,2.6L2.53,0l15.72,13.12L2.53,26.25l-2.53-2.57L12.75,13.17,0,2.6Z"/>
    </svg>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 15px;
}
#mobile {
  .cls-1 {
    fill: #666666;
  }
}

#light {
  .cls-1 {
    fill: #666666;
  }
}

#dark {
  .cls-1 {
    fill: #1bddcc;
  }
}

#hover {
  display: none;
  .cls-1 {
    fill: #ddd;
  }
}

#hover_dark {
  display: none;
  .cls-1 {
    fill: #ddd;
  }
}


.dp-menu-item:hover, .dp-menu-item.active {
  #hover, #hover_dark {
    display: block;
  }
  #light, #dark {
    display: none;
  }
}
</style>
