<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.84 15.33">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <path class="cls-1" d="M19.5,1.89l-1.56-1.55c-.43-.44-1.14-.46-1.58-.03,0,0-.02,.02-.03,.03L7.21,9.47l-3.71-3.72c-.45-.44-1.16-.44-1.61,0L.34,7.31c-.44,.43-.46,1.14-.03,1.58,0,0,.02,.02,.03,.03l6.07,6.07c.21,.22,.5,.34,.8,.34,.3,0,.59-.12,.8-.34L19.5,3.52c.44-.43,.46-1.14,.03-1.58,0,0-.02-.02-.03-.03v-.02Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>
svg {
  height: 15px;
}  

.cls-1 {
  fill: var(--lab-main-color);;
}
</style>
