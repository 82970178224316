<template>
    <div class="g25-main-icon">
      <svg id="light" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.9 40" v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>

        </defs>
        <path class="cls-1" d="M2.33,37.98s-.06,0-.1-.02c-1.34-.44-2.23-1.69-2.23-3.09V3.27C0,1.46,1.46,0,3.27,0H22.27c.47,0,.94,.1,1.35,.3,.33,.15,.66,.38,.95,.66l1.84,1.84c.12,.12,.13,.33,.01,.44-.12,.12-.31,.13-.42,.01l-1.86-1.86c-.23-.23-.5-.41-.77-.54-.34-.15-.72-.24-1.1-.24H3.27C1.8,.62,.62,1.8,.62,3.27v31.61c0,1.14,.73,2.15,1.81,2.51,.16,.06,.25,.23,.2,.39-.05,.12-.17,.21-.3,.21Z"/>
        <path class="cls-1" d="M30.63,40H5.13c-1.8,0-3.27-1.46-3.27-3.27V5.13c0-1.8,1.46-3.27,3.27-3.27H24.13c.47,0,.94,.1,1.35,.3,.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.19,.42,.3,.89,.3,1.35v25.1c0,1.8-1.46,3.27-3.27,3.27ZM5.13,2.47c-1.46,0-2.65,1.19-2.65,2.65v31.61c0,1.46,1.19,2.65,2.65,2.65H30.63c1.46,0,2.65-1.19,2.65-2.65V11.63c0-.38-.08-.76-.24-1.1-.13-.29-.31-.55-.54-.78l-6.5-6.51c-.23-.23-.49-.41-.77-.53-.34-.15-.72-.24-1.1-.24H5.13Z"/>
        <path class="cls-1" d="M33.32,10.71h-6.34c-1.06,0-1.93-.87-1.93-1.94V2.43c0-.1,.06-.2,.14-.26s.2-.07,.3-.02c.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.04,.09,.03,.2-.02,.3s-.15,.15-.25,.15Zm-7.66-7.75v5.82c0,.73,.59,1.32,1.32,1.32h5.82c-.09-.12-.18-.23-.29-.34l-6.5-6.51c-.11-.1-.22-.2-.34-.28Z"/>
        <path class="cls-1" d="M27.66,34.73H8.1c-1.05,0-1.9-.86-1.9-1.9,0-.5,.2-.99,.56-1.34,.36-.36,.84-.55,1.34-.55H27.66c1.05,0,1.9,.85,1.9,1.9,0,.5-.2,.98-.55,1.34-.36,.36-.84,.56-1.35,.56Zm-19.56-3.18c-.34,0-.66,.14-.91,.38-.24,.23-.38,.57-.38,.9,0,.71,.58,1.29,1.29,1.29H27.66c.34,0,.66-.14,.91-.38,.24-.25,.37-.57,.37-.91,0-.71-.57-1.28-1.28-1.28H8.1Z"/>
        <path class="cls-1" d="M17.88,28.46c-.52,0-1.02-.22-1.37-.61l-4.54-5.11c-.49-.55-.6-1.3-.3-1.97s.94-1.08,1.67-1.08h1.16v-5.62c0-1.01,.82-1.83,1.83-1.83h3.11c1,0,1.82,.82,1.82,1.83v5.62h1.16c.73,0,1.37,.41,1.67,1.08s.18,1.42-.3,1.97l-4.54,5.11c-.36,.39-.86,.61-1.38,.61Zm-4.54-8.15c-.49,0-.9,.26-1.11,.71-.2,.44-.12,.94,.2,1.31l4.54,5.11c.46,.52,1.35,.52,1.82,0l4.54-5.11c.32-.36,.4-.87,.2-1.31s-.62-.71-1.11-.71h-1.46c-.17,0-.31-.14-.31-.31v-5.93c0-.67-.54-1.21-1.21-1.21h-3.11c-.67,0-1.21,.54-1.21,1.21v5.93c0,.17-.14,.31-.31,.31h-1.46Z"/>
        <path class="cls-1" d="M13.24,5.54H6.5c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h6.73c.17,0,.31,.14,.31,.31s-.14,.31-.3,.31Z"/>
        <path class="cls-1" d="M9.87,7.93h-3.37c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h3.37c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z"/>
      </svg>
      <svg id="dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.9 40"  v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>

        </defs>
        <path class="cls-1" d="M2.33,37.98s-.06,0-.1-.02c-1.34-.44-2.23-1.69-2.23-3.09V3.27C0,1.46,1.46,0,3.27,0H22.27c.47,0,.94,.1,1.35,.3,.33,.15,.66,.38,.95,.66l1.84,1.84c.12,.12,.13,.33,.01,.44-.12,.12-.31,.13-.42,.01l-1.86-1.86c-.23-.23-.5-.41-.77-.54-.34-.15-.72-.24-1.1-.24H3.27C1.8,.62,.62,1.8,.62,3.27v31.61c0,1.14,.73,2.15,1.81,2.51,.16,.06,.25,.23,.2,.39-.05,.12-.17,.21-.3,.21Z"/>
        <path class="cls-1" d="M30.63,40H5.13c-1.8,0-3.27-1.46-3.27-3.27V5.13c0-1.8,1.46-3.27,3.27-3.27H24.13c.47,0,.94,.1,1.35,.3,.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.19,.42,.3,.89,.3,1.35v25.1c0,1.8-1.46,3.27-3.27,3.27ZM5.13,2.47c-1.46,0-2.65,1.19-2.65,2.65v31.61c0,1.46,1.19,2.65,2.65,2.65H30.63c1.46,0,2.65-1.19,2.65-2.65V11.63c0-.38-.08-.76-.24-1.1-.13-.29-.31-.55-.54-.78l-6.5-6.51c-.23-.23-.49-.41-.77-.53-.34-.15-.72-.24-1.1-.24H5.13Z"/>
        <path class="cls-1" d="M33.32,10.71h-6.34c-1.06,0-1.93-.87-1.93-1.94V2.43c0-.1,.06-.2,.14-.26s.2-.07,.3-.02c.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.04,.09,.03,.2-.02,.3s-.15,.15-.25,.15Zm-7.66-7.75v5.82c0,.73,.59,1.32,1.32,1.32h5.82c-.09-.12-.18-.23-.29-.34l-6.5-6.51c-.11-.1-.22-.2-.34-.28Z"/>
        <path class="cls-1" d="M27.66,34.73H8.1c-1.05,0-1.9-.86-1.9-1.9,0-.5,.2-.99,.56-1.34,.36-.36,.84-.55,1.34-.55H27.66c1.05,0,1.9,.85,1.9,1.9,0,.5-.2,.98-.55,1.34-.36,.36-.84,.56-1.35,.56Zm-19.56-3.18c-.34,0-.66,.14-.91,.38-.24,.23-.38,.57-.38,.9,0,.71,.58,1.29,1.29,1.29H27.66c.34,0,.66-.14,.91-.38,.24-.25,.37-.57,.37-.91,0-.71-.57-1.28-1.28-1.28H8.1Z"/>
        <path class="cls-1" d="M17.88,28.46c-.52,0-1.02-.22-1.37-.61l-4.54-5.11c-.49-.55-.6-1.3-.3-1.97s.94-1.08,1.67-1.08h1.16v-5.62c0-1.01,.82-1.83,1.83-1.83h3.11c1,0,1.82,.82,1.82,1.83v5.62h1.16c.73,0,1.37,.41,1.67,1.08s.18,1.42-.3,1.97l-4.54,5.11c-.36,.39-.86,.61-1.38,.61Zm-4.54-8.15c-.49,0-.9,.26-1.11,.71-.2,.44-.12,.94,.2,1.31l4.54,5.11c.46,.52,1.35,.52,1.82,0l4.54-5.11c.32-.36,.4-.87,.2-1.31s-.62-.71-1.11-.71h-1.46c-.17,0-.31-.14-.31-.31v-5.93c0-.67-.54-1.21-1.21-1.21h-3.11c-.67,0-1.21,.54-1.21,1.21v5.93c0,.17-.14,.31-.31,.31h-1.46Z"/>
        <path class="cls-1" d="M13.24,5.54H6.5c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h6.73c.17,0,.31,.14,.31,.31s-.14,.31-.3,.31Z"/>
        <path class="cls-1" d="M9.87,7.93h-3.37c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h3.37c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z"/>
      </svg>
      <svg id="hover" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.9 40" v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>

        </defs>
        <path class="cls-1" d="M2.33,37.98s-.06,0-.1-.02c-1.34-.44-2.23-1.69-2.23-3.09V3.27C0,1.46,1.46,0,3.27,0H22.27c.47,0,.94,.1,1.35,.3,.33,.15,.66,.38,.95,.66l1.84,1.84c.12,.12,.13,.33,.01,.44-.12,.12-.31,.13-.42,.01l-1.86-1.86c-.23-.23-.5-.41-.77-.54-.34-.15-.72-.24-1.1-.24H3.27C1.8,.62,.62,1.8,.62,3.27v31.61c0,1.14,.73,2.15,1.81,2.51,.16,.06,.25,.23,.2,.39-.05,.12-.17,.21-.3,.21Z"/>
        <path class="cls-1" d="M30.63,40H5.13c-1.8,0-3.27-1.46-3.27-3.27V5.13c0-1.8,1.46-3.27,3.27-3.27H24.13c.47,0,.94,.1,1.35,.3,.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.19,.42,.3,.89,.3,1.35v25.1c0,1.8-1.46,3.27-3.27,3.27ZM5.13,2.47c-1.46,0-2.65,1.19-2.65,2.65v31.61c0,1.46,1.19,2.65,2.65,2.65H30.63c1.46,0,2.65-1.19,2.65-2.65V11.63c0-.38-.08-.76-.24-1.1-.13-.29-.31-.55-.54-.78l-6.5-6.51c-.23-.23-.49-.41-.77-.53-.34-.15-.72-.24-1.1-.24H5.13Z"/>
        <path class="cls-1" d="M33.32,10.71h-6.34c-1.06,0-1.93-.87-1.93-1.94V2.43c0-.1,.06-.2,.14-.26s.2-.07,.3-.02c.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.04,.09,.03,.2-.02,.3s-.15,.15-.25,.15Zm-7.66-7.75v5.82c0,.73,.59,1.32,1.32,1.32h5.82c-.09-.12-.18-.23-.29-.34l-6.5-6.51c-.11-.1-.22-.2-.34-.28Z"/>
        <path class="cls-1" d="M27.66,34.73H8.1c-1.05,0-1.9-.86-1.9-1.9,0-.5,.2-.99,.56-1.34,.36-.36,.84-.55,1.34-.55H27.66c1.05,0,1.9,.85,1.9,1.9,0,.5-.2,.98-.55,1.34-.36,.36-.84,.56-1.35,.56Zm-19.56-3.18c-.34,0-.66,.14-.91,.38-.24,.23-.38,.57-.38,.9,0,.71,.58,1.29,1.29,1.29H27.66c.34,0,.66-.14,.91-.38,.24-.25,.37-.57,.37-.91,0-.71-.57-1.28-1.28-1.28H8.1Z"/>
        <path class="cls-1" d="M17.88,28.46c-.52,0-1.02-.22-1.37-.61l-4.54-5.11c-.49-.55-.6-1.3-.3-1.97s.94-1.08,1.67-1.08h1.16v-5.62c0-1.01,.82-1.83,1.83-1.83h3.11c1,0,1.82,.82,1.82,1.83v5.62h1.16c.73,0,1.37,.41,1.67,1.08s.18,1.42-.3,1.97l-4.54,5.11c-.36,.39-.86,.61-1.38,.61Zm-4.54-8.15c-.49,0-.9,.26-1.11,.71-.2,.44-.12,.94,.2,1.31l4.54,5.11c.46,.52,1.35,.52,1.82,0l4.54-5.11c.32-.36,.4-.87,.2-1.31s-.62-.71-1.11-.71h-1.46c-.17,0-.31-.14-.31-.31v-5.93c0-.67-.54-1.21-1.21-1.21h-3.11c-.67,0-1.21,.54-1.21,1.21v5.93c0,.17-.14,.31-.31,.31h-1.46Z"/>
        <path class="cls-1" d="M13.24,5.54H6.5c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h6.73c.17,0,.31,.14,.31,.31s-.14,.31-.3,.31Z"/>
        <path class="cls-1" d="M9.87,7.93h-3.37c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h3.37c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z"/>
      </svg>
      <svg id="hover_dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.9 40" v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>

        </defs>
        <path class="cls-1" d="M2.33,37.98s-.06,0-.1-.02c-1.34-.44-2.23-1.69-2.23-3.09V3.27C0,1.46,1.46,0,3.27,0H22.27c.47,0,.94,.1,1.35,.3,.33,.15,.66,.38,.95,.66l1.84,1.84c.12,.12,.13,.33,.01,.44-.12,.12-.31,.13-.42,.01l-1.86-1.86c-.23-.23-.5-.41-.77-.54-.34-.15-.72-.24-1.1-.24H3.27C1.8,.62,.62,1.8,.62,3.27v31.61c0,1.14,.73,2.15,1.81,2.51,.16,.06,.25,.23,.2,.39-.05,.12-.17,.21-.3,.21Z"/>
        <path class="cls-1" d="M30.63,40H5.13c-1.8,0-3.27-1.46-3.27-3.27V5.13c0-1.8,1.46-3.27,3.27-3.27H24.13c.47,0,.94,.1,1.35,.3,.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.19,.42,.3,.89,.3,1.35v25.1c0,1.8-1.46,3.27-3.27,3.27ZM5.13,2.47c-1.46,0-2.65,1.19-2.65,2.65v31.61c0,1.46,1.19,2.65,2.65,2.65H30.63c1.46,0,2.65-1.19,2.65-2.65V11.63c0-.38-.08-.76-.24-1.1-.13-.29-.31-.55-.54-.78l-6.5-6.51c-.23-.23-.49-.41-.77-.53-.34-.15-.72-.24-1.1-.24H5.13Z"/>
        <path class="cls-1" d="M33.32,10.71h-6.34c-1.06,0-1.93-.87-1.93-1.94V2.43c0-.1,.06-.2,.14-.26s.2-.07,.3-.02c.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.04,.09,.03,.2-.02,.3s-.15,.15-.25,.15Zm-7.66-7.75v5.82c0,.73,.59,1.32,1.32,1.32h5.82c-.09-.12-.18-.23-.29-.34l-6.5-6.51c-.11-.1-.22-.2-.34-.28Z"/>
        <path class="cls-1" d="M27.66,34.73H8.1c-1.05,0-1.9-.86-1.9-1.9,0-.5,.2-.99,.56-1.34,.36-.36,.84-.55,1.34-.55H27.66c1.05,0,1.9,.85,1.9,1.9,0,.5-.2,.98-.55,1.34-.36,.36-.84,.56-1.35,.56Zm-19.56-3.18c-.34,0-.66,.14-.91,.38-.24,.23-.38,.57-.38,.9,0,.71,.58,1.29,1.29,1.29H27.66c.34,0,.66-.14,.91-.38,.24-.25,.37-.57,.37-.91,0-.71-.57-1.28-1.28-1.28H8.1Z"/>
        <path class="cls-1" d="M17.88,28.46c-.52,0-1.02-.22-1.37-.61l-4.54-5.11c-.49-.55-.6-1.3-.3-1.97s.94-1.08,1.67-1.08h1.16v-5.62c0-1.01,.82-1.83,1.83-1.83h3.11c1,0,1.82,.82,1.82,1.83v5.62h1.16c.73,0,1.37,.41,1.67,1.08s.18,1.42-.3,1.97l-4.54,5.11c-.36,.39-.86,.61-1.38,.61Zm-4.54-8.15c-.49,0-.9,.26-1.11,.71-.2,.44-.12,.94,.2,1.31l4.54,5.11c.46,.52,1.35,.52,1.82,0l4.54-5.11c.32-.36,.4-.87,.2-1.31s-.62-.71-1.11-.71h-1.46c-.17,0-.31-.14-.31-.31v-5.93c0-.67-.54-1.21-1.21-1.21h-3.11c-.67,0-1.21,.54-1.21,1.21v5.93c0,.17-.14,.31-.31,.31h-1.46Z"/>
        <path class="cls-1" d="M13.24,5.54H6.5c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h6.73c.17,0,.31,.14,.31,.31s-.14,.31-.3,.31Z"/>
        <path class="cls-1" d="M9.87,7.93h-3.37c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h3.37c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z"/>
      </svg>
      <svg id="mobile" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.9 40" v-if="device == 'MOBILE'">
        <defs>

        </defs>
        <path class="cls-1" d="M2.33,37.98s-.06,0-.1-.02c-1.34-.44-2.23-1.69-2.23-3.09V3.27C0,1.46,1.46,0,3.27,0H22.27c.47,0,.94,.1,1.35,.3,.33,.15,.66,.38,.95,.66l1.84,1.84c.12,.12,.13,.33,.01,.44-.12,.12-.31,.13-.42,.01l-1.86-1.86c-.23-.23-.5-.41-.77-.54-.34-.15-.72-.24-1.1-.24H3.27C1.8,.62,.62,1.8,.62,3.27v31.61c0,1.14,.73,2.15,1.81,2.51,.16,.06,.25,.23,.2,.39-.05,.12-.17,.21-.3,.21Z"/>
        <path class="cls-1" d="M30.63,40H5.13c-1.8,0-3.27-1.46-3.27-3.27V5.13c0-1.8,1.46-3.27,3.27-3.27H24.13c.47,0,.94,.1,1.35,.3,.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.19,.42,.3,.89,.3,1.35v25.1c0,1.8-1.46,3.27-3.27,3.27ZM5.13,2.47c-1.46,0-2.65,1.19-2.65,2.65v31.61c0,1.46,1.19,2.65,2.65,2.65H30.63c1.46,0,2.65-1.19,2.65-2.65V11.63c0-.38-.08-.76-.24-1.1-.13-.29-.31-.55-.54-.78l-6.5-6.51c-.23-.23-.49-.41-.77-.53-.34-.15-.72-.24-1.1-.24H5.13Z"/>
        <path class="cls-1" d="M33.32,10.71h-6.34c-1.06,0-1.93-.87-1.93-1.94V2.43c0-.1,.06-.2,.14-.26s.2-.07,.3-.02c.34,.15,.66,.38,.95,.65l6.5,6.51c.28,.28,.51,.61,.66,.96,.04,.09,.03,.2-.02,.3s-.15,.15-.25,.15Zm-7.66-7.75v5.82c0,.73,.59,1.32,1.32,1.32h5.82c-.09-.12-.18-.23-.29-.34l-6.5-6.51c-.11-.1-.22-.2-.34-.28Z"/>
        <path class="cls-1" d="M27.66,34.73H8.1c-1.05,0-1.9-.86-1.9-1.9,0-.5,.2-.99,.56-1.34,.36-.36,.84-.55,1.34-.55H27.66c1.05,0,1.9,.85,1.9,1.9,0,.5-.2,.98-.55,1.34-.36,.36-.84,.56-1.35,.56Zm-19.56-3.18c-.34,0-.66,.14-.91,.38-.24,.23-.38,.57-.38,.9,0,.71,.58,1.29,1.29,1.29H27.66c.34,0,.66-.14,.91-.38,.24-.25,.37-.57,.37-.91,0-.71-.57-1.28-1.28-1.28H8.1Z"/>
        <path class="cls-1" d="M17.88,28.46c-.52,0-1.02-.22-1.37-.61l-4.54-5.11c-.49-.55-.6-1.3-.3-1.97s.94-1.08,1.67-1.08h1.16v-5.62c0-1.01,.82-1.83,1.83-1.83h3.11c1,0,1.82,.82,1.82,1.83v5.62h1.16c.73,0,1.37,.41,1.67,1.08s.18,1.42-.3,1.97l-4.54,5.11c-.36,.39-.86,.61-1.38,.61Zm-4.54-8.15c-.49,0-.9,.26-1.11,.71-.2,.44-.12,.94,.2,1.31l4.54,5.11c.46,.52,1.35,.52,1.82,0l4.54-5.11c.32-.36,.4-.87,.2-1.31s-.62-.71-1.11-.71h-1.46c-.17,0-.31-.14-.31-.31v-5.93c0-.67-.54-1.21-1.21-1.21h-3.11c-.67,0-1.21,.54-1.21,1.21v5.93c0,.17-.14,.31-.31,.31h-1.46Z"/>
        <path class="cls-1" d="M13.24,5.54H6.5c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h6.73c.17,0,.31,.14,.31,.31s-.14,.31-.3,.31Z"/>
        <path class="cls-1" d="M9.87,7.93h-3.37c-.17,0-.31-.14-.31-.31s.14-.31,.31-.31h3.37c.17,0,.31,.14,.31,.31s-.14,.31-.31,.31Z"/>
      </svg>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
#mobile {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#light {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#dark {
  .cls-1 {
    fill: #f9f9f9;
  }
}

#hover {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}

#hover_dark {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}


.dp-menu-item:hover, .dp-menu-item.active {
  #hover, #hover_dark {
    display: block;
  }
  #light, #dark {
    display: none;
  }
}
</style>
