<template>
  <div>
    <div class="table-container" v-if="$store.state.appConfig.windowWidth >= 1079">
      <div class="sample-table-list" v-if="$store.state.ancientDatabase.sampleDetail === null">
        <table class="sample-table">
          <thead>
            <tr>
              <th scope="col" width="30">Population</th>
              <th scope="col">Date</th>
              <th scope="col" class="text-center">Sample(s)</th>
              <th scope="col" class="text-center">Y-DNA</th>
              <th scope="col" class="text-center">mtDNA</th>
              <th scope="col">location</th>
              <th scope="col" class="text-center">Details</th>
            </tr>
          </thead>
          <tbody class="cs-scroll-style">
            <template v-if="popList && !$store.getters.getLoader">
              <tr v-for="item in popList" :key="item.id">
              <td width="30">{{item.title}}</td>
              <td>
                {{item.date}}
              </td>
              <td class="text-center">{{ item.samples.split(',').length }}</td>
              <td class="text-center">{{item.yDna.split(',').length}}</td>
              <td class="text-center">{{item.mtDna.split(',').length}}</td>
              <td>{{item.location}}</td>
              <td class="text-center">
                <span class="table-action table-action-edit lab-p-8" @click="openDetail(item)">
                  <feather-icon
                    icon="ArrowRightIcon"
                    class="cursor-pointer lab-text-steel"
                    size="25"
                  />
                </span>
              </td>
            </tr>
            </template>
            <SpinnerContainer v-else />
          </tbody>
        </table>
        <div class="pagination-container">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" >
              <span class="text-muted">
                Showing 
                {{ (pagination.PageIndex - 1) * pagination.PageSize }} to 
                {{ pagination.PageIndex * pagination.PageSize }} of {{ pagination.Count }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="pagination.PageIndex"
                :total-rows="pagination.Count"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="custom-pagination"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>

          </b-row>
        </div>
      </div>
      
      <AncientSampleDetail class="sample-table-detail" v-else/>
    </div>
    
    <div class="table-container" v-else>
      <div class="encyclopedia-mobile">
        <div v-for="(item, index) in popList" :key="index">
          <div class="encyclopedia-mobile-item dashboard-card">
            <div>
              <div class="mobile-item-top">
                <div class="item-info left-info">
                  <h5>Population</h5>
                  <h6>{{item.title}}</h6>
                </div>
                <div class="item-info right-info">
                  <h5>Location</h5>
                  <h6>{{item.location}}</h6>
                </div>
              </div>
              <div class="mobile-item-bottom">
                <div class="item-info left-info">
                  <h5>Date</h5>
                  <h6>{{item.date}}</h6>
                </div>
                <div class="item-info right-info">
                  <h5>Sample(s)</h5>
                  <h6>{{item.samples.split(',').length}}</h6>
                </div>
              </div>
            </div>
            <div class="mobile-encyclopedia-icon">
              <span class="cursor-pointer" @click="openDetailMobile(item, index)"> 
                <circle-plus-icon v-show="!item.isOpenDetail" className="encyclopedia"/>
                <circle-x-icon v-show="item.isOpenDetail" className="encyclopedia"/>
              </span>
            </div>
          </div>

          <AncientSampleDetailMobile v-if="$store.state.ancientDatabase.sampleDetail && item.isOpenDetail" />
        </div>
      </div>

      <div class="mt-4">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1" >
            <span class="text-muted">
              Showing 
              {{ (pagination.PageIndex - 1) * pagination.PageSize }} to 
              {{ pagination.PageIndex * pagination.PageSize }} of {{ pagination.Count }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
          <b-pagination
              v-model="pagination.PageIndex"
              :total-rows="pagination.Count"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="custom-pagination"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import CirclePlusIcon from '@/layouts/icons/CirclePlusIcon.vue';
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';
import AncientSampleDetail from './AncientSampleDetail.vue';
import AncientSampleDetailMobile from './AncientSampleDetailMobile.vue';


export default {
  components: {
    AncientSampleDetail,
    AncientSampleDetailMobile,
    CirclePlusIcon,
    CircleXIcon,
  },
  data() {
    return {
    }
  },
  methods: {
    openDetail(item) {
      this.$store.state.ancientDatabase.sampleDetail = item;
    },
    openDetailMobile(item, index) {
      item.isOpenDetail = !item.isOpenDetail;
      if (item.isOpenDetail) {
        this.$store.state.ancientDatabase.sampleDetail = item;
      } else {
        this.$store.state.ancientDatabase.sampleDetail = null;
      }

      this.popList.forEach((element, indexItem) => {
        if (indexItem !== index) {
          element.isOpenDetail = false;
        }
      });
    },
  },
  computed: {
    pagination() {
      return this.$store.state.sampleDatabase.filterQuery;
    },
    popList() {
      return this.$store.getters['ancientDatabase/getAncientDatabaseList'];
    },
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.dark-layout {
  .table-action {
    background:#13181c !important;
    color:#7a7a7a!important;
  }
}

</style>
