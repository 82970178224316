<template>
    <div class="g25-main-icon">
      <svg id="light" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40" v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M38.41,33.7l-.12-12.59c0-.17-.12-.34-.25-.42l-10.98-5.9c-.14-.08-.32-.08-.46,0l-10.86,6.11c-.13,.07-.24,.25-.24,.42l.12,12.59c0,.18,.1,.34,.26,.42,.07,.03,11.05,5.64,10.99,5.61,.23,.11,.36,0,.43,0l10.86-5.82c.16-.08,.25-.25,.25-.43ZM26.83,15.75l10,5.38-9.89,5.9-10-5.71,9.89-5.57Zm-10.38,6.4l10.02,5.71,.11,10.87-10.01-5.12-.11-11.47Zm11.08,16.58l-.11-10.87,9.9-5.91,.11,11.47-9.91,5.31Z"/>
        <path class="cls-1" d="M26.94,23.16h.03c1.43-.01,2.34-.94,2.33-1.86,0-.93-.94-1.82-2.36-1.82-1.43,.01-2.34,.94-2.33,1.86,0,.93,.93,1.82,2.33,1.82Z"/>
        <path class="cls-1" d="M29.48,28.51c-.48,.27-.64,.87-.37,1.34,.43,.77,1.57,.62,1.8-.22,.23-.84-.67-1.55-1.43-1.12Z"/>
        <path class="cls-1" d="M29.48,34.12c-.47,.27-.64,.87-.37,1.34,.27,.47,.86,.64,1.34,.37,.47-.27,.64-.87,.37-1.34-.27-.47-.87-.64-1.34-.37Z"/>
        <path class="cls-1" d="M22.02,29.01c-.95-.46-1.98,.46-1.64,1.45,.35,1,1.73,1.07,2.19,.13,.29-.59,.04-1.3-.55-1.59Z"/>
        <path class="cls-1" d="M20.33,26.16c-.1-.3-.32-.54-.6-.68-.59-.29-1.3-.04-1.59,.55-.38,.79,.19,1.7,1.06,1.7,.82,0,1.39-.8,1.12-1.57Z"/>
        <path class="cls-1" d="M23.28,33.24c-1.05,.59-.64,2.22,.58,2.22,.9,0,1.47-.98,1.03-1.77-.32-.57-1.04-.78-1.61-.45Z"/>
        <path class="cls-1" d="M34.98,27.07c.83,.23,1.56-.66,1.12-1.43-.43-.76-1.57-.62-1.8,.22-.15,.52,.16,1.07,.68,1.21Z"/>
        <path class="cls-1" d="M34.3,31.87c-.24,.84,.67,1.55,1.43,1.12,.47-.27,.64-.86,.37-1.34-.43-.76-1.57-.62-1.8,.22Z"/>
        <path class="cls-1" d="M.06,11.25l6.01,10.76c.06,.06,.13,.22,.37,.24,8.36,.81,7.27,.71,7.35,.71,.24,0,.45-.18,.48-.43,.03-.26-.17-.5-.43-.52l-6.51-.63,5.95-9.1,11.52,.59-.31,.47c-.14,.22-.08,.52,.14,.66,.22,.14,.52,.08,.66-.14l.76-1.17c.08-.13,.1-.34,.02-.49L20.22,1.2c-.08-.14-.22-.24-.39-.25L7.41,0c-.15-.01-.35,.09-.44,.22L.08,10.76c-.1,.15-.1,.34-.02,.49Zm24.77,.66l-11.5-.59L8.17,1.02l11.32,.86,5.33,10.03ZM7.31,1.44l5.16,10.32-5.95,9.1L1.04,11.04,7.31,1.44Z"/>
        <path class="cls-1" d="M13.79,15.58c.57,.66,1.66,.3,1.73-.57,.04-.54-.37-1.01-.91-1.06-.86-.07-1.39,.96-.82,1.62Z"/>
        <path class="cls-1" d="M10.78,18.86c-.67,.58-.29,1.66,.57,1.73,.55,.04,1.01-.37,1.06-.91,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M5.99,11.66c.27,.47,.88,.63,1.35,.35,.75-.44,.6-1.58-.25-1.8-.84-.22-1.54,.69-1.1,1.45h0Z"/>
        <path class="cls-1" d="M7.66,7.36c.47-.27,.63-.88,.35-1.35-.27-.47-.88-.63-1.35-.35-.47,.27-.63,.88-.35,1.35,.27,.47,.88,.63,1.35,.35Z"/>
        <path class="cls-1" d="M6.84,17.33c.66-.57,.3-1.66-.57-1.73-.54-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M2.63,10.56c-.41,.35-.46,.97-.11,1.39,.57,.66,1.66,.31,1.73-.57,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M10.28,12.89c.51,0,.94-.39,.98-.91h0c.04-.54-.37-1.01-.91-1.06-.53-.04-1.01,.37-1.06,.91-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M19.25,5.56c.66-.57,.3-1.66-.57-1.73-.55-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M15.42,5.54c.84,0,1.29-.99,.74-1.62-.57-.67-1.66-.3-1.73,.57-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M12.11,5.26c.51,0,.94-.39,.98-.91,.07-.87-.96-1.39-1.62-.82-.2,.17-.32,.41-.34,.67-.04,.57,.4,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M21.13,10.08h.01c.54,0,.98-.43,.98-.97,.01-.87-1.05-1.33-1.67-.72-.38,.37-.4,.99-.02,1.39,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M17.92,9.99h.01c.26,0,.5-.1,.69-.28,.62-.61,.2-1.68-.68-1.69-.54,0-.99,.43-1,.97,0,.54,.43,.99,.97,1Z"/>
        <path class="cls-1" d="M14.54,9.76h.01c.86,0,1.31-1.05,.7-1.67-.18-.19-.43-.29-.69-.3-.87,0-1.33,1.05-.72,1.67,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M19.76,16.07c.35,.41,.97,.46,1.39,.11,.66-.57,.3-1.66-.57-1.73-.87-.06-1.39,.96-.82,1.62Z"/>
      </svg>
      <svg id="dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40" v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M38.41,33.7l-.12-12.59c0-.17-.12-.34-.25-.42l-10.98-5.9c-.14-.08-.32-.08-.46,0l-10.86,6.11c-.13,.07-.24,.25-.24,.42l.12,12.59c0,.18,.1,.34,.26,.42,.07,.03,11.05,5.64,10.99,5.61,.23,.11,.36,0,.43,0l10.86-5.82c.16-.08,.25-.25,.25-.43ZM26.83,15.75l10,5.38-9.89,5.9-10-5.71,9.89-5.57Zm-10.38,6.4l10.02,5.71,.11,10.87-10.01-5.12-.11-11.47Zm11.08,16.58l-.11-10.87,9.9-5.91,.11,11.47-9.91,5.31Z"/>
        <path class="cls-1" d="M26.94,23.16h.03c1.43-.01,2.34-.94,2.33-1.86,0-.93-.94-1.82-2.36-1.82-1.43,.01-2.34,.94-2.33,1.86,0,.93,.93,1.82,2.33,1.82Z"/>
        <path class="cls-1" d="M29.48,28.51c-.48,.27-.64,.87-.37,1.34,.43,.77,1.57,.62,1.8-.22,.23-.84-.67-1.55-1.43-1.12Z"/>
        <path class="cls-1" d="M29.48,34.12c-.47,.27-.64,.87-.37,1.34,.27,.47,.86,.64,1.34,.37,.47-.27,.64-.87,.37-1.34-.27-.47-.87-.64-1.34-.37Z"/>
        <path class="cls-1" d="M22.02,29.01c-.95-.46-1.98,.46-1.64,1.45,.35,1,1.73,1.07,2.19,.13,.29-.59,.04-1.3-.55-1.59Z"/>
        <path class="cls-1" d="M20.33,26.16c-.1-.3-.32-.54-.6-.68-.59-.29-1.3-.04-1.59,.55-.38,.79,.19,1.7,1.06,1.7,.82,0,1.39-.8,1.12-1.57Z"/>
        <path class="cls-1" d="M23.28,33.24c-1.05,.59-.64,2.22,.58,2.22,.9,0,1.47-.98,1.03-1.77-.32-.57-1.04-.78-1.61-.45Z"/>
        <path class="cls-1" d="M34.98,27.07c.83,.23,1.56-.66,1.12-1.43-.43-.76-1.57-.62-1.8,.22-.15,.52,.16,1.07,.68,1.21Z"/>
        <path class="cls-1" d="M34.3,31.87c-.24,.84,.67,1.55,1.43,1.12,.47-.27,.64-.86,.37-1.34-.43-.76-1.57-.62-1.8,.22Z"/>
        <path class="cls-1" d="M.06,11.25l6.01,10.76c.06,.06,.13,.22,.37,.24,8.36,.81,7.27,.71,7.35,.71,.24,0,.45-.18,.48-.43,.03-.26-.17-.5-.43-.52l-6.51-.63,5.95-9.1,11.52,.59-.31,.47c-.14,.22-.08,.52,.14,.66,.22,.14,.52,.08,.66-.14l.76-1.17c.08-.13,.1-.34,.02-.49L20.22,1.2c-.08-.14-.22-.24-.39-.25L7.41,0c-.15-.01-.35,.09-.44,.22L.08,10.76c-.1,.15-.1,.34-.02,.49Zm24.77,.66l-11.5-.59L8.17,1.02l11.32,.86,5.33,10.03ZM7.31,1.44l5.16,10.32-5.95,9.1L1.04,11.04,7.31,1.44Z"/>
        <path class="cls-1" d="M13.79,15.58c.57,.66,1.66,.3,1.73-.57,.04-.54-.37-1.01-.91-1.06-.86-.07-1.39,.96-.82,1.62Z"/>
        <path class="cls-1" d="M10.78,18.86c-.67,.58-.29,1.66,.57,1.73,.55,.04,1.01-.37,1.06-.91,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M5.99,11.66c.27,.47,.88,.63,1.35,.35,.75-.44,.6-1.58-.25-1.8-.84-.22-1.54,.69-1.1,1.45h0Z"/>
        <path class="cls-1" d="M7.66,7.36c.47-.27,.63-.88,.35-1.35-.27-.47-.88-.63-1.35-.35-.47,.27-.63,.88-.35,1.35,.27,.47,.88,.63,1.35,.35Z"/>
        <path class="cls-1" d="M6.84,17.33c.66-.57,.3-1.66-.57-1.73-.54-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M2.63,10.56c-.41,.35-.46,.97-.11,1.39,.57,.66,1.66,.31,1.73-.57,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M10.28,12.89c.51,0,.94-.39,.98-.91h0c.04-.54-.37-1.01-.91-1.06-.53-.04-1.01,.37-1.06,.91-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M19.25,5.56c.66-.57,.3-1.66-.57-1.73-.55-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M15.42,5.54c.84,0,1.29-.99,.74-1.62-.57-.67-1.66-.3-1.73,.57-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M12.11,5.26c.51,0,.94-.39,.98-.91,.07-.87-.96-1.39-1.62-.82-.2,.17-.32,.41-.34,.67-.04,.57,.4,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M21.13,10.08h.01c.54,0,.98-.43,.98-.97,.01-.87-1.05-1.33-1.67-.72-.38,.37-.4,.99-.02,1.39,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M17.92,9.99h.01c.26,0,.5-.1,.69-.28,.62-.61,.2-1.68-.68-1.69-.54,0-.99,.43-1,.97,0,.54,.43,.99,.97,1Z"/>
        <path class="cls-1" d="M14.54,9.76h.01c.86,0,1.31-1.05,.7-1.67-.18-.19-.43-.29-.69-.3-.87,0-1.33,1.05-.72,1.67,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M19.76,16.07c.35,.41,.97,.46,1.39,.11,.66-.57,.3-1.66-.57-1.73-.87-.06-1.39,.96-.82,1.62Z"/>
      </svg>
      <svg id="hover" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40" v-if="variant == 'light' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M38.41,33.7l-.12-12.59c0-.17-.12-.34-.25-.42l-10.98-5.9c-.14-.08-.32-.08-.46,0l-10.86,6.11c-.13,.07-.24,.25-.24,.42l.12,12.59c0,.18,.1,.34,.26,.42,.07,.03,11.05,5.64,10.99,5.61,.23,.11,.36,0,.43,0l10.86-5.82c.16-.08,.25-.25,.25-.43ZM26.83,15.75l10,5.38-9.89,5.9-10-5.71,9.89-5.57Zm-10.38,6.4l10.02,5.71,.11,10.87-10.01-5.12-.11-11.47Zm11.08,16.58l-.11-10.87,9.9-5.91,.11,11.47-9.91,5.31Z"/>
        <path class="cls-1" d="M26.94,23.16h.03c1.43-.01,2.34-.94,2.33-1.86,0-.93-.94-1.82-2.36-1.82-1.43,.01-2.34,.94-2.33,1.86,0,.93,.93,1.82,2.33,1.82Z"/>
        <path class="cls-1" d="M29.48,28.51c-.48,.27-.64,.87-.37,1.34,.43,.77,1.57,.62,1.8-.22,.23-.84-.67-1.55-1.43-1.12Z"/>
        <path class="cls-1" d="M29.48,34.12c-.47,.27-.64,.87-.37,1.34,.27,.47,.86,.64,1.34,.37,.47-.27,.64-.87,.37-1.34-.27-.47-.87-.64-1.34-.37Z"/>
        <path class="cls-1" d="M22.02,29.01c-.95-.46-1.98,.46-1.64,1.45,.35,1,1.73,1.07,2.19,.13,.29-.59,.04-1.3-.55-1.59Z"/>
        <path class="cls-1" d="M20.33,26.16c-.1-.3-.32-.54-.6-.68-.59-.29-1.3-.04-1.59,.55-.38,.79,.19,1.7,1.06,1.7,.82,0,1.39-.8,1.12-1.57Z"/>
        <path class="cls-1" d="M23.28,33.24c-1.05,.59-.64,2.22,.58,2.22,.9,0,1.47-.98,1.03-1.77-.32-.57-1.04-.78-1.61-.45Z"/>
        <path class="cls-1" d="M34.98,27.07c.83,.23,1.56-.66,1.12-1.43-.43-.76-1.57-.62-1.8,.22-.15,.52,.16,1.07,.68,1.21Z"/>
        <path class="cls-1" d="M34.3,31.87c-.24,.84,.67,1.55,1.43,1.12,.47-.27,.64-.86,.37-1.34-.43-.76-1.57-.62-1.8,.22Z"/>
        <path class="cls-1" d="M.06,11.25l6.01,10.76c.06,.06,.13,.22,.37,.24,8.36,.81,7.27,.71,7.35,.71,.24,0,.45-.18,.48-.43,.03-.26-.17-.5-.43-.52l-6.51-.63,5.95-9.1,11.52,.59-.31,.47c-.14,.22-.08,.52,.14,.66,.22,.14,.52,.08,.66-.14l.76-1.17c.08-.13,.1-.34,.02-.49L20.22,1.2c-.08-.14-.22-.24-.39-.25L7.41,0c-.15-.01-.35,.09-.44,.22L.08,10.76c-.1,.15-.1,.34-.02,.49Zm24.77,.66l-11.5-.59L8.17,1.02l11.32,.86,5.33,10.03ZM7.31,1.44l5.16,10.32-5.95,9.1L1.04,11.04,7.31,1.44Z"/>
        <path class="cls-1" d="M13.79,15.58c.57,.66,1.66,.3,1.73-.57,.04-.54-.37-1.01-.91-1.06-.86-.07-1.39,.96-.82,1.62Z"/>
        <path class="cls-1" d="M10.78,18.86c-.67,.58-.29,1.66,.57,1.73,.55,.04,1.01-.37,1.06-.91,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M5.99,11.66c.27,.47,.88,.63,1.35,.35,.75-.44,.6-1.58-.25-1.8-.84-.22-1.54,.69-1.1,1.45h0Z"/>
        <path class="cls-1" d="M7.66,7.36c.47-.27,.63-.88,.35-1.35-.27-.47-.88-.63-1.35-.35-.47,.27-.63,.88-.35,1.35,.27,.47,.88,.63,1.35,.35Z"/>
        <path class="cls-1" d="M6.84,17.33c.66-.57,.3-1.66-.57-1.73-.54-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M2.63,10.56c-.41,.35-.46,.97-.11,1.39,.57,.66,1.66,.31,1.73-.57,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M10.28,12.89c.51,0,.94-.39,.98-.91h0c.04-.54-.37-1.01-.91-1.06-.53-.04-1.01,.37-1.06,.91-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M19.25,5.56c.66-.57,.3-1.66-.57-1.73-.55-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M15.42,5.54c.84,0,1.29-.99,.74-1.62-.57-.67-1.66-.3-1.73,.57-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M12.11,5.26c.51,0,.94-.39,.98-.91,.07-.87-.96-1.39-1.62-.82-.2,.17-.32,.41-.34,.67-.04,.57,.4,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M21.13,10.08h.01c.54,0,.98-.43,.98-.97,.01-.87-1.05-1.33-1.67-.72-.38,.37-.4,.99-.02,1.39,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M17.92,9.99h.01c.26,0,.5-.1,.69-.28,.62-.61,.2-1.68-.68-1.69-.54,0-.99,.43-1,.97,0,.54,.43,.99,.97,1Z"/>
        <path class="cls-1" d="M14.54,9.76h.01c.86,0,1.31-1.05,.7-1.67-.18-.19-.43-.29-.69-.3-.87,0-1.33,1.05-.72,1.67,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M19.76,16.07c.35,.41,.97,.46,1.39,.11,.66-.57,.3-1.66-.57-1.73-.87-.06-1.39,.96-.82,1.62Z"/>
      </svg>
      <svg id="hover_dark" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40" v-if="variant == 'dark' && device == 'DESKTOP'">
        <defs>
        </defs>
        <path class="cls-1" d="M38.41,33.7l-.12-12.59c0-.17-.12-.34-.25-.42l-10.98-5.9c-.14-.08-.32-.08-.46,0l-10.86,6.11c-.13,.07-.24,.25-.24,.42l.12,12.59c0,.18,.1,.34,.26,.42,.07,.03,11.05,5.64,10.99,5.61,.23,.11,.36,0,.43,0l10.86-5.82c.16-.08,.25-.25,.25-.43ZM26.83,15.75l10,5.38-9.89,5.9-10-5.71,9.89-5.57Zm-10.38,6.4l10.02,5.71,.11,10.87-10.01-5.12-.11-11.47Zm11.08,16.58l-.11-10.87,9.9-5.91,.11,11.47-9.91,5.31Z"/>
        <path class="cls-1" d="M26.94,23.16h.03c1.43-.01,2.34-.94,2.33-1.86,0-.93-.94-1.82-2.36-1.82-1.43,.01-2.34,.94-2.33,1.86,0,.93,.93,1.82,2.33,1.82Z"/>
        <path class="cls-1" d="M29.48,28.51c-.48,.27-.64,.87-.37,1.34,.43,.77,1.57,.62,1.8-.22,.23-.84-.67-1.55-1.43-1.12Z"/>
        <path class="cls-1" d="M29.48,34.12c-.47,.27-.64,.87-.37,1.34,.27,.47,.86,.64,1.34,.37,.47-.27,.64-.87,.37-1.34-.27-.47-.87-.64-1.34-.37Z"/>
        <path class="cls-1" d="M22.02,29.01c-.95-.46-1.98,.46-1.64,1.45,.35,1,1.73,1.07,2.19,.13,.29-.59,.04-1.3-.55-1.59Z"/>
        <path class="cls-1" d="M20.33,26.16c-.1-.3-.32-.54-.6-.68-.59-.29-1.3-.04-1.59,.55-.38,.79,.19,1.7,1.06,1.7,.82,0,1.39-.8,1.12-1.57Z"/>
        <path class="cls-1" d="M23.28,33.24c-1.05,.59-.64,2.22,.58,2.22,.9,0,1.47-.98,1.03-1.77-.32-.57-1.04-.78-1.61-.45Z"/>
        <path class="cls-1" d="M34.98,27.07c.83,.23,1.56-.66,1.12-1.43-.43-.76-1.57-.62-1.8,.22-.15,.52,.16,1.07,.68,1.21Z"/>
        <path class="cls-1" d="M34.3,31.87c-.24,.84,.67,1.55,1.43,1.12,.47-.27,.64-.86,.37-1.34-.43-.76-1.57-.62-1.8,.22Z"/>
        <path class="cls-1" d="M.06,11.25l6.01,10.76c.06,.06,.13,.22,.37,.24,8.36,.81,7.27,.71,7.35,.71,.24,0,.45-.18,.48-.43,.03-.26-.17-.5-.43-.52l-6.51-.63,5.95-9.1,11.52,.59-.31,.47c-.14,.22-.08,.52,.14,.66,.22,.14,.52,.08,.66-.14l.76-1.17c.08-.13,.1-.34,.02-.49L20.22,1.2c-.08-.14-.22-.24-.39-.25L7.41,0c-.15-.01-.35,.09-.44,.22L.08,10.76c-.1,.15-.1,.34-.02,.49Zm24.77,.66l-11.5-.59L8.17,1.02l11.32,.86,5.33,10.03ZM7.31,1.44l5.16,10.32-5.95,9.1L1.04,11.04,7.31,1.44Z"/>
        <path class="cls-1" d="M13.79,15.58c.57,.66,1.66,.3,1.73-.57,.04-.54-.37-1.01-.91-1.06-.86-.07-1.39,.96-.82,1.62Z"/>
        <path class="cls-1" d="M10.78,18.86c-.67,.58-.29,1.66,.57,1.73,.55,.04,1.01-.37,1.06-.91,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M5.99,11.66c.27,.47,.88,.63,1.35,.35,.75-.44,.6-1.58-.25-1.8-.84-.22-1.54,.69-1.1,1.45h0Z"/>
        <path class="cls-1" d="M7.66,7.36c.47-.27,.63-.88,.35-1.35-.27-.47-.88-.63-1.35-.35-.47,.27-.63,.88-.35,1.35,.27,.47,.88,.63,1.35,.35Z"/>
        <path class="cls-1" d="M6.84,17.33c.66-.57,.3-1.66-.57-1.73-.54-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M2.63,10.56c-.41,.35-.46,.97-.11,1.39,.57,.66,1.66,.31,1.73-.57,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M10.28,12.89c.51,0,.94-.39,.98-.91h0c.04-.54-.37-1.01-.91-1.06-.53-.04-1.01,.37-1.06,.91-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M19.25,5.56c.66-.57,.3-1.66-.57-1.73-.55-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M15.42,5.54c.84,0,1.29-.99,.74-1.62-.57-.67-1.66-.3-1.73,.57-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M12.11,5.26c.51,0,.94-.39,.98-.91,.07-.87-.96-1.39-1.62-.82-.2,.17-.32,.41-.34,.67-.04,.57,.4,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M21.13,10.08h.01c.54,0,.98-.43,.98-.97,.01-.87-1.05-1.33-1.67-.72-.38,.37-.4,.99-.02,1.39,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M17.92,9.99h.01c.26,0,.5-.1,.69-.28,.62-.61,.2-1.68-.68-1.69-.54,0-.99,.43-1,.97,0,.54,.43,.99,.97,1Z"/>
        <path class="cls-1" d="M14.54,9.76h.01c.86,0,1.31-1.05,.7-1.67-.18-.19-.43-.29-.69-.3-.87,0-1.33,1.05-.72,1.67,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M19.76,16.07c.35,.41,.97,.46,1.39,.11,.66-.57,.3-1.66-.57-1.73-.87-.06-1.39,.96-.82,1.62Z"/>
      </svg>
      <svg id="mobile" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.41 40" v-if="device == 'MOBILE'">
        <defs>
        </defs>
        <path class="cls-1" d="M38.41,33.7l-.12-12.59c0-.17-.12-.34-.25-.42l-10.98-5.9c-.14-.08-.32-.08-.46,0l-10.86,6.11c-.13,.07-.24,.25-.24,.42l.12,12.59c0,.18,.1,.34,.26,.42,.07,.03,11.05,5.64,10.99,5.61,.23,.11,.36,0,.43,0l10.86-5.82c.16-.08,.25-.25,.25-.43ZM26.83,15.75l10,5.38-9.89,5.9-10-5.71,9.89-5.57Zm-10.38,6.4l10.02,5.71,.11,10.87-10.01-5.12-.11-11.47Zm11.08,16.58l-.11-10.87,9.9-5.91,.11,11.47-9.91,5.31Z"/>
        <path class="cls-1" d="M26.94,23.16h.03c1.43-.01,2.34-.94,2.33-1.86,0-.93-.94-1.82-2.36-1.82-1.43,.01-2.34,.94-2.33,1.86,0,.93,.93,1.82,2.33,1.82Z"/>
        <path class="cls-1" d="M29.48,28.51c-.48,.27-.64,.87-.37,1.34,.43,.77,1.57,.62,1.8-.22,.23-.84-.67-1.55-1.43-1.12Z"/>
        <path class="cls-1" d="M29.48,34.12c-.47,.27-.64,.87-.37,1.34,.27,.47,.86,.64,1.34,.37,.47-.27,.64-.87,.37-1.34-.27-.47-.87-.64-1.34-.37Z"/>
        <path class="cls-1" d="M22.02,29.01c-.95-.46-1.98,.46-1.64,1.45,.35,1,1.73,1.07,2.19,.13,.29-.59,.04-1.3-.55-1.59Z"/>
        <path class="cls-1" d="M20.33,26.16c-.1-.3-.32-.54-.6-.68-.59-.29-1.3-.04-1.59,.55-.38,.79,.19,1.7,1.06,1.7,.82,0,1.39-.8,1.12-1.57Z"/>
        <path class="cls-1" d="M23.28,33.24c-1.05,.59-.64,2.22,.58,2.22,.9,0,1.47-.98,1.03-1.77-.32-.57-1.04-.78-1.61-.45Z"/>
        <path class="cls-1" d="M34.98,27.07c.83,.23,1.56-.66,1.12-1.43-.43-.76-1.57-.62-1.8,.22-.15,.52,.16,1.07,.68,1.21Z"/>
        <path class="cls-1" d="M34.3,31.87c-.24,.84,.67,1.55,1.43,1.12,.47-.27,.64-.86,.37-1.34-.43-.76-1.57-.62-1.8,.22Z"/>
        <path class="cls-1" d="M.06,11.25l6.01,10.76c.06,.06,.13,.22,.37,.24,8.36,.81,7.27,.71,7.35,.71,.24,0,.45-.18,.48-.43,.03-.26-.17-.5-.43-.52l-6.51-.63,5.95-9.1,11.52,.59-.31,.47c-.14,.22-.08,.52,.14,.66,.22,.14,.52,.08,.66-.14l.76-1.17c.08-.13,.1-.34,.02-.49L20.22,1.2c-.08-.14-.22-.24-.39-.25L7.41,0c-.15-.01-.35,.09-.44,.22L.08,10.76c-.1,.15-.1,.34-.02,.49Zm24.77,.66l-11.5-.59L8.17,1.02l11.32,.86,5.33,10.03ZM7.31,1.44l5.16,10.32-5.95,9.1L1.04,11.04,7.31,1.44Z"/>
        <path class="cls-1" d="M13.79,15.58c.57,.66,1.66,.3,1.73-.57,.04-.54-.37-1.01-.91-1.06-.86-.07-1.39,.96-.82,1.62Z"/>
        <path class="cls-1" d="M10.78,18.86c-.67,.58-.29,1.66,.57,1.73,.55,.04,1.01-.37,1.06-.91,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M5.99,11.66c.27,.47,.88,.63,1.35,.35,.75-.44,.6-1.58-.25-1.8-.84-.22-1.54,.69-1.1,1.45h0Z"/>
        <path class="cls-1" d="M7.66,7.36c.47-.27,.63-.88,.35-1.35-.27-.47-.88-.63-1.35-.35-.47,.27-.63,.88-.35,1.35,.27,.47,.88,.63,1.35,.35Z"/>
        <path class="cls-1" d="M6.84,17.33c.66-.57,.3-1.66-.57-1.73-.54-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M2.63,10.56c-.41,.35-.46,.97-.11,1.39,.57,.66,1.66,.31,1.73-.57,.07-.87-.96-1.39-1.62-.82Z"/>
        <path class="cls-1" d="M10.28,12.89c.51,0,.94-.39,.98-.91h0c.04-.54-.37-1.01-.91-1.06-.53-.04-1.01,.37-1.06,.91-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M19.25,5.56c.66-.57,.3-1.66-.57-1.73-.55-.04-1.01,.37-1.06,.91-.07,.87,.96,1.39,1.62,.82Z"/>
        <path class="cls-1" d="M15.42,5.54c.84,0,1.29-.99,.74-1.62-.57-.67-1.66-.3-1.73,.57-.04,.57,.41,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M12.11,5.26c.51,0,.94-.39,.98-.91,.07-.87-.96-1.39-1.62-.82-.2,.17-.32,.41-.34,.67-.04,.57,.4,1.06,.98,1.06Z"/>
        <path class="cls-1" d="M21.13,10.08h.01c.54,0,.98-.43,.98-.97,.01-.87-1.05-1.33-1.67-.72-.38,.37-.4,.99-.02,1.39,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M17.92,9.99h.01c.26,0,.5-.1,.69-.28,.62-.61,.2-1.68-.68-1.69-.54,0-.99,.43-1,.97,0,.54,.43,.99,.97,1Z"/>
        <path class="cls-1" d="M14.54,9.76h.01c.86,0,1.31-1.05,.7-1.67-.18-.19-.43-.29-.69-.3-.87,0-1.33,1.05-.72,1.67,.18,.19,.43,.29,.69,.3Z"/>
        <path class="cls-1" d="M19.76,16.07c.35,.41,.97,.46,1.39,.11,.66-.57,.3-1.66-.57-1.73-.87-.06-1.39,.96-.82,1.62Z"/>
      </svg>

        
    </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    device: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>

#mobile {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#light {
  .cls-1 {
    fill: #2d2d2d;
  }
}

#dark {
  .cls-1 {
    fill: #f9f9f9;
  }
}

#hover {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}

#hover_dark {
  display: none;
  .cls-1 {
    fill: #1bbdc1;
  }
}


.dp-menu-item:hover, .dp-menu-item.active {
  #hover, #hover_dark {
    display: block;
  }
  #light, #dark {
    display: none;
  }
}
</style>
