<template>
  <div class="diy-view-container">
    
    <div class="diy-view-column w-60">
      <div class="lab-dropdown mb-1">
        <label for="custom-dropdown" class="dropdown-label mb-1">Filter target type</label>
        <div class="row">
          <div class="col-sm-6">
            <div class="lab-dropdown-btn" @click="isDropdownOpen = !isDropdownOpen" ref="dropdownType">
              <span class="lab-dropdown-btn-value">
                {{ filteredTargetType || 'All' }}
              </span>
              <span class="lab-dropdown-btn-icon">
                <DropdownOneIcon />
              </span>
            </div>
            <div class="lab-dropdown-content cs-scroll-style" :class="{ 'show': isDropdownOpen}">
              <ul>
                <li v-for="item in targetTypes"
                    :key="item.id"
                    @click="$store.state.diySourceData.filteredTargetType = item.id">
                    {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-6">
            <b-form-input v-model="searchQuery" id="searchSelectedList" :placeholder="$t('Search')" autocomplete="off" />
          </div>
        </div>
      </div>
      <ul class="diy-targets-menu cs-scroll-style arrow-scroll-info" id="target-unselected-list" @wheel="onScroll(`target-unselected-list`)"  @scroll="onScroll(`target-unselected-list`)">
        <li v-for="item in targetUnSelectedList" :key="item.Id">
          <div class="diy-group" @click="toggleGroup(item)">
            <feather-icon
              icon="ChevronDownIcon"
              class="cursor-pointer lab-text-steel"
              size="25"
              v-if="isOpened(item)"
            />
            <feather-icon
              icon="ChevronRightIcon"
              class="cursor-pointer lab-text-steel"
              size="25"
              v-else
            />
            <div class="diy-name">
              {{ replaceName(item.groupName) }}
            </div>
          </div>
          <ul class="diy-targets-sub-menu cs-scroll-style" v-show="isOpened(item)">
            <li class="select-all-li"  @click="selectAll(item)" v-if="item.list.length > 1">
              <span>Select all</span> 
              <input type="checkbox" id="checkbox" :value="false" @change="forceFalse" :checked="false" />
            </li>
            <li v-for="subItem in item.list" :key="subItem.name" @click="moveToSelected(subItem)">
              <div class="diy-group p-0">
                <feather-icon
                  icon="ChevronRightIcon"
                  class="cursor-pointer lab-text-steel"
                  size="25"
                  />
                <div class="diy-name">
                  {{ replaceName(subItem.name.replaceAll(item.groupName + ':', '')) }}
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="diy-view-column border-diy"></div>
    <div class="diy-view-column w-40">
      <p class="selection-info p-1">
        Selected populations are listed here. Click on to unselect.
      </p>
      <div class="d-block p-1" v-if="targetSelectedList.length > 0">
          <button class="lab-btn lab-btn-remove-all" @click="removeAllSelected">Remove All</button>
      </div>
      <ul class="diy-targets-menu cs-scroll-style">
        <li v-for="item in targetSelectedList" class="select" :key="item.Id" @click="moveToUnselected(item)">
         {{ replaceName(item.name) }}
        </li>
      </ul>
    </div>
  </div>
</template>
    
    
<script>
import DropdownOneIcon from '@/layouts/icons/DropdownOneIcon.vue';

export default {
  components: {
    DropdownOneIcon,
  },
  data() {
    return {
      isDropdownOpen: false,
      searchQuery: '',
      noSelect: false,
      openedGroupList: [],
      targetTypes: [
        { id: 'MY_KITS', name: 'My Kits' },
        { id: 'ANCIENT_POP', name: 'Ancient Populations' },
        { id: 'MODERN_POP', name: 'Modern Populations' },
      ]
    }
  },
  methods: {
    documentClick(e) {
      /* eslint-disable */
      try {
        const el = this.$refs.dropdownType;
        const target = e.target;
        if (el !== target && !el.contains(target)) {
          this.isDropdownOpen = false;
        }
      } catch (error) {}
    },
    forceFalse(event) {
      event.target.checked = false;
    },
    replaceName(name) {
      return name.replaceAll('*', ' ').replaceAll(':', ': ').replaceAll('_scaled', '');
    },

    toggleGroup(item) {
      const findOpened = this.openedGroupList.find(x => x === item.groupName);
      if (findOpened) {
        const index = this.openedGroupList.indexOf(findOpened);

        if (index > -1) {
          this.openedGroupList.splice(index, 1);
        }
      } else {
        this.openedGroupList.push(item.groupName);
      }
      this.onScroll(`target-unselected-list`);
    },

    isOpened(item) {
      this.onScroll(`target-unselected-list`);
      return this.openedGroupList.find(x => x === item.groupName);
    },

    moveToSelected(item) {
      this.noSelect = false;
      const index = this.$store.state.diySourceData.targetUnSelectedList.indexOf(item);
      if (index > -1) {
        this.$store.state.diySourceData.targetUnSelectedList.splice(index, 1);
        // this.targetSelectedList.push(item);
        this.targetSelectedList.unshift(...[item]);
      } else {
        console.warn("Item not found in source list");
      }
    },

    selectAll(item) {
      for(let i=0; i < item.list.length; i++) {
        this.moveToSelected(item.list[i]);
      }
      this.noSelect = false;
    },

    moveToUnselected(item) {
      const index = this.targetSelectedList.indexOf(item);
      if (index > -1) {
        this.targetSelectedList.splice(index, 1);
        this.$store.state.diySourceData.targetUnSelectedList.push(item);
      } else {
        console.warn("Item not found in source list");
      }
    },

    removeAllSelected() {
      for(let i=0; i < this.targetSelectedList.length; i++) {
        this.$store.state.diySourceData.targetUnSelectedList.push(this.targetSelectedList[i]);
      }
      this.$store.state.diySourceData.targetSelectedList = [];
    },

    onScroll(name) {
      const element = document.getElementById(`${name}`);
      if (element) {
        this.$store.state.utils.checkIfScrolledToBottom(element);
      }
    },
  },
  computed: {
    filteredTargetType() {
      const type = this.$store.state.diySourceData.filteredTargetType;
      return this.targetTypes.find(x => x.id === type).name;
    },
    targetUnSelectedList() {
      const dataList = this.$store.state.diySourceData.targetUnSelectedList.filter(x => x.type === this.$store.state.diySourceData.filteredTargetType && x.name.toLowerCase().includes(this.searchQuery.toLowerCase())).sort((a, b) => {
        let comp = 0;
        if (a.name < b.name) {
          comp = -1;
        } else if (a.name > b.name) {
          comp = 1;
        }
        return comp;
      });
      return dataList.reduce((acc, item) => {
            const group = acc.find(g => g.groupName === item.groupName);
            if (group) {
              group.list.push(item);
            } else {
              acc.push({
                groupName: item.groupName,
                list: [item]
              });
            }
            return acc;
          }, [])
    },

    targetSelectedList() {
      return this.$store.state.diySourceData.targetSelectedList;
    },
  },
  mounted() {
    this.onScroll(`target-unselected-list`);
  },
  beforeMount() {
    document.addEventListener("click", this.documentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.documentClick);
  },
}
</script>

<style lang="scss" scoped>
.lab-dropdown-content ul {
  margin-bottom:1rem!important;
}

</style>