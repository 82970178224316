<template>
  <div>
    <div class="table-container" v-if="$store.state.appConfig.windowWidth >= 1079">
      <div class="sample-table-list" v-if="$store.state.modernDatabase.sampleDetail === null">
        <table class="sample-table">
          <thead>
            <tr>
              <th scope="col" class="pop-col">Population</th>
              <th scope="col" class="detail-col">Details</th>
            </tr>
          </thead>
          <tbody class="cs-scroll-style">
            <template v-if="popList && !$store.getters.getLoader">
              <tr v-for="item in popList" :key="item.id">
              <td class="pop-col">{{item.title}}</td>
              <td class="detail-col">
                <span class="table-action table-action-edit lab-p-8" @click="openDetail(item)">
                  <feather-icon
                    icon="ArrowRightIcon"
                    class="cursor-pointer lab-text-steel"
                    size="25"
                  />
                </span>
              </td>
              </tr>
            </template>
            <SpinnerContainer v-else />
          </tbody>
        </table>
      </div>
      <ModernSampleDetail v-else/>
    </div>

    <div v-else>
      <div class="encyclopedia-mobile">
        <div v-for="(item, index) in popList" :key="index">
          <div class="encyclopedia-mobile-item dashboard-card">
            <div>
              <div class="mobile-item-top">
                <div class="item-info left-info">
                  <h5>Population</h5>
                  <h6>{{item.title}}</h6>
                </div>
              </div>
            </div>
            <div class="mobile-encyclopedia-icon">
              <span class="cursor-pointer" @click="openDetailMobile(item, index)"> 
                <circle-plus-icon v-show="!item.isOpenDetail" className="encyclopedia"/>
                <circle-x-icon v-show="item.isOpenDetail" className="encyclopedia"/>
              </span>
            </div>
          </div>

          <ModernSampleDetailMobile v-if="$store.state.modernDatabase.sampleDetail && item.isOpenDetail" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CirclePlusIcon from '@/layouts/icons/CirclePlusIcon.vue';
import CircleXIcon from '@/layouts/icons/CircleXIcon.vue';
import ModernSampleDetail from './ModernSampleDetail.vue';
import ModernSampleDetailMobile from './ModernSampleDetailMobile.vue';

export default {
  components: {
    CirclePlusIcon,
    CircleXIcon,
    ModernSampleDetail,
    ModernSampleDetailMobile,
  },
  data() {
    return {
    }
  },
  methods: {
    openDetail(item) {
      this.$store.state.modernDatabase.sampleDetail = item;
    },
    openDetailMobile(item, index) {
      item.isOpenDetail = !item.isOpenDetail;
      if (item.isOpenDetail) {
        this.$store.state.modernDatabase.sampleDetail = item;
      } else {
        this.$store.state.modernDatabase.sampleDetail = null;
      }

      this.popList.forEach((element, indexItem) => {
        if (indexItem !== index) {
          element.isOpenDetail = false;
        }
      });
    },
  },
  computed: {
    popList() {
      return this.$store.getters['modernDatabase/getModernDatabaseList'];
    },
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.encyclopedia-mobile {
  padding: 1rem;
}
.table-container {
  .pop-col
  {
    width: 210px;
  }

  .detail-col
  {
    width: 600px;
  }
  
  tbody {
    max-height: 50vh!important;
  }
}
.dark-layout {
  .table-action {
    background:#13181c !important;
    color:#7a7a7a!important;
  }
}
</style>
