import store from '@/store';
import PcaService from '@/common/custom-service/dna-source/PcaService';

const PcaPlotCalculateWatcher = {
  isRunning: false,
  unwatchers: [],
  
  init() {
    this.watchSelectedResultState();
    this.watchRegionState();
    this.watchConvexHullState();
    this.watchSkinState();

    this.runPcaPlotResult();
  },

  watchSelectedResultState() {
    const unwatch = store.watch(
      (state) => state.daResult.selectedResult,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runPcaPlotResult();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchRegionState() {
    const unwatch = store.watch(
      (state) => state.pcaRegion.selectedRegion,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runPcaPlotResult();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchConvexHullState() {
    const unwatch = store.watch(
      (state) => state.pcaSourceData.isEnableConvexHull,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runPcaPlotResult();
        }
      }
    );

    this.unwatchers.push(unwatch);
  },

  watchSkinState() {
    const unwatch = store.watch(
      (state) => state.appConfig.layout.skin,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.runPcaPlotResult();
        }
      }
    )
    this.unwatchers.push(unwatch);
  },

  cleanup() {
    this.unwatchers.forEach(unwatch => unwatch());
    this.unwatchers = [];
    
    this.isRunning = false;
  },

  async runPcaPlotResult() {
    try {
      if (!(store.state.daResult.selectedResult.id && store.state.pcaRegion.selectedRegion)) {
        return;
      }
      
      if (this.isRunning) {
        return;
      }
      this.isRunning = true;
      
      const sourceDataRequest = {
        regionId: store.state.pcaRegion.selectedRegion.id,
      };
      const sourceDataResult = await store.dispatch('pcaSourceData/fetchPcaSourceData', sourceDataRequest);
      if (!sourceDataResult) {
        this.isRunning = false;
        return;
      }
      const selectedResult = store.state.daResult.selectedResult;
      
      PcaService.sourceObject.isEnableConvexHull = store.state.pcaSourceData.isEnableConvexHull;
      PcaService.sourceObject.selectedTargetTitle = selectedResult.resultTitle.replaceAll(' ', '_');
      
      PcaService.setSkin(store.state.appConfig.layout.skin);
      PcaService.onSetSourceDatas(sourceDataResult.sourceData, selectedResult.targetCoordinate);
      PcaService.runPCA();
      PcaService.plotPCA();
    } catch (error) {
      console.error('Error handling specific state change:', error);
    }

    this.isRunning = false;
  },
};

export default PcaPlotCalculateWatcher;
