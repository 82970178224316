<template>
  <div class="top-header-container" :class="{'top-header-container-remove-radius' : contentPosition === -250}">
    <div class="mobile-header-icon" v-if="$store.state.appConfig.windowWidth < 1079">
      <MobileHeaderIcon />
    </div>
    
    <h4 class="report-title">
      Periodical Breakdown
      
      <span @click="originBreakdownInfoModal.showModal = true" class="open-info-modal-btn">
        <sup class="title-info-button-area">
          <InfoIcon />
        </sup>
      </span>
      
    </h4>
    <hr />
    <p class="description">
      Explore your origins across different populations in history. 
      The breakdown is tailored to <b>{{ $store.state.region.selectedRegion.title }}</b> origins. 
      <!-- <a class="open-select-region" @click="selectRegionModal.showModal = true">Tailor the model</a> by selecting another origin that best represents you. -->
    </p>
    <hr/>
    <PeriodicalDefaultRegion class="mb-1" />
    <PeriodicalPeriods class="mb-1"/>
    <OriginBreakdownInfoModal v-if="originBreakdownInfoModal.showModal" :modalObject="originBreakdownInfoModal"/>
    <SelectRegionModal v-if="selectRegionModal.showModal" :modalObject="selectRegionModal" />
  </div>
</template>

<script>
import MobileHeaderIcon from '@/layouts/icons/MobileHeaderIcon.vue';
import InfoIcon from '@/layouts/icons/InfoIcon.vue';

import OriginBreakdownInfoModal from './modals/OriginBreakdownInfoModal.vue';
import SelectRegionModal from './modals/SelectRegionModal.vue'
import PeriodicalPeriods from './details/PeriodicalPeriods.vue';
import PeriodicalDefaultRegion from './details/PeriodicalDefaultRegion.vue';

export default {
  components: {
    MobileHeaderIcon,
    InfoIcon,
    OriginBreakdownInfoModal,
    SelectRegionModal,
    PeriodicalPeriods,
    PeriodicalDefaultRegion
  },
  props: {
    contentPosition: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      originBreakdownInfoModal: {
        showModal: false,
      },
      selectRegionModal: {
        showModal: false,
      },
    };
  },
  methods: {
    back() {
      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-header-container {
  padding: 28px;
  background-color: var(--bg-card-color);
}

.top-header-container-remove-radius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.open-select-region {
  color: var(--lab-main-color);
  cursor: pointer;
}

@media screen and (max-width: 1079px) {
  .top-header-container {
    border-top-left-radius: 20px!important;
    border-top-right-radius: 20px!important;
  }
}
</style>