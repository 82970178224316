/* eslint-disable */
import ApiService from '@/common/api.service';

const getDefaultState = () => {
  return {
    selectedTab: 1,
    savedSourceDatas: {},
    souceDataList: {},

    filteredTargetType: 'ANCIENT_POP',
    targetUnSelectedList: [],
    targetSelectedList: [],

    calculatedResults: [],
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
}

const actions = {
  async fetchDiySourceData(context, dataVersion) {
    try {
      const saveDetail = context.state.savedSourceDatas[dataVersion]
      if (saveDetail) {
        return saveDetail;
      } else {
        const response = await ApiService.get('data/DiySourceData', { dataVersion }, this);
        if (response.statusCode === 200) {
          context.state.savedSourceDatas[dataVersion] = response.result;
          return response.result;
        } else {
          throw error;
        }
      }
    } catch (error) {
      throw error;
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
