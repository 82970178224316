<template>
  <div class="sample-database-detail dashboard-card">
    <div class="sample-database-detail-header">
      <BackButton :clickMethod="back" class="mb-3"/>
    </div>
    <div class="sample-database-detail-body cs-scroll-style">
      <b-row>
        <b-col cols="12" md="3" lg="3">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'ID' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.sampleID}}
              </div>
            </div>

            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Date' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.date}}
              </div>
            </div>

            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Dating Method' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.datingMethod}}
              </div>
            </div>

            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Population' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.name}}
              </div>
            </div>

            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Location' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.location}}
              </div>
            </div>

            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Study' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.study}}
              </div>
            </div>

            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Doi' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.doi}}
              </div>
            </div>

            <!-- <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Research' }}</h5>
              </div>
              <hr />
              <div class="desc">
                <a :href="popData.researchLink" class="researchlink" target="_blank">{{popData.research}}</a>
              </div>
            </div> -->
        </b-col>

        <b-col cols="12" md="5" lg="5">
          <div class="title-header">
            <h5>{{ 'Genetic Profile' }}</h5>
          </div>
          <div class="p-1">
            <h5 v-if="!farmerHgResultList">Unavailable</h5>
            <template v-else >
              <div v-for="itemAncestry in farmerHgResultList.outPuts" :key="itemAncestry.resultsTable" class="farmer-hgs mb-1">
                <span class="label">{{ itemAncestry.resultsTable.replaceAll('*', ' ') }} :</span>
                <span class="percent"><b>{{ itemAncestry.currentResult.toFixed(1) }}%</b></span>
              </div>
            </template>
          </div>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <div class="title-header">
            <h5>{{ 'Genetically Closest Populations' }}</h5>
          </div>
          <div class="distance-container">
            <table class="table table-bordered distance-table">
              <thead>
                  <tr>
                    <th scope="col">Distance</th>
                    <th scope="col">Modern population</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="distanceItem in distanceList" :key="distanceItem.title">
                    <td><span class="td-size" :style="{ color: distanceItem.color }"> {{ distanceItem.distance }}</span></td>
                    <td>{{distanceItem.title.replaceAll('*', ' ')}}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
      <hr class="ancient-hr"/>
      <b-row class="mt-3">
          <b-col cols="12" md="4" lg="4">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'Y-DNA' }}</h5>
              </div>
              <hr />
              <div class="desc">
                <span v-if="popData.yDNAISOGG.length > 1 && popData.yDNATM.length > 1">{{popData.yDNAISOGG}} ({{popData.yDNATM}})</span>
                <span v-else-if="popData.yDNAISOGG.length > 1">{{popData.yDNAISOGG}}</span>
                <span v-else-if="popData.yDNATM.length > 1">{{popData.yDNATM}}</span>
              </div>
            </div>
          </b-col>

          <b-col cols="12" md="4" lg="4">
            <div class="pop-desc">
              <div class="title-header">
                <h5>{{ 'mtDNA' }}</h5>
              </div>
              <hr />
              <div class="desc">
                {{popData.mtDNA}}
              </div>
            </div>
          </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';

export default {
  components: {
    BackButton,
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    back() {
      this.$store.state.ancientIndividualDatabase.sampleDetail = null
    },
  },
  computed: {
    popData() {
      return this.$store.state.ancientIndividualDatabase.sampleDetail;
    },
    samples() {
      return this.popData.samples ? this.popData.samples.split(',') : [];
    },
    mtDna() {
      return this.popData.mtDna ? this.popData.mtDna.split(',') : [];
    },
    yDna() {
      return this.popData.yDna ? this.popData.yDna.split(',') : [];
    },

    distanceList() {
      return this.$store.state.sampleDatabase.calculatedDistance;
    },
    farmerHgResultList() {
      return this.$store.state.sampleDatabase.calculatedFarmerHgResult;
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
</style>
