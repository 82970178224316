<template>
  <button class="btn-back" @click="clickMethod"> 
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1569 1.77286C11.3997 1.60011 11.7304 1.63949 11.9312 1.87871L11.9854 1.95377C12.1466 2.21416 12.1098 2.5687 11.8867 2.78401L5.057 9.37484H16.668C17.0131 9.37484 17.293 9.65466 17.293 9.99984C17.293 10.3163 17.0578 10.5777 16.7528 10.6191L16.668 10.6248H5.057L11.8867 17.2157L11.9481 17.2842C12.1338 17.525 12.132 17.8817 11.9312 18.121C11.7103 18.3841 11.3323 18.4054 11.0869 18.1686L3.11587 10.4763L3.0554 10.4089C2.85383 10.149 2.87399 9.75679 3.11587 9.52337L11.0869 1.83106L11.1569 1.77286Z"/>
    </svg>
    Back
  </button>
</template>

<script>
export default {
  props: {
    clickMethod: {
      type: Function,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-back {
  display: flex; /* Enables Flexbox layout */
  gap: 8px; /* Adds space between the icon and text */
  padding: 4px 8px; /* Adds some padding for better click area */
  border: 0; /* Maintains no border */
  background-color: transparent; /* Maintains transparent background */
  color: var(--black-70); /* Maintains text color */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

// .btn-back:hover {
//   border-radius: 5px;
//   background-color: rgba(0, 0, 0, 0.05); /* Subtle hover effect */
// }

.btn-back svg {
  width: 20px;
  height: 20px;
  fill: var(--back-button-color); /* Ensures SVG color matches text color */
}

</style>
