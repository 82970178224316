<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="origin-breakdown-info-modal"
      hide-footer
      hide-header
      scrollable
      @hidden="hiddenModal"
      size="md"
    >
     <div class="modal-close-container">
      <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
     </div>
     <div class="info-modal-container">
        <div class="info-modal-body" :class="{'active' : selectedInfoNumber === 1}">
          <div class="info-modal-header">
            <h5>About the Hunter-Gatherer and Farmer Breakdown</h5>
          </div>
          <p class="description">
            As ancient populations transitioned from hunting and gathering to farming, distinct genetic and cultural lineages emerged. 
            As a result, many people today carry traces of both hunter-gatherer and early farmer DNA. When viewing your results, 
            the higher the percentage, the more closely your DNA resembles the genetic markers from each group.
          </p>
        </div>

        <div class="info-modal-body" :class="{'active' : selectedInfoNumber === 2}">
          <div class="info-modal-header">
            <h5>About the Hunter-Gatherer and Farmer Breakdown</h5>
          </div>
          <div class="third-info-content">
            <div class="third-info-content__reminder-box">
              <h5>Hunter-gatherers</h5> 
              <p class="description">
                Ancient hunter-gatherers played a key role in early human migration as they moved in search of food, favorable climates, and natural resources. 
                They gradually migrated from Africa to Eurasia, Australia, and the Americas over tens of thousands of years, often influenced by climate shifts. 
                As they migrated, hunter-gatherers adapted to diverse landscapes and developed new survival strategies, 
                laying the groundwork for genetic diversity and cultural development.
              </p>
            </div>
          </div>
        </div>

        <div class="info-modal-body" :class="{'active' : selectedInfoNumber === 3}">
          <div class="info-modal-header">
            <h5>About the Hunter-Gatherer and Farmer Breakdown</h5>
          </div>
          <div class="third-info-content">
            <div class="third-info-content__reminder-box">
              <h5>Farmers</h5> 
              <p class="description">
                The Neolithic Revolution, also known as the first Agricultural Revolution, saw human societies transition to an agricultural way of life 
                by cultivating crops and domesticating animals. 
                This created a more stable food supply, enabling the establishment of larger population settlements and more complex societies.
              </p>
            </div>
          </div>
        </div>

        <div class="info-modal-body" :class="{'active' : selectedInfoNumber === 4}">
          <div class="info-modal-header">
            <h5>Hunter-gatherer and farmer migration</h5>
          </div>
          <div class="third-info-content">
            <div class="third-info-content__reminder-box">
              <h5>Hunter-gatherer and farmer migration</h5> 
              <p class="description">
                As farming techniques spread, so did the migration of early farming communities, who brought seeds, tools, and knowledge with them. 
                These migrations helped transform Europe, Asia, and Africa, often intermingling with local hunter-gatherer groups, and blending their 
                distinct genetic makeup.
              </p>
            </div>
          </div>
        </div>
        
     </div>
     <div class="model-info-bottom">
      <div>
        <button class="info-modal-btn-prev" @click="prevContainer()" v-if="selectedInfoNumber > 1">Previous</button>
        <div v-else style="width: 60px;"> </div>
      </div>
      <div class="dot-container">
        <div class="dot dot-1" :class="{'active' : selectedInfoNumber === 1}" @click="selectedInfoNumber = 1"></div>
        <div class="dot dot-2" :class="{'active' : selectedInfoNumber === 2}" @click="selectedInfoNumber = 2"></div>
        <div class="dot dot-3" :class="{'active' : selectedInfoNumber === 3}" @click="selectedInfoNumber = 3"></div>
        <div class="dot dot-3" :class="{'active' : selectedInfoNumber === 4}" @click="selectedInfoNumber = 4"></div>
      </div>
      <button class="lab-btn info-modal-btn" @click="nextContainer()">{{ selectedInfoNumber === 4 ? 'Got it' : 'Next'}}</button>
     </div>
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';

export default {
  components: {
    CloseModalIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      selectedInfoNumber: 1,
    }
  },
  methods: {
    initialModal() {
      this.$refs['origin-breakdown-info-modal'].show();
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['origin-breakdown-info-modal'].hide();
    },

    nextContainer() {
      if (this.selectedInfoNumber === 4) {
        this.selectedInfoNumber = 1;
        this.hiddenModal();
      } else {
        this.selectedInfoNumber = this.selectedInfoNumber + 1;
      }
    },
    prevContainer() {
      this.selectedInfoNumber = this.selectedInfoNumber - 1;
    },
  },
  computed: {
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.scale-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  .scale-bar {
    width: 100%;
    height: 18px;
    border-radius: 10px;
    background: linear-gradient(to right, #5BD67E, #FFCE55, #FF7C55, #EF4232);
    position: relative;
  }

  .labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }

  .label {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--black-40);
  }
}

.info-fit-container {
  width: 100%;
  max-width: 500px;
  background-color: var(--bg-note-color);
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
  font-family: Arial, sans-serif;

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &-color-box {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin-right: 15px;
  }

  &-text-content {
    color: #333;

    strong {
      font-size: 0.95rem;
      color: var(--black-70);
    }

    p {
      font-size: 0.93rem;
      line-height: 1.35rem;
      font-weight: 400;
      color: var(--black-70);
      margin: 4px 0 0;
    }
  }

  /* Color variations */
  &-very-close { background-color: #5BD67E; }
  &-close { background-color: #FFCE55; }
  &-moderate { background-color: #FF7C55; }
  &-distant { background-color: #EF4232; }
}

.third-info-content {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    color: #333!important;
    h5 {
      color: #333!important;
    }
    ul {
      padding-left: 20px;
      list-style-type: disc;

      li {
        margin: 10px 0;
        font-size: 0.95rem;
        color: var(--black-70);
      }
    }

    &__reminder-box {
      background-color: var(--bg-note-color);
      padding: 15px;
      border-radius: 8px;
      margin-top: 20px;

      strong {
        font-weight: bold;
        font-size: 0.95rem;
        color: var(--black-70);
      }
    }
  }
</style>
