
<template>
<svg width="40" height="4" viewBox="0 0 40 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="4" rx="2" fill="black" fill-opacity="0.2"/>
</svg>
</template>
  
<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
svg {
  height: 18px;
}  
</style>
    
    