<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="genetic-fit-info-modal"
      hide-footer
      hide-header
      scrollable
      @hidden="hiddenModal"
      size="md"
    >
     <div class="modal-close-container">
      <button class="close-modal-btn" @click="hiddenModal()"><CloseModalIcon /></button>
     </div>
     <div class="info-modal-container">
        <div class="info-modal-body active">
          <div class="info-modal-header">
            <h5>How to interoperate Your genetic fit score</h5>
          </div>
          <p class="description">
            The Genetic Fit score indicates how closely the Ancient Origins Breakdown matches your DNA.
          </p>
          <div class="scale-container">
            <div class="scale-bar"></div>
            <div class="labels">
              <div class="label">Very close</div>
              <div class="label">Close</div>
              <div class="label">Moderate</div>
              <div class="label">Distant</div>
            </div>
          </div>
          <div class="info-fit-container">
            <div class="info-fit-container-item">
              <div class="info-fit-container-color-box info-fit-container-very-close"></div>
              <div class="info-fit-container-text-content">
                <strong>Very close: Less than 3.0</strong>
                <p>Very close match with the reference populations</p>
              </div>
            </div>
            <div class="info-fit-container-item">
              <div class="info-fit-container-color-box info-fit-container-close"></div>
              <div class="info-fit-container-text-content">
                <strong>Close: 3.0 - 4.0</strong>
                <p>Close match with the reference populations</p>
              </div>
            </div>
            <div class="info-fit-container-item">
              <div class="info-fit-container-color-box info-fit-container-moderate"></div>
              <div class="info-fit-container-text-content">
                <strong>Moderate: 5.0 - 6.0</strong>
                <p>Moderately match with some uncertainty</p>
              </div>
            </div>
            <div class="info-fit-container-item">
              <div class="info-fit-container-color-box info-fit-container-distant"></div>
              <div class="info-fit-container-text-content">
                <strong>Distant: Above 6.0</strong>
                <p>Distant match</p>
              </div>
            </div>
          </div>
        </div>
     </div>
     <div class="model-info-bottom">
      <div></div>
      <button class="lab-btn info-modal-btn" @click="hiddenModal()">Got it</button>
     </div>
    </b-modal>
  </div>
</template>

<script>
import CloseModalIcon from '@/layouts/icons/CloseModalIcon.vue';

export default {
  components: {
    CloseModalIcon,
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
    }
  },
  methods: {
    initialModal() {
      this.$refs['genetic-fit-info-modal'].show();
    },
    hiddenModal() {
      /* eslint-disable */
      this.modalObject.showModal = false;
      this.$refs['genetic-fit-info-modal'].hide()
    },
  },
  computed: {
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.scale-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px auto;
  .scale-bar {
    width: 100%;
    height: 18px;
    border-radius: 10px;
    background: linear-gradient(to right, #5BD67E, #FFCE55, #FF7C55, #EF4232);
    position: relative;
  }

  .labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
  }

  .label {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--black-40);
  }
}

.info-fit-container {
  width: 100%;
  max-width: 500px;
  background-color: var(--lab-bg-note-color);
  padding: 20px;
  border-radius: 8px;
  margin: 20px auto;
  font-family: Arial, sans-serif;

  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &-color-box {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin-right: 15px;
  }

  &-text-content {
    color: #333;

    strong {
      font-size: 0.95rem;
      color: var(--black-70);
    }

    p {
      font-size: 0.93rem;
      line-height: 1.35rem;
      font-weight: 400;
      color: var(--black-70);
      margin: 4px 0 0;
    }
  }

  /* Color variations */
  &-very-close { background-color: #5BD67E; }
  &-close { background-color: #FFCE55; }
  &-moderate { background-color: #FF7C55; }
  &-distant { background-color: #EF4232; }
}
</style>
