<template>
  <div class="pca-container">
    <div class="pca-plots" v-if="$store.state.appConfig.windowWidth >= 1079">
      <div class="result-container">
        <PcaPlotsSidebar />
      </div>
      <div class="map-container cs-scroll-style">
        <PcaPlotView />
      </div>
    </div>

    <div class="pca-plots-mobile" v-else>
      <div class="result-container mobile-header">
        <PcaPlotsSidebar />
      </div>
      <div class="result-container">
        <PcaPlotView class="mt-2"/>
      </div>
    </div>
  </div>
</template>

<script>
import PcaPlotCalculateWatcher from './services/PcaPlotCalculateWatcher'
import PcaPlotsSidebar from './details/PcaPlotsSidebar.vue';
import PcaPlotView from './details/PcaPlotView.vue';

export default {
  components: {
    PcaPlotsSidebar,
    PcaPlotView,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
  async mounted() {
    PcaPlotCalculateWatcher.init();
  },
  beforeDestroy() {
    PcaPlotCalculateWatcher.cleanup();
  },
};
</script>

<style lang="scss" scoped>
.pca-container {
  height: calc(100vh - 230px)!important;
  overflow: hidden;
}

@media screen and (max-width: 1079px) {
  .pca-container {
    height: calc(100vh - 60px) !important;
    overflow: hidden;
    overflow-y: auto;
  }
}

.pca-plots {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .result-container {
    z-index: 1;
    display: inline-block;
    position: relative;
    width: 20%;
  }
  .map-container {
    flex-grow: 1;
    width: 60%;
    height: 100%;
    position: relative;
    overflow: auto;
  }
}

.pca-plots-mobile {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  .mobile-header {
    margin-top: 60px;
  }
  .result-container {
    width: 100% !important;
  }
}
</style>