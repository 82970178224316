import { render, staticRenderFns } from "./AppTopHeader.vue?vue&type=template&id=23b9cf20&scoped=true&"
import script from "./AppTopHeader.vue?vue&type=script&lang=js&"
export * from "./AppTopHeader.vue?vue&type=script&lang=js&"
import style0 from "./AppTopHeader.vue?vue&type=style&index=0&id=23b9cf20&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b9cf20",
  null
  
)

export default component.exports